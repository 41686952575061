// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
    font-size: 0.70rem;
    background-color: #ffffff;
    font-weight: 300;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #1A486D0D;
    --bs-table-accent-bg: none;
}

.table-hover>tbody>tr:hover {
    background-color: #1A486D0D;
    --bs-table-accent-bg: none;
}

/* tbody {
    margin-inline: 10px !important;
} */

thead {
    height: 40px;
    background-color: #9595954E;
    font-weight: 100;
}

thead th {
    font-weight: 300;
}

tbody, td, tfoot, th, thead, tr {
    border-style: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/components/Table.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,0BAA0B;AAC9B;;AAEA;IACI,2BAA2B;IAC3B,0BAA0B;AAC9B;;AAEA;;GAEG;;AAEH;IACI,YAAY;IACZ,2BAA2B;IAC3B,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".table {\n    font-size: 0.70rem;\n    background-color: #ffffff;\n    font-weight: 300;\n}\n\n.table-striped>tbody>tr:nth-of-type(odd) {\n    background-color: #1A486D0D;\n    --bs-table-accent-bg: none;\n}\n\n.table-hover>tbody>tr:hover {\n    background-color: #1A486D0D;\n    --bs-table-accent-bg: none;\n}\n\n/* tbody {\n    margin-inline: 10px !important;\n} */\n\nthead {\n    height: 40px;\n    background-color: #9595954E;\n    font-weight: 100;\n}\n\nthead th {\n    font-weight: 300;\n}\n\ntbody, td, tfoot, th, thead, tr {\n    border-style: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
