import React, { useState, useEffect } from "react";
import "./ReportTransaction.css";
import DatePicker from "react-datepicker";
import Table from "../../../../../components/Table";
import AppData from "../../../../../config/appData.json";
import GetData from "../../../../../api/GetData";
import { useNavigate } from "react-router-dom";

export default function ReportTransaction({
  changeStartDate,
  changeEndDate,
  startDate,
  endDate,
}) {
  const navigate = useNavigate();
  const [history, setHistory] = useState([]);
  const [msg, setMsg] = useState("");

  const columns = React.useMemo(
    () => [
      {
        Header: "Merchant Name",
        accessor: "merchant_name",
      },
      {
        Header: "Merchant Id",
        accessor: "merchant_id",
      },
      {
        Header: "Merchant Acct No",
        accessor: "merchant_account_nr",
      },
      {
        Header: "Card Pan",
        accessor: "stan",
      },
      {
        Header: "Rrn",
        accessor: "rrn",
      },
      {
        Header: "Processor",
        accessor: "processor",
      },
      {
        Header: "Amount",
        accessor: "transaction_amount",
      },
      {
        Header: "Charge",
        accessor: "charge",
      },
    ],
    []
  );

  const handleErrorFunc = (error) => {
    if (error.response.data.message === "Expired Session, Please Login Again") {
      navigate("/signin");
    } else {
      setMsg(error.response.data.message);
    }
  };

  useEffect(() => {
    const fetchTransactionHistory = async () => {
      try {
        const endpoint = AppData.BASE_URL + "settlements";
        const response = await GetData(endpoint);
        setHistory(response);
      } catch (error) {
        handleErrorFunc(error);
      }
    };
    fetchTransactionHistory();
  }, []);

  return (
    <div>
      <div className="report-trans">
        <div className="search-params-area">
          <div className="search-params">
            <div>
              <div className="position-relative">
                <i className="fas fa-search"></i>
                <div className="pe-0">
                  <input
                    type="search"
                    id="form1"
                    className="form-control search ps-5"
                    // onKeyDown={HandleKeyDown}
                    placeholder="Hit enter to complete search"
                  />
                </div>
              </div>
            </div>
            <div className="select-search">
              <label>Processor</label>
              <select>
                <option value="All status">All Processor</option>
              </select>
            </div>
            <div className="date-range">
              <div className="d-flex">
                <div className="start me-3">
                  <label>From</label>
                  <i className="fa-solid fa-calendar-days"></i>
                  <DatePicker
                    selected={startDate}
                    onChange={changeStartDate}
                    dateFormat="yyyy-MM-dd"
                  />
                </div>
                <div className="end">
                  <label>To</label>
                  <i className="fa-solid fa-calendar-days"></i>
                  <DatePicker
                    selected={endDate}
                    onChange={changeEndDate}
                    dateFormat="yyyy-MM-dd"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive table-wrapper">
          {history.data && (
            <Table
              columns={columns}
              data={history.data}
              tableClass="table table-borderless table-striped table-hover fs-7 mb-0"
              enableRowSelection={false}
            />
          )}
        </div>
      </div>
    </div>
  );
}
