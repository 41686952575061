import React, { useState } from "react";
import "./ResetVerification.css";
import Images from "../../assets/images/Images";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AppData from "../../config/appData.json";
import PostData from "../../api/PostData";
import ResetPassword from "./ResetPassword";

export default function ResetVerification() {
  const [changePass, setChangePass] = useState(false);
  const [changePassSuccess, setChangePassSuccess] = useState(false);
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("");

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid Email Address!")
      .required("Enter a Valid Email"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = async (requestData) => {
    try {
      localStorage.setItem("email", requestData.email);
      const endpoint = AppData.VAS_URL + "auth/send-reset-code";
      await PostData(endpoint, requestData);
      setChangePass(true);
      setMsg(null);
    } catch (error) {
      console.log("error", error);
      const errorMessage = error.response?.data?.message || "An error occurred";
      setMsg(errorMessage);
    }
  };

  return (
    <main className="reset-pass">
      <div className="container">
        <div className="reset-header">
          <img src={Images.spoutLogo} alt="spoutpay" />
          <h1>You're Welcome</h1>
          <p>
            We can&apos;t wait for you to unbox the suprise we <br /> have got
            for you
          </p>
        </div>
        <div className="reset-section">
          <div className="home">
            <a href="/">Home</a>
          </div>
          <div
            className={
              !changePassSuccess ? "col-5 reset-img" : "col-4 success-img"
            }
          ></div>
          <div className="col">
            {!changePass ? (
              <div className="reset">
                <form
                  className="form"
                  onSubmit={handleSubmit(handleFormSubmit)}
                >
                  <h1>Forgot Password?</h1>
                  <p>Reset it with your registered email</p>
                  {msg && <small>{msg}</small>}
                  <div className="mb-3 mt-3 text-start">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      className={"form-control error"}
                      type="text"
                      placeholder="Enter username"
                      name="email"
                      {...register("email")}
                    />
                    <small>{errors.email?.message}</small>
                  </div>
                  <button type="submit" className="btn">
                    Confirm Password Reset
                  </button>
                </form>
              </div>
            ) : (
              <ResetPassword
                changePassSuccess={changePassSuccess}
                setChangePassSuccess={setChangePassSuccess}
              />
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
