import React from 'react';
import "./Downloads.css";
import mobile from "../../assets/images/mobile.png";
import download from "../../assets/images/group-img.png"

export default function Downloads() {
  return (
    <div className='download'>
        <div className='row'>
            <div className='col'>
                <h1>The Spoutpay Mobile App<br/> Is Here</h1>
                <p>Download The App Here Now </p>
                <img className="download-image" src={download} alt=""/>
            </div>
            <div className='col'>
                <div className='mobile'>
                <img src={mobile} alt='mobile'/>
                </div>
            </div>
        </div>
    </div>
  )
}
