import React, { useState, useEffect } from "react";
import "./Compliance.css";
import AppData from "../../../config/appData.json";
import GetData from "../../../api/GetData";
import Table from "../../../components/Table";
import RequestHandler from "./RequestHandler";
import GroupedInput from "../../../components/GroupedInput";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import Pagination from "../../../components/Pagination";

export default function Compliance() {
  const [updateList, setUpdateList] = useState([]);
  const [showUpdateList, setShowUpdateList] = useState(true);
  const [updatedData, setUpdatedData] = useState({});
  const [filterText, setFilterText] = useState("");
  const [filterWalletID, setFilterWalletID] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = AppData.VAS_URL + "controller/get-updated-data";
        const { data } = await GetData(url);
        // console.log("data", data);
        setUpdateList(data);
      } catch (error) {
        console.error("Error occurred");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (filterText || filterWalletID || selectedStatus) {
      const lowerCaseFilterText = filterText.toLowerCase();

      const filtered = updateList.filter((item) => {
        const matchesFirstName =
          (item.firstName &&
            item.firstName.toLowerCase().includes(lowerCaseFilterText)) ||
          (item.userData &&
            item.userData.initialFirstName &&
            item.userData.initialFirstName
              .toLowerCase()
              .includes(lowerCaseFilterText));
        const matchesWalletID =
          item?.userData?.walletId &&
          item?.userData?.walletId.includes(filterWalletID);
        const matchesStatus =
          selectedStatus !== "" ? item.status === selectedStatus : true;

        return matchesFirstName && matchesWalletID && matchesStatus;
      });
      setFilteredData(filtered);
    } else {
      // console.log("filtered", updateList);
      setFilteredData(updateList);
    }
  }, [updateList, filterText, filterWalletID, selectedStatus]);

  const handlePendingRequest = (indexData) => {
    setUpdatedData(indexData);
    setShowUpdateList(false);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const fractionalPart = filteredData.length % itemsPerPage;
  const numberOfPages =
    Math.floor(filteredData.length / itemsPerPage) +
    (fractionalPart > 0 ? 1 : 0);

  // console.log("indexOfLastItem", numberOfPages);

  const columns = [
    {
      Header: "S/N",
      id: "row",
      Cell: (row) => {
        const PageStart = (currentPage - 1) * itemsPerPage + 1;
        return <div>{row.row.index + PageStart}</div>;
      },
    },
    {
      Header: "Name",
      accessor: (fullName) => (
        <>
          {fullName && fullName.firstName
            ? `${fullName.firstName} ${fullName.lastName || ""}`
            : fullName && fullName.userData
            ? `${fullName.userData.initialFirstName} ${fullName.userData.initialLastName}`
            : "KYC Upload"}
        </>
      ),
    },
    {
      Header: "Wallet ID",
      accessor: (user) => (
        <div>{(user && user?.userData?.walletId) || "N/A"}</div>
      ),
    },
    {
      Header: "Date & Time",
      accessor: (date) => (
        <div>{new Date(date.createdAt).toLocaleString()}</div>
      ),
    },
    {
      Header: "Reason",
      accessor: (fullName) => (
        <>{fullName && fullName.firstName ? "KYC Update" : "KYC Doc Upload"}</>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (tabinstance) => {
        const { row: index } = tabinstance;
        const { status } = index.original;

        const statusClass =
          status === "pending"
            ? "pending-btn"
            : status === "failed"
            ? "declined-status"
            : "approved-status";

        return (
          <button
            className={statusClass}
            type="button"
            onClick={() => handlePendingRequest(index.original)}
            disabled={status !== "pending"}
          >
            {status}
          </button>
        );
      },
    },
  ];

  return (
    <div className="col compliance">
      {showUpdateList ? (
        <>
          <div className="row mb-3">
            <div className="col-1">
              <CustomSelect
                heading="Limit"
                selectedVal={itemsPerPage}
                setSelectedValue={setItemsPerPage}
                items={[
                  { name: "50" },
                  { name: "100" },
                  { name: "200" },
                  { name: "500" },
                  { name: "1000" },
                  { name: "2000" },
                  { name: "5000" },
                ]}
              />
            </div>
            <div className="col-2">
              <div className="grouped-input">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={filterText}
                  placeholder="Search by First Name"
                  onChange={(e) => setFilterText(e.target.value)}
                />
              </div>
            </div>
            <div className="col-2">
              <div className="grouped-input">
                <label htmlFor="walletId">Wallet ID</label>
                <input
                  type="text"
                  className="form-control"
                  value={filterWalletID}
                  placeholder="Search by Wallet ID"
                  onChange={(e) => setFilterWalletID(e.target.value)}
                />
              </div>
            </div>
            <div className="col-2">
              <CustomSelect
                heading="Select Status"
                selectedVal={selectedStatus ? selectedStatus : "Select Status"}
                setSelectedValue={setSelectedStatus}
                items={[
                  { name: "successful" },
                  { name: "pending" },
                  { name: "failed" },
                ]}
                defaultOption={{ label: "Select Status", value: "" }}
              />
            </div>
          </div>
          {currentItems?.length > 0 ? (
            <Table
              columns={columns}
              data={currentItems}
              tableClass="table table-borderless table-striped mb-0"
              enableRowSelection={false}
            />
          ) : (
            <p className="no-record">No record found</p>
          )}
          {numberOfPages > 1 && (
            <Pagination
              prevPage={currentPage - 1}
              nextPage={currentPage + 1}
              totalPages={numberOfPages}
              hasNextPage={currentPage < numberOfPages}
              hasPrevPage={currentPage > 1}
              setPageNum={setCurrentPage}
            />
          )}
        </>
      ) : (
        <RequestHandler
          setShowUpdateList={setShowUpdateList}
          updatedData={updatedData}
        />
      )}
    </div>
  );
}
