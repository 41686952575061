import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
// Other imports...

const CardSchemePerformance = ({ cardScheme, imageURL }) => {
  return (
    <div className="card-scheme">
      <h6 className="heading">Card Scheme Performance</h6>
      <div className="row">
        {cardScheme.cards.map((card, index) => (
          <div className="col-4" key={index}>
            <div
              className={
                card.card === "VISA"
                  ? "performance visa"
                  : card.card === "VERVE"
                  ? "performance verve"
                  : "performance mastercard"
              }
            >
              <img src={imageURL[card.card]} alt="card-type" />
              <div className="data">
                <div>
                  <div>
                    <h6>Total</h6>
                    <p className="total-value">
                      <span>₦</span>
                      {card.totalAmount.toLocaleString()}
                    </p>
                  </div>
                  <div className="performance-status">
                    <div className="success-value">
                      <h6>Volume</h6>
                      <p>{card.totalVolume}</p>
                    </div>
                  </div>
                </div>
                <div className="circle-progress">
                  <CircularProgressbar
                    value={card.percent}
                    text={`${Math.ceil(card.percent)}%`}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardSchemePerformance;
