import React, { useState } from "react";
import AppData from "../../config/appData.json";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import HandlePostPayment from "../../api/HandlePostPayment";
import LoadingSpinner from "../../components/spinner/LoadingSpinner";
import PinValidationModal from "../modal/PinValidationModal";
import imageURL from "../../imageUrls";

export default function Electricity({ electricity }) {
  const [transactionPin, setTransactionPin] = useState("");
  const [toggleState, setToggleState] = useState(0);
  const [showAccountDetails, setShowAccountDetails] = useState(false);
  const [showPinModal, setShowPinModal] = useState(false);
  const [accountType, setAccountType] = useState("prepaid");
  const [electricityService, setElectricityService] = useState("ekedc");
  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("");
  const [responseData, setResponseData] = useState({});
  const channel = "web";
  const uniqueId = uuidv4();
  const paymentMethod = "cash";

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const schema = yup.object().shape({
    service: yup.string().required(),
    account: yup.string().required("Enter a valid Meter Number!"),
    amount: yup.string().required("Enter a valid amount!"),
    accountType: yup.string().required().default(accountType),
    channel: yup.string().required().default(channel),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid!")
      .required("Phone number is required!"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleToggle = (index) => {
    if (index === 0) {
      setAccountType("prepaid");
      setShowAccountDetails(false);
    } else {
      setAccountType("postpaid");
      setShowAccountDetails(false);
    }
    setToggleState(index);
  };

  const handleConfirmed = (e) => {
    e.preventDefault();
    setShowPinModal(true);
  };

  const handleDataConfirmation = async (requestData) => {
    try {
      setPhone(requestData.phoneNumber);
      const url = AppData.VAS_URL + "vas/electricity/validation";
      const { data } = await HandlePostPayment(requestData, setIsLoading, url);
      if (data.responseCode === "00") {
        setResponseData(data);
      } else {
        setMsg(data.message);
      }
      setShowAccountDetails(true);
    } catch (error) {
      console.log("error", error);
    }
  };

  const { response, transactionId } = responseData;

  const info = `Your Token Is Successfully Delivered To ${response?.name}`;
  const requestUrl = AppData.VAS_URL + "vas/electricity/payment";
  const requestData = {
    phoneNumber: phone,
    transactionId: transactionId,
    uniqueId: uniqueId,
    paymentMethod: paymentMethod,
    pin: transactionPin,
  };

  const handleSelectChange = (e) => {
    setValue("service", e.target.value, {
      shouldValidate: true,
    });
    setElectricityService(e.target.value);
  };

  return (
    <div className="services">
      <div className="service-heading">Buy Electricity</div>
      <div className="row mt-3">
        <div className="col-2">
          <div className="service-type">
            <h6>Choose a meter type</h6>
            <ul>
              <li
                onClick={() => handleToggle(0)}
                className={toggleState === 0 ? "active-service" : ""}
              >
                Prepaid
              </li>
              <li
                onClick={() => handleToggle(1)}
                className={toggleState === 1 ? "active-service" : ""}
              >
                Postpaid
              </li>
            </ul>
          </div>
        </div>
        <div className="col-4">
          <div
            className={
              !showAccountDetails ? "service-form" : "service-form disabled"
            }
          >
            <h6>Complete Field To Buy Electricity</h6>
            {msg && <small>{msg}</small>}
            {toggleState === 0 && (
              <form onSubmit={handleSubmit(handleDataConfirmation)}>
                <div className="grouped-input default">
                  <label>Select Disco</label>
                  <select
                    className="form-control"
                    {...register("service")}
                    onChange={handleSelectChange}
                  >
                    {electricity &&
                      electricity.map((product, index) => (
                        <option key={index} value={product}>
                          {product}
                        </option>
                      ))}
                  </select>
                  <div className="select-icon">
                    <img
                      className="service-logo"
                      src={imageURL[electricityService]}
                      alt="service"
                    />
                    <div>
                      <i className="fa-solid fa-angle-down"></i>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    errors.account
                      ? "grouped-input error"
                      : "grouped-input default"
                  }
                >
                  <label>Beneficiary Meter Number</label>
                  <input
                    className="form-control"
                    placeholder="00000000000000"
                    {...register("account")}
                  />
                  <small>{errors.account?.message}</small>
                </div>
                <div
                  className={
                    errors.amount
                      ? "grouped-input error"
                      : "grouped-input default"
                  }
                >
                  <label>Input Amount</label>
                  <input
                    className="form-control"
                    placeholder="0.00"
                    {...register("amount")}
                  />
                  <small>{errors.amount?.message}</small>
                </div>
                <div
                  className={
                    errors.phoneNumber
                      ? "grouped-input error"
                      : "grouped-input default"
                  }
                >
                  <label>Phone Number</label>
                  <input
                    className="form-control"
                    placeholder="08000000000"
                    {...register("phoneNumber")}
                  />
                  <small>{errors.phoneNumber?.message}</small>
                </div>
                <div className="continue">
                  <button type="submit" className="btn">
                    Continue
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
        <div className="col">
          <div className="service-confirmation">
            <h6>Account Holders Details</h6>
            {isLoading && <LoadingSpinner />}{" "}
            {showAccountDetails && (
              <div>
                <table>
                  <tbody>
                    <tr>
                      <th>Meter Name</th>
                      <td>{response.name}</td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>{response.address}</td>
                    </tr>
                    <tr>
                      <th>Unit Arrears</th>
                      <td>
                        {response.arrears === null ? "0.00" : response.arrears}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="d-flex mt-4">
                  <div className="cancel">
                    <button
                      type="button"
                      className="btn"
                      onClick={() => setShowAccountDetails(false)}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="continue">
                    <button
                      type="button"
                      className="btn"
                      onClick={handleConfirmed}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showPinModal && (
        <PinValidationModal
          setShowModal={setShowPinModal}
          transactionPin={transactionPin}
          setTransactionPin={setTransactionPin}
          setMsg={setMsg}
          requestData={requestData}
          url={requestUrl}
          info={info}
        />
      )}
    </div>
  );
}
