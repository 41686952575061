import { useEffect, useState } from "react";
import "./Dashboard.css";
import Images from "../../assets/images/Images";
import ReportingChart from "../reporting-dashboard/reporting/ReportingChart";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import HalfDoughnut from "../../components/charts/HalfDoughnut";
import AppData from "../../config/appData.json";
import GetData from "../../api/GetData";

const state = {
  labels: ["January", "February"],
  datasets: [
    {
      backgroundColor: ["#F57A14", "#EEECF5"],
      hoverBackgroundColor: ["#F57A14", "#EEECF5"],
      borderColor: "transparent",
      cutout: "60%",
      data: [80, 20],
      circumference: 180,
      rotation: 270,
    },
  ],
};

const abbreviateLetters = (array) => {
  return array?.map((element) => {
    if (typeof element === "string") {
      return element.substring(0, 3);
    }
    return element;
  });
};

export default function Dashboard() {
  const [transactionData, setTransactionData] = useState({});
  const [transAnalysis, setTransAnalysis] = useState({});
  const [aggregatorData, setAggregatorData] = useState([]);

  const { data } = transAnalysis;
  const labels = data?.map((item) => item.month);
  const abbreviatedLabels = abbreviateLetters(labels);

  const billPaymentArray = [];
  const withdrawalArray = [];
  const transferArray = [];

  data?.forEach((entry) => {
    const monthlyData = entry.monthlyData;

    monthlyData.forEach((transaction) => {
      const product = transaction.product;

      if (product === "Bills Payment") {
        billPaymentArray.push(transaction.totalAmount);
      }
      if (product === "withdrawal") {
        withdrawalArray.push(transaction.totalAmount);
      } else {
        transferArray.push(transaction.totalAmount);
      }
    });
  });

  const billPayment = billPaymentArray?.map((item) => item);
  const withdrawal = withdrawalArray?.map((item) => item);
  const transfer = transferArray?.map((item) => item);

  const chartState = {
    labels: abbreviatedLabels,
    datasets: [
      {
        label: "Failed - Withdrawal",
        borderColor: "transparent",
        borderWidth: 2,
        barThickness: 8,
        maxBarThickness: 12,
        stack: "Withdrawal",
        backgroundColor: "#A0A0A0",
        data: [60000, 0, 0, 30000, 1000, 20000, 5000],
      },
      {
        label: "Success - Withdrawal",
        borderColor: "transparent",
        borderRadius: 12,
        borderWidth: 2,
        barThickness: 8,
        maxBarThickness: 12,
        stack: "Withdrawal",
        backgroundColor: "#0F8CFF",
        data: withdrawal,
      },
      {
        label: "Failed - Transfer",
        borderColor: "transparent",
        borderWidth: 2,
        barThickness: 8,
        maxBarThickness: 12,
        stack: "Transfer",
        backgroundColor: "#A0A0A0",
        data: [20000, 90000, 6000, 30000, 0, 40000, 5000],
      },
      {
        label: "Success - Transfer",
        borderColor: "transparent",
        borderRadius: 12,
        borderWidth: 2,
        barThickness: 8,
        maxBarThickness: 12,
        stack: "Transfer",
        backgroundColor: "#00E59E",
        data: transfer,
      },
      {
        label: "Failed - Bill",
        borderColor: "transparent",
        borderWidth: 2,
        barThickness: 8,
        maxBarThickness: 12,
        stack: "Bill",
        backgroundColor: "#A0A0A0",
        data: [0, 40000, 70000, 50000, 3000, 30000, 0],
      },
      {
        label: "Success - Bill",
        borderColor: "transparent",
        borderRadius: 12,
        borderWidth: 2,
        barThickness: 8,
        maxBarThickness: 12,
        stack: "Bill",
        backgroundColor: "#0F0E7D",
        backgroundColorHover: "#3e95cd",
        data: billPayment,
      },
    ],
  };

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const endpoint = AppData.VAS_URL + "auth/dashboard-data";
        const response = await GetData(endpoint);
        setTransactionData(response);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchTransactionData();
  }, []);

  useEffect(() => {
    const fetchTransactionAnalysis = async () => {
      try {
        // let clabels = [];
        const endpoint = AppData.VAS_URL + "auth/transaction-analysis";
        const response = await GetData(endpoint);
        // console.log("dashboard analysis", response);
        setTransAnalysis(response);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchTransactionAnalysis();
  }, []);

  useEffect(() => {
    const fetchAggegatorData = async () => {
      try {
        const endpoint = AppData.VAS_URL + "auth/dashboard-data-aggregator";
        const response = await GetData(endpoint);
        // console.log("dashboard data", response);
        setAggregatorData(response);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchAggegatorData();
  }, []);

  const {
    amountFunded,
    amountUsed,
    commissionWithdrew,
    totalCommission,
    transactionCount,
  } = transactionData;

  const { totalAgent, active, inActive } = aggregatorData;

  return (
    <main className="col overview">
      <h6>Overview</h6>
      <div className="row">
        <div className="col-3">
          <div className="card-summary">
            <div className="summary-icon">
              <img src={Images.allTrans} alt="" />
            </div>
            <div className="data">
              <p className="amount">
                <span>
                  {transactionCount && transactionCount.toLocaleString()}
                </span>
              </p>
              <p>All Transactions</p>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="card-summary">
            <div className="summary-icon">
              <img src={Images.successfulTrans} alt="" />
            </div>
            <div className="data">
              <p className="amount">
                ₦<span>{amountFunded && amountFunded.toLocaleString()}</span>
              </p>
              <p>Total Amount Funded</p>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="card-summary">
            <div className="summary-icon">
              <img src={Images.failedTrans} alt="" />
            </div>
            <div className="data">
              <p className="amount">
                ₦<span>{amountUsed && amountUsed.toLocaleString()}</span>
              </p>
              <p>Total Amount Used</p>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="commission-summary">
            <div>
              <h6>Commission Withdraw</h6>
              <p className="withdrawal">
                ₦ {commissionWithdrew && commissionWithdrew.toLocaleString()}
              </p>
            </div>
            <div className="progress commission-progress">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="70"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "40%" }}
              ></div>
            </div>
            <div>
              <h6>Total Commission</h6>
              <p>₦ {totalCommission && totalCommission.toLocaleString()}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-7 p-4">
          <h6>Monthly Analytics</h6>
          <div className="chart">
            <ReportingChart state={chartState} />
          </div>
        </div>
        <div className="col-5 p-4">
          <h6>Assigned Group</h6>
          <div className="assigned-agent-summary">
            <div className="row">
              <div className="col-4">
                <div className="agent-count">
                  <span>{totalAgent}</span>
                  <br />
                  Agents
                </div>
              </div>
              <div className="col-8">
                <div className="row">
                  <div className="col-7">
                    <div className="circle-progress">
                      <CircularProgressbar
                        value={active}
                        text={`${active}%`}
                        styles={buildStyles({
                          pathColor: "#106745",
                          rotation: 1.0,
                          strokeLinecap: "round",
                          pathTransitionDuration: 0.5,
                          textColor: "#d6d6d6",
                          trailColor: "#d6d6d6",
                        })}
                      />
                      <p>{active} Active</p>
                    </div>
                  </div>
                  <div className="col-5">
                    <div
                      className="circle-progress"
                      style={{ marginTop: "40px" }}
                    >
                      <CircularProgressbar
                        value={inActive}
                        text={`${inActive}%`}
                        styles={buildStyles({
                          pathColor: "#DE0000",
                          rotation: 1.0,
                          strokeLinecap: "round",
                          pathTransitionDuration: 0.5,
                          textColor: "#d6d6d6",
                          trailColor: "#d6d6d6",
                        })}
                      />
                      <p>{inActive} Inactive</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="weekly-summary mt-3">
            <h6>Your Group Performance This Week</h6>
            <div className="d-flex">
              <HalfDoughnut state={state} />
              <div className="p-2">
                <p>Your team performance is 5% better this week</p>
                <button className="view-performance-btn">View Details</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
