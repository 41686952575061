// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-onboarding h3 {
  font-size: 16px;
  color: #2a2f5c;
  font-weight: 600;
  margin-top: 15px;
}

.admin-onboarding h4 {
  font-size: 18px;
  color: #000000;
  margin-bottom: 5px;
}

.admin-onboarding p {
  color: #a0a0a0;
  margin-bottom: 0;
}

.admin-onboarding .action {
  background-color: #f5f5f5;
  padding: 10px;
}

.admin-onboarding .action .cancel-btn {
  background-color: transparent;
  border: 1px solid #c1bcbc;
  border-radius: 4px;
  padding: 10px;
}

.admin-onboarding .action .add-btn {
  background-color: #4d68c9;
  color: #ffffff;
  border-radius: 4px;
  border: none;
}

.admin-onboarding .message {
  color: #f41649;
}

.admin-onboarding .dropdown {
  border: 1px solid #327fec !important;
}
`, "",{"version":3,"sources":["webpack://./src/view/reporting-dashboard/onboarding/OnboardAdmin.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,6BAA6B;EAC7B,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".admin-onboarding h3 {\n  font-size: 16px;\n  color: #2a2f5c;\n  font-weight: 600;\n  margin-top: 15px;\n}\n\n.admin-onboarding h4 {\n  font-size: 18px;\n  color: #000000;\n  margin-bottom: 5px;\n}\n\n.admin-onboarding p {\n  color: #a0a0a0;\n  margin-bottom: 0;\n}\n\n.admin-onboarding .action {\n  background-color: #f5f5f5;\n  padding: 10px;\n}\n\n.admin-onboarding .action .cancel-btn {\n  background-color: transparent;\n  border: 1px solid #c1bcbc;\n  border-radius: 4px;\n  padding: 10px;\n}\n\n.admin-onboarding .action .add-btn {\n  background-color: #4d68c9;\n  color: #ffffff;\n  border-radius: 4px;\n  border: none;\n}\n\n.admin-onboarding .message {\n  color: #f41649;\n}\n\n.admin-onboarding .dropdown {\n  border: 1px solid #327fec !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
