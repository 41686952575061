import React from "react";
import images from "../../../assets/images/Images";
import SimpleLoader from "../../../components/spinner/SimpleLoader";

const ValueAddedServiceSummary = ({ vasSummary, transCounts }) => {
  var abbreviate = require("number-abbreviate");

  return (
    <div className="vas-summary">
      <div className="vas-total">
        <div className="vas-amount total-amount">
          <div>
            <h4>Total Vas Amount</h4>
            {vasSummary.total ? (
              <p className="amount">
                ₦<span>{abbreviate([vasSummary.total], [1])}</span>
              </p>
            ) : (
              <SimpleLoader />
            )}
          </div>
          <div>
            <h5>Amount used</h5>
            <p className="used-amount">20M</p>
          </div>
        </div>
        <div className="vas-amount">
          <div>
            <h4>Total Wallet Balance</h4>
            <p className="amount">764M</p>
          </div>
          <div>
            <h5>Amount used</h5>
            <p className="used-amount">97K</p>
          </div>
        </div>
      </div>
      <hr />
      <div className="commission">
        <img src={images.creditIcon} alt="" />
        <h6>Dispensed Commission</h6>
        <p>
          ₦
          <span>{transCounts.data && transCounts.data.dispenseCommission}</span>
        </p>
        <button className="btn">View Commision Records</button>
      </div>
      <hr />
      <div>
        <h6>Top Performing Agents</h6>
      </div>
    </div>
  );
};

export default ValueAddedServiceSummary;
