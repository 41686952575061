import React, { useState, useEffect } from "react";
import imageURL from "../../imageUrls";
import FormatDate from "../../components/FormatDate";
import FormatCurrency from "../../components/FormatCurrency";
import handlePostApi from "../../api/HandlePostApi";
import Table from "../../components/Table";
import AppData from "../../config/appData.json";
import Pagination from "../../components/Pagination";
import Images from "../../assets/images/Images";
import SimpleLoader from "../../components/spinner/SimpleLoader";

export default function GroupedAgentHistory({
  walletId,
  setShowAgentsTransactions,
}) {
  const [agentHistory, setAgentHistory] = useState({});
  const [agentSummary, setAgentSummary] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [pageNum, setPageNum] = useState(1);

  const getStatusStyles = (status) => {
    let backgroundColor, color;

    if (status === "successful") {
      color = "#106745";
    } else if (status === "failed") {
      color = "#DE0000";
    } else {
      color = "#F1B207";
    }

    return {
      backgroundColor,
      color,
      paddingTop: "4px",
      paddingLeft: "6px",
      width: "80px",
    };
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: (date) => FormatDate(date.createdAt),
      },
      {
        Header: "Service",
        accessor: (dataName) => (
          <div className="select-icon">
            {dataName.product}{" "}
            <img
              src={imageURL[dataName.product]}
              className="service-logo"
              alt="service"
            />
          </div>
        ),
      },
      {
        Header: "Aggregator ID",
        accessor: "walletId",
      },
      {
        Header: "Payment",
        accessor: (paymentMethod) => (
          <>
            {paymentMethod.paymentMethod}{" "}
            <img
              src={imageURL[paymentMethod.paymentMethod]}
              className="service-logo"
              alt="payment method"
            />
          </>
        ),
      },
      {
        Header: "Amount",
        accessor: (amount) => FormatCurrency(amount.amount),
      },
      {
        Header: "Channel",
        accessor: (channel) => (
          <>
            {channel.channel}{" "}
            <img
              src={imageURL[channel.channel]}
              className="service-logo"
              alt="service"
            />
          </>
        ),
      },
      {
        Header: "Status",
        accessor: (status) => (
          <div style={getStatusStyles(status.status)}>
            • <span style={{ fontSize: "11px" }}>{status.status}</span>
          </div>
        ),
      },
      {
        Header: "Ref",
        accessor: "reference",
      },
    ],
    []
  );

  const fetchAgentHistory = async () => {
    try {
      const endpoint = AppData.VAS_URL + `aggregator/user-history/${walletId}`;
      const requestData = {
        page: pageNum,
        startDate: "2023-01-01",
        endDate: endDate,
        status: "",
        reference: "",
        product: "",
        account: "",
        channel: "",
        provider: "",
        limit: "50",
        reversal: "",
      };
      const response = await handlePostApi(endpoint, requestData);
      setAgentHistory(response.transactions);
      setAgentSummary(response.summary);
      console.log("response", response);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchAgentHistory();
  }, [pageNum]);

  const { docs, hasNextPage, hasPrevPage, nextPage, prevPage, totalPages } =
    agentHistory;

  return (
    <>
      <button
        className="agent-list-btn"
        onClick={() => setShowAgentsTransactions(false)}
      >
        <i class="fa-solid fa-arrow-left"></i>Back to Agent's List
      </button>
      <div className="row mb-4">
        <div className="col-3">
          <div className="card-summary">
            <div className="summary-icon">
              <img src={Images.allTrans} alt="" />
            </div>
            <div className="data">
              <p className="amount">
                <span>{agentSummary?.total?.toLocaleString()}</span>
              </p>
              <p>All Transactions</p>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="card-summary">
            <div className="summary-icon">
              <img src={Images.successfulTrans} alt="" />
            </div>
            <div className="data">
              <p className="amount">
                ₦<span>{agentSummary?.amountFunded?.toLocaleString()}</span>
              </p>
              <p>Total Amount Funded</p>
              <div className="trans-summary-count">
                Count:{" "}
                <span>{agentSummary?.creditCount?.toLocaleString()}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="card-summary">
            <div className="summary-icon">
              <img src={Images.failedTrans} alt="" />
            </div>
            <div className="data">
              <p className="amount">
                ₦<span>{agentSummary?.amountDebited?.toLocaleString()}</span>
              </p>
              <p>Total Amount Used</p>
              <div className="trans-summary-count">
                Count: <span>{agentSummary?.debitCount?.toLocaleString()}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="card-summary">
            <div className="summary-icon">
              <img src={Images.failedTrans} alt="" />
            </div>
            <div className="data">
              <p className="amount">
                ₦<span>{agentSummary?.pending?.toLocaleString()}</span>
              </p>
              <p>Total Amount Pending</p>
              <div className="trans-summary-count">
                Count:{" "}
                <span>{agentSummary?.pendingCount?.toLocaleString()}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {docs ? (
        <Table
          columns={columns}
          data={docs}
          enableRowSelection={false}
          tableClass={"table table-borderless table-striped mb-0"}
        />
      ) : (
        <SimpleLoader />
      )}
      {totalPages > 1 && (
        <Pagination
          prevPage={prevPage}
          nextPage={nextPage}
          totalPages={totalPages}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          setPageNum={setPageNum}
        />
      )}
    </>
  );
}
