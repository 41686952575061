import React, { useState, useEffect } from "react";
import Table from "../../../../../components/Table";
import GetData from "../../../../../api/GetData";
import AppData from "../../../../../config/appData.json";

export default function StatusDetails({ summary }) {
  const [terminal, setTerminal] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Switch Name",
        accessor: "switch_name",
      },
      {
        Header: "Merchant Id",
        accessor: "merchant_id",
      },
      {
        Header: "Terminal Id",
        accessor: "terminal_id",
      },
      {
        Header: "Stan",
        accessor: "stan",
      },
      {
        Header: "Action",
        accessor: "",
      },
    ],
    []
  );

  useEffect(() => {
    const fetchTerminal = async () => {
      try {
        const endpoint = AppData.BASE_URL + `terminals`;
        const response = await GetData(endpoint);
        setTerminal(response);
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    fetchTerminal();
  }, []);

  return (
    <div className="bg-white p-5 border-top-0">
      <div>{summary}</div>
      <div className="mt-5">
        <div className="table-responsive table-wrapper">
          {terminal.data && (
            <Table
              columns={columns}
              data={terminal.data}
              tableClass="table table-borderless table-striped table-hover fs-7 mb-0"
              enableRowSelection={false}
            />
          )}
        </div>
      </div>
    </div>
  );
}
