// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-input {
    position: relative;
    /* margin-left: 40px; */
}

.search-input .form-control {
    background-color: #ffffff;
    padding: 12.5px;
    box-shadow: none;
    border: 0.5px solid #48484833;
    border-radius: 4px;
}

.search-input .fa-search {
    color: #17171780;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchInput.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,6BAA6B;IAC7B,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".search-input {\n    position: relative;\n    /* margin-left: 40px; */\n}\n\n.search-input .form-control {\n    background-color: #ffffff;\n    padding: 12.5px;\n    box-shadow: none;\n    border: 0.5px solid #48484833;\n    border-radius: 4px;\n}\n\n.search-input .fa-search {\n    color: #17171780;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
