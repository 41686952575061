import React from "react";
import "./Onboarding.css";
import Images from "../../../assets/images/Images";
import Overview from "./Overview";
import Profile from "./Profile";
import Team from "./Team";
import Business from "./Business";
import AuditTrail from "./AuditTrail";
import OnboardAdmin from "./OnboardAdmin";

const Tabs = ({ data = {}, handleTab, activeTab }) => {
  return (
    <>
      <header>
        <div className="onboard-nav">
          <h1>User Management</h1>
          <ul>
            {data.map((item, index) => (
              <li
                className={index === activeTab ? "nav-item active" : "nav-item"}
                key={index}
                onClick={() => handleTab(index)}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      </header>
      <main className="content">{data[activeTab].content}</main>
    </>
  );
};

export default function Onboarding() {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTab = (index) => {
    setActiveTab(index);
  };
  
  const onboard = <OnboardAdmin />;
  const overview = <Overview />;
  const profile = <Profile />;
  const team = <Team />;
  const business = <Business />;
  const audit = <AuditTrail />;

  return (
    <div className="col admin-onboard">
      <Tabs
        handleTab={handleTab}
        activeTab={activeTab}
        data={[
          {
            label: "Onboard Admin",
            content: onboard,
          },
          // {
          //   label: "Overview",
          //   content: overview,
          // },
          {
            label: "My Profile",
            content: profile,
          },
          {
            label: "Team",
            content: team,
          },
          // {
          //   label: "Business",
          //   content: business,
          // },
          // {
          //   label: "Audit Trail",
          //   content: audit,
          // },
        ]}
      />
    </div>
  );
}
