import React, { useState, useEffect, useCallback } from "react";
import Table from "../../../components/Table";
import GetData from "../../../api/GetData";
import AppData from "../../../config/appData.json";
import Pagination from "../../../components/Pagination";
import axios from "axios";

export default function ChargeProfiling() {
  const [riskList, setRiskList] = useState([]);
  const [statusVol, setStatusVol] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [pageNumber, setPageNumber] = useState(1);
  const postPerPage = 50;
  const lastPageNum = Math.ceil(statusVol / postPerPage);
  const pageStart = (pageNumber - 1) * postPerPage + 1;

  const toggleStatus = async (userId, status) => {
    try {
      const endpoint = AppData.PAYMENT_URL + `settings/switch/${userId}`;
      // console.log("status", status, !status);
      const payload = {
        status: !status,
      };
      await axios.patch(endpoint, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log("Error toggling staatus", error);
    } finally {
      fetchHistoryData();
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S/N",
        Cell: (row) => {
          return <div>{row.row.index + pageStart}</div>;
        },
      },
      {
        Header: "Switch Name",
        accessor: "name",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (row) => {
          const rowData = row.row.original;
          const { status, _id } = rowData;

          const handleStatusToggle = () => {
            toggleStatus(_id, status);
          };

          return (
            <label className="switch" htmlFor={`enabled-${row.row.index}`}>
              <input
                type="checkbox"
                className="checkbox"
                name="enabled"
                id={`enabled-${row.row.index}`}
                checked={status}
                onChange={handleStatusToggle}
              />
              <span className="slider" />
              <span className="inner" />
            </label>
          );
        },
      },
      {
        Header: "Date Created",
        accessor: (date) => {
          let dDate = date.createdAt;
          const formatDate = new Date(dDate);
          return <div> {formatDate.toLocaleString()}</div>;
        },
      },
      {
        Header: "Last Updated",
        accessor: (date) => {
          let dDate = date.updatedAt;
          const formatDate = new Date(dDate);
          return <div> {formatDate.toLocaleString()}</div>;
        },
      },
    ],
    [pageNumber]
  );

  const fetchHistoryData = async () => {
    try {
      const endpoint = AppData.PAYMENT_URL + "charge";
      const response = await GetData(endpoint);
      // console.log("charge response", response);
      setRiskList(response);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  function formatDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  const handleFilter = (e) => {
    e.preventDefault();
    fetchHistoryData();
  };

  useEffect(() => {
    fetchHistoryData();
  }, [pageNumber]);

  return (
    <div className="band-config">
      <div className="table-responsive">
        {riskList.data && riskList.data.length > 0 ? (
          <Table
            columns={columns}
            data={riskList.data}
            enableRowSelection={false}
            tableClass={"table table-borderless table-striped mb-0"}
          />
        ) : (
          <p>No record found</p>
        )}
        {lastPageNum > 1 && (
          <Pagination
            prevPage={pageNumber - 1}
            nextPage={pageNumber + 1}
            totalPages={lastPageNum}
            hasNextPage={pageNumber < lastPageNum}
            hasPrevPage={pageNumber > 1}
            setPageNum={setPageNumber}
          />
        )}
      </div>
    </div>
  );
}
