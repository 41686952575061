import React from "react";
import "./HeroSection.css";
import cable from "../../assets/images/cable.png";
import path from "../../assets/images/path.svg";
import playstore from "../../assets/images/playstore.png";
import appStore from "../../assets/images/app-store.png";
import bulb from "../../assets/images/bulb.png";
import terminal from "../../assets/images/pos1.png";
import airtime from "../../assets/images/airtime.png";
import internet from "../../assets/images/internet.png";
import others from "../../assets/images/other-services.png";
import insurance from "../../assets/images/insurance.png";
import arrow from "../../assets/images/arrow.svg";

const ServiceCard = ({ data }) => {
  return (
    <>
      {data.map((item, index) => (
        <div className="col-4 mb-3" key={index}>
          <div className="service">
            <img className="service-img" src={item.icon} alt="service" />
            <p className="service-label">{item.label}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default function HeroSection() {
  return (
    <div id="services" className="row hero">
      <div className="col intro">
        <h1>
          Make Easier <br /> Payment With Spoutpay.
        </h1>
        <h2>
          Perform seamless transactions without Network Glitch <br /> With
          <span>Spoutpay</span> today!
        </h2>
        <img src={path} className="path" alt="Spout pay" />
        <p>Make millions with commission now</p>
        <div>
          <button className="btn">Become An Agent</button>
        </div>
        <div className="pos">
          <img className="arrow-img" src={arrow} alt="pos" />
          <img className="pos-img" src={terminal} alt="pos" />
        </div>
      </div>
      <div className="col-5">
        <div className="payments">
          <p>Got Bills? Pay Here</p>
          <div className="row">
            <ServiceCard
              data={[
                {
                  icon: `${bulb}`,
                  label: "Electricity Tokens",
                },
                {
                  icon: `${airtime}`,
                  label: "Airtime & data",
                },
                {
                  icon: `${internet}`,
                  label: "Internet Services",
                },
                {
                  icon: `${insurance}`,
                  label: "Insurance",
                },
                {
                  icon: `${cable}`,
                  label: "Cable Tv",
                },
                {
                  icon: `${others}`,
                  label: "Other Services",
                },
              ]}
            />
          </div>
          <div className="app-download">
            <p>
              Want a better experience? <span>Download Mobile App</span>
            </p>
            <div className="download-links">
              <a><img className="download-app" src={playstore} alt="playstore"/></a>
              <a><img className="download-app" src={appStore} alt="app-store"/></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
