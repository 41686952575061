import React, { useState, useEffect } from "react";
import GetData from "../../../api/GetData";
import Table from "../../../components/Table";
import AppData from "../../../config/appData.json";
import Images from "../../../assets/images/Images";
import RaiseChargeModal from "./RaiseChargeModal";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import Modal from "../../../components/modal/Modal";
import ChargeProfile from "./ChargeProfile";

export default function RaiseCharge() {
  const navigate = useNavigate();
  const [transHistory, setTransHistory] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [openCharge, setOpenCharge] = useState(false);
  const [userId, setUserId] = useState("");

  const viewChargeProfile = (id) => {
    setUserId(id);
    setOpenCharge(true);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S/N",
        id: "row",
        Cell: (row) => {
          const PageStart = (1 - 1) * 50 + 1;
          return <div>{row.row.index + PageStart}</div>;
        },
      },
      {
        Header: "Name",
        accessor: (profile) => <div>{profile.name}</div>,
      },
      {
        Header: "Is Default",
        accessor: (isDefault) => <>{isDefault.isDefault ? "Yes" : "No"}</>,
      },
      {
        Header: "Created By",
        accessor: (profile) => (
          <div>
            {profile.adminId.firstName} {profile.adminId.lastName}
          </div>
        ),
      },
      {
        Header: "Date & Time",
        accessor: (date) => {
          let dDate = date.createdAt;
          const formatDate = new Date(dDate);
          return <div> {formatDate.toLocaleString()}</div>;
        },
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: (tableInstance) => {
          const { row: index } = tableInstance;
          return (
            <button
              className="fund"
              onClick={() => viewChargeProfile(index.original._id)}
            >
              View Charge
            </button>
          );
        },
      },
    ],
    []
  );

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const handleErrorFunc = (error) => {
    if (error.response.data.message === "Expired Session, Please Login Again") {
      navigate("/signin");
    } else {
      setMsg(error.response.data.message);
    }
  };

  const fetchChargeHistory = async () => {
    try {
      const url = AppData.VAS_URL + `admin/charges/profiles?limit=25&page=1`;
      const { profiles } = await GetData(url);
      // console.log("profiles", profiles);
      setTransHistory(profiles);
    } catch (error) {
      handleErrorFunc(error);
    }
  };

  const handlePageRefresh = () => {
    fetchChargeHistory();
  };

  useEffect(() => {
    fetchChargeHistory();
  }, []);

  const { docs, hasNextPage, hasPrevPage, nextPage, prevPage, totalPages } =
    transHistory;

  return (
    <>
      {openCharge ? (
        <ChargeProfile userId={userId} setOpenCharge={setOpenCharge} />
      ) : (
        <div className="raise-charge">
          <div className="d-flex mt-5">
            <div className="me-auto">
              {/* <button className="btn" onClick={openModal}>
                Raise Charge
              </button> */}
            </div>
            <div className="">
              <button className="btn refresh" onClick={handlePageRefresh}>
                <i className="fa-solid fa-arrows-rotate"></i>
                Refresh
              </button>
              {docs && (
                <CSVLink
                  data={docs}
                  filename="Charge history"
                  className="downloads"
                >
                  <img src={Images.download} alt="" />
                </CSVLink>
              )}
            </div>
          </div>
          <div className="vas-table table-responsive">
            {docs && (
              <Table
                columns={columns}
                data={docs}
                tableClass="table table-borderless table-striped table-hover fs-7 mb-0"
                enableRowSelection={false}
              />
            )}
            {totalPages > 1 && (
              <Pagination
                prevPage={prevPage}
                nextPage={nextPage}
                totalPages={totalPages}
                hasNextPage={hasNextPage}
                hasPrevPage={hasPrevPage}
                setPageNum={setPageNum}
              />
            )}
          </div>
          {isModalOpen && (
            <Modal
              isOpen={isModalOpen}
              onClose={closeModal}
              content={<RaiseChargeModal setShowModal={setIsModalOpen} />}
              setMsg={setMsg}
            />
          )}
        </div>
      )}
    </>
  );
}
