import React from "react";

const DateSelector = ({ days, clickIndex, handleDateFunc }) => {
  return (
    <div className="date-container">
      <div className="dashboard-param">
        <ul className="nav nav-pills">
          {days.map((day, index) => (
            <li
              key={index}
              className={index === clickIndex ? "nav-link active" : "nav-link"}
              onClick={() => handleDateFunc(index)}
            >
              <button className="border-end">{day}</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DateSelector;
