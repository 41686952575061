import React, { useEffect, useState } from "react";
import Table from "../../../components/Table";

export default function RequestOnboardModal({ onClose, merchantInfo }) {
  const [adminId, setAdminId] = useState("");

  const makerId = merchantInfo.requested_by[0].id;
  const merchantData = merchantInfo.merchant[0];

  const approveRequest = async () => {
    try {
      const endpoint = "";
    } catch (error) {
      console.error("Error approving request", error);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Location",
        accessor: "location",
      },
      {
        Header: "Count",
        accessor: "count",
      },
      {
        Header: "State Code",
        accessor: "statecode",
      },
      {
        Header: "LGA LCDA",
        accessor: "lga_lcda",
      },
    ],
    []
  );

  useEffect(() => {
    setAdminId(localStorage.getItem("AdminId"));
  }, []);

  return (
    <div className="request-onboard-modal">
      <h3 className="mb-4">Merchant Onboarding Form</h3>
      <div className="row">
        <div className="col">
          <div className="mb-3 row">
            <p className="col-sm-3">Account Number</p>
            <p className="col-sm-8">{merchantData.merchant_email}</p>
          </div>
          <div className="mb-3 row">
            <p className="col-sm-3">Address</p>
            <p className="col-sm-8">{merchantData.merchant_address}</p>
          </div>
          <div className="mb-3 row">
            <p className="col-sm-3">Contact Number</p>
            <p className="col-sm-8">{merchantData.contact_phone}</p>
          </div>
          <div className="mb-3 row">
            <p className="col-sm-3">Contact Person</p>
            <p className="col-sm-8">{merchantData.contact_person}</p>
          </div>
          <div className="mb-3 row">
            <p className="col-sm-3">Contact Title</p>
            <p className="col-sm-8">{merchantData.contact_title}</p>
          </div>
          <div className="mb-3 row">
            <p className="col-sm-3">E.O.D Notification</p>
            <p className="col-sm-8">{merchantData.notification}</p>
          </div>
        </div>
        <div className="col">
          <div className="mb-3 row">
            <p className="col-sm-3">Merchant Name</p>
            <p className="col-sm-8">{merchantData.merchant_name}</p>
          </div>
          <div className="mb-3 row">
            <p className="col-sm-3">Account Type</p>
            <div className="col-sm-8">{merchantData.account_type}</div>
          </div>
          <div className="mb-3 row">
            <p className="col-sm-3">Customized Report</p>
            <div className="col-sm-8">
              {merchantData.custom_report ? "True" : "false"}
            </div>
          </div>
          <div className="mb-3 row">
            <p className="col-sm-3">Email</p>
            <div className="col-sm-8">{merchantData.merchant_email}</div>
          </div>
          <div className="mb-3 row">
            <p className="col-sm-3">Expected Monthly Turnover</p>
            <div className="col-sm-8">{merchantData.monthly_turnover}</div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col">
          <div className="mb-3 row">
            <p className="col-sm-3">Relationship Manager Name</p>
            <p className="col-sm-8">{merchantData.relationship_manager}</p>
          </div>
          <div className="mb-3 row">
            <p className="col-sm-3">Branch</p>
            <div className="col-sm-8">{merchantData.branch || "N/A"}</div>
          </div>
          <div className="mb-3 row">
            <p className="col-sm-3">Region</p>
            <div className="col-sm-8">{merchantData.region || "N/A"}</div>
          </div>
          <div className="mb-3 row">
            <p className="col-sm-3">Zone</p>
            <div className="col-sm-8">{merchantData.zone || "N/A"}</div>
          </div>
          <div className="mb-3 row">
            <p className="col-sm-3">
              Do you want your device(s) integrated with a third party
              application?
            </p>
            <div className="col-sm-8">
              {merchantData.third_party_integration ? "True" : "false"}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3 row">
            <p className="col-sm-3">Total Number of Sales Outlet</p>
            <p className="col-sm-8">{merchantData.total_outlet}</p>
          </div>
          <div className="mb-3 row">
            <p className="col-sm-3">Merchant Solution</p>
            <div className="col-sm-8">{merchantData.merchant_solution}</div>
          </div>
          <div className="mb-3 row">
            <p className="col-sm-3">Total Number of Terminals</p>
            <div className="col-sm-8">{merchantData.total_terminals}</div>
          </div>
          <div className="mb-3 row">
            <p className="col-sm-3">
              Should we enable your device(s) for International Card Acceptance?
            </p>
            <div className="col-sm-8">
              {merchantData.international_card ? "True" : "false"}
            </div>
          </div>
          <div className="mb-3 row">
            <p className="col-sm-3">Location of Terminal(s)</p>
            <div className="col-sm-8">
              {merchantData.terminal_locations.length > 0 ? (
                <Table
                  columns={columns}
                  data={merchantData.terminal_locations}
                />
              ) : (
                <p>No location information</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col">
          <div className="mb-3 row">
            <p className="col-sm-3">Merchant Business Segment</p>
            <div className="col-sm-8">
              {merchantData.business_industry || "N/A"}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3 row">
            <p className="col-sm-3">What Do You Do?</p>
            <div className="col-sm-8">{merchantData.product || "N/A"}</div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col">
          <div className="mb-3 row">
            <p className="col-sm-3">
              Merchant CIF ID Exta Identifier Transaction Fee
            </p>
            <div className="col-sm-8">
              {merchantData.merchant_cif_id || "N/A"}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3 row">
            <p className="col-sm-3">Comment or Extra Information</p>
            <div className="col-sm-8">
              {merchantData.extra_identifier || "N/A"}
            </div>
          </div>
          <div className="mb-3 row">
            <p className="col-sm-3">Merchant Category Code</p>
            <div className="col-sm-8">{merchantData.mcc || "N/A"}</div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col">
          <div className="mb-3 row">
            <p className="col-sm-3">Branch State Code</p>
            <div className="col-sm-8">{merchantData.state_code || "N/A"}</div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3 row">
            <p className="col-sm-3">Merchant ID</p>
            <div className="col-sm-8">
              {merchantData.merchant_cif_id || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        {makerId === adminId && (
          <small className="mb-3">
            You do not permission to approve this request
          </small>
        )}
      </div>
      <div className="d-flex">
        <button
          className={
            makerId === adminId ? "me-4" : "request-action-btn approve-merchant"
          }
          disabled={makerId === adminId}
          onClick={approveRequest}
        >
          Approve
        </button>
        <button
          className={
            makerId === adminId ? "" : "request-action-btn reject-merchant"
          }
          disabled={makerId === adminId}
        >
          Reject
        </button>
      </div>
    </div>
  );
}
