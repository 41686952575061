// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download {
    position: relative;
    margin-bottom: 90px;
    background-color: transparent;
    color: #ffffff;
    padding: 30px 85px;
}

.download-image {
    height: 75px;
    margin-top: 30px;
}

.download .mobile {
    position: absolute;
    right: 20px;
    bottom: -280px;
}

.download .mobile img {
    height: 500px;
    width: 500px;
}`, "",{"version":3,"sources":["webpack://./src/view/landing/Downloads.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,6BAA6B;IAC7B,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB","sourcesContent":[".download {\n    position: relative;\n    margin-bottom: 90px;\n    background-color: transparent;\n    color: #ffffff;\n    padding: 30px 85px;\n}\n\n.download-image {\n    height: 75px;\n    margin-top: 30px;\n}\n\n.download .mobile {\n    position: absolute;\n    right: 20px;\n    bottom: -280px;\n}\n\n.download .mobile img {\n    height: 500px;\n    width: 500px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
