import React from "react";
import "./FundTransactions.css";
import FundingList from "./FundingList";
import ProfileList from "./ProfileList";

const Tabs = ({ data = {}, handleTab, activeTab }) => {
  return (
    <>
      <nav className="vas-nav">
        <h1>Fund Transactions</h1>
        <p></p>
        <ul>
          {data.map((item, index) => (
            <li
              className={index === activeTab ? "nav-item active" : "nav-item"}
              key={index}
              onClick={() => handleTab(index)}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </nav>
      <div className="content">{data[activeTab].content}</div>
    </>
  );
};

export default function FundTransactions() {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTab = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="vas-trans">
      <main className="col vas-container">
        <div className="vas-dashboard">
          <Tabs
            handleTab={handleTab}
            activeTab={activeTab}
            data={[
              {
                label: "Raise Charge",
                content: <ProfileList />,
              },
              {
                label: "Funding List",
                content: <FundingList />,
              },
            ]}
          />
        </div>
      </main>
    </div>
  );
}
