import React, { useState, useEffect, useRef } from "react";
import GetData from "../../api/GetData";
import AppData from "../../config/appData.json";
import Id from "../../assets/images/id-icon.svg";
import axios from "axios";
import InviteLink from "../../components/InviteLink";

export default function AddAgents() {
  const [aggregatorsLink, setAggregatorsLink] = useState("");
  const [loading, setLoading] = useState(true);
  const inputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [msg, setMsg] = useState(false);

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
    setIsDragOver(false);
  };

  const handleAddAgents = (e) => {
    e.preventDefault();
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      axios
        .post(AppData.VAS_URL + "register-bulk", formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          redirect: "follow",
        })
        .then((response) => {
          // console.log("File uploaded successfully", response);
          setMsg(response.data.message);
        })
        .catch((error) => {
          console.error("Error uploading file", error);
          setMsg("Error uploading file");
        });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetData(
          AppData.VAS_URL + "aggregator/register-link"
        );
        setAggregatorsLink(response.link);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <h6 className="mb-2">Add Agent</h6>
      <small>{msg && msg}</small>
      <div className="d-flex justify-content-center align-items-center">
        <div className="add-agent">
          <button className="btn btn-add-agent">
            <i className="fa-solid fa-user"></i>Add Agent
          </button>
          <InviteLink link={aggregatorsLink} loading={loading} />
        </div>
        <form className="d-flex add-agent-form" onSubmit={handleAddAgents}>
          <div
            className="file-area"
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <img className="upload-icon" src={Id} alt="" />
            <input type="file" onChange={handleChange} hidden ref={inputRef} />
            <div type="button" onClick={handleClick}>
              Click Here To Upload .xlsx file
            </div>
            <p>
              Or <br /> Drag Files Here
            </p>
          </div>
          {file && (
            <div className="ms-3 mt-4">
              <p className="file-name">{file.name}</p>
              <button className="btn btn-add-agent" type="submit">
                <i className="fa-solid fa-user"></i>Add Agents
              </button>
            </div>
          )}
        </form>
      </div>
    </>
  );
}
