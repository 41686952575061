import React, { useState, useEffect } from "react";
import "./Settlement.css";
import DropdownMenu from "../../../../components/DropdownMenu";
import Reconciliation from "./settlement-reconciliation/Reconciliation";
import Report from "./settlement-report/Report";
import SettlementUpload from "./settllement-upload/SettlementUpload";
import AppData from "../../../../config/appData.json";
import GetData from "../../../../api/GetData";

export default function Settlements() {
  const [fileUploadHistory, setFileUploadHistory] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const url = "";
  const data = { email: "email", password: "password" };

  const changeStartDate = (date) => {
    setStartDate(date);
  };

  const changeEndDate = (date) => {
    setEndDate(date);
  };

  useEffect(() => {
    const fetchFileUploadHistory = async () => {
      try {
        const endpoint = AppData.BASE_URL + "settlements/uploads";
        const response = await GetData(endpoint);
        setFileUploadHistory(response);
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    fetchFileUploadHistory();
  }, []);

  return (
    <div>
      <DropdownMenu
        label="Settlement"
        items={[
          {
            name: "Settlement Report",
            content: (
              <Report
                startDate={startDate}
                endDate={endDate}
                changeStartDate={changeStartDate}
                changeEndDate={changeEndDate}
              />
            ),
          },
          {
            name: "Settlement Upload",
            content: <SettlementUpload fileUploadHistory={fileUploadHistory} />,
          },
          { name: "Settlement Reconciliation", content: <Reconciliation /> },
        ]}
      />
    </div>
  );
}
