import React, { useState, useContext } from "react";
import "./UserDashboard.css";
import Header from "./header/Header";
import InitialsAvatar from "react-initials-avatar";
import { UserContext } from "../../context/UserContext";
import avatar from "../../assets/images/avatar.jpg";
import calender from "../../assets/images/calender.svg";
import wallet from "../../assets/images/wallet.svg";
import invite from "../../assets/images/invite.svg";
import home from "../../assets/images/home-icon.svg";
import dashboard from "../../assets/images/dashboard-icon.svg";
import support from "../../assets/images/support.svg";
import flag from "../../assets/images/flag.svg";
import logOut from "../../assets/images/noun-logout.svg";
import spoutLogo from "../../assets/images/spout-logo.png";
import { useNavigate } from "react-router-dom";
import Home from "./Home";
import Dashboard from "./Dashboard";
import Profile from "./Profile";
import Group from "./Group";
import History from "./History";
import Agent from "./Agent";

const navItems = [
  { name: "Home", icon: home, content: <Home /> },
  { name: "Dashboard", icon: dashboard, content: <Dashboard /> },
  { name: "History", icon: calender, content: <History /> },
  { name: "Profile", icon: invite, content: <Profile /> },
  { name: "Group", icon: invite, content: <Group /> },
  { name: "Agents", icon: invite, content: <Agent /> },
  { name: "Support", icon: support, content: "" },
];

export default function UserDashboard() {
  const navigate = useNavigate();
  const [activeNav, setActiveNav] = useState(0);
  const { userData } = useContext(UserContext);
  const user = userData.user ? userData.user : userData;
  const heading = navItems[activeNav].name;

  const filteredNavItems = navItems.filter((item, index) => {
    if (user.agentType !== "aggregator") {
      return item.name !== "Group" && item.name !== "Agents";
    } else {
      return item.name !== "Home";
    }
  });

  const handleLogOut = () => {
    localStorage.removeItem("token");
    navigate("/signin");
  };

  const activateNav = (index) => {
    setActiveNav(index);
  };
  return (
    <div className="user-dashboard">
      <Header heading={heading} />
      <div className="container-fluid">
        <div className="row">
          <aside className="col-3 sidebar">
            <div className="logo">
              <img src={spoutLogo} alt="Spoutpay" />
            </div>
            <div className="user-account">
              <div className="user-card">
                <div className="user-profile">
                  {user.picture ? (
                    <img
                      className="profile-pic"
                      src={user.picture}
                      alt="profile-pic"
                    />
                  ) : (
                    <InitialsAvatar
                      name={`${user.firstName} ${user.lastName}`}
                    />
                  )}
                  <div className="about-user">
                    <h1>
                      {user && user.firstName} {user && user.lastName}
                    </h1>
                    <p>
                      Current Tier <span>1</span>
                    </p>
                  </div>
                </div>
                <p>
                  Wallet ID <span>{user && user.walletId}</span>
                </p>
              </div>
              <div className="tier">
                <h2>Tier completion level</h2>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: "40%" }}
                  ></div>
                </div>
              </div>
              <div className="balance">
                <h3>Virtual Account</h3>
                <p>{user?.vfdAcctDetails?.accountNo}</p>
              </div>
            </div>
            <div className="nav-links">
              <ul className="nav flex-column">
                {filteredNavItems.map((nav, i) => (
                  <li
                    key={i}
                    className={i === activeNav ? "nav-item active" : "nav-item"}
                    onClick={() => activateNav(i)}
                  >
                    <div className="nav-link active-link">
                      <img
                        src={nav.icon}
                        alt=""
                        className={i === activeNav && "active-icon"}
                      />
                      <span>{nav.name}</span>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="sign-out">
                <button className="nav-link log-out" onClick={handleLogOut}>
                  <span>
                    <img src={logOut} />
                  </span>
                  Log Out
                </button>
              </div>
            </div>
          </aside>
          {filteredNavItems[activeNav].content}
        </div>
      </div>
    </div>
  );
}
