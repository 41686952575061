import axios from "axios";

export default async function putData(url, requestData) {
  try {
    const response = await axios.put(url, requestData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
