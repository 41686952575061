import React from "react";
import "./Landing.css";
import Navbar from "../../components/navbar/Navbar";
import HeroSection from "./HeroSection";
import About from "./About";
import Description from "./Description";
import Experience from "./Experience";
import Downloads from "./Downloads";
import Faq from "./Faq";
import Footer from "./Footer";


export default function Landing() {
  return (
    <div className="spout-landing">
      <Navbar navClass={"navbar nav-linear"} />
      <main>
        <HeroSection/>
        <About/>
        <Description/>
        <Experience />
        <Downloads />
        <Faq/>
        <Footer />
      </main>
    </div>
  );
}
