import React, { useState, useEffect, useContext } from "react";
import "./Profile.css";
import { UserContext } from "../../context/UserContext";
import AppData from "../../config/appData.json";
import AddAgents from "./AddAgents";
import UserDataForm from "./UserDataForm";
import { useNavigate } from "react-router-dom";
import GetData from "../../api/GetData";
import Id from "../../assets/images/id-icon.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DocumentItem = ({ heading, required, imageUrl, onFileChange }) => {
  return (
    <div className="col-6 document-border">
      <div className="upload-file">
        <h3>{heading}</h3>
        <p className="required">{required}</p>
        <div className="file-area mb-3">
          <img className="w-100" src={imageUrl} alt="Document uploaded" />
        </div>
        <input type="file" onChange={onFileChange} accept="image/*" />
      </div>
    </div>
  );
};

export default function Profile() {
  const [user, setUser] = useState({});
  const [documentEditMode, setDocumentEditMode] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState({
    passport: null,
    idCard: null,
    utilityBill: null,
    guarantorsPassport: null,
    guarantorsId: null,
    cacDocument: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = AppData.VAS_URL + "user/profile";
        const { user } = await GetData(endpoint);
        setUser(user);

        // Convert default image links to files
        const imageLinks = [
          "passport",
          "idCard",
          "utilityBill",
          "guarantorsPassport",
          "guarantorsId",
          "cacDocument",
        ];

        const imagePromises = imageLinks.map(async (fieldName) => {
          if (user[fieldName]) {
            const response = await fetch(user[fieldName]);
            const blob = await response.blob();
            setSelectedFiles((prevFiles) => ({
              ...prevFiles,
              [fieldName]: new File([blob], `${fieldName}.jpg`, {
                type: "image/jpeg",
              }),
            }));
          }
        });

        await Promise.all(imagePromises);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, []);

  const handleDocumentEditClick = () => {
    setDocumentEditMode(!documentEditMode);
  };

  const handleFileChange = (fieldName, event) => {
    setSelectedFiles({
      ...selectedFiles,
      [fieldName]: event.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = AppData.VAS_URL + "auth/upload-kyc-documents";
    const formData = new FormData();

    Object.keys(selectedFiles).forEach((fieldName) => {
      formData.append(fieldName, selectedFiles[fieldName]);
    });

    try {
      await axios.post(url, formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      toast("Documents uploaded successfully!");
      setDocumentEditMode(false);
    } catch (error) {
      toast("Error uploading documents:");
      console.error("Error uploading documents:", error);
    }
  };

  return (
    <main className="col profile">
      {user.agentType === "aggregator" && <AddAgents />}
      <p className="alert">
        <i className="fa-solid fa-circle-exclamation"></i>
        Please Note That Any Changes Made Needs A Spout Payment Admin Approval
        Before You Can Proceed
      </p>
      <small>This Takes Few Minutes</small>
      <UserDataForm userProfileData={user} />
      <form className="documents" onSubmit={handleSubmit}>
        <div className="section-heading">
          <h6 className="mb-0">Documents</h6>
          {!documentEditMode ? (
            <button className="edit-btn" onClick={handleDocumentEditClick}>
              Edit <i className="fa-solid fa-pen-to-square"></i>
            </button>
          ) : (
            <div className="edit-action">
              <button className="cancel-btn" onClick={handleDocumentEditClick}>
                Cancel
              </button>
              <button className="save-btn" type="submit">
                Save
              </button>
            </div>
          )}
        </div>
        <div
          className={
            !documentEditMode ? "row upload disabled-doc" : "row upload"
          }
        >
          <div className="row">
            <DocumentItem
              heading="Passport"
              required="Required"
              imageUrl={user.passport}
              onFileChange={(event) => handleFileChange("passport", event)}
            />
            <DocumentItem
              heading="ID Card"
              required="Required"
              imageUrl={user.idCard}
              onFileChange={(event) => handleFileChange("idCard", event)}
            />
            <DocumentItem
              heading="Utility Bill"
              required="Required"
              imageUrl={user.utilityBill}
              onFileChange={(event) => handleFileChange("utilityBill", event)}
            />
            <DocumentItem
              heading="Guarantor's Passport"
              required="Required"
              imageUrl={user.guarantorsPassport}
              onFileChange={(event) =>
                handleFileChange("guarantorsPassport", event)
              }
            />
            <DocumentItem
              heading="Guarantor's ID"
              required="Required"
              imageUrl={user.guarantorsId}
              onFileChange={(event) => handleFileChange("guarantorsId", event)}
            />
            <DocumentItem
              heading="CAC Doc"
              required="Optional"
              imageUrl={user.cacDocument}
              onFileChange={(event) => handleFileChange("cacDocument", event)}
              imageName={selectedFiles.cacDocument}
            />
          </div>
        </div>
      </form>
      <ToastContainer />
    </main>
  );
}
