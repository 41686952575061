import React from "react";
import "./Overview.css";
import LineChart from "../../../components/charts/LineChart";
import SearchInput from "../../../components/SearchInput";

export default function Overview() {
  return (
    <div className="onboard-overview">
      <div className="row mt-3">
        <div className="col">
          <div className="agent-summary total">
            <h1>Total Agent</h1>
            <p>33333</p>
          </div>
        </div>
        <div className="col">
          <div className="agent-summary active-agent">
            <h1>Active Agent</h1>
            <p>33333</p>
          </div>
        </div>
        <div className="col">
          <div className="agent-summary inactive-agent">
            <h1>Inactive Agent</h1>
            <p>33333</p>
          </div>
        </div>
        <div className="col">
          <div className="agent-summary invalid-wallet">
            <h1>Invalid Wallet</h1>
            <p>33333</p>
          </div>
        </div>
        <div className="col">
          <div className="agent-summary not-in-use">
            <h1>Wallet Not In Use</h1>
            <p>33333</p>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-8 chart-representation">
          <div className="activity-chart">
            <div className="header">
              <h2>Activity Chart</h2>
            </div>
            <select>
              <option>Year</option>
            </select>
          </div>
          <div className="graph-chart"><LineChart/></div>
        </div>
        <div className="col-4">
          <div className="suspected-wallet">
            <div className="header">
              <h2>Suspected Wallet</h2>
              <div className="search">
                <SearchInput />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
