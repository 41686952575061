import React, { useState, useEffect } from "react";
import Table from "../../../components/Table";
import AppData from "../../../config/appData.json";
import handlePostApi from "../../../api/HandlePostApi";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import GroupModal from "../../modal/GroupModal";
import Pagination from "../../../components/Pagination";
import NaijaStates from "naija-state-local-government";
import FilterForm from "./FilterForm";
import SimpleLoader from "../../../components/spinner/SimpleLoader";

const AGENT_TYPES = [
  { name: "Tier 1" },
  { name: "Tier 2" },
  { name: "Tier 3" },
];

export default function UnassignedAgentsList({ setShowUserData, setUserId }) {
  const [agents, setAgents] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [location, setLocation] = useState("Region");
  const [agentType, setAgentType] = useState("Tier 1");
  const [isRowSelectionEnabled, setIsRowSelectionEnabled] = useState(true);
  const [shouldReloadTable, setShouldReloadTable] = useState(false);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedLga, setSelectedLga] = useState("");
  const [lgas, setLgas] = useState([]);
  const [emailAddress, setEmailAddress] = useState("");
  const [username, setUsername] = useState("");
  const [selectedLimit, setSelectedLimit] = useState("50");

  const navigate = useNavigate();

  const handleOpenUserProfile = (user) => {
    setShowUserData(true);
    setUserId(user._id);
  };

  const columns = [
    {
      Header: "Wallet Id",
      accessor: "walletId",
      Cell: (tabinstance) => {
        const { row: index } = tabinstance;
        const indexData = index.original;
        return (
          <button
            className="wallet-link-btn"
            onClick={() => handleOpenUserProfile(indexData)}
          >
            {indexData.walletId}
          </button>
        );
      },
    },
    {
      Header: "VFD",
      accessor: (vfd) => (
        <>
          <div style={{ fontSize: "10px", color: "#D57200" }}>
            {vfd?.vfdAcctDetails?.accountName}
          </div>
          {vfd?.vfdAcctDetails?.accountNo}
        </>
      ),
    },
    {
      Header: "Username",
      accessor: "username",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone No",
      accessor: "phoneNumber",
    },
    // {
    //   Header: "Address",
    //   accessor: "address",
    // },
    {
      Header: "State",
      accessor: "officeState",
    },
    {
      Header: "LGA",
      accessor: "officeLga",
    },
  ];

  const handleFetchError = (error) => {
    const { response } = error;
    const err = response?.data?.message;
    console.error("Error fetching agents:", error);
    if (err === "Expired Session, Please Login Again") {
      navigate("/signin");
    }
  };

  const requestData = {
    page: pageNum,
    agentType: "agent",
    email: "",
    username: "",
    officeState: "",
    officeLga: "",
    hasAggregatorWallet: false,
    limit: "",
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmailAddress(value);
    } else if (name === "username") {
      setUsername(value);
    }
  };

  const fetchData = async (data) => {
    try {
      const url = AppData.WALLET_URL + "admin/get-users";
      const { users } = await handlePostApi(url, data);
      setAgents(users);
      // console.log("response", users);
    } catch (error) {
      handleFetchError(error);
    }
  };

  const handleRowSelect = (rowId) => {
    const walletId = rowId.values.walletId;
    setSelectedIds((prevSelectedIds) => {
      const isSelectedId = prevSelectedIds.includes(walletId);
      if (isSelectedId) {
        return prevSelectedIds.filter((selectedId) => selectedId !== walletId);
      } else {
        return [...prevSelectedIds, walletId];
      }
    });
  };

  const handleSelectAllRow = (rowIds, isChecked) => {
    setSelectedIds(isChecked ? rowIds : []);
  };

  const handleShowCreateGroup = () => {
    setShowCreateGroupModal(true);
  };

  useEffect(() => {
    setStates(() => NaijaStates.states());
    setSelectedLga("");
    if (selectedState) {
      setLgas(NaijaStates.lgas(selectedState).lgas);
    }
  }, [selectedState]);

  const handleFilter = (e) => {
    e.preventDefault();
    const data = {
      page: pageNum,
      agentType: "agent",
      hasAggregatorWallet: false,
      email: emailAddress,
      username: username,
      officeState: selectedState,
      officeLga: selectedLga,
      limit: selectedLimit,
    };
    fetchData(data);
  };

  const handleRefresh = (e) => {
    e.preventDefault();
    setEmailAddress("");
    setUsername("");
    setSelectedState("");
    setSelectedLga("");
    setPageNum(1);
    setSelectedLimit(50);
    fetchData(requestData);
  };

  useEffect(() => {
    fetchData(requestData);
  }, [pageNum, agentType, selectedLimit]);

  useEffect(() => {
    if (shouldReloadTable) {
      setIsRowSelectionEnabled(true);
      setShouldReloadTable(false);
    }
  }, [shouldReloadTable]);

  const { docs, hasNextPage, hasPrevPage, nextPage, prevPage, totalPages } =
    agents;

  return (
    <div className="pt-4">
      <div className="d-flex mb-3">
        <div className="d-flex gap-3">
          <CustomSelect
            heading="State"
            selectedVal={selectedState ? selectedState : "All States"}
            setSelectedValue={setSelectedState}
            items={states}
            defaultOption={{ label: "All States", value: "" }}
          />
          {selectedState && (
            <CustomSelect
              heading="LGA"
              selectedVal={selectedLga ? selectedLga : "All Lgas"}
              setSelectedValue={setSelectedLga}
              items={lgas}
              defaultOption={{ label: "All Lga", value: "" }}
            />
          )}
          <CustomSelect
            heading="Agent Type"
            selectedVal={agentType}
            setSelectedValue={setAgentType}
            items={AGENT_TYPES}
          />
        </div>
        <div className="ms-auto">
          <button
            className={`group-btn ${
              selectedIds.length < 1 ? "disabled-btn" : "create-btn"
            }`}
            disabled={selectedIds.length < 1}
            onClick={handleShowCreateGroup}
          >
            Create Group
          </button>
        </div>
      </div>
      {shouldReloadTable ? (
        <div className="loading-spinner" />
      ) : (
        <>
          <FilterForm
            selectedLimit={selectedLimit}
            setSelectedLimit={setSelectedLimit}
            username={username}
            emailAddress={emailAddress}
            handleInputChange={handleInputChange}
            handleFilter={handleFilter}
            handleRefresh={handleRefresh}
            docs={docs}
          />
          {docs ? (
            <Table
              columns={columns}
              data={docs}
              enableRowSelection={isRowSelectionEnabled}
              handleRowSelect={handleRowSelect}
              handleSelectAllRow={handleSelectAllRow}
              tableClass={"table table-borderless table-striped mb-0"}
            />
          ) : (
            <SimpleLoader />
          )}
          {totalPages > 1 && (
            <Pagination
              prevPage={prevPage}
              nextPage={nextPage}
              totalPages={totalPages}
              hasNextPage={hasNextPage}
              hasPrevPage={hasPrevPage}
              setPageNum={setPageNum}
            />
          )}
          {showCreateGroupModal && (
            <GroupModal
              setShowCreateGroupModal={setShowCreateGroupModal}
              selectedIds={selectedIds}
              hasAggregatorWallet={false}
              setSelectedIds={setSelectedIds}
            />
          )}
        </>
      )}
    </div>
  );
}
