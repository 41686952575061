import React, { useState } from "react";
import "./NewMerchantRequest.css";
import CustomSelect from "../../../../components/customSelect/CustomSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LocationForm from "./LocationForm";
import industryOptions from "../../../../utils/IndustryOptions.json";
import AppData from "../../../../config/appData.json";
import handlePostApi from "../../../../api/HandlePostApi";
import axios from "axios";

export default function NewMerchantRequest() {
  const [title, setTitle] = useState("Mr");
  const [notificationType, setNotificationType] = useState("SMS");
  const [reportType, setReportType] = useState("NO");
  const [branch, setBranch] = useState("");
  const [region, setRegion] = useState("");
  const [zone, setZone] = useState("");
  const [industry, setIndustry] = useState("");
  const [selectedSolutions, setSelectedSolutions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [integrateWithThirdParty, setIntegrateWithThirdParty] = useState(false);
  const [enableInternationalCard, setEnableInternationalCard] = useState(false);
  const [fileCount, setFileCount] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);

  console.log("selectedFiles", fileCount, selectedFiles);

  const validationSchema = yup.object().shape({
    account_number: yup.string().required("Account No is required"),
    merchant_address: yup.string().required("Merchant Address is required"),
    contact_phone: yup.string().required("Contact Phone is required"),
    contact_person: yup.string().required("Contact Person is required"),
    contact_title: yup
      .string()
      .required("Contact title is required")
      .default(title),
    notification: yup
      .string()
      .required("Notification is required")
      .default(notificationType),
    merchant_name: yup.string().required("Merchant Name is required"),
    custom_report: yup
      .string()
      .required("Custom Report is required")
      .default(reportType),
    merchant_email: yup
      .string()
      .email("Invalid email")
      .required("Email Address is required"),
    monthly_turnover: yup.string().required("Monthly turnover is required"),
    relationship_manager: yup
      .string()
      .required("Relationship Manager is required"),
    branch: yup.string().required("Branch is required").default(branch),
    region: yup.string().required("Region is required").default(region),
    zone: yup.string().required("Zone is required").default(zone),
    total_outlet: yup.string().required("Total Outlet is required"),
    merchant_solution: yup
      .array()
      .of(yup.string())
      .required("Merchant Solution is required")
      .default(selectedSolutions),
    terminal_locations: yup
      .array()
      .required("Field is required")
      .default(locations),
    total_terminals: yup
      .string()
      .required("Total Terminals is required")
      .min(1, "At least one terminal is required"),
    file: yup
      .array()
      .required("Please select at least one file")
      .default(() => selectedFiles)
      .test(
        "fileCount",
        "You can upload a minimum of two files and a maximum of four files",
        (value) => !value || (value.length >= 2 && value.length <= 4)
      ),
    third_party_integration: yup
      .boolean()
      .required("Third Party is required")
      .default(integrateWithThirdParty),
    international_card: yup
      .boolean()
      .required("International Card is required")
      .default(enableInternationalCard),
    industry: yup.string().required("Industry is required").default(industry),
    product: yup.string().required("Product is required"),
  });

  // const defaultValues = {
  //   file: selectedFiles,
  // };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedSolutions((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedSolutions((prevSelected) =>
        prevSelected.filter((item) => item !== value)
      );
    }
  };

  const handleThirdPartyOption = (event) => {
    const { value } = event.target;
    setIntegrateWithThirdParty(value === "YES");
  };

  const handleEnableInternationalCardChange = (event) => {
    const { value } = event.target;
    setEnableInternationalCard(value === "YES");
  };

  const handleMultipleFileChange = (event) => {
    const files = event.target.files;
    // console.log("files", files);

    const newSelectedFiles = [...selectedFiles, ...Array.from(files)].slice(
      0,
      4
    );
    // console.log("newSelectedFiles", newSelectedFiles, newSelectedFiles.length);

    setSelectedFiles(newSelectedFiles);
    setFileCount(newSelectedFiles.length);
  };

  // console.log("newSelectedFiles", selectedFiles);

  const handleSingleFileChange = (event) => {
    const file = event.target.files[0];

    // Add the single file to the existing selectedFiles array
    setSelectedFiles([...selectedFiles, file]);
    setFileCount([...selectedFiles, file].length);
  };

  const createMerchant = async (requestData) => {
    // Stringify the terminal_locations and merchant_solution arrays
    // console.log("Processing request", requestData);
    const file = [...selectedFiles];

    // console.log("Processing file", file);

    const stringifiedData = {
      ...requestData,
      terminal_locations: JSON.stringify(requestData.terminal_locations),
      merchant_solution: JSON.stringify(requestData.merchant_solution),
    };

    console.log("Processing file", stringifiedData);

    const formData = new FormData();

    // Append each form field to the FormData object
    Object.entries(stringifiedData).forEach(([key, value]) => {
      // Handle arrays by appending each item individually
      // console.log("key & value", key, value);
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(key, value);
      }
    });

    requestData.file.forEach((file, index) => {
      formData.append(`file[${index}]`, file);
    });

    try {
      const endpoint = AppData.BASE_URL + "merchants/onboard";
      console.log("form data", formData);
      // await axios.post(endpoint, formData, {
      //   headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      // });
      // console.log("res", response);
    } catch (error) {
      console.error("Error processing data", error);
    }
  };

  return (
    <div className="new-merchant-request">
      <p className="desc">
        Please complete this form and provide documentary evidence as
        appropriate. Submission of fraudulent documentation and false
        information will lead to the refusal of this application.
      </p>
      <form
        className="new-merchant-form"
        onSubmit={handleSubmit(createMerchant)}
      >
        <div className="row">
          <div className="col">
            <div className="mb-3 row">
              <label
                htmlFor="merchant_email"
                className="col-sm-3 col-form-label"
              >
                Email
              </label>
              <div className="col-sm-8">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  {...register("merchant_email")}
                />
                <div className="invalid-feedback">
                  {errors.merchant_email?.message}
                </div>
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="merchant_name"
                className="col-sm-3 col-form-label"
              >
                Merchant Name
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Merchant Name"
                  {...register("merchant_name")}
                />
                <div className="invalid-feedback">
                  {errors.merchant_name?.message}
                </div>
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="account_number"
                className="col-sm-3 col-form-label"
              >
                Account Number
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Account Number"
                  {...register("account_number")}
                />
                <div className="invalid-feedback">
                  {errors.account_number?.message}
                </div>
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="merchant_address"
                className="col-sm-3 col-form-label"
              >
                Address
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  {...register("merchant_address")}
                />
                <div className="invalid-feedback">
                  {errors.merchant_address?.message}
                </div>
              </div>
            </div>
            <div className="mb-4 row">
              <label htmlFor="notification" className="col-sm-3 col-form-label">
                E.O.D Notification
              </label>
              <div className="col-sm-8">
                <CustomSelect
                  selectedVal={notificationType}
                  setSelectedValue={setNotificationType}
                  items={[
                    {
                      name: "SMS",
                    },
                    {
                      name: "Email",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="mb-3 row">
              <label
                htmlFor="contact_title"
                className="col-sm-3 col-form-label"
              >
                Contact Person
              </label>
              <div className="col-sm-3">
                <CustomSelect
                  selectedVal={title}
                  setSelectedValue={setTitle}
                  items={[
                    {
                      name: "Mr",
                    },
                    {
                      name: "Mrs",
                    },
                    {
                      name: "Miss",
                    },
                  ]}
                />
              </div>
              <div className="col-sm-5">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Contact Person"
                  {...register("contact_person")}
                />
                <div className="invalid-feedback">
                  {errors.contact_person?.message}
                </div>
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="contact_phone"
                className="col-sm-3 col-form-label"
              >
                Contact Number
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Contact Phone Number"
                  {...register("contact_phone")}
                />
                <div className="invalid-feedback">
                  {errors.contact_phone?.message}
                </div>
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="custom_report"
                className="col-sm-3 col-form-label"
              >
                Customised Report
              </label>
              <div className="col-sm-8">
                <CustomSelect
                  selectedVal={reportType}
                  setSelectedValue={setReportType}
                  items={[
                    {
                      name: "YES",
                    },
                    {
                      name: "NO",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="monthly_turnover"
                className="col-sm-3 col-form-label"
              >
                Expected Monthly TurnOver
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Expected Monthly TurnOver"
                  {...register("monthly_turnover")}
                />
                <div className="invalid-feedback">
                  {errors.monthly_turnover?.message}
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="col">
            <div className="mb-3 mt-2 row">
              <label
                htmlFor="relationship_manager"
                className="col-sm-3 col-form-label"
              >
                Relationship Manager Name
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Relationship Manager"
                  {...register("relationship_manager")}
                />
                <div className="invalid-feedback">
                  {errors.relationship_manager?.message}
                </div>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="branch" className="col-sm-3 col-form-label">
                Branch
              </label>
              <div className="col-sm-8">
                <CustomSelect
                  selectedVal={branch || "-Select Branch-"}
                  setSelectedValue={setBranch}
                  items={[
                    {
                      name: "Branch",
                    },
                  ]}
                  defaultOption={{ label: "-Select Branch-", value: "" }}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="region" className="col-sm-3 col-form-label">
                Region
              </label>
              <div className="col-sm-8">
                <CustomSelect
                  selectedVal={region || "-Select Region-"}
                  setSelectedValue={setRegion}
                  items={[
                    {
                      name: "Region",
                    },
                  ]}
                  defaultOption={{ label: "-Select Region-", value: "" }}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="zone" className="col-sm-3 col-form-label">
                Zone
              </label>
              <div className="col-sm-8">
                <CustomSelect
                  selectedVal={zone || "-Select Zone-"}
                  setSelectedValue={setZone}
                  items={[
                    {
                      name: "Zone",
                    },
                  ]}
                  defaultOption={{ label: "-Select Zone-", value: "" }}
                />
              </div>
            </div>
            <div className="mb-3 mt-2 row">
              <label
                htmlFor="relationship_manager"
                className="col-sm-3 col-form-label"
              >
                Do you want your device(s) integrated with a third party
                application?
              </label>
              <div className="col-sm-4">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="third_party_app"
                    id="true"
                    value="YES"
                    onChange={handleThirdPartyOption}
                  />
                  <label className="form-check-label" htmlFor="true">
                    YES
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="third_party_app"
                    id="false"
                    value="NO"
                    onChange={handleThirdPartyOption}
                  />
                  <label className="form-check-label" htmlFor="false">
                    NO
                  </label>
                </div>
              </div>
              <div className="col-sm-4">
                {integrateWithThirdParty && (
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleSingleFileChange}
                    accept=".pdf, .doc, .docx"
                  />
                )}
              </div>
            </div>
            {/* <div className="invalid-feedback">
              {errors.relationship_manager?.message}
            </div> */}
            <div className="mb-3 mt-2 row">
              <label
                htmlFor="international_card"
                className="col-sm-3 col-form-label"
              >
                Should we enable your device(s) for International Card
                Acceptance?
              </label>
              <div className="col-sm-4">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="enable_international_card"
                    id="enableInternationalCardTrue"
                    value="YES"
                    onChange={handleEnableInternationalCardChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="enableInternationalCardTrue"
                  >
                    YES
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="enable_international_card"
                    id="enableInternationalCardFalse"
                    value="NO"
                    onChange={handleEnableInternationalCardChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="enableInternationalCardFalse"
                  >
                    NO
                  </label>
                </div>
              </div>
              <div className="col-sm-4">
                {enableInternationalCard && (
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleSingleFileChange}
                    accept=".pdf, .doc, .docx"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col">
            <div className="mb-3 mt-2 row">
              <label htmlFor="total_outlet" className="col-sm-3 col-form-label">
                Total Number Of Sales Outlet
              </label>
              <div className="col-sm-8">
                <input
                  type="number"
                  className="form-control"
                  placeholder="No of outlets"
                  {...register("total_outlet")}
                />
                <div className="invalid-feedback">
                  {errors.total_outlet?.message}
                </div>
              </div>
            </div>
            <div className="mb-3 mt-2 row">
              <label
                htmlFor="merchant_solution"
                className="col-sm-3 col-form-label"
              >
                Merchant Solution
              </label>
              <div className="col-sm-8">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    value="POS"
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label" htmlFor="inlineCheckbox1">
                    POS
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    value="MPOS"
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label" htmlFor="inlineCheckbox1">
                    MPOS
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox2"
                    value="CGATE"
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label" htmlFor="inlineCheckbox2">
                    CGATE
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <label className="form-check-label" htmlFor="inlineCheckbox3">
                    MARTVILLE
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox3"
                    value="MARTVILLE"
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
            </div>
            <div className="mb-3 mt-2 row">
              <label
                htmlFor="total_terminals"
                className="col-sm-3 col-form-label"
              >
                Total Number Of Terminals
              </label>
              <div className="col-sm-8">
                <input
                  type="number"
                  className="form-control"
                  placeholder="No of terminals"
                  {...register("total_terminals")}
                />
                <div className="invalid-feedback">
                  {errors.total_terminals?.message}
                </div>
              </div>
            </div>
            {watch("total_terminals") > 0 && (
              <LocationForm
                locations={locations}
                setLocations={setLocations}
                totalTerminals={watch("total_terminals")}
              />
            )}
          </div>
          <hr />
          <div className="col mb-5">
            <div className="mb-3 mt-2 row">
              <label
                htmlFor="business-segment"
                className="col-sm-3 col-form-label"
              >
                Merchant Business Segment
              </label>
              <div className="col-sm-8">
                <CustomSelect
                  selectedVal={industry || "-Select Segment-"}
                  setSelectedValue={setIndustry}
                  items={industryOptions}
                  defaultOption={{ label: "-Select Region-", value: "" }}
                />
              </div>
            </div>
            <div className="mb-3 mt-2 row">
              <label htmlFor="product" className="col-sm-3 col-form-label">
                What Do You Do?
              </label>
              <div className="col-sm-8">
                <textarea
                  className="form-control"
                  placeholder="Product"
                  {...register("product")}
                />
                <div className="invalid-feedback">
                  {errors.product?.message}
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="mb-3 mt-2 row">
              <label htmlFor="file" className="col-sm-3 col-form-label">
                Upload Other Files
              </label>
              <div className="col-sm-8">
                <input
                  type="file"
                  multiple
                  accept=".pdf, .doc, .docx"
                  onChange={handleMultipleFileChange}
                />
                {selectedFiles.length > 0 && (
                  <div>
                    <strong>Selected Files:</strong>
                    <ul>
                      {selectedFiles.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {fileCount > 4 && (
                  <p className="text-danger">
                    You can only upload a maximum of four files.
                  </p>
                )}
                <div className="invalid-feedback">{errors.file?.message}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <button className="btn ms-auto" type="submit">
            Create New Merchant
          </button>
        </div>
      </form>
    </div>
  );
}
