import debitIcon from "./debit-request-icon.svg";
import creditIcon from "./credit-request-icon.svg";
import fund from "./fund-icon.svg";
import onboard from "./onboard-icon.svg";
import onlineTerm from "./online-term-2.svg";
import role from "./role-icon.svg";
import b2b from "./b2b-icon.svg";
import invite from "./invite.svg";
import settings from "./settings-icon.svg";
import upgrade from "./upgrade-icon.svg";
import vas from "./vas-icon.svg";
import wallet from "./wallet-icon.svg";
import channel from "./channel-icon.svg";
import card from "./card-icon.svg";
import audit from "./audit-icon.svg";
import authorize from "./authorize-icon.svg";
import logout from "./logout-icon.svg";
import debitAuthorize from "./debit-authorize-icon.svg";
import verve from "./verve-logo.svg";
import visa from "./visa-logo.svg";
import mastercard from "./mastercard.png";
import download from "./download-btn.svg";
import spoutLogo from "./spout-logo.png";
import woman from "./woman-jumping.png";
import show from "./pass-icon.svg";
import passReset from "./reset-pass.png";
import celebrate from "./celebrate.png";
import security from "./security-icon.svg";
import failedImg from "./failed-transaction.png";
import nqr from "./nqr-icon.svg";
import transfer from "./transfer-icon.svg";
import ussd from "./ussd-icon.svg";
import mtn from "./mtn-logo.png";
import airtel from "./airtel-logo.png";
import nineMobile from "./9mobile-logo.png";
import glo from "./glo-logo.jpeg";
import successfulTrans from "./successful-transaction.svg";
import allTrans from "./all-transaction.svg";
import failedTrans from "./failed-transaction-icon.png";
import pendingTrans from "./pending-transaction.svg";
import ekedc from "./ekedc.jpg";
import aedc from "./aedc.png";
import ikedc from "./ikedc.png";
import ibedc from "./ibedc.jpeg";
import jedc from "./jedc.jpeg";
import eedc from "./eedc.png";
import kedco from "./kedco.png";
import phedc from "./phedc.jpeg";
import withdraw from "./withdraw-icon.png";
import transferImg from "./transfer-img.png";
import posIcon from "./pos-icon.jpeg";
import business2b from "./business2b-icon.png";
import mobileIcon from "./mobile-icon.jpeg";
import webIcon from "./web-icon.jpeg";
import androidpos from "./androidpos.jpeg";
import cash from "./cash-icon.png";
import cardIcon from "./debit-card-icon.png";
import fbnIcon from "./fbn.png";
import zenithIcon from "./zenithbank.png";
import ubaIcon from "./uba.png";
import accessIcon from "./accessbank.png";
import gtbIcon from "./gtco.png";
import kadec from "./gray-bg.png";
import startimes from "./startimes-logo.jpg";
import multichoice from "./multichoice.png";
import smile from "./smile-logo-beta.png";

const Images = {
  startimes,
  multichoice,
  smile,
  debitIcon,
  creditIcon,
  fund,
  b2b,
  invite,
  onboard,
  role,
  settings,
  upgrade,
  vas,
  wallet,
  channel,
  card,
  audit,
  authorize,
  debitAuthorize,
  logout,
  verve,
  visa,
  mastercard,
  download,
  spoutLogo,
  woman,
  show,
  passReset,
  celebrate,
  security,
  failedImg,
  nqr,
  transfer,
  ussd,
  mtn,
  airtel,
  nineMobile,
  glo,
  successfulTrans,
  allTrans,
  failedTrans,
  pendingTrans,
  ekedc,
  aedc,
  ikedc,
  ibedc,
  jedc,
  kedco,
  kadec,
  eedc,
  phedc,
  transferImg,
  withdraw,
  webIcon,
  business2b,
  mobileIcon,
  posIcon,
  androidpos,
  cardIcon,
  cash,
  fbnIcon,
  zenithIcon,
  ubaIcon,
  gtbIcon,
  accessIcon,
  onlineTerm,
};

export default Images;
