import React, { useEffect, useState } from "react";
import GetData from "../../../api/GetData";
import AppData from "../../../config/appData.json";
import Table from "../../../components/Table";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import DateRange from "../../../components/DateRange";
import Pagination from "../../../components/Pagination";
import FormatAmount from "../../../helpers/FormatAmount";

export default function SettlementReconciliation() {
  const [history, setHistory] = useState({});
  const [limit, setLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const totalVol = history.data && history?.data?.length;
  const lastPageNum = Math.ceil(totalVol / limit);
  const pageStart = (pageNumber - 1) * limit + 1;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  const columns = React.useMemo(
    () => [
      {
        Header: "S/N",
        Cell: (row) => {
          return <div>{row.row.index + pageStart}</div>;
        },
      },
      {
        Header: "Merchant Name",
        accessor: "merchant_name",
      },
      {
        Header: "Merchant ID",
        accessor: "merchant_id",
      },
      {
        Header: "Terminal ID",
        accessor: "terminal_id",
      },
      {
        Header: "RRN",
        accessor: "rrn",
      },
      {
        Header: "Amount",
        accessor: (amount) => {
          const formattedAmount = FormatAmount(amount.amount);

          return <>{formattedAmount}</>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Transaction Date",
        accessor: "trans_date",
      },
    ],
    []
  );

  const fetchReport = async () => {
    try {
      const endpoint =
        AppData.BASE_URL +
        `reconciliation/?startdate=${formattedStartDate}&enddate=${formattedEndDate}&page=1&limit=50&search=&merchant=`;
      const response = await GetData(endpoint);
      setHistory(response.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  function formatDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  const handleFilter = () => {
    fetchReport();
  };

  useEffect(() => {
    fetchReport();
  }, []);
  return (
    <>
      <div className="p-4">
        <div className="row me-3">
          <div className="col-2 me-3">
            <CustomSelect
              className="custom-select"
              heading="Limit"
              selectedVal={limit}
              setSelectedValue={setLimit}
              items={[
                {
                  name: "50",
                },
                {
                  name: "100",
                },
                {
                  name: "200",
                },
                {
                  name: "500",
                },
                {
                  name: "1000",
                },
              ]}
            />
          </div>
          <div className="col-3">
            <div className="date-filter">
              <p>Filter by Date: </p>
              <DateRange
                startDate={startDate}
                endDate={endDate}
                handleStartDate={(date) => setStartDate(date)}
                handleEndDate={(date) => setEndDate(date)}
              />
            </div>
          </div>
          <div className="col-2">
            <button type="button" className="btn filter" onClick={handleFilter}>
              Filter
            </button>
          </div>
        </div>
      </div>
      {history?.length > 0 ? (
        <div className="table-responsive table-wrapper">
          <Table
            columns={columns}
            data={history}
            tableClass="table table-borderless table-striped table-hover fs-7 mb-0"
            enableRowSelection={false}
          />
        </div>
      ) : (
        <p>No record found</p>
      )}
      {lastPageNum > 1 && (
        <Pagination
          prevPage={pageNumber - 1}
          nextPage={pageNumber + 1}
          totalPages={lastPageNum}
          hasNextPage={pageNumber < lastPageNum}
          hasPrevPage={pageNumber > 1}
          setPageNum={setPageNumber}
        />
      )}
    </>
  );
}
