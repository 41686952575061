import React from "react";
import "./About.css";
import brandgroup1 from "../../assets/images/brandgroup1.png";
import brandgroup2 from "../../assets/images/brandgroup2.png";
import border from "../../assets/images/dotted-border.png";
import whySpout from "../../assets/images/why-spoutpay.png";
import globe from "../../assets/images/globe-icon.png";
import business from "../../assets/images/b2c-icon.png";
import secure from "../../assets/images/security-icon.png";
import start from "../../assets/images/start-icon.svg";

const AboutCard = ({ data }) => {
  return (
    <>
      {data.map((item, index) => (
        <div className="col" key={index}>
          <div className="card">
            <img src={item.icon} alt="about-icon" />
            <h1>{item.heading}</h1>
            <p>{item.description}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default function About() {
  return (
    <div className="row about">
      <p>over 32K+ Businesses and banks growing with spoutpayment</p>
      <div className="brands">
        <img className="group-1" src={brandgroup1} alt="brand1"/>
        <br/>
        <img src={brandgroup2} alt="brand2"/>
      </div>
      <div className="why-spoutpay">
          <div className="heading">
            <img src={whySpout} alt="why spoutpay"/>
          </div>
          <div className="dotted-line">
            <img src={border} alt=""/>
            why Spoutpay
          </div>
        </div>
      <div className="card-section">
        <h3>We Are Specially Designed For <br/> Payment</h3>
        <div className="row">
          <AboutCard
            data={[
              {
                icon: `${secure}`,
                heading: "Super Secure",
                description:
                  "Our payment platform allows consumers to pay within the most secure  environment that aligns with industry standards.",
              },
              {
                icon: `${business}`,
                heading: "Get Started In Seconds",
                description:
                  "We build a unique branded payment experiences and increase conversions. Win more merchants with flexible payment options.",
              },
              {
                icon: `${start}`,
                heading: "Super Secure",
                description:
                  "Easy sign up for a Spoutpoay virtual account online in seconds to use our seamless integration and greet your funds settled in your bank account",
              },
              {
                icon: `${globe}`,
                heading: "Global",
                description:
                  "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
