import React, { useState, useEffect } from "react";
import Table from "../../../../components/Table";
import { CSVLink } from "react-csv";
import Images from "../../../../assets/images/Images";
import CustomSelect from "../../../../components/customSelect/CustomSelect";
import SearchInput from "../../../../components/SearchInput";
import DateRange from "../../../../components/DateRange";
import GetData from "../../../../api/GetData";
import AppData from "../../../../config/appData.json";
import Pagination from "../../../../components/Pagination";
import imageURL from "../../../../imageUrls";
import SimpleLoader from "../../../../components/spinner/SimpleLoader";

export default function History() {
  const [history, setHistory] = useState([]);
  const [status, setStatus] = useState("");
  const [statusVol, setStatusVol] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedStatus, setSelectedStatus] = useState("Status");
  const [selectedMethod, setSelectedMethod] = useState("Card");
  const [limit, setLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const postPerPage = 50;
  const lastPageNum = Math.ceil(statusVol / postPerPage);
  const pageStart = (pageNumber - 1) * postPerPage + 1;

  const borderColors = ["#838383", "#6C540E", "#0584FE", "#FB4013", "#550000"];
  const backgroundColors = [
    "#0000001A",
    "#745A021A",
    "#0584FE19",
    "#FB40131A",
    "#55000019",
  ];

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  const columns = React.useMemo(
    () => [
      {
        Header: "S/N",
        Cell: (row) => {
          return <div>{row.row.index + pageStart}</div>;
        },
      },
      {
        Header: "Customer Name",
        accessor: (name) => (
          <div className="merchant-name">{name.customer_name}</div>
        ),
      },
      {
        Header: "Email Address",
        accessor: "email_address",
      },
      {
        Header: "Phone No",
        accessor: "phone_number",
      },
      {
        Header: "Spout REF",
        accessor: "spout_tx_ref",
      },
      {
        Header: "Amount",
        accessor: (amount) => (
          <div>
            {amount.currency}
            {amount.amount.toLocaleString()}
          </div>
        ),
      },
      {
        Header: "Request Type",
        accessor: "request_type",
      },
      {
        Header: "Bank",
        accessor: (bankPan) => {
          const bankCode = bankPan?.pan?.slice(0, 6);
          const bankImage = imageURL[bankCode];
          if (bankImage) {
            return (
              <div className="select-icon">
                <img src={bankImage} className="service-logo" alt="service" />
              </div>
            );
          } else {
            return <div className="select-icon">N/A</div>;
          }
        },
      },
      {
        Header: "Status",
        accessor: (response) => (
          <div
            style={{
              color:
                response.status === "success" || response.status === "succss"
                  ? "#106745"
                  : response.status === "failed"
                  ? "#DE0000"
                  : "#F1B207",
            }}
          >
            {response.status}
          </div>
        ),
      },
      {
        Header: "Date",
        accessor: (date) => {
          let dDate = date.transaction_date;
          const formatDate = new Date(dDate);
          return <div> {formatDate.toLocaleString()}</div>;
        },
      },
    ],
    [pageNumber]
  );

  const fetchHistoryData = async () => {
    try {
      const endpoint =
        AppData.PAYMENT_URL +
        `transactions/history?limit=${limit}&&page=${pageNumber}&&startdate=${formattedStartDate}&&enddate=${formattedEndDate}`;
      const response = await GetData(endpoint);
      setHistory(response);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const fetchHistoryStatus = async () => {
    try {
      const endpoint =
        AppData.PAYMENT_URL +
        `transactions/stats?startdate=${formattedStartDate}&&enddate=${formattedEndDate}`;
      const response = await GetData(endpoint);
      setStatus(response.data);
      setStatusVol(response.data.total_volume);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  function formatDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  const handleFilter = (e) => {
    e.preventDefault();
    fetchHistoryData();
    fetchHistoryStatus();
  };

  useEffect(() => {
    fetchHistoryData();
  }, [pageNumber]);

  useEffect(() => {
    fetchHistoryStatus();
  }, []);

  return (
    <div className="history">
      <div className="summary-row">
        <div className="row">
          <div className="col ps-0 pe-0">
            <div className="summary-card total-card">
              <p className="total-label">Total Transaction</p>
              {status.total_value !== null &&
              status.total_value !== undefined &&
              status.total_value !== "" ? (
                <p className="total-value">
                  ₦ <span>{status?.total_value?.toLocaleString()}</span>
                </p>
              ) : (
                <SimpleLoader />
              )}
              <p className="count-label">Count</p>
              <p className="count-value">
                {status?.total_volume?.toLocaleString()}
              </p>
            </div>
          </div>
          <div className="col pe-0">
            <div className="summary-card success-card">
              <p className="total-label">Success Amount</p>
              {status.success_value !== null &&
              status.success_value !== undefined &&
              status.success_value !== "" ? (
                <p className="total-value">
                  ₦ <span>{status?.success_value?.toLocaleString()}</span>
                </p>
              ) : (
                <SimpleLoader />
              )}
              <p className="count-label">Count</p>
              <p className="count-value">
                {status?.success_count?.toLocaleString()}
              </p>
            </div>
          </div>
          <div className="col pe-0">
            <div className="summary-card failed-card">
              <p className="total-label">Failed Amount</p>
              {status.failed_value !== null &&
              status.failed_value !== undefined &&
              status.failed_value !== "" ? (
                <p className="total-value">
                  ₦ <span>{status?.failed_value?.toLocaleString()}</span>
                </p>
              ) : (
                <SimpleLoader />
              )}
              <p className="count-label">Count</p>
              <p className="count-value">
                {status?.failed_count?.toLocaleString()}
              </p>
            </div>
          </div>
        </div>
      </div>
      <form className="history-param" onSubmit={handleFilter}>
        <div className="row me-3">
          <div className="row">
            <div className="col-6">
              <CustomSelect
                heading="Limit"
                selectedVal={limit}
                setSelectedValue={setLimit}
                items={[
                  { name: "50" },
                  { name: "100" },
                  { name: "200" },
                  { name: "300" },
                  { name: "400" },
                  { name: "500" },
                ]}
              />
            </div>
            <div className="col-6">
              <CustomSelect
                heading="Status"
                selectedVal={selectedStatus}
                setSelectedValue={setSelectedStatus}
                items={[
                  {
                    name: "All Status",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div>
          <SearchInput />
        </div>
        <div>
          <div className="d-flex">
            <div className="date-filter">
              <p>Filter by Date: </p>
              <DateRange
                startDate={startDate}
                endDate={endDate}
                handleStartDate={(date) => setStartDate(date)}
                handleEndDate={(date) => setEndDate(date)}
              />
            </div>
            <button className="filter-btn ms-3" type="submit">
              Filter
            </button>
            <button type="button" className="downloads">
              <img src={Images.download} alt="download" />
            </button>
          </div>
        </div>
      </form>
      <div className="export">
        {history.data && history.data.length > 0 && (
          <CSVLink
            data={history.data}
            filename="History"
            className="export-btn btn"
          >
            Export Data
          </CSVLink>
        )}
      </div>
      <h6 className="mt-3">Transaction History</h6>
      <div className="history-table table-responsive">
        {history.data && history.data.length > 0 ? (
          <Table
            columns={columns}
            data={history.data}
            enableRowSelection={false}
            tableClass={"table table-borderless table-striped mb-0"}
          />
        ) : (
          <p>No record found</p>
        )}
        {lastPageNum > 1 && (
          <Pagination
            prevPage={pageNumber - 1}
            nextPage={pageNumber + 1}
            totalPages={lastPageNum}
            hasNextPage={pageNumber < lastPageNum}
            hasPrevPage={pageNumber > 1}
            setPageNum={setPageNumber}
          />
        )}
      </div>
    </div>
  );
}
