// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spout-landing .navbar {
    background-color: #FAFAFF;
    border: 1px solid #D9D9D9;
    opacity: 1;
    height: 80px;
    position: fixed;
    width: 100%;
    z-index: 2;
    padding-inline: 75px;
    color: #060D27;
}

.spout-landing .nav-logo {
    margin-right: 50px;
}

.spout-landing .enterance .btn {
    border: none;
    border-radius: 10px;
    opacity: 1;
    padding-inline: 25px;
    font-weight: 600;
}

.signin-btn {
    background-color: #F7D600;
    color: #060D27;
}

.signin-btn:hover {
    background-color: #f4e375;
    color: #060D27;
}

.signup-btn {
    background-color: #060D27;
    color: #ffffff;
}

.signup-btn:hover {
    background-color: #101d51;
    color: #ffffff;
}

.spout-landing .navbar .nav{
    margin-right: auto;
    display: flex;
    gap: 15px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.5px;
}

.spout-landing .nav-item a {
    text-decoration: none;
    letter-spacing: 0px;
    color: #060D27;
    opacity: 1;
    font-weight: 400;
}

.spout-landing .nav-item a:hover {
    color: #F7D600;
}`, "",{"version":3,"sources":["webpack://./src/components/navbar/Navbar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,UAAU;IACV,YAAY;IACZ,eAAe;IACf,WAAW;IACX,UAAU;IACV,oBAAoB;IACpB,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,UAAU;IACV,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,SAAS;IACT,gBAAgB;IAChB,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,cAAc;IACd,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".spout-landing .navbar {\n    background-color: #FAFAFF;\n    border: 1px solid #D9D9D9;\n    opacity: 1;\n    height: 80px;\n    position: fixed;\n    width: 100%;\n    z-index: 2;\n    padding-inline: 75px;\n    color: #060D27;\n}\n\n.spout-landing .nav-logo {\n    margin-right: 50px;\n}\n\n.spout-landing .enterance .btn {\n    border: none;\n    border-radius: 10px;\n    opacity: 1;\n    padding-inline: 25px;\n    font-weight: 600;\n}\n\n.signin-btn {\n    background-color: #F7D600;\n    color: #060D27;\n}\n\n.signin-btn:hover {\n    background-color: #f4e375;\n    color: #060D27;\n}\n\n.signup-btn {\n    background-color: #060D27;\n    color: #ffffff;\n}\n\n.signup-btn:hover {\n    background-color: #101d51;\n    color: #ffffff;\n}\n\n.spout-landing .navbar .nav{\n    margin-right: auto;\n    display: flex;\n    gap: 15px;\n    font-weight: 500;\n    font-size: 14px;\n    letter-spacing: 0.5px;\n}\n\n.spout-landing .nav-item a {\n    text-decoration: none;\n    letter-spacing: 0px;\n    color: #060D27;\n    opacity: 1;\n    font-weight: 400;\n}\n\n.spout-landing .nav-item a:hover {\n    color: #F7D600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
