import axios from "axios";

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const getUserType = (userData) => {
  if (userData.adminType) {
    localStorage.setItem("userType", "admin");
  } else {
    localStorage.setItem("userType", "user");
  }
};

// Add an interceptor to handle the response
api.interceptors.response.use(
  (response) => {
    const { data } = response;

    if (data) {
      if (data.userData) {
        getUserType(data.userData); // Assuming getUserType is defined and operates correctly
      }
      if (data.token) {
        localStorage.setItem("token", data.token);
      }
      if (data.data && data.data.token) {
        localStorage.setItem("token", data.data.token);
      }
    }

    return data; // Always return the modified data or the original response
  },
  (error) => {
    console.error("Error fetching data", error);
    return Promise.reject(error);
  }
);

export default function postData(url, data) {
  return api.post(url, data);
}
