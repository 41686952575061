// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    padding: 50px 85px;
    color: #ffffff;
}

.footer h1 {
    font-size: 18px;
    font-weight: 600;
}

.footer p {
    margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/view/landing/Footer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".footer {\n    padding: 50px 85px;\n    color: #ffffff;\n}\n\n.footer h1 {\n    font-size: 18px;\n    font-weight: 600;\n}\n\n.footer p {\n    margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
