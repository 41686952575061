import React, { useEffect, useState } from "react";
import HandlePostPayment from "../../api/HandlePostPayment";
import AppData from "../../config/appData.json";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import PinValidationModal from "../modal/PinValidationModal";
import imageURL from "../../imageUrls";

export default function Data({ dataDiscos }) {
  const [transactionPin, setTransactionPin] = useState("");
  const [showPinModal, setShowPinModal] = useState(false);
  const [dataService, setDataService] = useState("mtndata");
  // const [tariffCode, setTariffCode] = useState("");
  const [dataResponse, setDataResponse] = useState("");
  const [dataPackages, setDataPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("");
  const channel = "b2b";
  const uniqueId = uuidv4();
  const transID = dataResponse.data?.transactionId;
  const [paymentData, setPaymentData] = useState([]);
  const items = paymentData?.tariff?.split(" ");
  const code = items && items[0];
  const amount = items && items[1];

  const requestUrl = AppData.VAS_URL + "vas/data/payment";
  const info = `Your Subscription for N${amount} Is Successfully Delivered To`;
  const extraInfo = paymentData.phoneNumber;
  const requestData = {
    transactionId: paymentData.transactionId,
    phoneNumber: paymentData.phoneNumber,
    code: code,
    amount: amount,
    service: paymentData.service,
    paymentMethod: "cash",
    uniqueId: paymentData.uniqueId,
    pin: transactionPin,
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const schema = yup.object().shape({
    transactionId: yup.string().required().default(transID),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid!")
      .required(),
    tariff: yup.string().required("This field is required"),
    // amount: yup.string().required().default("paymentAmount"),
    uniqueId: yup.string().required().default(uniqueId),
    service: yup.string().required("Field is required"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleServiceChange = (e) => {
    setValue("service", e.target.value, {
      shouldValidate: true,
    });
    setDataService(e.target.value);
  };

  const handleSelectPackage = (e) => {
    setValue("tariff", e.target.value, {
      shouldValidate: true,
    });
    // setTariffCode(e.target.value);
  };

  const handleFormSubmit = (requestData) => {
    setPaymentData(requestData);
    setShowPinModal(true);
  };

  useEffect(() => {
    const url = AppData.VAS_URL + "vas/data/validation";
    const validationData = { service: dataService, channel: channel };

    const fetchDataPackages = async () => {
      try {
        const response = await HandlePostPayment(
          validationData,
          setIsLoading,
          url
        );
        if (response.data.responseCode === "00") {
          console.log(response);
          setDataResponse(response);
          setDataPackages(response.data.response.data);
        } else {
          setMsg(response.data.message);
        }
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    fetchDataPackages();
  }, [dataService]);

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div
          className={
            errors.service ? "grouped-input error" : "grouped-input default"
          }
        >
          <label>Select Disco</label>
          <select
            className="form-control"
            {...register("service")}
            onChange={(e) => handleServiceChange(e)}
          >
            {dataDiscos &&
              dataDiscos.map((disco, index) => (
                <option key={index}>{disco}</option>
              ))}
          </select>
          <div className="select-icon">
            <img
              className="service-logo"
              src={imageURL[dataService]}
              alt="service"
            />
            <div>
              <i className="fa-solid fa-angle-down"></i>
            </div>
          </div>
          <small>{errors.service?.message}</small>
        </div>
        <div
          className={
            errors.tariff ? "grouped-input error" : "grouped-input default"
          }
        >
          <label>Choose Package</label>
          <select
            className="form-control"
            {...register("tariff")}
            onChange={(e) => handleSelectPackage(e)}
          >
            <option value="">Select Data Plan</option>
            {dataPackages.map((data, index) => (
              <option key={index} value={data.datacode + " " + data.price}>
                {data.name} {"for"} N{data.amount}
              </option>
            ))}
          </select>
          <div className="select-icon">
            <div>
              <i className="fa-solid fa-angle-down"></i>
            </div>
          </div>
          <small>{errors.tariff?.message}</small>
        </div>
        <div
          className={
            errors.phoneNumber ? "grouped-input error" : "grouped-input default"
          }
        >
          <label>Beneficiary Phone Number</label>
          <input
            type="tel"
            className="form-control"
            placeholder="00000000000000"
            {...register("phoneNumber")}
          />
          <small>{errors.phoneNumber?.message}</small>
        </div>
        <div className="continue">
          <button type="submit" className="btn">
            Continue
          </button>
        </div>
      </form>
      {showPinModal && (
        <PinValidationModal
          setShowModal={setShowPinModal}
          requestData={requestData}
          serviceIcon={imageURL[dataService]}
          setMsg={setMsg}
          url={requestUrl}
          transactionPin={transactionPin}
          setTransactionPin={setTransactionPin}
          transType="airtime"
          info={info}
          extraInfo={extraInfo}
        />
      )}
    </>
  );
}
