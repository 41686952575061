// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.experience {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D6D9E5;
    padding: 30px 85px;
    text-align: center;
}

.experience h1 {
    color: #060D27;
    letter-spacing: -1px;
    margin-bottom: 30px;
}

.experience h2 {
    font-size: 14px;
    font-weight: 600;
    color: #002E5B;
}

.experience p {
    font-size: 32px;
    font-weight: 600;
}

.testimonies {
    margin-top: 20px;
}

.testimonies img {
    height: 170px;
}

.testimonies .female {
    display: flex;
}

.testimonies .testifier {
    margin-left: 130px;
}`, "",{"version":3,"sources":["webpack://./src/view/landing/Experience.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".experience {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #D6D9E5;\n    padding: 30px 85px;\n    text-align: center;\n}\n\n.experience h1 {\n    color: #060D27;\n    letter-spacing: -1px;\n    margin-bottom: 30px;\n}\n\n.experience h2 {\n    font-size: 14px;\n    font-weight: 600;\n    color: #002E5B;\n}\n\n.experience p {\n    font-size: 32px;\n    font-weight: 600;\n}\n\n.testimonies {\n    margin-top: 20px;\n}\n\n.testimonies img {\n    height: 170px;\n}\n\n.testimonies .female {\n    display: flex;\n}\n\n.testimonies .testifier {\n    margin-left: 130px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
