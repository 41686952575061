import React, { useState, useEffect } from "react";
import Table from "../../../components/Table";
import { useNavigate } from "react-router-dom";
import AppData from "../../../config/appData.json";
import GetData from "../../../api/GetData";

export default function Roles() {
  const navigate = useNavigate();
  const [history, setHistory] = useState([]);
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("");

  const columns = React.useMemo(
    () => [
      {
        Header: "Default Roles",
        accessor: (role) => (
          <>
            <div>{role.role}</div>
          </>
        ),
      },
      {
        Header: "permissions",
        accessor: (permissions) => (
          <>
            <div>{permissions.permissions.toString()}</div>
          </>
        ),
      },
    ],
    []
  );

  const handleErrorFunc = (error) => {
    if (error.response.data.message === "Expired Session, Please Login Again") {
      navigate("/signin");
    } else {
      setMsg(error.response.data.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = AppData.WALLET_URL + "admin/roles-permissions";
        const response = await GetData(endpoint);

        setHistory(response.data);
      } catch (error) {
        handleErrorFunc(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="member">
      <div className="header">
        <h6 className="mt-2">Manage Roles</h6>
        {/* <div className="add-member">
          <button className="btn add-member-btn">Add Custom Role</button>
        </div> */}
      </div>
      <div className="m-3">
        <Table
          columns={columns}
          data={history}
          tableClass={"table table-borderless table-striped mb-0"}
        />
      </div>
    </div>
  );
}
