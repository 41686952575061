import React from "react";
import "./MerchantManagement.css";
import DropdownMenu from "../../../../components/DropdownMenu";
import MerchantView from "./view-merchants/MerchantView";
import TerminalView from "./view-terminals/TerminalView";

export default function MerchantManagement() {
  const Merchant = <MerchantView />;
  const Terminal = <TerminalView />;
  return (
    <div>
      <DropdownMenu
        label="Merchant Management"
        items={[
          { name: "View Terminal", content: Terminal },
          { name: "View Merchant", content: Merchant },
        ]}
      />
    </div>
  );
}
