import React from 'react';
import "./Footer.css";
import spoutLogo from "../../assets/images/spout-logo1.png";

export default function Footer() {
  return (
    <div className='footer'>
        <div className='row'>
            <div className='col'>
                <img src={spoutLogo} alt="logo"/>
                <div className='mt-5 mb-4'>
                    <p>2a, Ribadu street, Awolowo Road.<b/> Ikoyi, Lagos-Nigeria</p>
                </div>
                <div>
                    <p>Call us</p>
                    <p>+234901233577</p>
                    <p>+234803453666</p>
                </div>
            </div>
            <div className='col'>
                <h1>Who Are We</h1>
            </div>
            <div className='col'>
                <h1>Developers</h1>
            </div>
            <div className='col'>
                <h1>Products</h1>
            </div>
        </div>
    </div>
  )
}
