import React from "react";
import "./Tab.css";

const Tab = ({
  activeTab,
  tabClass,
  activeTabClass,
  tabName,
  handleTabChange,
  index,
}) => {
  return (
    <li
      className={index === activeTab ? activeTabClass : tabClass}
      onClick={handleTabChange}
    >
      {tabName}
    </li>
  );
};

export default Tab;
