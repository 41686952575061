import React from "react";
import { useTable, useRowSelect } from "react-table";
import "./Table.css";

function Table({
  columns,
  data,
  enableRowSelection,
  handleRowSelect,
  handleSelectAllRow,
  tableClass
}) {
  const initialState = { hiddenColumns: ["Action"] };
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds },
    toggleAllRowsSelected,
    toggleRowSelected,
  } = useTable(
    {
      columns,
      data,
      initialState,
    },
    enableRowSelection ? useRowSelect : undefined, // Conditionally apply useRowSelect
    (hooks) => {
      // Only modify columns if row selection is enabled
      if (enableRowSelection) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <input
                type="checkbox"
                {...getToggleAllRowsSelectedProps()}
                onChange={(e) => {
                  const allRowIds = data.map((row) => row.walletId);
                  toggleAllRowsSelected(e.target.checked);
                  handleSelectAllRow(allRowIds, e.target.checked);
                }}
              />
            ),
            Cell: ({ row }) => (
              <input
                type="checkbox"
                {...row.getToggleRowSelectedProps()}
                checked={row.isSelected}
                onChange={() => {
                  toggleRowSelected(row.id, !row.isSelected);
                  handleRowSelect(row);
                }}
              />
            ),
          },
          ...columns,
        ]);
      }
    }
  );
  // Render the UI for your table
  return (
    <table
      {...getTableProps()}
      className={tableClass}
    >
      <thead className="text-secondary border-0 text-uppercase">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr key={i} {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
export default Table;
