import React, { useState, useEffect } from "react";
import Table from "../../../components/Table";
import AppData from "../../../config/appData.json";
import handlePostApi from "../../../api/HandlePostApi";
import { useNavigate } from "react-router-dom";
import GroupModal from "../../modal/GroupModal";
import Pagination from "../../../components/Pagination";
import FilterForm from "./FilterForm";
import SimpleLoader from "../../../components/spinner/SimpleLoader";

export default function AssignedAgentsList({ setShowUserData, setUserId }) {
  const [agents, setAgents] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [shouldReloadTable, setShouldReloadTable] = useState(false);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [username, setUsername] = useState("");
  const [selectedLimit, setSelectedLimit] = useState("50");

  const navigate = useNavigate();

  const requestData = {
    page: pageNum,
    agentType: "agent",
    email: "",
    username: "",
    hasAggregatorWallet: true,
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmailAddress(value);
    } else if (name === "username") {
      setUsername(value);
    }
  };

  const fetchData = async (data) => {
    try {
      const url = AppData.WALLET_URL + "admin/get-users";

      const { users } = await handlePostApi(url, data);
      setAgents(users);
    } catch (error) {
      const { response } = error;
      const err = response?.data?.message;
      console.error("Error fetching agents:", error);
      if (err === "Expired Session, Please Login Again") {
        navigate("/signin");
      }
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();
    const data = {
      page: pageNum,
      agentType: "agent",
      hasAggregatorWallet: true,
      email: emailAddress,
      username: username,
    };
    fetchData(data);
  };

  const handleRefresh = (e) => {
    e.preventDefault();
    setEmailAddress("");
    setUsername("");
    setPageNum(1);
    setSelectedLimit(50);
    fetchData(requestData);
  };

  useEffect(() => {
    fetchData(requestData);
  }, [pageNum, selectedLimit]);

  useEffect(() => {
    setShouldReloadTable(true);
  }, []);

  useEffect(() => {
    if (shouldReloadTable) {
      setShouldReloadTable(false);
    }
  }, [shouldReloadTable]);

  const handleReassignAgent = (rowId) => {
    const walletId = rowId.walletId;
    setSelectedIds((prevSelectedIds) => {
      const isSelectedId = prevSelectedIds.includes(walletId);
      if (isSelectedId) {
        return prevSelectedIds.filter((selectedId) => selectedId !== walletId);
      } else {
        return [...prevSelectedIds, walletId];
      }
    });
    setShowCreateGroupModal(true);
  };

  const handleOpenUserProfile = (user) => {
    setShowUserData(true);
    setUserId(user._id);
  };

  const columns = [
    {
      Header: "Wallet Id",
      accessor: "walletId",
      Cell: (tabinstance) => {
        const { row: index } = tabinstance;
        const indexData = index.original;
        return (
          <button
            className="wallet-link-btn"
            onClick={() => handleOpenUserProfile(indexData)}
          >
            {indexData.walletId}
          </button>
        );
      },
    },
    {
      Header: "VFD",
      accessor: (vfd) => <>{vfd?.vfdAcctDetails?.accountNo}</>,
    },
    {
      Header: "Username",
      accessor: "username",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "User Type",
      accessor: "agentType",
    },
    {
      Header: "Group ID",
      accessor: "aggregatorWallet",
    },
    {
      Header: "Phone No",
      accessor: "phoneNumber",
    },
    {
      Header: "Office State",
      accessor: "officeState",
    },
    {
      Header: "Office LGA",
      accessor: "officeLga",
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row }) => (
        <button
          className="reassign-btn"
          onClick={() => handleReassignAgent(row.original)}
        >
          Reassign
        </button>
      ),
    },
  ];

  const { docs, hasNextPage, hasPrevPage, nextPage, prevPage, totalPages } =
    agents;

  return (
    <div className="pt-4">
      {shouldReloadTable ? (
        <div className="loading-spinner" />
      ) : (
        <>
          {docs ? (
            <>
              <FilterForm
                selectedLimit={selectedLimit}
                setSelectedLimit={setSelectedLimit}
                username={username}
                emailAddress={emailAddress}
                handleInputChange={handleInputChange}
                handleFilter={handleFilter}
                handleRefresh={handleRefresh}
                docs={docs}
              />
              <Table
                columns={columns}
                data={docs}
                enableRowSelection={false}
                tableClass={"table table-borderless table-striped mb-0"}
              />
            </>
          ) : (
            <SimpleLoader />
          )}
          {totalPages > 1 && (
            <Pagination
              prevPage={prevPage}
              nextPage={nextPage}
              totalPages={totalPages}
              hasNextPage={hasNextPage}
              hasPrevPage={hasPrevPage}
              setPageNum={setPageNum}
            />
          )}
          {showCreateGroupModal && (
            <GroupModal
              setShowCreateGroupModal={setShowCreateGroupModal}
              selectedIds={selectedIds}
              hasAggregatorWallet={true}
              setSelectedIds={setSelectedIds}
            />
          )}
        </>
      )}
    </div>
  );
}
