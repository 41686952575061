import React from "react";
import Images from "../../assets/images/Images";

export default function TransactionSuccess({info, extraInfo, receiverName }) {
  return (
    <div className="trans-success">
      <div className="modal">
        <div className="modal-content">
          <h4>Congratulations</h4>
          <img src={Images.celebrate} alt="" />
          <p>
            {info}
          </p>
          <p className="token">{extraInfo}</p>
          <p>{receiverName}</p>
          <a href="/user">
            <button className="btn">Back To Homepage</button>
          </a>
        </div>
      </div>
    </div>
  );
}
