import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/Table";
import Pagination from "../../../components/Pagination";
import AppData from "../../../config/appData.json";
import handlePostApi from "../../../api/HandlePostApi";
import UserProfile from "./UserProfile";
import FilterForm from "./FilterForm";
import SimpleLoader from "../../../components/spinner/SimpleLoader";
import Modal from "../../../components/modal/Modal";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import { ToastContainer, toast } from "react-toastify";

export default function WebUsers() {
  const [allUsers, setAllUsers] = useState({});
  const [pageNum, setPageNum] = useState(1);
  const [showUserData, setShowUserData] = useState(false);
  const [userId, setUserId] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [username, setUsername] = useState("");
  const [selectedLimit, setSelectedLimit] = useState("50");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const requestData = {
    page: pageNum,
    agentType: "user",
    email: "",
    username: "",
    limit: selectedLimit,
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmailAddress(value);
    } else if (name === "username") {
      setUsername(value);
    }
  };

  const handleOpenUserProfile = (user) => {
    setShowUserData(true);
    setUserId(user._id);
  };

  const handleModal = (user) => {
    setIsModalOpen(true);
    setUserId(user);
  };

  const fetchData = async (data) => {
    try {
      const url = AppData.WALLET_URL + "admin/get-users";
      const { users } = await handlePostApi(url, data);
      setAllUsers(users);
    } catch (error) {
      const { response } = error;
      const err = response?.data?.message;
      console.error("Error fetching transactions:", error);
      if (err === "Expired Session, Please Login Again") {
        navigate("/signin");
      }
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Wallet Id",
        accessor: "walletId",
        Cell: (tabinstance) => {
          const { row: index } = tabinstance;
          const indexData = index.original;
          return (
            <button
              className="wallet-link-btn"
              onClick={() => handleOpenUserProfile(indexData)}
            >
              {indexData.walletId}
            </button>
          );
        },
      },
      {
        Header: "Username",
        accessor: "username",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "User Type",
        accessor: "agentType",
      },
      {
        Header: "Phone No",
        accessor: "phoneNumber",
      },
      {
        Header: "Address",
        accessor: (address) => (
          <div style={{ width: "250px" }}>{address.address}</div>
        ),
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => (
          <button
            className="update-modal-btn"
            onClick={() => handleModal(row.original)}
          >
            Upgrade
          </button>
        ),
      },
    ],
    [pageNum]
  );

  const handleFilter = (e) => {
    e.preventDefault();
    const data = {
      page: pageNum,
      agentType: "user",
      email: emailAddress,
      username: username,
    };
    fetchData(data);
  };

  const handleRefresh = (e) => {
    e.preventDefault();
    setEmailAddress("");
    setUsername("");
    setPageNum(1);
    setSelectedLimit(50);
    fetchData(requestData);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    fetchData(requestData);
  };

  useEffect(() => {
    if (message?.trim().length > 0) {
      toast(message);
      setMessage(""); // Clear the message after displaying the toast
    }
  }, [message]);

  useEffect(() => {
    fetchData(requestData);
  }, [pageNum, selectedLimit]);

  const { docs, hasNextPage, hasPrevPage, nextPage, prevPage, totalPages } =
    allUsers;

  // console.log("assddmlk", totalPages, hasNextPage, pageNum, hasPrevPage);

  return (
    <div className="mt-4 users-list">
      {showUserData ? (
        <UserProfile userId={userId} setShowUserData={setShowUserData} />
      ) : (
        <>
          {docs ? (
            <>
              <FilterForm
                selectedLimit={selectedLimit}
                setSelectedLimit={setSelectedLimit}
                username={username}
                emailAddress={emailAddress}
                handleInputChange={handleInputChange}
                handleFilter={handleFilter}
                handleRefresh={handleRefresh}
                docs={docs}
              />
              <Table
                columns={columns}
                data={docs}
                enableRowSelection={false}
                tableClass={"table table-borderless table-striped mb-0"}
              />
              {totalPages > 1 && (
                <Pagination
                  prevPage={prevPage}
                  nextPage={nextPage}
                  totalPages={totalPages}
                  hasNextPage={hasNextPage}
                  hasPrevPage={hasPrevPage}
                  setPageNum={setPageNum}
                />
              )}
            </>
          ) : (
            <SimpleLoader />
          )}
        </>
      )}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          content={
            <UpgradeUser
              user={userId}
              onClose={closeModal}
              setMessage={setMessage}
            />
          }
        />
      )}
      <ToastContainer />
    </div>
  );
}

const UpgradeUser = ({ user, setMessage, onClose }) => {
  const [selectedType, setSelectedType] = useState("");

  const handleUserUpgrade = async (e) => {
    e.preventDefault();
    const requestData = { newUserType: selectedType };
    try {
      const endpoint = AppData.VAS_URL + `admin/upgrade-user/${user._id}`;
      const response = await handlePostApi(endpoint, requestData);
      setMessage(response.message);
      onClose();
    } catch (error) {
      console.error("Error Upgrading user", error);
      setMessage(error.response.data.message || "error processing request");
      onClose();
    }
  };
  return (
    <form onSubmit={handleUserUpgrade}>
      <h4>Change User Type</h4>
      <p>
        Current Type: <span className="current-type">{user.agentType}</span>
      </p>
      <p>Select from the list below to change user type</p>
      <CustomSelect
        heading="User Type"
        selectedVal={selectedType ? selectedType : "Select User Type"}
        setSelectedValue={setSelectedType}
        items={[
          { name: "agent" },
          { name: "b2b" },
          { name: "aggregator" },
          { name: "aggregator" },
          { name: "trade-partner" },
          { name: "settleMerchant" },
        ]}
        defaultOption={{ label: "Select User Type", value: "" }}
      />
      <button className="upgrade-btn" type="submit">
        Update
      </button>
    </form>
  );
};
