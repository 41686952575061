import React, { useState } from "react";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import HandlePostApi from "../../../api/HandlePostApi";
import AppData from "../../../config/appData.json";

export default function RequestModal({ setShowModal }) {
  const [selectedVal, setSelectedValue] = useState("Credit");
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("");

  const url = AppData.VAS_URL + "admin/credit-user-wallet";

  const schema = yup.object().shape({
    walletId: yup.string().required("Wallet ID is required!"),
    amount: yup
      .number()
      .typeError("The value must be a number")
      .integer("The value must be a number")
      .required("This field is required"),
    reference: yup.string().required("Transaction reerence is required!"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFormSubmit = (data) => {
    if (selectedVal === "Credit") {
      HandlePostApi(url, data, setErr).then((result) => {
        const { status, message } = result;
        if (status === "error") {
          setErr(true);
          setMsg(message[0]);
          return;
        }
        setShowModal(false);
      });
    } else {
      HandlePostApi(
        AppData.VAS_URL + "admin/debit-user-wallet",
        data,
        setErr
      ).then((result) => {
        const { status, message } = result;
        if (status === "error") {
          setErr(true);
          setMsg(message[0]);
          return;
        }
        setShowModal(false);
      });
    }
  };

  return (
    <div className="modal raise-charge-modal">
      <div className="modal-content">
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="form-container">
            <div className="header">Request Debit / Credit</div>
            <div className="row p-4">
              {msg && <small>{msg}</small>}
              <div className="col-4 mb-3">
                <CustomSelect
                  className="custom-select"
                  heading="Request Type"
                  selectedVal={selectedVal}
                  setSelectedValue={setSelectedValue}
                  items={[
                    {
                      name: "Credit",
                    },
                    {
                      name: "Debit",
                    },
                  ]}
                />
              </div>
              <div className="col-4 mb-3">
                <div className="grouped-input">
                  <label htmlFor="amount">Input Amount</label>
                  <input
                    type="text"
                    className={
                      errors.amount ? "form-control error" : "form-control"
                    }
                    placeholder="0.00"
                    {...register("amount")}
                  />
                  <small>{errors.amount?.message}</small>
                </div>
              </div>
              <div className="col-4 mb-3">
                <div className="grouped-input">
                  <label htmlFor="walletId">Wallet ID</label>
                  <input
                    type="tel"
                    className={
                      errors.walletId ? "form-control error" : "form-control"
                    }
                    placeholder="1234567890"
                    {...register("walletId")}
                  />
                  <small>{errors.walletId?.message}</small>
                </div>
              </div>
              <div className="col-5 mb-3">
                <div className="grouped-input">
                  <label>Reference</label>
                  <input
                    type="text"
                    className={
                      errors.amount ? "form-control error" : "form-control"
                    }
                    {...register("reference")}
                  />
                </div>
                <small>{errors.reference?.message}</small>
              </div>
            </div>
          </div>
          <div className="form-footer">
            <div className="charge-action mt-3">
              <button className="action-btn cancel" onClick={handleCloseModal}>
                Cancel
              </button>
              <button className="action-btn create" type="submit">
                Proceed to Request
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
