import React, { useState } from "react";
import Table from "../../components/Table";
import { useEffect } from "react";
import AppData from "../../config/appData.json";
import handlePostApi from "../../api/HandlePostApi";
import FormatCurrency from "../../components/FormatCurrency";
import FormatDate from "../../components/FormatDate";
import imageURL from "../../imageUrls";
import Pagination from "../../components/Pagination";
import CustomSelect from "../../components/customSelect/CustomSelect";
import SearchInput from "../../components/SearchInput";
import DateRange from "../../components/DateRange";
import SimpleLoader from "../../components/spinner/SimpleLoader";

const getStatusStyles = (status) => {
  let backgroundColor, color;

  if (status === "successful") {
    color = "#106745";
  } else if (status === "failed") {
    color = "#DE0000";
  } else {
    color = "#F1B207";
  }

  return {
    backgroundColor,
    color,
    paddingTop: "4px",
    paddingLeft: "6px",
    width: "80px",
  };
};

export default function History() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const firstDayOfYear = new Date(currentYear, 0, 1);
  const [loadingData, setLoadingData] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState({});
  const [limit, setLimit] = useState(50);
  const [pageNum, setPageNum] = useState(1);
  const [selectedVal, setSelectedValue] = useState("Successful");
  const [startDate, setStartDate] = useState(firstDayOfYear);
  const [endDate, setEndDate] = useState(new Date());

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: (date) => FormatDate(date.createdAt),
      },
      {
        Header: "Service",
        accessor: (dataName) => (
          <div className="select-icon">
            <img
              src={imageURL[dataName.product]}
              className="service-logo"
              alt="service"
            />
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: "product",
      },
      {
        Header: "Payment",
        accessor: (paymentMethod) => (
          <>
            {paymentMethod.paymentMethod}{" "}
            <img
              src={imageURL[paymentMethod.paymentMethod]}
              className="service-logo"
              alt="payment method"
            />
          </>
        ),
      },
      {
        Header: "Amount",
        accessor: (amount) => FormatCurrency(amount.amount),
      },
      {
        Header: "Channel",
        accessor: (channel) => (
          <>
            {channel.channel}{" "}
            <img
              src={imageURL[channel.channel]}
              className="service-logo"
              alt="service"
            />
          </>
        ),
      },
      {
        Header: "Status",
        accessor: (status) => (
          <div style={getStatusStyles(status.status)}>
            • <span style={{ fontSize: "11px" }}>{status.status}</span>
          </div>
        ),
      },
      {
        Header: "Ref",
        accessor: "reference",
      },
      {
        Header: "",
        accessor: "id",
        Cell: (view) => (
          <a style={{ color: "blue", fontSize: "12px", fontWeight: "100" }}>
            view
          </a>
        ),
      },
    ],
    []
  );

  const fetchTransactions = async () => {
    try {
      const url = AppData.WALLET_URL + "user/transaction-history";
      const requestData = {
        page: pageNum,
        limit: limit,
        pageNumber: pageNum,
        startDate: startDate,
        endDate: endDate,
      };

      const response = await handlePostApi(url, requestData);
      setTransactionHistory(response.transactions);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  useEffect(() => {
    const fetchTransactionsData = async () => {
      setLoadingData(true);
      try {
        await fetchTransactions();
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setLoadingData(false);
      }
    };

    fetchTransactionsData();
  }, []);

  useEffect(() => {
    const fetchTransactionsData = async () => {
      try {
        await fetchTransactions();
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchTransactionsData();
  }, [pageNum]);

  const handleFilter = (event) => {
    event.preventDefault();
    fetchTransactions();
  };

  const { docs, hasNextPage, hasPrevPage, nextPage, prevPage, totalPages } =
    transactionHistory;

  return (
    <main className="col">
      <div className="row mt-3 mb-3">
        <div className="col-2">
          <div className="channel">
            <CustomSelect
              className="custom-select mb-3"
              heading="Status"
              selectedVal={selectedVal}
              setSelectedValue={setSelectedValue}
              items={[
                {
                  name: "Successful",
                },
                {
                  name: "Pending",
                },
                {
                  name: "Failed",
                },
              ]}
            />
          </div>
        </div>
        <div className="col-3">
          <SearchInput />
        </div>
        <div className="col-3">
          <div className="d-flex">
            <div className="date-filter">
              <p>Filter by Date: </p>
              <DateRange
                startDate={startDate}
                endDate={endDate}
                handleStartDate={(date) => setStartDate(date)}
                handleEndDate={(date) => setEndDate(date)}
              />
            </div>
          </div>
        </div>
        <div className="col-2">
          <button
            type="button"
            className="btn filter-btn"
            onClick={handleFilter}
          >
            Filter
          </button>
        </div>
      </div>
      <div>
        {loadingData ? (
          <SimpleLoader />
        ) : (
          docs && (
            <Table
              columns={columns}
              data={docs}
              enableRowSelection={false}
              tableClass={"table table-borderless table-striped mb-0"}
            />
          )
        )}
      </div>
      {totalPages > 1 && (
        <Pagination
          prevPage={prevPage}
          nextPage={nextPage}
          totalPages={totalPages}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          setPageNum={setPageNum}
        />
      )}
    </main>
  );
}
