import React, { useState, useContext } from "react";
import "./Sidebar.css";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import images from "../../assets/images/Images";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

export default function Sidebar({setHeading}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);
  const { userData } = useContext(UserContext);

  const handleLogOut = () => {
    localStorage.removeItem("token");
    navigate("/signin");
  };

  const handleTabClick = (path, text) => {
    setActiveTab(path);
    setHeading(text);
  };

  const isTabActive = (path) => {
    return activeTab === path ? "active-url" : "";
  };

  const renderNavItem = (to, icon, text) => (
    <Link
      to={to}
      className={`nav-link ${isTabActive(to)}`}
      onClick={() => handleTabClick(to, text)}
    >
      <img src={icon} alt={text} />
      <span className={isTabActive(to)}>{text}</span>
    </Link>
  );

  return (
    <nav className="col-2 sidebar">
      <div className="sidebar-container">
        <h6 className="sidebar-heading">
          <span>Analytics</span>
        </h6>
        <ul className="nav flex-column mb-3">
          {renderNavItem("/report", images.vas, "Reporting")}
          {renderNavItem("/report/vas", images.vas, "Vas Transaction")}
          {renderNavItem("/report/fund", images.fund, "Fund Transaction")}
          {renderNavItem("/report/payment-gateway", images.fund, "Payment Gateway")}
          {renderNavItem("/report/card", images.card, "Card Transaction")}
          {renderNavItem("/report/providers", images.channel, "Providers")}
          {renderNavItem("/report/targets", images.role, "Target")}
        </ul>
        <h6 className="sidebar-heading">
          <span>User Management</span>
        </h6>
        <ul className="nav flex-column mb-3">
          {renderNavItem("/report/onboarding", images.onboard, "Onboarding")}
          {renderNavItem("/report/group", images.upgrade, "Profiles")}
          {/* {renderNavItem("#", images.invite, "Agent Fee Structure")} */}
          {/* {userData?.adminType === "controller" && */}
          {renderNavItem("/report/compliance", images.invite, "Compliance")}
        </ul>
        {/* <h6 className="sidebar-heading">
          <span>Charge Management</span>
        </h6> */}
        {/* <ul className="nav flex-column mb-3">
          {renderNavItem("#", images.wallet, "Wallet Commission")}
          {renderNavItem("#", images.b2b, "B2B Commission")}
        </ul> */}
        <div className="sidebar-footer">
          <ul className="nav flex-column mb-2">
            {/* {renderNavItem("#", images.audit, "Audit Logs")} */}
            {renderNavItem("#", images.settings, "Settings")}
            <li className="nav-item">
              <button className="nav-link log-out" onClick={handleLogOut}>
                <img src={images.logout} alt="Log Out" />
                <span>Log Out</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
