// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circular-progressbar {
    position: relative;
    height: 180px;
    width: 180px;
}

.circular-chart {
    display: block;
    margin: 0px auto;
    max-width: 100%;
    max-height: 180px;
}

.circle-bg {
    fill: none;
}

.circle {
    fill: none;
}

.center-content {
    position: absolute;
    width: 100px;
    height: 100px;
    margin-top: -79%;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.center-text {
    margin-bottom: 5px;
}

.center-value {
    color: #3E4095;
    text-transform: capitalize;
    opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/circleProgressBar/CircleProgressBar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,0BAA0B;IAC1B,UAAU;AACd","sourcesContent":[".circular-progressbar {\n    position: relative;\n    height: 180px;\n    width: 180px;\n}\n\n.circular-chart {\n    display: block;\n    margin: 0px auto;\n    max-width: 100%;\n    max-height: 180px;\n}\n\n.circle-bg {\n    fill: none;\n}\n\n.circle {\n    fill: none;\n}\n\n.center-content {\n    position: absolute;\n    width: 100px;\n    height: 100px;\n    margin-top: -79%;\n    margin-left: 40px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.center-text {\n    margin-bottom: 5px;\n}\n\n.center-value {\n    color: #3E4095;\n    text-transform: capitalize;\n    opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
