import React, { useEffect, useState } from "react";
import "./MerchantView.css";
import { useNavigate } from "react-router-dom";
import TransactionDashboard from "./transaction-dashboard/TransactionDashboard";
import Settlement from "./settlement/Settlement";
import spout from "../../assets/images/spout-logo.png";
import InitialsAvatar from "react-initials-avatar";
import Account from "./account/Account";

const Tabs = ({ tabsData = [], tabHeading }) => {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };
  return (
    <>
      <nav className="card-trans-nav">
        <h1>{tabHeading}</h1>
        <ul>
          {tabsData.map((item, index) => (
            <li
              className={index === activeTab ? "nav-item active" : "nav-item"}
              key={index}
              onClick={() => handleTabChange(index)}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </nav>
      <div className="content">{tabsData[activeTab].content}</div>
    </>
  );
};

export default function MerchantView() {
  const navigate = useNavigate();
  const [merchantInfo, setMerchantInfo] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("merchantInfo");
    localStorage.removeItem("token");
    navigate("/merchant");
    // Additional logic like redirecting to login page, etc.
  };

  const tabsData = [
    {
      label: "Transaction Dashboard",
      content: <TransactionDashboard />,
    },
    {
      label: "Account",
      content: <Account />,
    },
    {
      label: "Settlement",
      content: <Settlement />,
    },
    // {
    //   label: "Dispute Resolution",
    //   content: "",
    // },
  ];

  useEffect(() => {
    const merchantInfoString = localStorage.getItem("merchantInfo");
    const merchantInfo = JSON.parse(merchantInfoString);
    setMerchantInfo(merchantInfo);
  }, []);

  return (
    <div className="merchant-user">
      <header className="navbar">
        <div className="navbar-items">
          <a className="navbar-logo" href="#">
            <img src={spout} alt="spout" />
          </a>
          <div className="navbar-heading">
            <h1>Merchant Transactions</h1>
            <p>Merchant ID: {merchantInfo?.merchant_id}</p>
          </div>
        </div>
        <div className="user">
          <div
            className="user-pill"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <InitialsAvatar name={`${merchantInfo?.merchant_name}`} />
            <div className="details">
              <h2>{merchantInfo?.merchant_name}</h2>
              <p>{merchantInfo?.merchant_email}</p>
            </div>
            <div>
              {showDropdown ? (
                <i className="fa-solid fa-caret-up"></i>
              ) : (
                <i className="fa-solid fa-caret-down"></i>
              )}
            </div>
          </div>
          {showDropdown && (
            <div className="user-pill-content">
              <div className="merchant-email">
                  <i class="fa-regular fa-user"></i>
                  {merchantInfo?.merchant_email}
                </div>
              <ul>
                <li onClick={handleLogout}>
                  <i class="fa-solid fa-right-from-bracket"></i>
                  <button className="logout-btn">Logout</button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </header>
      <div className="merchant-view">
        <div className="trans-dashboard">
          <Tabs tabsData={tabsData} tabHeading="Merchant Transactions" />
        </div>
      </div>
    </div>
  );
}
