import React, { useState, useRef } from "react";
import "./DocUpload.css";
import AppData from "../../config/appData.json";
import Id from "../../assets/images/id-icon.svg";
import Passport from "../../assets/images/passport-icon.svg";
import fileImg from "../../assets/images/file-icon.svg";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import DocumentItem from "../../components/DocumentItem";
import axios from "axios";

export default function DocUpload() {
  const navigate = useNavigate();
  const inputRef = useRef([]);
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const [documents, setDocuments] = useState([
    {
      type: "idCard",
      heading: "ID Card",
      required: "Required",
      icon: Id,
    },
    {
      type: "passport",
      heading: "Passport Photo",
      required: "Required",
      icon: Passport,
    },
    {
      type: "utilityBill",
      heading: "Utility Bill",
      required: "Required",
      icon: fileImg,
    },
    {
      type: "guarantorsId",
      heading: "Guarantors ID",
      required: "Required",
      icon: Id,
    },
    {
      type: "guarantorsPassport",
      heading: "Guarantors Passport Photo",
      required: "Required",
      icon: Passport,
    },
    {
      type: "cacDocument",
      heading: "CAC",
      required: "Optional",
      icon: fileImg,
    },
  ]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setErr(false);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    updateDocument(index, file);
  };

  const handleChange = (e, index) => {
    e.preventDefault();
    const file = e.target.files[0];
    updateDocument(index, file);
  };

  const deleteDocument = (index) => {
    setDocuments((prevDocuments) =>
      prevDocuments.map((doc, i) =>
        i === index ? { ...doc, name: "", file: null } : doc
      )
    );
  };

  const updateDocument = (index, file) => {
    setDocuments((prevDocuments) =>
      prevDocuments.map((doc, i) =>
        i === index ? { ...doc, name: file.name, file: file } : doc
      )
    );
  };

  const handleClick = (index) => {
    setErr(false);
    inputRef.current[index].click();
  };

  const handleDocumentUpload = async (e) => {
    e.preventDefault();
    const url = AppData.VAS_URL + "auth/upload-kyc-documents";
    let formData = new FormData();

    documents.forEach((doc) => {
      if (doc.file) {
        formData.append(doc.type, doc.file);
      }
    });

    try {
      setLoading(true);
      const response = await axios.post(url, formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      if (response.data.status === "success") {
        setMsg(response.data.message);
        window.scrollTo(0, 0);
        setTimeout(() => {
          setLoading(false);
          navigate("/user");
        }, 3000);
      } else {
        setMsg(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="onboard">
      <Header />
      <main>
        <form className="doc-upload" onSubmit={handleDocumentUpload}>
          <a href="/user">
            <button type="button" className="btn skip">
              skip
            </button>
          </a>
          <h2>Document Uploads</h2>
          <p>Please provide these documents in JPG, PNG</p>
          <div className="row upload">
            {err && <small className="mb-3">Files Not Uploaded!</small>}
            {msg && <p className="upload-success">{msg}</p>}
            {documents.map((doc, index) => (
              <DocumentItem
                key={index}
                doc={doc}
                index={index}
                handleDragOver={handleDragOver}
                handleDrop={handleDrop}
                inputRef={inputRef}
                handleClick={handleClick}
                handleChange={handleChange}
                deleteDocument={deleteDocument}
              />
            ))}
          </div>
          <div className="operations">
            <button type="submit" className="btn">
              {loading ? "...loading" : "Save & Continue"}
            </button>
          </div>
        </form>
      </main>
    </div>
  );
}
