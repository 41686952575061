// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-upload {
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-upload-card {
    background: #FFFFFF;
    opacity: 1;
    width: 400px;
    height: 230px;
    margin-top: -60px;
    padding: 30px;
}

.file-upload-area {
    border: 2px dashed #3359D7;
    padding: 10px;
    color: #3359D7;
}`, "",{"version":3,"sources":["webpack://./src/view/reporting-dashboard/card-transactions/settlement/settllement-upload/SettlementUpload.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,UAAU;IACV,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,0BAA0B;IAC1B,aAAa;IACb,cAAc;AAClB","sourcesContent":[".file-upload {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.file-upload-card {\n    background: #FFFFFF;\n    opacity: 1;\n    width: 400px;\n    height: 230px;\n    margin-top: -60px;\n    padding: 30px;\n}\n\n.file-upload-area {\n    border: 2px dashed #3359D7;\n    padding: 10px;\n    color: #3359D7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
