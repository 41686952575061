import React from "react";
import "./Settlement.css"
import TabView from "../../../components/TabView";
import SettlementReport from "./SettlementReport";
import SettlementExpectation from "./SettlementExpectation";
import SettlementReconciliation from "./SettlementReconciliation";

export default function TransactionDashboard() {
  const Report = <SettlementReport />;
  const Expectation = <SettlementExpectation />;
  const Reconciliation = <SettlementReconciliation />;

  return (
    <div className="settlement-dashboard pb-3">
      <TabView
        tabs={[
          {
            name: "Report",
            content: Report,
          },
          {
            name: "Expectation",
            content: Expectation,
          },
          {
            name: "Reconciliation",
            content: Reconciliation,
          },
        ]}
      />
    </div>
  );
}
