import React, { useState, useEffect } from "react";
import Table from "../../../components/Table";
import { CSVLink } from "react-csv";
import Images from "../../../assets/images/Images";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import SearchInput from "../../../components/SearchInput";
import DateRange from "../../../components/DateRange";
import GetData from "../../../api/GetData";
import AppData from "../../../config/appData.json";
import Pagination from "../../../components/Pagination";
import imageURL from "../../../imageUrls";
import SimpleLoader from "../../../components/spinner/SimpleLoader";

export default function Compliance() {
  const [complianceList, setComplianceList] = useState([]);
  const [status, setStatus] = useState("");
  const [statusVol, setStatusVol] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedStatus, setSelectedStatus] = useState("Status");
  const [selectedMethod, setSelectedMethod] = useState("Card");
  const [limit, setLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const postPerPage = 50;
  const lastPageNum = Math.ceil(statusVol / postPerPage);
  const pageStart = (pageNumber - 1) * postPerPage + 1;

  const borderColors = ["#838383", "#6C540E", "#0584FE", "#FB4013", "#550000"];
  const backgroundColors = [
    "#0000001A",
    "#745A021A",
    "#0584FE19",
    "#FB40131A",
    "#55000019",
  ];

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  const columns = React.useMemo(
    () => [
      {
        Header: "S/N",
        Cell: (row) => {
          return <div>{row.row.index + pageStart}</div>;
        },
      },
      {
        Header: "Name",
        accessor: (compliance) => (
          <>
            {compliance?.identification.firstname}{" "}
            {compliance?.identification.lastname}
          </>
        ),
      },
      {
        Header: "Reference ID",
        accessor: "",
      },
      {
        Header: "Identification Type",
        accessor: (compliance) => (
          <>{compliance?.identification.identity_type}</>
        ),
      },
      {
        Header: "Date/Time",
        accessor: (date) => {
          let dDate = date.createdAt;
          const formatDate = new Date(dDate);
          return <div> {formatDate.toLocaleString()}</div>;
        },
      },
      {
        Header: "Business Name",
        accessor: (compliance) => <>{compliance?.profile.business_name}</>,
      },
      {
        Header: "Category",
        accessor: (compliance) => <>{compliance?.profile.category}</>,
      },
      {
        Header: "Status",
        accessor: "",
      },
    ],
    [pageNumber]
  );

  const fetchHistoryData = async () => {
    try {
      const endpoint = AppData.PAYMENT_URL + `settings/all-compliance`;
      const response = await GetData(endpoint);
      // console.log("response", response);
      setComplianceList(response);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const fetchHistoryStatus = async () => {
    try {
      const endpoint =
        AppData.PAYMENT_URL +
        `transactions/stats?startdate=${formattedStartDate}&&enddate=${formattedEndDate}`;
      const response = await GetData(endpoint);
      setStatus(response.data);
      setStatusVol(response.data.total_volume);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  function formatDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  const handleFilter = (e) => {
    e.preventDefault();
    fetchHistoryData();
    fetchHistoryStatus();
  };

  useEffect(() => {
    fetchHistoryData();
  }, [pageNumber]);

  useEffect(() => {
    fetchHistoryStatus();
  }, []);

  return (
    <div className="gateway-compliance">
      <div className="history-table table-responsive">
        {complianceList.data && complianceList.data.length > 0 ? (
          <Table
            columns={columns}
            data={complianceList.data}
            enableRowSelection={false}
            tableClass={"table table-borderless table-striped mb-0"}
          />
        ) : (
          <p>No record found</p>
        )}
        {lastPageNum > 1 && (
          <Pagination
            prevPage={pageNumber - 1}
            nextPage={pageNumber + 1}
            totalPages={lastPageNum}
            hasNextPage={pageNumber < lastPageNum}
            hasPrevPage={pageNumber > 1}
            setPageNum={setPageNumber}
          />
        )}
      </div>
    </div>
  );
}
