import React, { useState } from "react";
import Images from "../../../assets/images/Images";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import GroupedInput from "../../../components/GroupedInput";
import { CSVLink } from "react-csv";

export default function FilterForm({
  selectedLimit,
  setSelectedLimit,
  username,
  emailAddress,
  handleInputChange,
  handleFilter,
  handleRefresh,
  docs,
}) {
  const [selectedHeaders, setSelectedHeaders] = useState([]);

  const formatArrayField = (field) => {
    if (Array.isArray(field)) {
      return field.join(", ");
    } else if (typeof field === "object") {
      return JSON.stringify(field);
    }
    return field;
  };

  const formattedDocs = docs
    ? docs.map((doc) => ({
        ...doc,
        vfdAcctDetails: formatArrayField(doc.vfdAcctDetails),
      }))
    : [];

  // Dynamically get all headers from the docs
  const allHeaders = docs
    ? Object.keys(docs[0]).filter((header) => header !== "vfdAcctDetails")
    : [];

  const handleHeaderSelection = (selected) => {
    setSelectedHeaders(selected);
  };

  return (
    <form className="filter-data" onSubmit={handleFilter}>
      <div className="limit-select">
        <CustomSelect
          heading="Limit"
          selectedVal={selectedLimit}
          setSelectedValue={setSelectedLimit}
          items={[
            { name: "50" },
            { name: "100" },
            { name: "200" },
            { name: "500" },
            { name: "1000" },
            { name: "2000" },
            { name: "5000" },
          ]}
        />
      </div>

      {/* Select dropdown for choosing headers */}
      {/* <div className="header-select">
        <label>Select Headers</label>
        <CustomSelect
          selectedVal={selectedHeaders}
          setSelectedValue={handleHeaderSelection}
          items={allHeaders.map((header) => ({ name: header }))}
          isMultiSelect
        />
      </div> */}

      {/* Input fields for username and email */}
      <div className="grouped-input">
        <label>Username</label>
        <input
          type="text"
          name="username"
          className="form-control"
          placeholder="Enter username"
          value={username}
          onChange={handleInputChange}
        />
      </div>
      <div className="grouped-input">
        <label>Email</label>
        <input
          type="text"
          name="email"
          className="form-control"
          placeholder="Enter email"
          value={emailAddress}
          onChange={handleInputChange}
        />
      </div>

      {/* Filter and Refresh buttons */}
      <button className="btn btn-filter" type="submit">
        Filter
      </button>
      <button
        className="btn btn-refresh ms-auto"
        type="button"
        onClick={handleRefresh}
      >
        Refresh
      </button>

      {/* CSV download link */}
      {docs && (
        <CSVLink data={formattedDocs} filename="History" className="downloads">
          <img src={Images.download} alt="download" />
        </CSVLink>
      )}
    </form>
  );
}
