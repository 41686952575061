import React, { useState } from "react";
import Images from "../../assets/images/Images";
import { useNavigate } from "react-router-dom";
import handlePostApi from "../../api/HandlePostApi";
import AppData from "../../config/appData.json";

export default function SetTransactionPin({ setShowTransPinModal }) {
  const [msg, setMsg] = useState("");
  const [pin, setPin] = useState("");
  const [pinConfirmation, setPinConfirmation] = useState("");

  const url = AppData.VAS_URL + "auth/create-transaction-pin";
  const navigate = useNavigate();

  // const handleVerification = async (e) => {
  //   e.preventDefault();
  //   if (pin !== pinConfirmation) {
  //     setMsg("Pin Don't Match!");
  //     return;
  //   }

  //   const requestData = {
  //     pin: pin,
  //     pin_confirmation: pinConfirmation,
  //   };

  //   try {
  //     const response = await handlePostApi(url, requestData);
  //     if (response) {
  //       navigate("/user");
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //     setMsg(error?.response?.data?.message);
  //   }
  // };

  const handleVerification = async (e) => {
    e.preventDefault();
    if (pin !== pinConfirmation) {
      setMsg("Pin Don't Match!");
      return;
    }
    const requestData = {
      pin: pin,
      pin_confirmation: pinConfirmation,
    };

    try {
      const response = await handlePostApi(url, requestData);
      if (response) {
        navigate("/onboard");
      }
    } catch (error) {
      console.log("error", error);
      setMsg(error?.response?.data?.message);
    }
  };

  return (
    <div className="modal set-pin">
      <form className="modal-content" onSubmit={handleVerification}>
        <img className="security-icon" src={Images.security} alt="" />
        <div
          className="cancel-modal"
          onClick={() => setShowTransPinModal(false)}
        >
          X
        </div>
        <h6>Create Transaction Pin</h6>
        <p>please set a 4 digit pin you can remember</p>
        {msg && <p>{msg}</p>}
        <div className="form-group">
          <label htmlFor="create-pin">Create Pin</label>
          <input
            className="form-control"
            type="text"
            maxLength="4"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirm-pin">Confirm Pin</label>
          <input
            className="form-control"
            type="text"
            maxLength="4"
            value={pinConfirmation}
            onChange={(e) => setPinConfirmation(e.target.value)}
          />
        </div>
        <div className="btn-container">
          <button type="submit" className="btn">
            Become An Agent
          </button>
        </div>
        <p>
          please note that you&apos;re a{" "}
          <span className="level">Tier 1 Agent</span> you <br />
          can&apos;t perform more above NGN 100, you want to do more?
        </p>
        {/* <div>
          <button
            className="registration"
            type="button"
            onClick={handleCompleteReg}
          >
            To become an Agent/Aggregator Click HERE.
          </button>
        </div> */}
      </form>
    </div>
  );
}
