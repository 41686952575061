import React, { useState, useEffect, useContext } from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import "../../assets/fonts/OCR-A-Std-Regular.ttf";
import AppData from "../../config/appData.json";
import { UserContext } from "../../context/UserContext";
import passIcon from "../../assets/images/pass-icon.svg";

import bulb from "../../assets/images/bulb.png";
import airtimeIcon from "../../assets/images/airtime.png";
import internet from "../../assets/images/internet.png";
import insurance from "../../assets/images/insurance.png";
import cable from "../../assets/images/cable.png";
import other from "../../assets/images/other-services.png";
import arrow from "../../assets/images/bill-arrow.svg";
import GetData from "../../api/GetData";
import Electricity from "./Electricity";
import AgentTransactions from "./AgentTransactions";
import Airtime from "./Airtime";
import Internet from "./Internet";
import Cable from "./Cable";
import OtherServices from "./OtherServices";
import BankTransfer from "./BankTransfer";
import handlePostApi from "../../api/HandlePostApi";

export default function Home() {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const [providers, setProviders] = useState({});
  const user = userData.user ? userData.user : userData;
  const [balance, setBalance] = useState("");
  const [hideBalance, setHideBalance] = useState(false);
  const [activeService, setActiveService] = useState(0);
  const totalWalletBalance = balance && balance.balance;
  let walletBalance = Math.round(totalWalletBalance * 100) / 100;
  let sha256 = require("js-sha256");
  let walletBalanceDec;

  const { electricity, airtime, data, tv } = providers;

  if (walletBalance === Math.floor(walletBalance)) {
    walletBalanceDec = "00";
    walletBalance = walletBalance.toLocaleString();
  } else {
    walletBalanceDec = walletBalance.toString().split(".")[1];
    walletBalance = walletBalance.toLocaleString().split(".")[0];
  }

  const handleGetProviders = async () => {
    try {
      const { products } = await handlePostApi(
        AppData.VAS_URL + "admin/get-products"
      );
      setProviders(products);
    } catch (error) {
      const err = error.response.data.message;
      console.error("Error fetching transactions:", error);
      if (err === "Expired Session, Please Login Again") {
        navigate("/signin");
      }
    }
  };

  const handleActiveService = (index) => {
    setActiveService(index);
  };

  const services = [
    {
      content: <AgentTransactions />,
    },
    {
      service: "Buy Electricity",
      providers: "IKEDC, IBEDC, MEDCO, ETC",
      icon: `${bulb}`,
      content: <Electricity electricity={electricity} />,
    },
    {
      service: "Airtime & Data",
      providers: "MTN , AIRTEL, GLO ETC",
      icon: `${airtimeIcon}`,
      content: <Airtime airtimeServices={airtime} dataDiscos={data} />,
    },
    {
      service: "Internet Service",
      providers: "SMILE, SPECTRANET, IPNX",
      icon: `${internet}`,
      content: <Internet />,
    },
    {
      service: "Bank Transfer",
      providers: "TRANSFER FROM WALLET TO ANY BANK",
      icon: `${insurance}`,
      content: <BankTransfer />,
    },
    {
      service: "Cable TV",
      providers: "DSTV, GOTV, ETC",
      icon: `${cable}`,
      content: <Cable />,
    },
    {
      service: "Other Bills",
      providers: "TOLL, IGR ETC",
      icon: `${other}`,
      content: <OtherServices />,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = AppData.VAS_URL + "user/wallet-balance";
        const response = await GetData(endpoint);
        setBalance(response.data);
      } catch (error) {
        const err = error.response.data.message;
        console.error("Error fetching transactions:", error);
        if (err === "Expired Session, Please Login Again") {
          navigate("/signin");
        }
      }
    };
    fetchData();
    handleGetProviders();
  }, []);

  const handleHideBalance = () => {
    setHideBalance(!hideBalance);
  };

  return (
    <main className="col">
      <div className="row">
        <div className="col-3">
          <div className="wallet-summary">
            <div className="hide-data" onClick={handleHideBalance}>
              <img src={passIcon} alt="" />
            </div>
            <div className="available-balance">
              <h1>Available Balance</h1>
              {!hideBalance ? (
                <p>
                  ₦{walletBalance}
                  <sup>.{walletBalanceDec}</sup>
                </p>
              ) : (
                <p>********</p>
              )}
            </div>
            <p>
              Wallet ID <span>{user && user.walletId}</span>
            </p>
            <button className="btn">Fund Wallet</button>
          </div>
        </div>
        <div className="col">
          <div className="row">
            {services.map((service, index) => (
              <div
                className={index === 0 ? "d-none" : "col-4 mb-3 vas-services"}
                key={index}
                onClick={() => handleActiveService(index)}
              >
                <div className="bill-payment">
                  <div>
                    <img src={service.icon} alt="bulb" />
                  </div>
                  <div className="services">
                    <h1>{service.service}</h1>
                    <p>{service.providers}</p>
                  </div>
                  <div>
                    <img className="arrow" src={arrow} alt="" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {services[activeService].content}
    </main>
  );
}
