// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.support-config {
    background-color: #ffffff;
    border: 0.5px solid #CBCBCB;
    margin-top: 30px;
    padding: 40px;
    font-size: 14px;
    color: #194065;
}

.support-config .heading {
    font-size: 14px;
    margin-bottom: 20px;
}

.support-config .form-control {
    border: 1px solid #707070;
    border-radius: 4px;
    opacity: 1;
}

.support-config .btn {
    background-color: #016AA7;
    color: #ffffff;
    padding-inline: 20px;
    font-size: 14px;
    border-radius: 4px;
}
.support-config .form-label {
    color: #000000;
}

.support-config .form-label span {
    font-weight: 600;
    font-size: 18px;
}

.auto-support select {
    border: 1px solid #707070;
    border-radius: 5px;
    opacity: 1;
    padding: 5px;
}

.auto-support label {
    margin-top: 5px;
}

.auto-support .btn {
    background-color: #016AA7;
    color: #ffffff;
    font-size: 14px;
    border: 1px solid #707070;
    opacity: 1;
    width: 222px;
}`, "",{"version":3,"sources":["webpack://./src/view/reporting-dashboard/card-transactions/configuration/support/Support.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,2BAA2B;IAC3B,gBAAgB;IAChB,aAAa;IACb,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,oBAAoB;IACpB,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,eAAe;IACf,yBAAyB;IACzB,UAAU;IACV,YAAY;AAChB","sourcesContent":[".support-config {\n    background-color: #ffffff;\n    border: 0.5px solid #CBCBCB;\n    margin-top: 30px;\n    padding: 40px;\n    font-size: 14px;\n    color: #194065;\n}\n\n.support-config .heading {\n    font-size: 14px;\n    margin-bottom: 20px;\n}\n\n.support-config .form-control {\n    border: 1px solid #707070;\n    border-radius: 4px;\n    opacity: 1;\n}\n\n.support-config .btn {\n    background-color: #016AA7;\n    color: #ffffff;\n    padding-inline: 20px;\n    font-size: 14px;\n    border-radius: 4px;\n}\n.support-config .form-label {\n    color: #000000;\n}\n\n.support-config .form-label span {\n    font-weight: 600;\n    font-size: 18px;\n}\n\n.auto-support select {\n    border: 1px solid #707070;\n    border-radius: 5px;\n    opacity: 1;\n    padding: 5px;\n}\n\n.auto-support label {\n    margin-top: 5px;\n}\n\n.auto-support .btn {\n    background-color: #016AA7;\n    color: #ffffff;\n    font-size: 14px;\n    border: 1px solid #707070;\n    opacity: 1;\n    width: 222px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
