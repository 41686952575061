import React, { useState } from "react";
import AppData from "../../../config/appData.json";
import putData from "../../../api/PutData";
import DocumentRequest from "./DocumentRequest";

const UserDetails = ({ heading, name }) => (
  <div className="col-3">
    <div>
      <h4>{heading}</h4>
      <p className="data-info">{name}</p>
    </div>
  </div>
);

const STATUS_CLASSES = {
  success: "status success-message",
  failed: "status failed-message",
  pending: "status pending-message",
};

const RequestHandler = ({ setShowUpdateList, updatedData }) => {
  const {
    firstName,
    lastName,
    dateOfBirth,
    businessName,
    nokPhone,
    phoneNumber,
    address,
    officeState,
    stateOfOrigin,
    officeLga,
    localGovernment,
    gender,
    userId,
    _id,
    userData,
  } = updatedData;

  // console.log("userData", updatedData);

  const [error, setError] = useState(null);
  const [message, setMessage] = useState("Status Pending");
  const [requestStatus, setRequestStatus] = useState("");
  const requestData = { updateId: _id };

  const handleApproveRequest = async (endpoint) => {
    try {
      const response = await putData(endpoint, requestData);
      if (response.status === "success") {
        setRequestStatus("success");
        setMessage(response.message);
        setTimeout(() => {
          setShowUpdateList(true);
        }, 3000);
      } else {
        setRequestStatus("failed");
        setMessage(response.data.message);
      }
    } catch (error) {
      console.log("error", error);
      setRequestStatus("failed");
      setMessage(error.response?.data?.message || "An error occurred");
    }
  };

  const handleDeclineRequest = async (endpoint) => {
    try {
      const response = await putData(endpoint, requestData);
      if (response.status === "success") {
        setRequestStatus("failed");
        setMessage(response.message);
        setTimeout(() => {
          setShowUpdateList(true);
        }, 3000);
      } else {
        setRequestStatus("failed");
        setMessage(response.data.message);
      }
    } catch (error) {
      console.log("error", error);
      setRequestStatus("failed");
      setMessage(error.response?.data?.message || "An error occurred");
    }
  };

  const handleApproveRequestClick = () => {
    console.log("userData", userData);
    const endpoint = userData
      ? AppData.VAS_URL + `controller/accept/user-edit/${userId}`
      : AppData.VAS_URL + `controller/accept/biodata/${userId}`;
    handleApproveRequest(endpoint);
  };

  const handleDeclineRequestClick = () => {
    const endpoint = AppData.VAS_URL + `controller/reject/update/${userId}`;
    handleDeclineRequest(endpoint);
  };

  const getStatusClass = () => {
    return STATUS_CLASSES[requestStatus] || STATUS_CLASSES.pending;
  };

  return (
    <div>
      <button className="back-btn" onClick={() => setShowUpdateList(true)}>
        <i className="fa-solid fa-arrow-left"></i>Back to verification
      </button>
      <p className={getStatusClass()}>{message}</p>
      {address ? (
        <div className="bordered-row">
          {userData && (
            <div>
              <div className="user-data">
                <div className="section-header">Initial Details</div>
                <div className="row">
                  <UserDetails
                    heading="Initial Firstname"
                    name={userData.initialFirstName}
                  />
                  <UserDetails
                    heading="Initial Lastname"
                    name={userData.initialLastName}
                  />
                  <UserDetails
                    heading="Initial PhoneNumber"
                    name={userData.initialPhoneNumber}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="user-data">
            <div className="section-header">Updated Details</div>
            <div className="row">
              <UserDetails heading="First name" name={firstName} />
              <UserDetails heading="Last name" name={lastName} />
              <UserDetails
                heading="Date of birth"
                name={dateOfBirth.toLocaleString()}
              />
              <UserDetails heading="Company name" name={businessName} />
              <UserDetails
                heading="State Of Origin"
                name={officeState ? officeState : stateOfOrigin}
              />
              <UserDetails
                heading="Local Govt Area"
                name={officeLga ? officeLga : localGovernment}
              />
              <UserDetails
                heading="Phone Number"
                name={nokPhone ? nokPhone : phoneNumber}
              />
              <UserDetails heading="Gender" name={gender} />
              <UserDetails heading="Address" name={address} />
            </div>
          </div>
          <div className="actions">
            <button
              className="btn approve-btn"
              onClick={handleApproveRequestClick}
            >
              Approve
            </button>
            <button
              className="btn decline-btn"
              onClick={handleDeclineRequestClick}
            >
              Decline
            </button>
          </div>
        </div>
      ) : (
        <DocumentRequest
          updatedData={updatedData}
          handleDeclineRequest={handleDeclineRequest}
          userId={userId}
          requestId={_id}
          setShowUpdateList={setShowUpdateList}
          setRequestStatus={setRequestStatus}
          setMessage={setMessage}
          statusClass={STATUS_CLASSES}
        />
      )}
    </div>
  );
};

export default RequestHandler;
