// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-dashboard .table .service-logo {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.user-dashboard .user-profile .profile-pic {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.agent .custom-select {
  width: 120px;
}
`, "",{"version":3,"sources":["webpack://./src/view/user-dashboard/UserDashboard.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".user-dashboard .table .service-logo {\n  width: 25px;\n  height: 25px;\n  border-radius: 50%;\n}\n\n.user-dashboard .user-profile .profile-pic {\n  height: 40px;\n  width: 40px;\n  border-radius: 50%;\n}\n\n.agent .custom-select {\n  width: 120px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
