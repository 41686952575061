// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-merchant-request .desc {
  width: 400px;
  font-weight: 100;
  font-size: 12px;
}

.new-merchant-request .col-form-label {
  font-size: 12px;
  font-weight: 100;
}

.new-merchant-request .custom-select .dropdown {
  background-color: #f4f5f7;
  padding: 10px;
}

.new-merchant-request .invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
`, "",{"version":3,"sources":["webpack://./src/view/reporting-dashboard/profle/add-merchant/NewMerchantRequest.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,cAAc;EACd,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".new-merchant-request .desc {\n  width: 400px;\n  font-weight: 100;\n  font-size: 12px;\n}\n\n.new-merchant-request .col-form-label {\n  font-size: 12px;\n  font-weight: 100;\n}\n\n.new-merchant-request .custom-select .dropdown {\n  background-color: #f4f5f7;\n  padding: 10px;\n}\n\n.new-merchant-request .invalid-feedback {\n  display: block;\n  width: 100%;\n  margin-top: 0.25rem;\n  font-size: 0.875em;\n  color: #dc3545;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
