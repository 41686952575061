// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-overview {
    background-color: #ffffff;
    border-top: 0;
    padding-top: 50px;
}

.map-overview .summary {
    margin-bottom: 50px;
}`, "",{"version":3,"sources":["webpack://./src/view/reporting-dashboard/card-transactions/merchant-management/view-terminals/MapOverview.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".map-overview {\n    background-color: #ffffff;\n    border-top: 0;\n    padding-top: 50px;\n}\n\n.map-overview .summary {\n    margin-bottom: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
