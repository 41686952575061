// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grouped-input {
  background-color: transparent;
  border: 1px solid #327fec;
  border-radius: 4px;
  padding: 2px 8px;
}

.grouped-input-error {
  border: 1px solid #f41649 !important;
}

.grouped-input small {
  color: #f41649;
  font-size: 12px;
}

.grouped-input label {
  font-size: 10px;
  color: #a0a0a0;
}

.grouped-input input {
  background-color: transparent;
  border: none;
  padding: 0;
  box-shadow: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/GroupedInput.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,UAAU;EACV,2BAA2B;AAC7B","sourcesContent":[".grouped-input {\n  background-color: transparent;\n  border: 1px solid #327fec;\n  border-radius: 4px;\n  padding: 2px 8px;\n}\n\n.grouped-input-error {\n  border: 1px solid #f41649 !important;\n}\n\n.grouped-input small {\n  color: #f41649;\n  font-size: 12px;\n}\n\n.grouped-input label {\n  font-size: 10px;\n  color: #a0a0a0;\n}\n\n.grouped-input input {\n  background-color: transparent;\n  border: none;\n  padding: 0;\n  box-shadow: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
