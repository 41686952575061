import React, { useState, useContext } from "react";
import "./Profile.css";
import { UserContext } from "../../../context/UserContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import HandlePostApi from "../../../api/HandlePostApi";
import AppData from "../../../config/appData.json";
import { ToastContainer, toast } from "react-toastify";

export default function Profile() {
  const { userData } = useContext(UserContext);
  const [msg, setMsg] = useState("");

  const schema = yup.object().shape({
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters!")
      .required("Password field Cannot be empty!"),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords Don't Match")
      .required("Passwords Don't Match!"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleResetPass = async (data) => {
    try {
      const url = AppData.VAS_URL + "admin/update-password";
      await HandlePostApi(url, data);
      toast("Password changed successfully!");
    } catch (error) {
      toast("An error occured!");
      console.error("Error:", error);
    }
  };

  return (
    <div className="profile mt-5">
      <h4>Admin User Profile</h4>
      <div className="profile-container">
        <div className="profile-content">
          <div className="header">Your Profile</div>
          <div className="main">
            <form className="row" onSubmit={handleSubmit(handleResetPass)}>
              <div className="col-12 mb-2">
                <div className="details">
                  <h6>E-Mail</h6>
                  <p>{userData.admin?.email || userData.email}</p>
                </div>
              </div>
              <div className="col-6  mb-2">
                <div className="details">
                  <h6>First Name</h6>
                  <p>{userData.admin?.firstName || userData.firstName}</p>
                </div>
              </div>
              <div className="col-6  mb-2">
                <div className="details">
                  <h6>Last Name</h6>
                  <p>{userData.admin?.lastName || userData.lastName}</p>
                </div>
              </div>
              <div className="col-5 mb-2">
                <div className="grouped-input">
                  <label htmlFor="password">Passsword</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="**********"
                    {...register("password")}
                  />
                  <div>
                    <small>{errors.password?.message}</small>
                  </div>
                </div>
              </div>
              <div className="col-5 mb-2">
                <div className="grouped-input">
                  <label htmlFor="password_confirmation">
                    Confirm Passsword
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="**********"
                    {...register("password_confirmation")}
                  />
                  <div>
                    <small>{errors.password_confirmation?.message}</small>
                  </div>
                </div>
              </div>
              <div className="col-2  mb-2">
                <button className="btn" type="submit">
                  Reset
                </button>
              </div>
            </form>
          </div>
          <div className="footer">
            <button className="btn cancel">Cancel</button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
