import React, { useState, useEffect } from "react";
import "./GroupProfile.css";
import Tab from "../../../components/Tab";
import WebUsers from "./WebUsers";
import Aggregators from "./Aggregators";
import AgentsList from "./AgentsList";
import MerchantList from "./MerchantList";
import AddMerchant from "./add-merchant/AddMerchant";

const Tabs = ({ tabsData = [], tabHeading, activeTab, handleTabChange }) => {
  return (
    <>
      <nav className="card-trans-nav">
        <h1>{tabHeading}</h1>
        <ul>
          {tabsData.map((tab, index) => (
            <Tab
              key={index}
              index={index}
              activeTab={activeTab}
              tabClass={"nav-item"}
              activeTabClass={"nav-item active"}
              tabName={tab.label}
              handleTabChange={() => handleTabChange(index)}
            />
          ))}
        </ul>
      </nav>
      <div className="content">{tabsData[activeTab].content}</div>
    </>
  );
};

export default function GroupProfile() {
  const [activeTab, setActiveTab] = useState(0);
  const [addMerchant, setAddMerchant] = useState(false);

  const tabsData = [
    {
      label: "Web Users",
      content: <WebUsers />,
    },
    {
      label: "Agents",
      content: <AgentsList />,
    },
    {
      label: "Aggregator",
      content: <Aggregators />,
    },
    {
      label: "Merchants",
      content: <MerchantList setAddMerchant={setAddMerchant} />,
    },
    // {
    //   label: "Banks",
    //   content: "",
    // },
  ];

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  // useEffect(() => {
  //   console.log("Previous Active Tab:", activeTab);
  // }, [addMerchant]);

  return (
    <main className="col dashboard-main">
      {!addMerchant ? (
        <div className="agent-group">
          <Tabs
            tabsData={tabsData}
            tabHeading="Profiles"
            activeTab={activeTab}
            handleTabChange={handleTabChange}
          />
        </div>
      ) : (
        <AddMerchant setAddMerchant={setAddMerchant} />
      )}
    </main>
  );
}
