import React, { useState, useEffect, useContext } from "react";
import HandlePostApi from "../../../api/HandlePostApi";
import Table from "../../../components/Table";
import AppData from "../../../config/appData.json";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../../components/SearchInput";
import DateRange from "../../../components/DateRange";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import Images from "../../../assets/images/Images";
import RequestModal from "./RequestModal";
import ViewFundApproval from "./ViewFundApproval";
import { UserContext } from "../../../context/UserContext";
import Pagination from "../../../components/Pagination";
import GroupedInput from "../../../components/GroupedInput";
import SimpleLoader from "../../../components/spinner/SimpleLoader";

export default function FundingList() {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [msg, setMsg] = useState("");
  const [history, setHistory] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [viewApprovalModal, setViewApprovalModal] = useState(false);
  const [approvalData, setApprovalData] = useState({});
  const { userData } = useContext(UserContext);
  const [reference, setReference] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [pageNum, setPageNum] = useState(1);

  // console.log("date-range", startDate, endDate);

  const requestData = {
    page: pageNum,
    startDate: startDate,
    endDate: endDate,
    status: selectedStatus,
    walletId: "",
    reference: reference,
    product: "",
    account: "",
    channel: "",
    provider: "",
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S/N",
        id: "row",
        Cell: (row) => {
          const PageStart = (pageNum - 1) * 50 + 1;
          return <div>{row.row.index + PageStart}</div>;
        },
      },
      {
        Header: "Wallet ID",
        accessor: "walletId",
      },
      {
        Header: "User",
        accessor: (user) => (
          <div style={{ textTransform: "capitalize" }}>
            {user.userId.firstName}
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Amount",
        accessor: (amount) => <>N{amount.amount.toLocaleString()}</>,
      },
      {
        Header: "Initiated by",
        accessor: (initiator) => (
          <div style={{ textTransform: "capitalize" }}>
            {initiator.postedBy.username}
          </div>
        ),
      },
      {
        Header: "Reference",
        accessor: "reference",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Date & Time",
        accessor: (date) => {
          let dDate = date.createdAt;
          const formatDate = new Date(dDate);
          return <div> {formatDate.toLocaleString()}</div>;
        },
      },
      {
        Header: "",
        accessor: "id",
        Cell: (tableInstance) => {
          const { row: index } = tableInstance;
          return (
            <button
              className="fund"
              onClick={() => handleViewDetails(index.original)}
            >
              View
            </button>
          );
        },
      },
    ],
    [history]
  );

  const handleErrorFunc = (error) => {
    if (error.response.data.message === "Expired Session, Please Login Again") {
      navigate("/signin");
    } else {
      setMsg(error.response.data.message);
    }
  };

  const fetchData = async (data) => {
    try {
      const endpoint = AppData.WALLET_URL + "admin/fund-list";
      const response = await HandlePostApi(endpoint, data);
      // console.log("huhyuyg", response);
      setHistory(response.fundLists);
    } catch (error) {
      handleErrorFunc(error);
    }
  };
  const handleRequest = () => {
    setShowModal(true);
  };

  const handleViewDetails = (index) => {
    setApprovalData(index);
    setViewApprovalModal(true);
  };

  const handleFilter = (e) => {
    e.preventDefault();
    fetchData(requestData);
  };

  const handleRefresh = () => {
    const data = {
      page: 1,
      startDate: new Date(),
      endDate: new Date(),
      status: "",
      walletId: "",
      reference: "",
      product: "",
      account: "",
      channel: "",
      provider: "",
    };
    // Reset all filter state variables to their initial values
    setSelectedStatus("");
    setReference("");

    // Reset date range state variables if needed
    setStartDate(new Date());
    setEndDate(new Date());

    // Reset pagination page number to 1
    setPageNum(1);

    // Fetch data with reset filters
    fetchData(data);
  };

  useEffect(() => {
    fetchData(requestData);
  }, [pageNum]);

  const { docs, hasNextPage, hasPrevPage, nextPage, prevPage, totalPages } =
    history;

  return (
    <>
      <div className="table-actions mt-4">
        <div className="row">
          <div className="col-9">
            <form className="table-filter" onSubmit={handleFilter}>
              <div className="row">
                <div className="col-2">
                  <div className="channel">
                    <CustomSelect
                      className="custom-select mb-3"
                      heading="Status"
                      selectedVal={
                        selectedStatus ? selectedStatus : "All Status"
                      }
                      setSelectedValue={setSelectedStatus}
                      items={[
                        {
                          name: "completed",
                        },
                        {
                          name: "pending",
                        },
                        {
                          name: "declined",
                        },
                      ]}
                      defaultOption={{ label: "All Status", value: "" }}
                    />
                  </div>
                </div>
                <div className="col-2 grouped-input">
                  <label>Reference</label>
                  <input
                    type="text"
                    name="reference"
                    className="form-control"
                    placeholder="Enter Reference"
                    value={reference}
                    onChange={(e) => setReference(e.target.value)}
                  />
                </div>
                <div className="col-4">
                  <div className="d-flex">
                    <div className="date-filter">
                      <p>Filter by Date: </p>
                      <DateRange
                        startDate={startDate}
                        endDate={endDate}
                        handleStartDate={(date) => setStartDate(date)}
                        handleEndDate={(date) => setEndDate(date)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <button type="submit" className="btn filter">
                    Filter
                  </button>
                  <button
                    className="btn btn-refresh ms-3"
                    type="button"
                    onClick={handleRefresh}
                  >
                    Refresh
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col">
            {userData?.admin?.adminType === "financial-admin" && (
              <div className="table-buttons">
                <button className="btn request" onClick={handleRequest}>
                  Request Debit/Credit
                </button>
                <button type="button" className="downloads">
                  <img src={Images.download} alt="" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="vas-table table-responsive">
        {docs?.length > 0 ? (
          <Table
            columns={columns}
            data={docs}
            tableClass="table table-borderless table-striped table-hover fs-7 mb-0"
            enableRowSelection={false}
          />
        ) : (
          <p className="no-record">No record found</p>
        )}
        {totalPages > 1 && (
          <Pagination
            prevPage={prevPage}
            nextPage={nextPage}
            totalPages={totalPages}
            hasNextPage={hasNextPage}
            hasPrevPage={hasPrevPage}
            setPageNum={setPageNum}
          />
        )}
      </div>
      {showModal && <RequestModal setShowModal={setShowModal} />}
      {viewApprovalModal && (
        <ViewFundApproval
          setViewApprovalModal={setViewApprovalModal}
          approvalData={approvalData}
          userData={userData.admin}
        />
      )}
    </>
  );
}
