import React, { useState, useEffect } from "react";
import "./SameDay.css";
import MerchantConfig from "./MerchantConfig";
import NewConfig from "./CreateConfig";
import TabView from "../../../../../components/TabView";
import GetData from "../../../../../api/GetData";
import AppData from "../../../../../config/appData.json";

export default function SameDay() {
  const [configSettlements, setConfigSettlements] = useState([]);

  useEffect(() => {
    const fetchConfigSettlement = async () => {
      try {
        const endpoint = AppData.BASE_URL + "config/settlements";
        const response = await GetData(endpoint);
        setConfigSettlements(response);
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    fetchConfigSettlement();
  }, []);

  return (
    <div className="same-day">
      <TabView
        tabs={[
          {
            name: "Merchant Sameday Configuration",
            content: (
              <MerchantConfig configSettlements={configSettlements.data} />
            ),
          },
          { name: "New Configuration", content: <NewConfig /> },
        ]}
      />
    </div>
  );
}
