import React, { useState, useEffect } from "react";
import "./App.css";
import AppData from "./config/appData.json";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Signin from "./view/entrance/Signin";
import Landing from "./view/landing/Landing";
import Signup from "./view/entrance/Signup";
import VasTransaction from "./view/reporting-dashboard/vas-transactions/VasTransaction";
import CardTransactions from "./view/reporting-dashboard/card-transactions/CardTransactions";
import DocUpload from "./view/onboarding/DocUpload";
import About from "./view/onboarding/About";
import Reporting from "./view/reporting-dashboard/reporting/Reporting";
import { UserContext } from "./context/UserContext";
import ReportingDashbord from "./view/reporting-dashboard/ReportingDashbord";
import Onboarding from "./view/reporting-dashboard/onboarding/Onboarding";
import GetData from "./api/GetData";
import Providers from "./view/reporting-dashboard/providers/Providers";
import FundTransactions from "./view/reporting-dashboard/fund-transactions/FundTransactions";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import UserDashboard from "./view/user-dashboard/UserDashboard";
import ConfirmPassword from "./view/entrance/ChangePassword";
import ResetVerification from "./view/entrance/ResetVerification";
import Compliance from "./view/reporting-dashboard/compliance/Compliance";
import Targets from "./view/reporting-dashboard/target/Targets";
import AllTargets from "./view/reporting-dashboard/target/AllTargets";
import GroupProfile from "./view/reporting-dashboard/profle/GroupProfile";
import GatewayReport from "./view/reporting-dashboard/payment-gateway/GatewayReport";
import MerchantView from "./view/merchant-view/MerchantView";
import Login from "./view/merchant-view/Login";

function App() {
  const [userData, setUserData] = useState("");

  const adminId = localStorage.getItem("AdminId");

  const handleFetchError = (error) => {
    const err = error?.response?.data?.message;
    if (err === "Expired Session, Please Login Again") {
      localStorage.removeItem("token");
    }
  };

  const fetchData = async (url, setData) => {
    try {
      const response = await GetData(url);
      setData(response);
    } catch (error) {
      handleFetchError(error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const profileType =
        localStorage.getItem("userType") === "admin" ? "admin" : "user";
      const url =
        profileType === "admin"
          ? AppData.WALLET_URL + `admin/get/${adminId}`
          : AppData.VAS_URL + "user/profile";

      fetchData(url, setUserData);
    }
  }, []);

  return (
    // <MerchantView />
    <UserContext.Provider value={{ userData, setUserData }}>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/merchant" element={<Login />} />
          <Route path="/merchant/view" element={<MerchantView />} />
          <Route path="/pass-reset" element={<ResetVerification />} />
          <Route path="/set-password" element={<ConfirmPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/onboard" element={<About />} />
            <Route path="/onboard/upload" element={<DocUpload />} />
            <Route path="/user" element={<UserDashboard />} />
            <Route path="/report" element={<ReportingDashbord />}>
              <Route path="/report" element={<Reporting />} />
              <Route path="/report/vas" element={<VasTransaction />} />
              <Route path="/report/fund" element={<FundTransactions />} />
              <Route path="/report/card" element={<CardTransactions />} />
              <Route path="/report/payment-gateway" element={<GatewayReport />} />
              <Route path="/report/providers" element={<Providers />} />
              <Route path="/report/targets" element={<Targets />} />
              <Route path="/report/targets/all" element={<AllTargets />} />
              <Route path="/report/onboarding" element={<Onboarding />} />
              <Route path="/report/group" element={<GroupProfile />} />
              <Route path="/report/compliance" element={<Compliance />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
