import React, { useState, useEffect, useCallback } from "react";
import Table from "../../../components/Table";
import GetData from "../../../api/GetData";
import AppData from "../../../config/appData.json";
import Pagination from "../../../components/Pagination";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Modal from "../../../components/modal/Modal";
import CustomSelect from "../../../components/customSelect/CustomSelect";

export default function SwitchConfig() {
  const [bandList, setBandList] = useState([]);
  const [statusVol, setStatusVol] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [pageNumber, setPageNumber] = useState(1);
  const postPerPage = 50;
  const lastPageNum = Math.ceil(statusVol / postPerPage);
  const pageStart = (pageNumber - 1) * postPerPage + 1;

  const handleCreateSwitch = async () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleStatus = async (userId, status) => {
    try {
      const endpoint = AppData.PAYMENT_URL + `settings/switch/${userId}`;
      const payload = {
        status: !status,
      };
      // console.log("endpoint", endpoint);
      await axios.patch(endpoint, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    } catch (error) {
      console.log("Error toggling staatus", error);
    } finally {
      fetchHistoryData();
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S/N",
        Cell: (row) => {
          return <div>{row.row.index + pageStart}</div>;
        },
      },
      {
        Header: "Switch Name",
        accessor: "name",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (row) => {
          const rowData = row.row.original;
          const { status, _id } = rowData;

          const handleStatusToggle = () => {
            toggleStatus(_id, status);
          };

          return (
            <label className="switch" htmlFor={`enabled-${row.row.index}`}>
              <input
                type="checkbox"
                className="checkbox"
                name="enabled"
                id={`enabled-${row.row.index}`}
                checked={status}
                onChange={handleStatusToggle}
              />
              <span className="slider" />
              <span className="inner" />
            </label>
          );
        },
      },
      {
        Header: "Date Created",
        accessor: (date) => {
          let dDate = date.createdAt;
          const formatDate = new Date(dDate);
          return <div> {formatDate.toLocaleString()}</div>;
        },
      },
      {
        Header: "Last Updated",
        accessor: (date) => {
          let dDate = date.updatedAt;
          const formatDate = new Date(dDate);
          return <div> {formatDate.toLocaleString()}</div>;
        },
      },
    ],
    [pageNumber]
  );

  const fetchHistoryData = async () => {
    try {
      const endpoint = AppData.PAYMENT_URL + `settings/all-switch`;
      const response = await GetData(endpoint);
      // console.log("response", response);
      setBandList(response);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  function formatDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  const handleFilter = (e) => {
    e.preventDefault();
    fetchHistoryData();
  };

  useEffect(() => {
    fetchHistoryData();
  }, [pageNumber]);

  return (
    <div className="band-config">
      <div className="add-config">
        <button className="add-config-btn" onClick={handleCreateSwitch}>
          Add Switch
        </button>
      </div>
      <div className="table-responsive">
        {bandList.data && bandList.data.length > 0 ? (
          <Table
            columns={columns}
            data={bandList.data}
            enableRowSelection={false}
            tableClass={"table table-borderless table-striped mb-0"}
          />
        ) : (
          <p>No record found</p>
        )}
        {lastPageNum > 1 && (
          <Pagination
            prevPage={pageNumber - 1}
            nextPage={pageNumber + 1}
            totalPages={lastPageNum}
            hasNextPage={pageNumber < lastPageNum}
            hasPrevPage={pageNumber > 1}
            setPageNum={setPageNumber}
          />
        )}
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          content={<CreateSwitch />}
        />
      )}
    </div>
  );
}

const CreateSwitch = () => {
  const [selectedStatus, setSelectedStatus] = useState(true);

  const schema = yup.object().shape({
    name: yup.string().required("Band Name is required"),
    status: yup.string().required("Band Description is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const endpoint = AppData.PAYMENT_URL + "settings/switch";
      const response = await axios.post(endpoint, data);
      console.log(response);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <form className="risk-profile-modal" onSubmit={handleSubmit(onSubmit)}>
      <h4>Create Switch</h4>

      <div className="grouped-input mb-3">
        <label className="col-12" htmlFor="name">
          Switch Name:
        </label>
        <input
          className="form-control"
          type="text"
          id="name"
          {...register("name")}
        />
        <small>{errors.name?.message}</small>
      </div>

      <div className="switch-stat mb-3">
        <CustomSelect
          heading="Select Status"
          selectedVal={selectedStatus ? selectedStatus : "Select Status"}
          setSelectedValue={setSelectedStatus}
          items={[{ name: "ON" }, { name: "OFF" }]}
          defaultOption={{ label: "Select Status", value: "" }}
        />
      </div>

      <button type="submit" className="update-btn">
        Create
      </button>
    </form>
  );
};
