// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/bg-image.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.all-target-header {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    padding: 10px;
    display: flex;
    color: #a6a3a3;
}

.percentage-cell {
    display: flex;
    align-items: center;
  }
  
  .status-circle {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 5px;
    margin-bottom: 3px;
  }
  
  .amount-cell {
    color: #905E0E;
  }

  .all-target-header h6 {
    margin: 0px;
    text-transform: uppercase;
    font-size: 10px;
    margin-top: 10px;
  }

  .all-target-header span {
    font-size: 20px;
    color: #ffffff;
    font-weight: 600;
    letter-spacing: 1.5px;
  }

  .agent-name {
    text-decoration: none;
    color: #2171e5;
    font-weight: 300;
    font-size: 0.65rem;
    text-transform: capitalize;
  }

  .agent-name:hover {
    color: black;
  }`, "",{"version":3,"sources":["webpack://./src/view/reporting-dashboard/target/AllTargets.css"],"names":[],"mappings":"AAAA;IACI,yDAA4D;IAC5D,aAAa;IACb,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;IACrB,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,0BAA0B;EAC5B;;EAEA;IACE,YAAY;EACd","sourcesContent":[".all-target-header {\n    background-image: url(\"../../../assets/images/bg-image.png\");\n    padding: 10px;\n    display: flex;\n    color: #a6a3a3;\n}\n\n.percentage-cell {\n    display: flex;\n    align-items: center;\n  }\n  \n  .status-circle {\n    width: 7px;\n    height: 7px;\n    border-radius: 50%;\n    margin-right: 5px;\n    margin-bottom: 3px;\n  }\n  \n  .amount-cell {\n    color: #905E0E;\n  }\n\n  .all-target-header h6 {\n    margin: 0px;\n    text-transform: uppercase;\n    font-size: 10px;\n    margin-top: 10px;\n  }\n\n  .all-target-header span {\n    font-size: 20px;\n    color: #ffffff;\n    font-weight: 600;\n    letter-spacing: 1.5px;\n  }\n\n  .agent-name {\n    text-decoration: none;\n    color: #2171e5;\n    font-weight: 300;\n    font-size: 0.65rem;\n    text-transform: capitalize;\n  }\n\n  .agent-name:hover {\n    color: black;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
