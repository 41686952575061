import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PutData from "../../api/PutData";
import AppData from "../../config/appData.json";

export default function UserDataForm({ userProfileData }) {
  const [editMode, setEditMode] = useState(false);
  const [msg, setMsg] = useState("");
  const {
    businessName,
    firstName,
    lastName,
    username,
    walletId,
    address,
    stateOfOrigin,
    localGovernment,
    dateOfBirth,
    gender,
    nokPhone,
  } = userProfileData;
  const formData = {
    companyName: businessName,
    firstName: firstName,
    lastName: lastName,
    username: username,
    aggregatorWallet: walletId,
    address: address,
    state: stateOfOrigin,
    lga: localGovernment,
    dateOfBirth: dateOfBirth,
    gender: gender,
    phoneNumber: nokPhone,
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const schema = yup.object().shape({
    companyName: yup.string().required("Organization name is required!"),
    firstName: yup.string().required("Your first name is required!"),
    lastName: yup.string().required("Your last name is required!"),
    address: yup.string().required("Address field must not be empty!"),
    stateOfOrigin: yup.string().required("State field must not be empty!"),
    localGovernment: yup.string().required("Field is required!"),
    dateOfBirth: yup.date().required("Date is required").nullable(),
    gender: yup.string().required(),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid!")
      .required(),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formData,
  });

  const handleActivateEdit = () => {
    setEditMode(!editMode);
  };

  const handleFormSubmit = async (requestDate) => {
    try {
      const url = AppData.VAS_URL + "user/profile";

      const response = await PutData(url, requestDate);

      setMsg(response.message);
    } catch (error) {
      console.log("error", error);
      setMsg(error.response.data.message);
    }
  };

  return (
    <section className="about-you">
      <form className="form mt-3" onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="section-heading">
          <h6 className="mb-0">About You</h6>
          {!editMode ? (
            <button className="edit-btn" onClick={handleActivateEdit}>
              Edit <i className="fa-solid fa-pen-to-square"></i>
            </button>
          ) : (
            <div className="edit-action">
              <button className="cancel-btn" onClick={handleActivateEdit}>
                Cancel
              </button>
              <button className="save-btn" type="submit">
                Save
              </button>
            </div>
          )}
        </div>
        <div className="row w-50 mt-4">
          <div className="col-12 mb-2">
            <div
              className={`grouped-input ${!editMode ? "disabled-input" : ""}`}
            >
              <label htmlFor="companyName">Organization Name</label>
              <input
                type="text"
                id="companyName"
                className="form-control"
                disabled={!editMode}
                {...register("companyName")}
                defaultValue={formData.companyName}
              />
            </div>
            {errors.companyName && (
              <div>
                <small>{errors.companyName?.message}</small>
              </div>
            )}
          </div>
          <div className="col-6 mb-2">
            <div
              className={`grouped-input ${!editMode ? "disabled-input" : ""}`}
            >
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                className="form-control"
                disabled={!editMode}
                {...register("firstName")}
                defaultValue={formData.firstName}
              />
            </div>
            {errors.firstName && (
              <div>
                <small>{errors.firstName?.message}</small>
              </div>
            )}
          </div>
          <div className="col-6 mb-2">
            <div
              className={`grouped-input ${!editMode ? "disabled-input" : ""}`}
            >
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                className="form-control"
                disabled={!editMode}
                {...register("lastName")}
                defaultValue={formData.lastName}
              />
            </div>
            {errors.lastName && (
              <div>
                <small>{errors.lastName?.message}</small>
              </div>
            )}
          </div>
          <div className="col-7 mb-2">
            <div className="grouped-input disabled-input">
              <label htmlFor="username">User Name</label>
              <input
                type="text"
                id="username"
                className="form-control"
                value={formData.username}
                disabled={editMode}
                readOnly
              />
            </div>
          </div>
          <div className="col-5 mb-2">
            <div className="grouped-input disabled-input">
              <label htmlFor="aggregatorWallet">Wallet ID</label>
              <input
                type="text"
                id="aggregatorWallet"
                className="form-control"
                value={formData.aggregatorWallet}
                disabled={editMode}
                readOnly
              />
            </div>
          </div>
          <div className="col-3 mb-2">
            <div
              className={`grouped-input ${!editMode ? "disabled-input" : ""}`}
            >
              <label htmlFor="dateOfBirth">Date Of Birth</label>
              <input
                type="date"
                id="dateOfBirth"
                className="form-control"
                disabled={!editMode}
                {...register("dateOfBirth")}
                defaultValue={formData.dateOfBirth}
              />
            </div>
            {errors.dateOfBirth && (
              <div>
                <small>{errors.dateOfBirth?.message}</small>
              </div>
            )}
          </div>
          <div className="col-4 mb-2">
            <div
              className={`grouped-input ${!editMode ? "disabled-input" : ""}`}
            >
              <label htmlFor="gender">Gender</label>
              <select
                className="form-control"
                disabled={!editMode}
                {...register("gender")}
                defaultValue={formData.gender}
                onChange={(e) =>
                  setValue("gender", e.target.value, {
                    shouldValidate: true,
                  })
                }
              >
                <option value="DEFAULT" disabled>
                  Select Gender
                </option>
                <option value="male">male</option>
                <option value="female">female</option>
              </select>
            </div>
            {errors.gender && (
              <div>
                <small>{errors.gender?.message}</small>
              </div>
            )}
          </div>
          <div className="col-5 mb-2">
            <div
              className={`grouped-input ${!editMode ? "disabled-input" : ""}`}
            >
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="text"
                id="phoneNumber"
                className="form-control"
                disabled={!editMode}
                {...register("phoneNumber")}
                defaultValue={formData.phoneNumber}
              />
            </div>
            {errors.phoneNumber && (
              <div>
                <small>{errors.phoneNumber?.message}</small>
              </div>
            )}
          </div>
          <div className="col-12 mb-2">
            <div
              className={`grouped-input ${!editMode ? "disabled-input" : ""}`}
            >
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                className="form-control"
                disabled={!editMode}
                {...register("address")}
                defaultValue={formData.address}
              />
            </div>
            {errors.address && (
              <div>
                <small>{errors.address?.message}</small>
              </div>
            )}
          </div>
          <div className="col-7">
            <div
              className={`grouped-input ${!editMode ? "disabled-input" : ""}`}
            >
              <label htmlFor="state">State</label>
              <input
                type="text"
                id="stateOfOrigin"
                className="form-control"
                disabled={!editMode}
                {...register("stateOfOrigin")}
                defaultValue={formData.state}
              />
            </div>
            {errors.stateOfOrigin && (
              <div>
                <small>{errors.stateOfOrigin?.message}</small>
              </div>
            )}
          </div>
          <div className="col-5">
            <div
              className={`grouped-input ${!editMode ? "disabled-input" : ""}`}
            >
              <label htmlFor="lga">Local Govt Area</label>
              <input
                type="text"
                id="localGovernment"
                className="form-control"
                disabled={!editMode}
                {...register("localGovernment")}
                defaultValue={formData.lga}
              />
            </div>
            {errors.localGovernment && (
              <div>
                <small>{errors.localGovernment?.message}</small>
              </div>
            )}
          </div>
        </div>
      </form>
    </section>
  );
}
