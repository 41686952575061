import React, { useState } from "react";
import "./Modal.css";
import AppData from "../../config/appData.json";
import OtpInput from "react-otp-input";
import security from "../../assets/images/security-icon.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PostData from "../../api/PostData";
import SetTransactionPin from "./SetTransactionPin";

export default function OtpModal({ setShowOTPModal }) {
  const [msg, setMsg] = useState("");
  const [otp, setOtp] = useState("");
  const [showTransPinModal, setShowTransPinModal] = useState(false);
  const email = localStorage.getItem("email");

  yup.addMethod(yup.string, "stripEmptyString", function () {
    return this.transform((value) => (value === "" ? undefined : value));
  });

  const schema = yup.object().shape({
    email: yup.string().stripEmptyString().default(email),
    activationPin: yup.string().required("Invalid OTP!").default(otp),
  });

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const handleVerification = async (requestData) => {
    try {
      const url = AppData.VAS_URL + "auth/verify-email";
      await PostData(url, requestData);
      setShowTransPinModal(true);
    } catch (error) {
      console.log("Error fetching data", error);
      setMsg(error.response.data.message);
    }
  };

  const hadleResendOTP = async () => {
    try {
      const userEmail = { email: email };
      const url = AppData.VAS_URL + "auth/resent-email-verification-pin";
      await PostData(url, userEmail);
    } catch (error) {
      console.log("Error fetching data", error);
      setMsg(error.response.data.message);
    }
  };

  return (
    <div className="modal otp-modal">
      <form
        className="modal-content"
        onSubmit={handleSubmit(handleVerification)}
      >
        <img className="security-icon" src={security} alt="" />
        <div className="cancel-modal" onClick={() => setShowOTPModal(false)}>
          X
        </div>
        <h6>Enter OTP Below</h6>
        <p>We sent an OTP code to your e-mail address just now</p>
        {msg && <small>{msg}</small>}
        <div className="otp-inputs">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={<span> </span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>
        <p className="resend">
          Didn&apos;t get any OTP?{" "}
          <span>
            <button
              className="resend-otp"
              type="button"
              onClick={hadleResendOTP}
            >
              Resend
            </button>
          </span>
        </p>
        <div className="btn-container">
          <button type="submit" className="btn">
            Verify
          </button>
        </div>
      </form>
      {showTransPinModal && (
        <SetTransactionPin setShowTransPinModal={setShowTransPinModal} />
      )}
    </div>
  );
}
