import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import Data from "./Data";
import AppData from "../../config/appData.json";
import PinValidationModal from "../modal/PinValidationModal";
import imageURL from "../../imageUrls";

export default function Airtime({ airtimeServices, dataDiscos }) {
  const [toggleState, setToggleState] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [transactionPin, setTransactionPin] = useState("");
  const [service, setService] = useState("mtnvtu");
  const [msg, setMsg] = useState("");
  const [inputData, setInputData] = useState("");
  const channel = "web";
  const uniqueId = uuidv4();
  const paymentMethod = "cash";

  const info = `Your Airtime Of N${inputData.amount} Is Successfully Delivered To`;
  const extraInfo = inputData.phoneNumber;
  const requestUrl = AppData.VAS_URL + "vas/airtime/purchase";
  const requestData = {
    phoneNumber: inputData.phoneNumber,
    paymentMethod: paymentMethod,
    amount: inputData.amount,
    service: inputData.service,
    uniqueId: uniqueId,
    channel: channel,
    pin: transactionPin,
  };

  const schema = yup.object().shape({
    amount: yup.string().required("Amount is required"),
    channel: yup.string().required().default(channel),
    phoneNumber: yup.string().required("Phone number is required"),
    uniqueId: yup.string().required().default(uniqueId),
    service: yup.string().required("Field is required"),
    paymentMethod: yup.string().required().default(paymentMethod),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleToggle = (index) => {
    setToggleState(index);
  };

  const handleSelectChange = (e) => {
    setValue("service", e.target.value, {
      shouldValidate: true,
    });
    setService(e.target.value);
  };

  const handleFormSubmit = (data) => {
    setInputData(data);
    setShowModal(true);
  };

  return (
    <div className="services">
      <div className="service-heading">Buy Airtime</div>
      <div className="row mt-3">
        <div className="col-2">
          <div className="service-type">
            <h6>Choose a meter type</h6>
            <ul>
              <li
                onClick={() => handleToggle(0)}
                className={toggleState === 0 ? "active-service" : ""}
              >
                Airtime
              </li>
              <li
                onClick={() => handleToggle(1)}
                className={toggleState === 1 ? "active-service" : ""}
              >
                Data
              </li>
            </ul>
          </div>
        </div>
        <div className="col-4">
          <div className="service-form">
            <h6>Complete Field To Buy Electricity</h6>
            {msg && <small>{msg}</small>}
            {toggleState === 0 ? (
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="grouped-input default">
                  <label>Select Disco</label>
                  <select
                    className="form-control"
                    {...register("service")}
                    onChange={handleSelectChange}
                  >
                    {airtimeServices &&
                      airtimeServices.map((service, index) => (
                        <option key={index}>{service}</option>
                      ))}
                  </select>
                  <div className="select-icon">
                    <img
                      className="service-logo"
                      src={imageURL[service]}
                      alt="service"
                    />
                    <div>
                      <i className="fa-solid fa-angle-down"></i>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    errors.phoneNumber
                      ? "grouped-input error"
                      : "grouped-input default"
                  }
                >
                  <label>Beneficiary Phone Number</label>
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="08100000000"
                    {...register("phoneNumber")}
                  />
                  <small>{errors.phoneNumber?.message}</small>
                </div>
                <div
                  className={
                    errors.amount
                      ? "grouped-input error"
                      : "grouped-input default"
                  }
                >
                  <label>Input Amount</label>
                  <input
                    className="form-control"
                    placeholder="Enter amount"
                    {...register("amount")}
                  />
                  <small>{errors.amount?.message}</small>
                </div>
                <div className="continue">
                  <button type="submit" className="btn">
                    Continue
                  </button>
                </div>
              </form>
            ) : (
              <Data dataDiscos={dataDiscos} />
            )}
          </div>
        </div>
        <div className="col">
          <div className="service-confirmation">
            <h6>Account Holders Details</h6>
            {showModal && (
              <PinValidationModal
                setShowModal={setShowModal}
                requestData={requestData}
                serviceIcon={imageURL[service]}
                setMsg={setMsg}
                url={requestUrl}
                transactionPin={transactionPin}
                setTransactionPin={setTransactionPin}
                transType="airtime"
                info={info}
                extraInfo={extraInfo}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
