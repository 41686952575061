import React, { useState, useEffect } from "react";
import Table from "../../../components/Table";
import { useNavigate } from "react-router-dom";
import AppData from "../../../config/appData.json";
import handlePostApi from "../../../api/HandlePostApi";
import Pagination from "../../../components/Pagination";
import UserProfile from "./UserProfile";
import FilterForm from "./FilterForm";
import SimpleLoader from "../../../components/spinner/SimpleLoader";

export default function Aggregators() {
  const [allUsers, setAllUsers] = useState({});
  const [pageNum, setPageNum] = useState(1);
  const [showUserData, setShowUserData] = useState(false);
  const [userId, setUserId] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [username, setUsername] = useState("");
  const [selectedLimit, setSelectedLimit] = useState("50");

  const navigate = useNavigate();

  const requestData = {
    page: 1,
    agentType: "aggregator",
    email: "",
    username: "",
    limit: selectedLimit,
  };

  const handleOpenUserProfile = (user) => {
    setShowUserData(true);
    setUserId(user._id);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmailAddress(value);
    } else if (name === "username") {
      setUsername(value);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Wallet Id",
        accessor: "walletId",
        Cell: (tabinstance) => {
          const { row: index } = tabinstance;
          const indexData = index.original;
          return (
            <button
              className="wallet-link-btn"
              onClick={() => handleOpenUserProfile(indexData)}
            >
              {indexData.walletId}
            </button>
          );
        },
      },
      {
        Header: "Username",
        accessor: "username",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "User Type",
        accessor: "agentType",
      },
      {
        Header: "Phone No",
        accessor: "phoneNumber",
      },
      {
        Header: "Address",
        accessor: (address) => (
          <div style={{ width: "250px" }}>{address.address}</div>
        ),
      },
    ],
    [pageNum]
  );

  const fetchData = async (data) => {
    try {
      const url = AppData.WALLET_URL + "admin/get-users";
      const { users } = await handlePostApi(url, data);
      setAllUsers(users);
    } catch (error) {
      const { response } = error;
      const err = response?.data?.message;
      console.error("Error fetching transactions:", error);
      if (err === "Expired Session, Please Login Again") {
        navigate("/signin");
      }
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();
    const data = {
      page: pageNum,
      agentType: "aggregator",
      email: emailAddress,
      username: username,
    };
    fetchData(data);
  };

  const handleRefresh = (e) => {
    e.preventDefault();
    setEmailAddress("");
    setUsername("");
    setPageNum(1);
    setSelectedLimit(50);
    fetchData(requestData);
  };

  useEffect(() => {
    fetchData(requestData);
  }, [pageNum, selectedLimit]);

  const { docs, hasNextPage, hasPrevPage, nextPage, prevPage, totalPages } =
    allUsers;

  return (
    <div className="mt-4">
      {showUserData ? (
        <UserProfile userId={userId} setShowUserData={setShowUserData} />
      ) : (
        <>
          {docs ? (
            <>
              <FilterForm
                selectedLimit={selectedLimit}
                setSelectedLimit={setSelectedLimit}
                username={username}
                emailAddress={emailAddress}
                handleInputChange={handleInputChange}
                handleFilter={handleFilter}
                handleRefresh={handleRefresh}
                docs={docs}
              />
              <Table
                columns={columns}
                data={docs}
                enableRowSelection={false}
                tableClass={"table table-borderless table-striped mb-0"}
              />
            </>
          ) : (
            <SimpleLoader />
          )}
          {totalPages > 1 && (
            <Pagination
              prevPage={prevPage}
              nextPage={nextPage}
              totalPages={totalPages}
              hasNextPage={hasNextPage}
              hasPrevPage={hasPrevPage}
              setPageNum={setPageNum}
            />
          )}
        </>
      )}
    </div>
  );
}
