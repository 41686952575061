import React, { useState } from "react";
import Images from "../../assets/images/Images";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PasswordChecker from "../../components/PasswordChecker";
import postData from "../../api/PostData";
import AppData from "../../config/appData.json";
import SetTransactionPin from "../modal/SetTransactionPin";

export default function ChangePassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showTransPinModal, setShowTransPinModal] = useState(false);
  const [pwdChecker, setPwdChecker] = useState(false);
  const [msg, setMsg] = useState("");
  const [checks, setChecks] = useState({
    capsLetterCheck: false,
    smallLetterCheck: false,
    numberCheck: false,
    pwdLengthCheck: false,
    specialCharCheck: false,
  });

  yup.addMethod(yup.string, "stripEmptyString", function () {
    return this.transform((value) => (value === "" ? undefined : value));
  });

  const passwordRegex = /.*[!@#$%^&*(),.?":{}|<>]/;

  const schema = yup.object().shape({
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters!")
      .required("Password field cannot be empty!")
      .matches(/[a-z]/, "Password must contain a lowercase letter")
      .matches(/[A-Z]/, "Password must contain an uppercase letter")
      .matches(/\d/, "Password must contain a number")
      .matches(passwordRegex, "Password must contain a special character"),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords don't match")
      .required("Password confirmation field cannot be empty!"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const handleKeyUp = (e) => {
    const { value } = e.target;
    const smallLetterCheck = /[a-z]/.test(value);
    const capsLetterCheck = /[A-Z]/.test(value);
    const numberCheck = /[0-9]/.test(value);
    const pwdLengthCheck = value.length >= 6;
    const specialCharCheck = /[!@#$%^&*(),.?":{}|<>']/.test(value);
    setChecks({
      capsLetterCheck,
      smallLetterCheck,
      numberCheck,
      pwdLengthCheck,
      specialCharCheck,
    });
  };

  const handleFormSubmit = async (requestData) => {
    try {
      const endpoint = AppData.VAS_URL + "change/bulk/password";
      await postData(endpoint, requestData);
      setShowTransPinModal(true);
      setMsg(null);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setMsg(error.response.data.message);
      } else {
        setMsg("Error submitting data. Please try again."); // Generic error message
      }
      console.log("Error submitting data", error);
    }
  };

  return (
    <main className="reset-pass">
      <div className="container">
        <div className="reset-header">
          <img src={Images.spoutLogo} alt="spoutpay" />
          <h1>You're Welcome</h1>
          <p>
            We can&apos;t wait for you to unbox the suprise we <br /> have got
            for you
          </p>
        </div>
        <div className="reset-section">
          {/* <div className="home">
            <a href="/">Home</a>
          </div> */}
          <div className="col-5 reset-img"></div>
          <div className="col">
            <div className="change-pass">
              <form className="form" onSubmit={handleSubmit(handleFormSubmit)}>
                <h1>Choose A New Password</h1>
                {msg && <small>{msg}</small>}
                <div className="mb-3 mt-3 text-start">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    className={"form-control error"}
                    type="text"
                    placeholder="Email Address"
                    name="email"
                    {...register("email")}
                  />
                  <small>{errors.email?.message}</small>
                </div>
                <div className="mb-3 mt-3 text-start position-relative">
                  <label htmlFor="login" className="form-label">
                    New Password
                  </label>
                  <input
                    className={
                      errors.password ? "form-control error" : "form-control"
                    }
                    type={showPassword ? "text" : "password"}
                    placeholder="•••••••••••••••"
                    name="pswd"
                    onFocus={() => setPwdChecker(true)}
                    onBlur={() => setPwdChecker(false)}
                    onKeyUp={handleKeyUp}
                    {...register("password")}
                  />
                  <img
                    className="pass-view"
                    src={Images.show}
                    alt="pass"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                  <small>{errors.password?.message}</small>
                </div>
                <div className="mb-5 mt-3 text-start position-relative">
                  <label htmlFor="login" className="form-label">
                    Confirm New Password
                  </label>
                  <input
                    className={
                      errors.password_confirmation
                        ? "form-control error"
                        : "form-control"
                    }
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="•••••••••••••••"
                    name="pswd"
                    {...register("password_confirmation")}
                  />
                  <img
                    className="pass-view"
                    src={Images.show}
                    alt="pass"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                  <small>{errors.password_confirmation?.message}</small>
                  {pwdChecker && <PasswordChecker checks={checks} />}
                  <div className="mt-5 text-center">
                    <button type="submit" className="btn">
                      Reset Password
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showTransPinModal && (
        <SetTransactionPin setShowTransPinModal={setShowTransPinModal} />
      )}
    </main>
  );
}
