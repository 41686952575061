import axios from "axios";

export default function DeleteData(url, setStateFunc) {
  axios
    .delete(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      setStateFunc("Delete Successful");
    })
    .catch(function (error) {
      console.log(error);
    });
}
