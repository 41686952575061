import React, { useState, useEffect } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
// import { useNavigate } from "react-router-dom";
import AppData from "../../../config/appData.json";
import LineChart from "../../../components/charts/LineChart";
import "./Status.css";
import check from "../../../assets/images/shield-check.png";
import xmark from "../../../assets/images/shield-xmark.png";
import GetData from "../../../api/GetData";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import DateRange from "../../../components/DateRange";

export default function Status() {
  // const navigate = useNavigate();
  const [banks, setBanks] = useState([]);
  const [status, setStatus] = useState("");
  const [terminals, setTerminals] = useState("");
  const [date] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedBank, setSelectedBank] = useState("All banks");
  const [msg, setMsg] = useState("");

  const formattedDate =
    date.getFullYear() +
    "-" +
    parseInt(date.getMonth() + 1) +
    "-" +
    date.getDate();
  const formattedStartDate =
    startDate.getFullYear() +
    "-" +
    parseInt(startDate.getMonth() + 1) +
    "-" +
    startDate.getDate();
  const formattedEndDate =
    endDate.getFullYear() +
    "-" +
    parseInt(endDate.getMonth() + 1) +
    "-" +
    endDate.getDate();

  const handleErrorFunc = (error) => {
    if (error.response.data.message === "Expired Session, Please Login Again") {
      // navigate("/signin");
    } else {
      setMsg(error.response.data.message);
    }
  };

  useEffect(() => {
    const fetchTransactionStatus = async () => {
      try {
        const endpoint =
          AppData.BASE_URL +
          `transactions/stats?date=${formattedDate}&startdate=${formattedStartDate}&enddate=${formattedEndDate}`;
        const response = await GetData(endpoint);
        setStatus(response);
      } catch (error) {
        handleErrorFunc(error);
      }
    };
    fetchTransactionStatus();
  }, [formattedStartDate, formattedEndDate]);

  useEffect(() => {
    const fetchTerminalData = async () => {
      try {
        const endpoint = AppData.BASE_URL + "terminals/stats";
        const response = await GetData(endpoint);
        setTerminals(response);
      } catch (error) {
        handleErrorFunc(error);
      }
    };
    fetchTerminalData();
  }, []);

  useEffect(() => {
    const fetchBankData = async () => {
      try {
        const endpoint = AppData.BASE_URL + "banks/list";
        const response = await GetData(endpoint);
        setBanks(response);
      } catch (error) {
        handleErrorFunc(error);
      }
    };
    fetchBankData();
  }, []);

  return (
    <div className="status">
      <div className="status-param">
        <div>
          <CustomSelect
            className="custom-select"
            heading="Banks"
            selectedVal={selectedBank}
            setSelectedValue={setSelectedBank}
            items={banks.data}
          />
        </div>
        <div className="date-filter">
          <p>Filter by Date: </p>
          <DateRange
            startDate={startDate}
            endDate={endDate}
            handleStartDate={(date) => setStartDate(date)}
            handleEndDate={(date) => setEndDate(date)}
          />
        </div>
      </div>
      <div className="row text-center summary">
        <div className="col-4 mb-2 pb-3">
          <h5 className="summary-heading">Total Transactions</h5>
          <p className="summary-total">
            ₦ {status.data && status.data.total_value.toLocaleString()}
          </p>
          <div className="row total">
            <div className="col-6 border-end">
              <p className="mb-0">Successful</p>
              <p className="success-value">
                ₦ {status.data && status.data.success_value.toLocaleString()}
              </p>
            </div>
            <div className="col-6">
              <p className="mb-0">Failed</p>
              <p className="failed-value">
                ₦ {status.data && status.data.failed_value.toLocaleString()}
              </p>
            </div>
          </div>
        </div>
        <div className="col-4 total-terminal mb-2 pb-3">
          <h5 className="summary-heading">Total Terminal</h5>
          <p className="summary-total">
            {terminals.data && terminals.data.allTerminals}
          </p>
          <div className="row">
            <div className="col-6 border-end">
              <p className="mb-0">Active</p>
              <p className="terminal-count">
                {terminals.data && terminals.data.activeTerminals}
              </p>
            </div>
            <div className="col-6">
              <p className="mb-0">Inactive</p>
              <p className="terminal-count">
                {terminals.data && terminals.data.inactiveTerminals}
              </p>
            </div>
          </div>
        </div>
        <div className="col-4 mb-2 pb-3">
          <h5 className="summary-heading">Online Terminals</h5>
          <p className="summary-total">
            {terminals.data && terminals.data.onlineTerminals}
          </p>
          <div className="row">
            <div className="col-6 border-end">
              <p className="mb-0">Successful</p>
              <p className="terminal-count">127,000</p>
            </div>
            <div className="col-6">
              <p className="mb-0">Failed</p>
              <p className="terminal-count">73,000</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-4 m-2">
        <div className="col-8">
          <div className="w-100 pt-5">
            <LineChart />
          </div>
        </div>
        <div className="col-4 terminal-progress-summary">
          <div className="border p-2 shadow">
            <div className="row p-2 utilized">
              <h4 className="heading">Utilised Terminal</h4>
              <div className="col-6">
                <p className="fs-7 mb-1">
                  This week
                  <span>
                    <i className="fa-solid fa-angle-down"></i>
                  </span>
                </p>
                <p className="total-value">
                  {status.data &&
                    status.data.utilized_terminals.toLocaleString()}
                </p>
                <p>5% Down Today</p>
              </div>
              <div className="col-6 ps-4">
                <div className="circular-bar">
                  <CircularProgressbarWithChildren
                    value={
                      status.data && status.data.utilized_terminals_percent
                    }
                    styles={buildStyles({
                      pathColor: "#228835",
                      rotation: 1.0,
                      strokeLinecap: "round",
                      textSize: "12px",
                      pathTransitionDuration: 0.5,
                      textColor: "#f88",
                      trailColor: "#d6d6d6",
                    })}
                  >
                    <div>
                      <img src={check} alt="success" />
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
              </div>
            </div>
          </div>
          <div className="border mt-5 p-2 shadow">
            <div className="row p-2 unutilized">
              <h4 className="heading">Non-Utilised Terminal</h4>
              <div className="col-6">
                <p className="fs-7 mb-1">
                  This week
                  <span>
                    <i className="fa-solid fa-angle-down"></i>
                  </span>
                </p>
                <p className="total-value">
                  {status.data &&
                    status.data.non_utilized_terminals.toLocaleString()}
                </p>
                <p>5% Down Today</p>
              </div>
              <div className="col-6 ps-4">
                <div className="circular-bar">
                  <CircularProgressbarWithChildren
                    value={
                      status.data && status.data.non_utilized_terminals_percent
                    }
                    styles={buildStyles({
                      pathColor: "#FF0000",
                      rotation: 1.0,
                      strokeLinecap: "round",
                      textSize: "12px",
                      pathTransitionDuration: 0.5,
                      textColor: "#f88",
                      trailColor: "#d6d6d6",
                    })}
                  >
                    <div>
                      <img src={xmark} alt="failed" />
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
