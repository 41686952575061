// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transactions-dashboard {
    margin-top: 60px;
}

.dashboard-main {
    margin-left: 14%;
    margin-top: 30px;
}

.transactions-dashboard .date-filter {
    display: flex;
    align-items: center;
}

.transactions-dashboard .date-filter p {
    margin-bottom: 0;
    margin-right: 5px;
    font-size: 10px;
}

.transactions-dashboard .downloads {
    margin-left: 55px;
    border: none;
    padding: 0;
}

.transactions-dashboard .downloads img {
    height: 46px;
}

.history-param .search-input {
    width: 270px;
    margin-right: 40px;
}

.transactions-dashboard .custom-select {
    width: 120px;
}

.transactions-dashboard .custom-select .dropdown-menu {
    height: 150px;
    overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/view/reporting-dashboard/card-transactions/transaction-dashboard/TransactionDashboard.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,cAAc;AAClB","sourcesContent":[".transactions-dashboard {\n    margin-top: 60px;\n}\n\n.dashboard-main {\n    margin-left: 14%;\n    margin-top: 30px;\n}\n\n.transactions-dashboard .date-filter {\n    display: flex;\n    align-items: center;\n}\n\n.transactions-dashboard .date-filter p {\n    margin-bottom: 0;\n    margin-right: 5px;\n    font-size: 10px;\n}\n\n.transactions-dashboard .downloads {\n    margin-left: 55px;\n    border: none;\n    padding: 0;\n}\n\n.transactions-dashboard .downloads img {\n    height: 46px;\n}\n\n.history-param .search-input {\n    width: 270px;\n    margin-right: 40px;\n}\n\n.transactions-dashboard .custom-select {\n    width: 120px;\n}\n\n.transactions-dashboard .custom-select .dropdown-menu {\n    height: 150px;\n    overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
