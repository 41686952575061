// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.merchant-view {
    background-color: #ffffff;
    border: 0.5px solid #CBCBCB;
    padding: 20px;
}

/* .merchant-count {
 margin-left: 30px;
} */

.merchant-count .heading {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 0;
}

.merchant-count .count {
    color: #2A3B5F;
    font-weight: 600;
    font-size: 24px;
}`, "",{"version":3,"sources":["webpack://./src/view/reporting-dashboard/card-transactions/merchant-management/view-merchants/MerchantView.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,2BAA2B;IAC3B,aAAa;AACjB;;AAEA;;GAEG;;AAEH;IACI,eAAe;IACf,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".merchant-view {\n    background-color: #ffffff;\n    border: 0.5px solid #CBCBCB;\n    padding: 20px;\n}\n\n/* .merchant-count {\n margin-left: 30px;\n} */\n\n.merchant-count .heading {\n    font-size: 14px;\n    text-transform: uppercase;\n    margin-bottom: 0;\n}\n\n.merchant-count .count {\n    color: #2A3B5F;\n    font-weight: 600;\n    font-size: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
