import React from "react";
import "./Team.css";
import Member from "./Member";
import Roles from "./Roles";

function Tabs({ data = {}, handleTab, activeTab }) {
  return (
    <>
    <div className="onboard-nav mt-3">
      <ul>
        {data.map((item, index) => (
          <li
            className={index === activeTab ? "nav-item active" : "nav-item"}
            key={index}
            onClick={() => handleTab(index)}
          >
            {item.label}
          </li>
        ))}
      </ul>
    </div>
    <div className="content">{data[activeTab].content}</div>
    </>
  );
}

export default function Team() {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTab = (index) => {
    setActiveTab(index);
  };

  const member = <Member/>;
  const role = <Roles/>;
  return (
    <div className="team">
      <Tabs
        handleTab={handleTab}
        activeTab={activeTab}
        data={[
          {
            label: "Members",
            content: member,
          },
          {
            label: "Roles",
            content: role,
          },
        ]}
      />
    </div>
  );
}
