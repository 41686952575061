import React from "react";
import "./Description.css";
import screenshot from "../../assets/images/screenshot1.png";
import screenshot2 from "../../assets/images/screenshot2.png";
import screenshot3 from "../../assets/images/screenshot3.png";

export default function Description() {
  const [displayImage, setDisplayImage] = React.useState(1);
  let content = "";

  const handleChangeDisplay = (index) => {
    setDisplayImage(index);
  };

  if (displayImage === 1) {
    content = (
      <div className="screenshot">
        <img src={screenshot} alt="" />
      </div>
    );
  } else if (displayImage === 2) {
    content = (
      <div className="screenshot two">
        <img src={screenshot2} alt="" />
      </div>
    );
  } else {
    content = (
      <div className="screenshot three">
        <img src={screenshot3} alt="" />
      </div>
    );
  }

  return (
    <div id="how-it-works" className="description">
      <div className="row">
        <div className="col">
          <h1>How It Works</h1>
          <div className="options">
            <div className="option">
              <h2 className={displayImage === 1 ? "active" : ""} onClick={() => handleChangeDisplay(1)}>
                Create an Account
              </h2>
              <p>Log In To Our Web App And Create Your Account</p>
            </div>
            <div className="option" onClick={() => handleChangeDisplay(2)}>
              <h2 className={displayImage === 2 ? "active" : ""}>Create Passcode</h2>
              <p>Log In To Out Web App And Create Your Account</p>
            </div>
            <div className="option" onClick={() => handleChangeDisplay(3)}>
              <h2 className={displayImage === 3 ? "active" : ""}>Get A Dashbaord And Start Transacting</h2>
              <p>Log In To Our Web App And Create Your Account</p>
            </div>
          </div>
        </div>
        <div className="col">{content}</div>
      </div>
    </div>
  );
}
