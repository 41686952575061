// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-merchant .tab-view .content {
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 20px;
}

.add-merchant .exit-btn {
  background-color: transparent;
  border: none;
  color: #0981fd;
}

.add-merchant .tab-view {
  margin-top: 4px;
}

.add-merchant .term-no-error {
  color: #dc3545;
}
`, "",{"version":3,"sources":["webpack://./src/view/reporting-dashboard/profle/add-merchant/AddMerchant.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".add-merchant .tab-view .content {\n  background-color: #ffffff;\n  padding: 10px;\n  margin-bottom: 20px;\n}\n\n.add-merchant .exit-btn {\n  background-color: transparent;\n  border: none;\n  color: #0981fd;\n}\n\n.add-merchant .tab-view {\n  margin-top: 4px;\n}\n\n.add-merchant .term-no-error {\n  color: #dc3545;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
