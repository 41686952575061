// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doc-upload {
  font-size: 12px;
  position: relative;
}

.doc-upload h2 {
  margin-top: 20px;
  color: #2a2f5c;
  font-size: 22px;
  font-weight: 600;
}

.doc-upload .skip {
  position: absolute;
  right: 50px;
  top: -70px;
  background-color: transparent;
  color: blue;
  border: none;
  border-radius: 0;
  padding-bottom: 15px;
  box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/view/onboarding/DocUpload.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,UAAU;EACV,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,oBAAoB;EACpB,gBAAgB;AAClB","sourcesContent":[".doc-upload {\n  font-size: 12px;\n  position: relative;\n}\n\n.doc-upload h2 {\n  margin-top: 20px;\n  color: #2a2f5c;\n  font-size: 22px;\n  font-weight: 600;\n}\n\n.doc-upload .skip {\n  position: absolute;\n  right: 50px;\n  top: -70px;\n  background-color: transparent;\n  color: blue;\n  border: none;\n  border-radius: 0;\n  padding-bottom: 15px;\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
