import React, { useState } from "react";
import DeleteData from "../../../api/DeleteData";
import AppData from "../../../config/appData.json";

export default function ConfirmDeleteModal({
  setConfirmDeleteModal,
  adminData,
}) {
  const [status, setStatus] = useState("");
  const transId = adminData._id;
  const url = AppData.VAS_URL + `admin/delete-admin/${transId}`;
  const handleCloseModal = () => {
    setConfirmDeleteModal(false);
  };

  const handleConfirmDelete = () => {
    DeleteData(url, setStatus);
    setConfirmDeleteModal(false);
  };
  return (
    <div className="modal add-admin-modal">
      <div className="modal-content mt-3 w-50">
        <div className="modal-header">
          <h4>Delete Confirmation</h4>
        </div>
        <div className="modal-body">
          <p>
            Are you sure you want to delete {adminData.firstName}{" "}
            {adminData.lastName}? This process CAN NOT be undone!
          </p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="cancel-btn"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="delete-btn"
            onClick={handleConfirmDelete}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}
