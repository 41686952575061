import React, { useState, useContext } from "react";
import AppData from "../../config/appData.json";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Images from "../../assets/images/Images";
import PostData from "../../api/PostData";
import { useNavigate } from "react-router-dom";
import { UserContext, AdminContext } from "../../context/UserContext";

export default function Login() {
  const [msg, setMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().required("Merchant ID is required!"),
    password: yup.string().required("Passsword is required!"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const TogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const url = AppData.BASE_URL + "auth/login";
  const navigate = useNavigate();

  const handleLogin = async (data = {}) => {
    try {
      const result = await PostData(url, data);
      const merchantInfo = JSON.stringify(result.data.user);
      localStorage.setItem("merchantInfo", merchantInfo);

      navigate("/merchant/view");
      //   console.log("result", result);
    } catch (error) {
      console.log(error);
      setMsg(error.response.data.message);
    }
  };

  return (
    <main className="signin">
      <div className="container">
        <div className="signin-header">
          <img src={Images.spoutLogo} alt="spoutpay" />
          <h1>You're Welcome</h1>
          <p>
            We can&apos;t wait for you to unbox the suprise we <br /> have got
            for you
          </p>
        </div>
        <div className="signin-section">
          <div className="home">
            <a href="/">Home</a>
          </div>
          <div className="col-7 signin-img"></div>
          <div className="col">
            <form className="form" onSubmit={handleSubmit(handleLogin)}>
              <h2>Login to get Great Deals</h2>
              {msg && <small>{msg}</small>}
              <div className="p-3">
                <div className="mb-3 mt-3 text-start">
                  <label htmlFor="email" className="form-label">
                    Merchant ID
                  </label>
                  <input
                    className={
                      errors.email ? "form-control error" : "form-control"
                    }
                    type="text"
                    placeholder="Enter username"
                    {...register("email")}
                  />
                  <small>{errors.email?.message}</small>
                </div>
                <div className="mb-3 mt-3 text-start password">
                  <label htmlFor="pwd" className="form-label">
                    Password
                  </label>
                  <input
                    className={
                      errors.password ? "form-control error" : "form-control"
                    }
                    type={showPassword ? "text" : "password"}
                    placeholder="•••••••••••••••"
                    {...register("password")}
                  />
                  <img
                    className="pass-view"
                    src={Images.show}
                    alt="pass"
                    onClick={TogglePassword}
                  />
                  <small>{errors.password?.message}</small>
                </div>
                <div className="reset-pass">
                  <a href="/pass-reset">Forgot password?</a>
                </div>
                <button type="submit" className="btn">
                  Start Transacting
                </button>
                <p>
                  Are you new here? Please{" "}
                  <span>
                    <a href="/signup">Sign up</a>
                  </span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}
