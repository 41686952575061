import React, { useState, useEffect } from "react";
import Table from "../../components/Table";
import AppData from "../../config/appData.json";
import handlePostApi from "../../api/HandlePostApi";
import FormatDate from "../../components/FormatDate";
import FormatCurrency from "../../components/FormatCurrency";
import { useNavigate } from "react-router-dom";
import imageURL from "../../imageUrls";

const getStatusStyles = (status) => {
  let backgroundColor, color;

  if (status === "successful") {
    color = "#106745";
  } else if (status === "failed") {
    color = "#DE0000";
  } else {
    color = "#F1B207";
  }

  return {
    backgroundColor,
    color,
    paddingTop: "4px",
    paddingLeft: "6px",
    width: "80px",
  };
};

export default function AgentTransactions() {
  const [history, setHistory] = useState([]);
  const navigate = useNavigate();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const firstDayOfYear = new Date(currentYear, 0, 1);

  const url = AppData.WALLET_URL + "user/transaction-history";
  const data = {
    page: 1,
    limit: 10,
    pageNumber: 1,
    startDate: firstDayOfYear,
    endDate: new Date(),
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: (date) => FormatDate(date.createdAt),
      },
      {
        Header: "Service",
        accessor: (dataName) => (
          <div className="select-icon">
            <img
              src={imageURL[dataName.product]}
              className="service-logo"
              alt="service"
            />
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: "product",
      },
      {
        Header: "Payment",
        accessor: (paymentMethod) => (
          <>
            {paymentMethod.paymentMethod}{" "}
            <img
              src={imageURL[paymentMethod.paymentMethod]}
              className="service-logo"
              alt="payment method"
            />
          </>
        ),
      },
      {
        Header: "Amount",
        accessor: (amount) => FormatCurrency(amount.amount),
      },
      {
        Header: "Channel",
        accessor: (channel) => (
          <>
            {channel.channel}{" "}
            <img
              src={imageURL[channel.channel]}
              className="service-logo"
              alt="service"
            />
          </>
        ),
      },
      {
        Header: "Status",
        accessor: (status) => (
          <div style={getStatusStyles(status.status)}>
            • <span style={{ fontSize: "11px" }}>{status.status}</span>
          </div>
        ),
      },
      {
        Header: "Ref",
        accessor: "reference",
      },
      {
        Header: "",
        accessor: "id",
        Cell: (view) => (
          <a style={{ color: "blue", fontSize: "12px", fontWeight: "100" }}>
            view
          </a>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await handlePostApi(url, data);
        setHistory(response.transactions.docs);
      } catch (error) {
        const err = error.response.data.message;
        console.error("Error fetching transactions:", error);
        if (err === "Expired Session, Please Login Again") {
          navigate("/signin");
        }
      }
    };

    fetchTransactions();
  }, []);

  return (
    <>
      <div className="mt-5">
        <div className="table-heading">
          <h5>Recent History</h5>
          {/* <a href="#">View all</a> */}
        </div>
        <div>
          <Table
            columns={columns}
            data={history}
            enableRowSelection={false}
            tableClass={"table table-borderless table-striped mb-0"}
          />
        </div>
      </div>
    </>
  );
}
