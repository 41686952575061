import React, { useState, useEffect } from "react";
import Table from "../../../../components/Table";
import { CSVLink } from "react-csv";
import "./Analysis.css";
import CustomSelect from "../../../../components/customSelect/CustomSelect";
import DateRange from "../../../../components/DateRange";
import GetData from "../../../../api/GetData";
import AppData from "../../../../config/appData.json";
import Pagination from "../../../../components/Pagination";

export default function Analysis() {
  const [performance, setPerformance] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [searchParm, setSearchParam] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [directionValue, setDirectionValue] = useState("desc");

  const postPerPage = 50;
  const totalVol = performance.data && performance.data.length;
  const lastPageNum = Math.ceil(totalVol / postPerPage);

  const formattedStartDate =
    startDate.getFullYear() +
    "-" +
    parseInt(startDate.getMonth() + 1) +
    "-" +
    startDate.getDate();
  const formattedEndDate =
    endDate.getFullYear() +
    "-" +
    parseInt(endDate.getMonth() + 1) +
    "-" +
    endDate.getDate();

  const columns = React.useMemo(
    () => [
      {
        Header: "Merchant Name",
        accessor: (name) => (
          <div className="text-primary">{name.merchant_name}</div>
        ),
      },
      {
        Header: "Merchant Id",
        accessor: "merchant_id",
      },
      {
        Header: "Value(₦)",
        accessor: "trans_value",
      },
      {
        Header: "Value Change(₦)",
        accessor: (valueChange) => (
          <div
            className={
              valueChange.value_change < 0 ? "text-danger" : "text-success"
            }
          >
            {valueChange.value_change}
          </div>
        ),
      },
      {
        Header: "Volume",
        accessor: "trans_volume",
      },
      {
        Header: "Volume Change",
        accessor: (volume) => (
          <div
            className={
              volume.volume_change < 0 ? "text-danger" : "text-success"
            }
          >
            {volume.volume_change}
          </div>
        ),
      },
      {
        Header: "Active Terminal",
        accessor: "active_terminals",
      },
      {
        Header: "Inactive Terminal",
        accessor: "inactive_terminals",
      },
    ],
    []
  );

  useEffect(() => {
    const fetchPerformanceData = async () => {
      try {
        const endpoint =
          AppData.BASE_URL +
          `transactions/performance-records?startdate=${formattedStartDate}&enddate=${formattedEndDate}&dir=${directionValue}`;
        const response = await GetData(endpoint);
        setPerformance(response);
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    fetchPerformanceData();
  }, [formattedStartDate, formattedEndDate, directionValue]);

  return (
    <div className="analysis">
      <div className="analysis-param">
        <div className="row me-3">
          <div className="col-4 me-3">
            <CustomSelect
              className="custom-select"
              heading="Status"
              selectedVal={directionValue}
              setSelectedValue={setDirectionValue}
              items={[
                {
                  name: "desc",
                },
                {
                  name: "asc",
                },
              ]}
            />
          </div>
        </div>
        <div>
          <div className="d-flex">
            <div className="date-filter">
              <p>Filter by Date: </p>
              <DateRange
                startDate={startDate}
                endDate={endDate}
                handleStartDate={(date) => setStartDate(date)}
                handleEndDate={(date) => setEndDate(date)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="export">
        {performance.data && performance.data.length > 0 && (
          <CSVLink
            data={performance.data}
            filename="Performance"
            className="export-btn btn"
          >
            Export Data
          </CSVLink>
        )}
      </div>
      <hr />
      <div className="p-4">
        <div className="table-responsive table-wrapper">
          {performance.data && (
            <Table
              columns={columns}
              data={performance.data}
              tableClass="table table-borderless table-striped table-hover fs-7 mb-0"
              enableRowSelection={false}
            />
          )}
          {lastPageNum > 1 && (
            <Pagination
              prevPage={pageNumber - 1}
              nextPage={pageNumber + 1}
              totalPages={lastPageNum}
              hasNextPage={pageNumber < lastPageNum}
              hasPrevPage={pageNumber > 1}
              setPageNum={setPageNumber}
            />
          )}
        </div>
      </div>
      <hr />
    </div>
  );
}
