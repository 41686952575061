// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about {
    position: relative;
    background-color: #FAFBFF;
    padding: 40px 85px;
    text-align: center;
}

.about .card {
    background-color: #FFFFFF;
    border: none;
    box-shadow: 0px 2px 20px #0000001A;
    border-radius: 8px;
    padding: 20px;
    height: 220px;
}

.about .card h1 {
    font-size: 18px;
    margin-top: 10px;
    font-weight: 600;
}

.about p {
    color: #808080;
}

.about .card p {
    font-size: 12px;
}

.about .card img{
    height: 50px;
    width: 50px;
    margin-bottom: 10px;
}

.brands .group-1{
    width: 80%;
    margin-bottom: 20px;
    height: 30px;
}

.why-spoutpay .heading {
    display: flex;
    justify-content: flex-start;
}

.why-spoutpay .heading img {
    width: 335px;
    height: 50px;
}

.dotted-line {
    display: flex;
    align-items: flex-start;
}

.dotted-line img {
    margin-top: 8px;
    width: 40%;
}

.about .card-section {
    margin-top: 30px;
}

.about .card-section h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/view/landing/About.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kCAAkC;IAClC,kBAAkB;IAClB,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,UAAU;AACd;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".about {\n    position: relative;\n    background-color: #FAFBFF;\n    padding: 40px 85px;\n    text-align: center;\n}\n\n.about .card {\n    background-color: #FFFFFF;\n    border: none;\n    box-shadow: 0px 2px 20px #0000001A;\n    border-radius: 8px;\n    padding: 20px;\n    height: 220px;\n}\n\n.about .card h1 {\n    font-size: 18px;\n    margin-top: 10px;\n    font-weight: 600;\n}\n\n.about p {\n    color: #808080;\n}\n\n.about .card p {\n    font-size: 12px;\n}\n\n.about .card img{\n    height: 50px;\n    width: 50px;\n    margin-bottom: 10px;\n}\n\n.brands .group-1{\n    width: 80%;\n    margin-bottom: 20px;\n    height: 30px;\n}\n\n.why-spoutpay .heading {\n    display: flex;\n    justify-content: flex-start;\n}\n\n.why-spoutpay .heading img {\n    width: 335px;\n    height: 50px;\n}\n\n.dotted-line {\n    display: flex;\n    align-items: flex-start;\n}\n\n.dotted-line img {\n    margin-top: 8px;\n    width: 40%;\n}\n\n.about .card-section {\n    margin-top: 30px;\n}\n\n.about .card-section h3 {\n    font-size: 18px;\n    font-weight: 600;\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
