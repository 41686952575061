import React from "react";
import "./DocumentItem.css";
import cancel from "../assets/images/cancel-icon.svg";

export default function DocumentItem({
  doc,
  index,
  handleDragOver,
  handleDrop,
  inputRef,
  handleClick,
  handleChange,
  deleteDocument,
}) {
  const handleUploadClick = () => {
    handleClick(index);
  };

  const handleFileChange = (e) => {
    handleChange(e, index);
  };

  const handleDeleteClick = () => {
    deleteDocument(index);
  };
  return (
    <div className="col-4 document-border">
      <div className="upload-file">
        <h3>{doc.heading}</h3>
        <p className={doc.required === "Required" ? "required" : "optional"}>
          {doc.required}
        </p>
        <div
          className="file-area"
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, index)}
        >
          <img className="upload-icon" src={doc.icon} alt="" />
          <input
            type="file"
            accept=".png, .jpeg"
            onChange={handleFileChange}
            hidden
            ref={(element) => {
              inputRef.current[index] = element;
            }}
          />
          <div className="click-upload-btn" onClick={handleUploadClick}>
            Click here to upload your <br /> {doc.heading}
          </div>
          <p>
            Or <br /> Drag Files Here
          </p>
        </div>
        {doc.name && (
          <div className="about-file">
            <div className="file-image">
              <img src={doc.icon} alt="" />
            </div>
            <p className="file-name">{doc.name}</p>
            <div className="cancel-upload" onClick={handleDeleteClick}>
              <img src={cancel} alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
