import React from "react";
import "./GroupedInput.css";

export default function GroupedInput({
  label,
  placeholder,
  register,
  name,
  error,
  required,
  type,
}) {
  return (
    <div
      className={error ? "grouped-input grouped-input-error" : "grouped-input"}
    >
      <label>
        {label} {required && <small>*</small>}
      </label>
      <input
        type={type ? type : "text"}
        className="form-control"
        placeholder={placeholder}
        {...register(name)}
      />
    </div>
  );
}
