import React from "react";
import { Bar } from "react-chartjs-2";

export default function BarChart({ indexAxis, state, scales, cp, bp }) {
  return (
    <>
      <Bar
        data={state}
        options={{
          categoryPercentage: cp,
          barPercentage: bp,
          indexAxis: indexAxis,
          title: {
            display: true,
            fontSize: 20,
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: scales,
        }}
      />
    </>
  );
}
