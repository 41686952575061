import React, { useState } from "react";
import HandlePostApi from "../../../api/HandlePostApi";
import AppData from "../../../config/appData.json";

export default function ViewFundApproval({
  setViewApprovalModal,
  approvalData,
  userData,
}) {
  const [err, setErr] = useState();
  const [msg, setMsg] = useState("");
  const data = { fundId: approvalData.id };

  const handleCloseModal = () => {
    setViewApprovalModal(false);
  };

  const handleApproveRequest = () => {
    if (
      approvalData.postedBy._id === userData._id ||
      userData.adminType !== "financial-admin"
    ) {
      setMsg("You do not have permission to approve this request!");
    } else {
      const url = AppData.VAS_URL + "admin/initiate-wallet-fund";
      HandlePostApi(url, data);
      setViewApprovalModal(false);
    }
  };

  const handleDeclineRequest = () => {
    if (
      approvalData.postedBy._id === userData._id ||
      userData.adminType !== "financial-admin"
    ) {
      setMsg("You do not have permission to decline this request!");
    } else {
      const url = AppData.VAS_URL + "admin/reject-wallet-fund";
      HandlePostApi(url, data);
      setViewApprovalModal(false);
    }
  };

  return (
    <div className="modal fund-approval-modal">
      <div className="modal-content">
        <div className="fund-approval-container">
          <div className="header">Funding Request Summary</div>
          <div className="row fund-approval-content">
            <div className="col-4">
              <div className="funding-data">
                <div>
                  <h1>Reference Number</h1>
                  <p>{approvalData.reference}</p>
                </div>
                <div>
                  <h1>Agent Name</h1>
                  <p>
                    {approvalData.userId.firstName}{" "}
                    {approvalData.userId.firstName}
                  </p>
                </div>
                <div>
                  <h1>Logged By</h1>
                  <p>
                    {approvalData.postedBy.firstName}{" "}
                    {approvalData.postedBy.firstName}
                  </p>
                </div>
                <div>
                  <h1>Amount</h1>
                  <p>N{approvalData.amount.toLocaleString()}</p>
                </div>
                <div>
                  <h1>Description</h1>
                  <p>{approvalData.description}</p>
                </div>
                <div>
                  <h1>Status</h1>
                  <p>{approvalData.status}</p>
                </div>
              </div>
            </div>
            <div className="col">
              {msg && <small>{msg}</small>}
              {approvalData.status === "completed" && (
                <div className="approval-details">
                  <div className="approval-status approved">Approved</div>
                  <div className="approved-by">
                    <h1>Approved By</h1>
                    <p>
                      {approvalData.approvedBy.firstName}{" "}
                      {approvalData.approvedBy.lastName}
                    </p>
                  </div>
                </div>
              )}
              {approvalData.status === "declined" && (
                <div className="approval-details">
                  <div className="approval-status declined">Declined</div>
                  <div className="approved-by">
                    <h1>Declined By</h1>
                    <p>
                      {approvalData.approvedBy.firstName}{" "}
                      {approvalData.approvedBy.lastName}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="approval-footer mb-4">
            <div className="approval-action mt-3">
              <button className="action-btn cancel" onClick={handleCloseModal}>
                Cancel
              </button>
              {approvalData.status === "pending" && (
                <div>
                  <button
                    className="action-btn create me-3"
                    type="button"
                    onClick={handleApproveRequest}
                  >
                    Approve Request
                  </button>
                  <button
                    className="action-btn decline"
                    type="button"
                    onClick={handleDeclineRequest}
                  >
                    Decline Request
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
