// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-trans {
    position: relative;
    background-color: #ffffff;
    border: 0.5px solid #CBCBCB;
    padding: 40px;
    padding-top: 10px;
    font-size: 14px;
}

.report-trans .search-params-area {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;
}

.report-trans .search-params {
    display: flex;
    gap: 15px;
}

.report-trans .select-search {
    display: flex;
    flex-direction: column;
}

.report-trans .select-search select{
    border: 0.5px solid #016AA7;
    width: 130px;
    border-radius: 4px;
    padding: 9px;
    background-color: transparent;
    color: #016AA7;
}

.report-trans .search {
    border: 0.5px solid #016AA7;
    background-color: transparent;
    border-radius: 4px !important;
    opacity: 1;
    font-size: 10px;
    margin-top: 20px;
    padding: 13px;
    width: 210px;
}`, "",{"version":3,"sources":["webpack://./src/view/reporting-dashboard/card-transactions/settlement/settlement-report/ReportTransaction.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,2BAA2B;IAC3B,aAAa;IACb,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,2BAA2B;IAC3B,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,6BAA6B;IAC7B,cAAc;AAClB;;AAEA;IACI,2BAA2B;IAC3B,6BAA6B;IAC7B,6BAA6B;IAC7B,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,YAAY;AAChB","sourcesContent":[".report-trans {\n    position: relative;\n    background-color: #ffffff;\n    border: 0.5px solid #CBCBCB;\n    padding: 40px;\n    padding-top: 10px;\n    font-size: 14px;\n}\n\n.report-trans .search-params-area {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    margin-bottom: 30px;\n}\n\n.report-trans .search-params {\n    display: flex;\n    gap: 15px;\n}\n\n.report-trans .select-search {\n    display: flex;\n    flex-direction: column;\n}\n\n.report-trans .select-search select{\n    border: 0.5px solid #016AA7;\n    width: 130px;\n    border-radius: 4px;\n    padding: 9px;\n    background-color: transparent;\n    color: #016AA7;\n}\n\n.report-trans .search {\n    border: 0.5px solid #016AA7;\n    background-color: transparent;\n    border-radius: 4px !important;\n    opacity: 1;\n    font-size: 10px;\n    margin-top: 20px;\n    padding: 13px;\n    width: 210px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
