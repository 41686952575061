import React, { useState, useEffect } from "react";
import Table from "../../../components/Table";
import { useNavigate } from "react-router-dom";
import AppData from "../../../config/appData.json";
import HandlePostApi from "../../../api/HandlePostApi";
import AddAdmin from "./AddAdmin";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

export default function Member() {
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(true);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("");
  const [history, setHistory] = useState([]);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [adminData, setAdminData] = useState({});

  const url = AppData.WALLET_URL + "admin/get/all";

  const handleDeleteAdmin = (data) => {
    setAdminData(data);
    setConfirmDeleteModal(true);
  };

  const data = {
    page: "1",
    email: "",
    username: "",
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Members",
        accessor: (name) => (
          <>
            {name.firstName}
            <div
              style={{
                fontSize: "10px",
                color: "#A0A0A0",
                textTransform: "lowercase",
              }}
            >
              {name.email}
            </div>
          </>
        ),
      },
      {
        Header: "Roles",
        accessor: "adminType",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
      },
      {
        Header: ".",
        accessor: "id",
        Cell: (tableInstance) => {
          const { row: index } = tableInstance;
          return (
            <div className="d-flex">
              <div className="me-3">
                {" "}
                <button className="fund">Change Role</button>
              </div>
              <div>
                {" "}
                <button
                  className="fund"
                  onClick={() => handleDeleteAdmin(index.original)}
                >
                  Remove
                </button>
              </div>
            </div>
          );
        },
        show: false,
      },
    ],
    []
  );

  const initialState = { hiddenColumns: ["Action"] };

  const fetchData = () => {
    HandlePostApi(url, data).then((result) => {
      const { error, message } = result;
      if (result.status === "error") {
        navigate("/signin");
      }
      // console.log("history", result.admins.docs)
      setHistory(result.admins.docs);
      if (error) {
        setErr(true);
        setMsg(message);
        return;
      }
    });
  };

  const addAdmin = () => {
    setShowAdminModal(true);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <div className="member">
      <div className="header">
        <h6 className="mt-2">Members</h6>
        {/* <div className="add-member">
          <button className="btn add-member-btn" onClick={addAdmin}>
            Add a Member
          </button>
        </div> */}
      </div>
      <div className="m-3">
        <Table
          columns={columns}
          data={history}
          initialState={initialState}
          enableRowSelection={false}
          tableClass={"table table-borderless table-striped mb-0"}
        />
      </div>
      {showAdminModal && <AddAdmin setShowAdminModal={setShowAdminModal} />}
      {confirmDeleteModal && (
        <ConfirmDeleteModal
          setConfirmDeleteModal={setConfirmDeleteModal}
          adminData={adminData}
        />
      )}
    </div>
  );
}
