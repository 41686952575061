// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-pass form {
    padding-top: 30px;
    /* padding-bottom: 30px; */
}

.change-pass .pass-view {
    position: absolute;
    top: 42px;
    right: 10px;
    height: 12px;
    margin-right: 15px;
    cursor: pointer;
}

.change-pass .form small {
    color: #E86880;
}

.change-pass .form input {
    letter-spacing: 5px;
}

.pwd-checker .checker {
    display: flex;
    gap: 6px;
}

.pwd-checker .check-text {
    font-size: 10px;
    margin-top: 4px;
}

.checked {
    background-color: #106745;
}

.unchecked {
    background-color: #DE0000;
}

.pwd-checker .check-icon {
    border-radius: 50%;
    height: 17px;
    width: 17px;
    display: flex;
    padding-left: 2px;
    align-items: center;
}

.pwd-checker .check-icon .fa-solid {
    color: #ffffff;
}

.pass-confirmation h2 {
    color: #1B3179;
    margin-top: 70px;
    font-size: 30px;
    font-weight: 600;
}

.pass-confirmation .sub-header {
    color: #A0A0A0;
}

.pass-confirmation form .btn {
    background-color: #060D27;
    color: #ffffff;
    width: 50%;
    border-radius: 30px;
    margin-top: 25px;
    font-weight: 500;
    margin-bottom: 150px;
}`, "",{"version":3,"sources":["webpack://./src/view/entrance/ResetPassword.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,QAAQ;AACZ;;AAEA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,UAAU;IACV,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,oBAAoB;AACxB","sourcesContent":[".change-pass form {\n    padding-top: 30px;\n    /* padding-bottom: 30px; */\n}\n\n.change-pass .pass-view {\n    position: absolute;\n    top: 42px;\n    right: 10px;\n    height: 12px;\n    margin-right: 15px;\n    cursor: pointer;\n}\n\n.change-pass .form small {\n    color: #E86880;\n}\n\n.change-pass .form input {\n    letter-spacing: 5px;\n}\n\n.pwd-checker .checker {\n    display: flex;\n    gap: 6px;\n}\n\n.pwd-checker .check-text {\n    font-size: 10px;\n    margin-top: 4px;\n}\n\n.checked {\n    background-color: #106745;\n}\n\n.unchecked {\n    background-color: #DE0000;\n}\n\n.pwd-checker .check-icon {\n    border-radius: 50%;\n    height: 17px;\n    width: 17px;\n    display: flex;\n    padding-left: 2px;\n    align-items: center;\n}\n\n.pwd-checker .check-icon .fa-solid {\n    color: #ffffff;\n}\n\n.pass-confirmation h2 {\n    color: #1B3179;\n    margin-top: 70px;\n    font-size: 30px;\n    font-weight: 600;\n}\n\n.pass-confirmation .sub-header {\n    color: #A0A0A0;\n}\n\n.pass-confirmation form .btn {\n    background-color: #060D27;\n    color: #ffffff;\n    width: 50%;\n    border-radius: 30px;\n    margin-top: 25px;\n    font-weight: 500;\n    margin-bottom: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
