// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service-performance .doughnut-chart {
  height: 300px;
  width: 300px;
}

.service-performance .service-traffic-growth {
  margin-top: 30px;
  margin-right: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/view/reporting-dashboard/target/ServicePerformance.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".service-performance .doughnut-chart {\n  height: 300px;\n  width: 300px;\n}\n\n.service-performance .service-traffic-growth {\n  margin-top: 30px;\n  margin-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
