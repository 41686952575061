import React from "react";
import { Doughnut } from "react-chartjs-2";

export default function DoughnutChart() {
  const data = {
    labels: ["Red", "Blue", "Yellow"],
    datasets: [
      {
        data: [200, 50, 10, 40],
        backgroundColor: ["#182EA5", "#7BA8FF", "#00B56F", "#DBC140"],
        hoverBackgroundColor: ["#182EA5", "#7BA8FF", "#00B56F", "#DBC140"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <>
      <Doughnut
        data={data}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
        }}
      />
    </>
  );
}
