// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.config-dashboard {
    background-color: #ffffff;
    border: 0.5px solid #CBCBCB;
    color: #194065;
    font-size: 14px;
    margin-top: 20px;
}

.config-dashboard .heading {
    font-size: 14px;
    margin-bottom: 15px;
}

.config-dashboard .card {
    border: 1px solid #707070;
    opacity: 1;
    border-radius: 10px;
    padding: 40px;
    padding-bottom: 20px;
}

.config-dashboard .form-control {
    background-color: #EBEBEBC9;
    border: 1px solid #707070;
    border-radius: 4px;
}

.submit-btn {
    padding-left: 4rem !important;
    margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/view/reporting-dashboard/card-transactions/configuration/dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,2BAA2B;IAC3B,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,UAAU;IACV,mBAAmB;IACnB,aAAa;IACb,oBAAoB;AACxB;;AAEA;IACI,2BAA2B;IAC3B,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,mBAAmB;AACvB","sourcesContent":[".config-dashboard {\n    background-color: #ffffff;\n    border: 0.5px solid #CBCBCB;\n    color: #194065;\n    font-size: 14px;\n    margin-top: 20px;\n}\n\n.config-dashboard .heading {\n    font-size: 14px;\n    margin-bottom: 15px;\n}\n\n.config-dashboard .card {\n    border: 1px solid #707070;\n    opacity: 1;\n    border-radius: 10px;\n    padding: 40px;\n    padding-bottom: 20px;\n}\n\n.config-dashboard .form-control {\n    background-color: #EBEBEBC9;\n    border: 1px solid #707070;\n    border-radius: 4px;\n}\n\n.submit-btn {\n    padding-left: 4rem !important;\n    margin-bottom: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
