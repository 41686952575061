// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spout-landing {
    background-color: #060D27;
    height: 100vh;
    font-family: hoss-round, sans-serif;
    font-style: normal;
    color: #2A2F5C;
    overflow-x: hidden;
}



`, "",{"version":3,"sources":["webpack://./src/view/landing/Landing.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,mCAAmC;IACnC,kBAAkB;IAClB,cAAc;IACd,kBAAkB;AACtB","sourcesContent":[".spout-landing {\n    background-color: #060D27;\n    height: 100vh;\n    font-family: hoss-round, sans-serif;\n    font-style: normal;\n    color: #2A2F5C;\n    overflow-x: hidden;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
