// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.configuration {
    margin-left: 14%;
    margin-top: 30px;
}

.switch-config {
    background-color: #ffffff;
    padding: 40px;
    font-size: 14px;
    border: 0.5px solid #CBCBCB;
}

.switch-config .heading {
    color: #194065;
    font-size: 14px;
}

.switch-config .form-label {
    color: #000000;
    font-size: 12px;
}

.switch-config .form-control {
    background: #EBEBEBC9 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
}

.switch-config select {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    font-size: 12px;
    border-radius: 5px;
    width: 155px;
    height: 41px;
    padding: auto;
    padding: 5px 15px;
    opacity: 0.5;
    /* appearance: none; */
}`, "",{"version":3,"sources":["webpack://./src/view/reporting-dashboard/card-transactions/configuration/Configuration.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,eAAe;IACf,2BAA2B;AAC/B;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,iDAAiD;IACjD,yBAAyB;AAC7B;;AAEA;IACI,+CAA+C;IAC/C,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,YAAY;IACZ,sBAAsB;AAC1B","sourcesContent":[".configuration {\n    margin-left: 14%;\n    margin-top: 30px;\n}\n\n.switch-config {\n    background-color: #ffffff;\n    padding: 40px;\n    font-size: 14px;\n    border: 0.5px solid #CBCBCB;\n}\n\n.switch-config .heading {\n    color: #194065;\n    font-size: 14px;\n}\n\n.switch-config .form-label {\n    color: #000000;\n    font-size: 12px;\n}\n\n.switch-config .form-control {\n    background: #EBEBEBC9 0% 0% no-repeat padding-box;\n    border: 1px solid #707070;\n}\n\n.switch-config select {\n    background: #FFFFFF 0% 0% no-repeat padding-box;\n    border: 1px solid #707070;\n    font-size: 12px;\n    border-radius: 5px;\n    width: 155px;\n    height: 41px;\n    padding: auto;\n    padding: 5px 15px;\n    opacity: 0.5;\n    /* appearance: none; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
