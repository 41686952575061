// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-btn {
  background-color: transparent;
  border: none;
}

.view-btn .fa-list {
  color: #3381f2;
  font-size: 20px;
}

.modal-body img {
  height: 50px;
  width: 50px;
}

.grp-btn {
  color: #ffffff;
  border: none;
  padding: 5px 12px;
  border-radius: 4px;
  margin-right: 10px;
}

.cls-btn {
  background-color: #3381f2;
}

.del-btn {
  background-color: #de0000;
}

.name-link {
  color: blue;
  cursor: pointer;
  font-weight: 600;
}

.group .custom-select {
  width: 120px;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/view/user-dashboard/Group.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".view-btn {\n  background-color: transparent;\n  border: none;\n}\n\n.view-btn .fa-list {\n  color: #3381f2;\n  font-size: 20px;\n}\n\n.modal-body img {\n  height: 50px;\n  width: 50px;\n}\n\n.grp-btn {\n  color: #ffffff;\n  border: none;\n  padding: 5px 12px;\n  border-radius: 4px;\n  margin-right: 10px;\n}\n\n.cls-btn {\n  background-color: #3381f2;\n}\n\n.del-btn {\n  background-color: #de0000;\n}\n\n.name-link {\n  color: blue;\n  cursor: pointer;\n  font-weight: 600;\n}\n\n.group .custom-select {\n  width: 120px;\n  margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
