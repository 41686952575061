import React, { useState, useEffect, useCallback } from "react";
import Table from "../../../components/Table";
import GetData from "../../../api/GetData";
import AppData from "../../../config/appData.json";
import Pagination from "../../../components/Pagination";
import axios from "axios";
import Modal from "../../../components/modal/Modal";
import GroupedInput from "../../../components/GroupedInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export default function RiskProfiing() {
  const [bandList, setBandList] = useState([]);
  const [bandProfile, setBandProfile] = useState({});
  const [statusVol, setStatusVol] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const postPerPage = 50;
  const lastPageNum = Math.ceil(statusVol / postPerPage);
  const pageStart = (pageNumber - 1) * postPerPage + 1;
  const [message, setMessage] = useState("");

  const handleUpdateProfile = async (row) => {
    setBandProfile(row);
    setIsModalOpen(true);
  };

  const handleDeleteProfile = (row) => {
    console.log("Handling delete", row);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S/N",
        Cell: (row) => {
          return <div>{row.row.index + pageStart}</div>;
        },
      },
      {
        Header: "Band Name",
        accessor: (row) => row.band[0]?.name || "N/A",
      },
      {
        Header: "Maximum Amount/Day",
        accessor: (row) => row.amount_daily.toLocaleString(),
      },
      {
        Header: "Minimum Amount/Month",
        accessor: (row) => row.amount_cumulative.toLocaleString(),
      },
      {
        Header: "Description",
        accessor: (row) => row.band[0]?.description || "N/A",
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <button
              className="btn"
              onClick={() => handleUpdateProfile(row.original)}
            >
              <i className="fas fa-edit"></i>
              Edit
            </button>
            <button
              className="btn delete-btn"
              onClick={() => handleDeleteProfile(row)}
            >
              <i className="fa-solid fa-trash"></i>
              Delete
            </button>
          </>
        ),
      },
    ],
    [pageNumber]
  );

  const fetchHistoryData = async () => {
    try {
      const endpoint = AppData.PAYMENT_URL + `risk`;
      const response = await GetData(endpoint);
      // console.log("response", response);
      setBandList(response);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  function formatDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleFilter = (e) => {
    e.preventDefault();
    fetchHistoryData();
  };

  useEffect(() => {
    fetchHistoryData();
  }, [pageNumber]);

  return (
    <div className="risk-config">
      <div className="table-responsive">
        {bandList.data && bandList.data.length > 0 ? (
          <Table
            columns={columns}
            data={bandList.data}
            enableRowSelection={false}
            tableClass={"table table-borderless table-striped mb-0"}
          />
        ) : (
          <p>No record found</p>
        )}
        {lastPageNum > 1 && (
          <Pagination
            prevPage={pageNumber - 1}
            nextPage={pageNumber + 1}
            totalPages={lastPageNum}
            hasNextPage={pageNumber < lastPageNum}
            hasPrevPage={pageNumber > 1}
            setPageNum={setPageNumber}
          />
        )}
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          content={
            <UpdateRiskProfile
              onClose={closeModal}
              setMessage={setMessage}
              bandProfile={bandProfile}
            />
          }
        />
      )}
    </div>
  );
}

const UpdateRiskProfile = ({ onClose, setMessage, bandProfile }) => {
  const [amountDaily, setAmountDaily] = useState(bandProfile.amount_daily);
  const [amountCumulative, setAmountCumulative] = useState(
    bandProfile.amount_cumulative
  );

  const schema = yup.object().shape({
    amount_daily: yup.string(),
    amount_cumulative: yup.string(),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSubmitForm = async () => {
    try {
      const endpoint = `${AppData.PAYMENT_URL}risk/${bandProfile.user_id}`;
      const payload = {
        band_id: bandProfile.band_id,
        user_id: bandProfile.user_id,
        amount_daily: bandProfile.amount_daily,
        amount_cumulative: bandProfile.amount_cumulative,
      };
      const response = await axios.patch(endpoint, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      setMessage(response.message);
      onClose();
    } catch (error) {
      console.log("Error Updating profile", error);
      setMessage(error.response?.data?.message || "error processing request");
      onClose();
    }
  };
  return (
    <form
      className="risk-profile-modal"
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <h4>Edit profile</h4>
      <p className="band-name">Band Name: {bandProfile.band[0].name}</p>
      <div className="grouped-input mb-3">
        <label className="col-12" htmlFor="amountDaily">
          Amount Daily:
        </label>
        <input
          className="form-control"
          type="text"
          id="amountDaily"
          value={amountDaily}
          onChange={(e) => setAmountDaily(e.target.value)}
        />
      </div>
      <div className="grouped-input mb-3">
        <label className="col-12" htmlFor="amountCumulative">
          Amount Cumulative:
        </label>
        <input
          className="form-control"
          type="text"
          id="amountCumulative"
          value={amountCumulative}
          onChange={(e) => setAmountCumulative(e.target.value)}
        />
      </div>
      <button type="submit" className="update-btn">
        Update Profile
      </button>
    </form>
  );
};
