// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.merchant-account {
  margin-top: 40px;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  padding: 20px;
}

.merchant-account .user-field {
  padding-right: 84px;
  padding-bottom: 10px;
}

.merchant-account .user-value {
  font-weight: 100;
}`, "",{"version":3,"sources":["webpack://./src/view/merchant-view/account/Account.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".merchant-account {\n  margin-top: 40px;\n  background-color: #ffffff;\n  border: 1px solid #dee2e6;\n  padding: 20px;\n}\n\n.merchant-account .user-field {\n  padding-right: 84px;\n  padding-bottom: 10px;\n}\n\n.merchant-account .user-value {\n  font-weight: 100;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
