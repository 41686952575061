// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.merchant-view {
  background-color: transparent;
  border: none;
  padding: 20px;
}

.merchant-user .user-pill-content {
  background-color: #ffffff;
  position: absolute;
  top: 82px;
  right: 30px;
  padding: 15px;
  padding-inline: 5px;
  border: 1px solid #dedede;
}

.merchant-user .user-pill-content .fa-solid,
.fa-user {
  padding-right: 5px;
}

.merchant-user ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.merchant-user li {
  padding: 4px;
  padding-inline: 10px;
}

.merchant-user li:hover {
  background-color: #dedede;
}

.merchant-user .logout-btn {
  background-color: transparent;
  border: none;
  padding: 0;
  color: rgb(247, 24, 24);
}

.merchant-user .fa-right-from-bracket {
  color: rgb(247, 24, 24);
}

.merchant-user .merchant-email {
  padding-inline: 10px;
  margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/view/merchant-view/MerchantView.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,UAAU;EACV,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;AACpB","sourcesContent":[".merchant-view {\n  background-color: transparent;\n  border: none;\n  padding: 20px;\n}\n\n.merchant-user .user-pill-content {\n  background-color: #ffffff;\n  position: absolute;\n  top: 82px;\n  right: 30px;\n  padding: 15px;\n  padding-inline: 5px;\n  border: 1px solid #dedede;\n}\n\n.merchant-user .user-pill-content .fa-solid,\n.fa-user {\n  padding-right: 5px;\n}\n\n.merchant-user ul {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n}\n\n.merchant-user li {\n  padding: 4px;\n  padding-inline: 10px;\n}\n\n.merchant-user li:hover {\n  background-color: #dedede;\n}\n\n.merchant-user .logout-btn {\n  background-color: transparent;\n  border: none;\n  padding: 0;\n  color: rgb(247, 24, 24);\n}\n\n.merchant-user .fa-right-from-bracket {\n  color: rgb(247, 24, 24);\n}\n\n.merchant-user .merchant-email {\n  padding-inline: 10px;\n  margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
