import React from "react";
import BarChart from "../../../components/charts/BarChart";

const scales = {
  x: {
    stacked: true,
    ticks: {
      beginAtZero: true,
      maxRotation: 0,
      minRotation: 0,
    },
  },
  y: {
    stacked: true,
    grid: {
      display: false,
    },
  },
};

export default function ReportingChart({ state }) {
  return (
    <BarChart state={state} indexAxis={"x"} scales={scales} cp="0.8" bp="0.5" />
  );
}
