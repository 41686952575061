import React, { useState } from "react";
import "./VasTransaction.css";
import WalletReport from "./WalletReport";
import Transactions from "./Transactions";

const Tabs = ({ data = {}, handleTab, activeTab }) => {
  return (
    <div className="vas-dashboard">
      <nav className="vas-nav">
        <h1>Vas Transactions</h1>
        <p>
          Online Transacting Users <span>1,366</span>
        </p>
        <ul>
          {data.map((item, index) => (
            <li
              className={index === activeTab ? "nav-item active" : "nav-item"}
              key={index}
              onClick={() => handleTab(index)}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </nav>
      <div className="content">{data[activeTab].content}</div>
    </div>
  );
};

export default function VasTransaction() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTab = (index) => {
    setActiveTab(index);
  };

  const transactions = <Transactions />;
  const wallet = <WalletReport />;

  return (
    <div className="vas-trans">
      <main className="col vas-container">
        <div className="vas-dashboard">
          <Tabs
            handleTab={handleTab}
            activeTab={activeTab}
            data={[
              {
                label: "Transactions",
                content: transactions,
              },
              {
                label: "Wallet Report",
                content: wallet,
              },
            ]}
          />
        </div>
      </main>
    </div>
  );
}
