import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import DateRange from "../../../components/DateRange";
import "./WalletReport.css";
import Table from "../../../components/Table";
import AppData from "../../../config/appData.json";
import SearchInput from "../../../components/SearchInput";
import HandlePostApi from "../../../api/HandlePostApi";
import HalfDoughnut from "../../../components/charts/HalfDoughnut";
import Pagination from "../../../components/Pagination";
import SimpleLoader from "../../../components/spinner/SimpleLoader";

const state = {
  labels: ["January", "February"],
  datasets: [
    {
      backgroundColor: ["#91B7FF", "#2300E5"],
      hoverBackgroundColor: ["#91B7FF", "#2300E5"],
      borderColor: "transparent",
      cutout: "60%",
      data: [40, 59],
      circumference: 180,
      rotation: 270,
    },
  ],
};

export default function WalletReport() {
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(true);
  const [summary, setSummary] = useState([]);
  const [transactionHistory, setTransactionHistory] = useState({});
  const [selectedVal, setSelectedValue] = useState("Select");
  const [searchValue, setSearchValue] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [msg, setMsg] = React.useState("");

  const limit = 20;
  const [pageNum, setPageNum] = useState(1);
  const { docs, hasNextPage, hasPrevPage, nextPage, prevPage, totalPages } =
    transactionHistory;

  const requestData = {
    page: pageNum,
    startDate: startDate,
    endDate: endDate,
    status: "",
    walletId: "",
    reference: "",
    product: "",
    account: "",
    channel: "",
    provider: "",
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S/N",
        id: "row",
        Cell: (row) => {
          const PageStart = (pageNum - 1) * limit + 1;
          return <div>{row.row.index + PageStart}</div>;
        },
      },
      {
        Header: "Wallet Id",
        accessor: "walletId",
      },
      // {
      //   Header: "Terminal Id",
      //   accessor: "",
      // },
      {
        Header: "Amount",
        accessor: (amount) => (
          <>
            {" "}
            N{amount.amount.toLocaleString()}
            <div
              style={{
                color: amount.type === "credit" ? "#106745" : "#F20000",
                textTransform: "capitalize",
              }}
            >
              {amount.type}
            </div>
          </>
        ),
      },
      {
        Header: "Prev Balance",
        accessor: (prevBal) => <>N{prevBal.previousBalance.toLocaleString()}</>,
      },
      {
        Header: "New Balance",
        accessor: (currentBalamce) => (
          <div style={{ color: "#804D08" }}>
            N{currentBalamce.newBalance.toLocaleString()}
          </div>
        ),
      },
      {
        Header: "Description",
        accessor: (desc) => (
          <div style={{ width: "200px" }}>{desc.description}</div>
        ),
      },
      {
        Header: "Reference",
        accessor: "reference",
      },
      {
        Header: "Date & time",
        accessor: (date) => {
          let dDate = date.createdAt;
          const formatDate = new Date(dDate);
          return <div> {formatDate.toLocaleString()}</div>;
        },
      },
    ],
    [pageNum]
  );

  const fetchData = async () => {
    try {
      const url = AppData.WALLET_URL + "admin/wallet-history";

      const response = await HandlePostApi(url, requestData);
      setTransactionHistory(response.history);
      setSummary(response.summary);
      setLoadingData(false);
    } catch (error) {
      console.log("error", error);
      if (
        error.response.data.message === "Expired Session, Please Login Again"
      ) {
        navigate("/signin");
      } else {
        setMsg(error.response.data.message);
      }
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();
    fetchData();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum]);

  return (
    <div className="vas-wallet-report">
      <div className="row mt-3 ms-0">
        <div className="col">
          <div className="wallet-summary">
            <div className="row  mb-3">
              <div className="col">
                <div className="row wallet-trans">
                  <div className="col ps-0 pe-0">
                    <div className="total-trans-amount">
                      <p className="total-label">Total Amount</p>
                      {summary.total ? (
                        <p className="total-value">
                          ₦ <span>{summary.total.toLocaleString()}</span>
                        </p>
                      ) : (
                        <SimpleLoader />
                      )}
                      <p className="count-label">Count</p>
                      <p className="count-value">
                        {summary.totalCount &&
                          summary.totalCount.toLocaleString()}
                      </p>
                    </div>
                  </div>
                  <div className="col pe-0">
                    <div className="total-credit">
                      <p className="total-label">Total Credit</p>
                      {summary.creditAmount ? (
                        <p className="total-value">
                          ₦ <span>{summary.creditAmount.toLocaleString()}</span>
                        </p>
                      ) : (
                        <SimpleLoader />
                      )}
                      <p className="count-label">Count</p>
                      <p className="count-value">
                        {summary?.creditCount?.toLocaleString()}
                      </p>
                    </div>
                  </div>
                  <div className="col pe-0">
                    <div className="total-debit">
                      <p className="total-label">Total Debit</p>
                      {summary.debitAmount ? (
                        <p className="total-value">
                          ₦ <span>{summary.debitAmount.toLocaleString()}</span>
                        </p>
                      ) : (
                        <SimpleLoader />
                      )}
                      <p className="count-label">Count</p>
                      <p className="count-value">
                        {summary?.debitCount?.toLocaleString()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="user-summary">
                  <HalfDoughnut state={state} />
                  <div></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="channel">
                  <CustomSelect
                    className="custom-select mb-3"
                    heading="Transaction Type"
                    selectedVal={selectedVal}
                    setSelectedValue={setSelectedValue}
                    items={[
                      {
                        name: "Credit",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="col-4">
                <SearchInput value={searchValue} setValue={setSearchValue} />
              </div>
              <div className="col">
                <div className="d-flex">
                  <div className="date-filter">
                    <p>Filter by Date: </p>
                    <DateRange
                      startDate={startDate}
                      endDate={endDate}
                      handleStartDate={(date) => setStartDate(date)}
                      handleEndDate={(date) => setEndDate(date)}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn filter"
                    onClick={handleFilter}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="card p-2">
            <div className="wallet-funding credit">
              <h3>Wallet funding (CR)</h3>
              <p className="funding-label">Total Amount Withdraw</p>
              {summary.creditAmount ? (
                <p className="funding-amount">
                  ₦ <span>{summary.creditAmount.toLocaleString()}</span>
                </p>
              ) : (
                <SimpleLoader />
              )}
            </div>
            <div className="wallet-funding debit">
              <h3>Wallet funding (DR)</h3>
              <p className="funding-label">Total Amount Withdraw</p>
              {summary.debitAmount ? (
                <p className="funding-amount">
                  ₦ <span>{summary.debitAmount.toLocaleString()}</span>
                </p>
              ) : (
                <SimpleLoader />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="vas-table mb-3">
        {loadingData ? (
          <SimpleLoader />
        ) : (
          <Table
            columns={columns}
            data={docs}
            tableClass="table table-borderless table-striped table-hover fs-7 mb-0"
            enableRowSelection={false}
          />
        )}
        {totalPages > 1 && (
          <Pagination
            prevPage={prevPage}
            nextPage={nextPage}
            totalPages={totalPages}
            hasNextPage={hasNextPage}
            hasPrevPage={hasPrevPage}
            setPageNum={setPageNum}
          />
        )}
      </div>
    </div>
  );
}
