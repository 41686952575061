import React, { useState, useEffect } from "react";
import Table from "../../../components/Table";
import GetData from "../../../api/GetData";
import AppData from "../../../config/appData.json";
import Pagination from "../../../components/Pagination";
import Modal from "../../../components/modal/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";

export default function BandConfig() {
  const [bandList, setBandList] = useState([]);
  const [status, setStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [statusVol, setStatusVol] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [pageNumber, setPageNumber] = useState(1);
  const postPerPage = 50;
  const lastPageNum = Math.ceil(statusVol / postPerPage);
  const pageStart = (pageNumber - 1) * postPerPage + 1;

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  const handleCreateBand = async () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S/N",
        Cell: (row) => {
          return <div>{row.row.index + pageStart}</div>;
        },
      },
      {
        Header: "Band Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Amount Daily",
        accessor: (amount) => {
          return <>{amount.amount_daily.toLocaleString()}</>;
        },
      },
      {
        Header: "Amount Cummulative Monthly",
        accessor: (amount) => {
          return <>{amount.amount_cumulative.toLocaleString()}</>;
        },
      },
      {
        Header: "Chargeback",
        accessor: "",
      },
      {
        Header: "Action",
        accessor: "",
      },
    ],
    [pageNumber]
  );

  const fetchHistoryData = async () => {
    try {
      const endpoint = AppData.PAYMENT_URL + `band`;
      const response = await GetData(endpoint);
      // console.log("response", response);
      setBandList(response);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const fetchHistoryStatus = async () => {
    try {
      const endpoint =
        AppData.PAYMENT_URL +
        `transactions/stats?startdate=${formattedStartDate}&&enddate=${formattedEndDate}`;
      const response = await GetData(endpoint);
      setStatus(response.data);
      setStatusVol(response.data.total_volume);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  function formatDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  const handleFilter = (e) => {
    e.preventDefault();
    fetchHistoryData();
    fetchHistoryStatus();
  };

  useEffect(() => {
    fetchHistoryData();
  }, [pageNumber]);

  useEffect(() => {
    fetchHistoryStatus();
  }, []);

  return (
    <div className="band-config">
      <div className="add-config">
        <button className="add-config-btn" onClick={handleCreateBand}>
          Add Band
        </button>
      </div>
      <div className="table-responsive">
        {bandList.data && bandList.data.length > 0 ? (
          <Table
            columns={columns}
            data={bandList.data}
            enableRowSelection={false}
            tableClass={"table table-borderless table-striped mb-0"}
          />
        ) : (
          <p>No record found</p>
        )}
        {lastPageNum > 1 && (
          <Pagination
            prevPage={pageNumber - 1}
            nextPage={pageNumber + 1}
            totalPages={lastPageNum}
            hasNextPage={pageNumber < lastPageNum}
            hasPrevPage={pageNumber > 1}
            setPageNum={setPageNumber}
          />
        )}
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          content={<CreateBand />}
        />
      )}
    </div>
  );
}

const CreateBand = () => {
  const schema = yup.object().shape({
    name: yup.string().required("Band Name is required"),
    desc: yup.string().required("Band Description is required"),
    amountDaily: yup
      .number()
      .typeError("Amount Daily must be a number")
      .required("Amount Daily is required"),
    amountCumulative: yup
      .number()
      .typeError("Amount Cumulative must be a number")
      .required("Amount Cumulative is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const endpoint = AppData.PAYMENT_URL + "band/create";
      const response = await axios.post(endpoint, data);
      console.log(response); // Handle the response as needed
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <form className="risk-profile-modal" onSubmit={handleSubmit(onSubmit)}>
      <h4>Create Band</h4>

      <div className="grouped-input mb-3">
        <label className="col-12" htmlFor="name">
          Band Name:
        </label>
        <input
          className="form-control"
          type="text"
          id="name"
          {...register("name")}
        />
        <small>{errors.name?.message}</small>
      </div>

      <div className="grouped-input mb-3">
        <label className="col-12" htmlFor="desc">
          Band Description:
        </label>
        <input
          className="form-control"
          type="text"
          id="desc"
          {...register("desc")}
        />
        <small>{errors.desc?.message}</small>
      </div>

      <div className="grouped-input mb-3">
        <label className="col-12" htmlFor="amountDaily">
          Amount Daily:
        </label>
        <input
          className="form-control"
          type="text"
          id="amountDaily"
          {...register("amountDaily")}
        />
        <small>{errors.amountDaily?.message}</small>
      </div>

      <div className="grouped-input mb-3">
        <label className="col-12" htmlFor="amountCumulative">
          Amount Cumulative:
        </label>
        <input
          className="form-control"
          type="text"
          id="amountCumulative"
          {...register("amountCumulative")}
        />
        <small>{errors.amountCumulative?.message}</small>
      </div>

      <button type="submit" className="update-btn">
        Create
      </button>
    </form>
  );
};
