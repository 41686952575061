import React from "react";
import "./Faq.css";
import faqImage from "../../assets/images/Faq.png";
import dashed from "../../assets/images/dashed.png";

export default function Faq() {
  return (
    <div className="faq">
      <div className="row">
        <div className="col-5">
          <div className="faq-image">
            <img src={faqImage} alt="faq" />
          </div>
          <img src={dashed} alt="faq" />
          <h1 className="faq-desc-heading">
            Got Questions?
            <br /> We Have Answers
            <br /> For You
          </h1>
          <p className="faq-desc-text">
            Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam
            Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam
          </p>
        </div>
        <div className="col">
          <section id="accordion">
            <div className="faq--body">
              <input type="radio" name="accordion" id="one" />
              <div className="content">
                <label htmlFor="one" className="question">
                  How do i become an Agent ?
                  <i className="fa-solid fa-angle-down"></i>
                </label>
                <div className="answer">
                  <p className="answer--text">
                    Download the Spout pay agent Mobile app from Google play
                    store or Register on the Spout Pay weblink to have an agent
                    wallet created <a href="http://139.162.203.47:5000/agent-details">http://139.162.203.47:5000/agent-details.</a>
                  </p>
                </div>
              </div>
            </div>

            <div className="faq--body">
              <input type="radio" name="accordion" id="two" />
              <div className="content">
                <label htmlFor="two" className="question">
                  How long does it take to become an Agent?
                  <i className="fa-solid fa-angle-down"></i>
                </label>
                <div className="answer">
                  <p className="answer--text">Less than 5 mins to be an Agent.</p>
                </div>
              </div>
            </div>
            <div className="faq--body">
              <input type="radio" name="accordion" id="three" />
              <div className="content">
                <label htmlFor="three" className="question">
                  What do i need to become an Agent ?
                  <i className="fa-solid fa-angle-down"></i>
                </label>
                <div className="answer">
                  <p className="answer--text">
                    1. A functional phone no and email address <br />
                    2. Your Bank Verification Number (BVN).
                    <br />
                    3. A smart device that can connect to the internet (either a
                    smart phone or a computer )<br /> 4. A valid form of
                    Identification (Nin slip,PVC,Int passport or drivers
                    license.
                    <br /> 4. Proof of Address /Utility Bill(Nepa Bill, Waste
                    bill/Lawma or Bank statement of account.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
