import React, { useState, useEffect } from "react";
import GetData from "../../../api/GetData";
import Table from "../../../components/Table";
import AppData from "../../../config/appData.json";
import Images from "../../../assets/images/Images";
import RaiseChargeModal from "./RaiseChargeModal";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import Modal from "../../../components/modal/Modal";

export default function ChargeProfile({ userId, setOpenCharge }) {
  const navigate = useNavigate();
  const [transHistory, setTransHistory] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [pageNum, setPageNum] = useState(1);

  const columns = React.useMemo(
    () => [
      {
        Header: "S/N",
        id: "row",
        Cell: (row) => {
          const PageStart = (1 - 1) * 50 + 1;
          return <div>{row.row.index + PageStart}</div>;
        },
      },
      {
        Header: "Product",
        accessor: (product) => <div>{product.product}</div>,
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Action",
        accessor: "action",
      },
      {
        Header: "Total Commission",
        accessor: "totalCommission",
      },
      {
        Header: "User Commission",
        accessor: "userCommission",
      },
      {
        Header: "Aggregator Commission",
        accessor: "aggregatorCommission",
      },
      {
        Header: "System Commission",
        accessor: "systemCommission",
      },
      {
        Header: "Date & Time",
        accessor: (date) => {
          let dDate = date.createdAt;
          const formatDate = new Date(dDate);
          return <div> {formatDate.toLocaleString()}</div>;
        },
      },
    ],
    []
  );

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const handleErrorFunc = (error) => {
    if (error.response.data.message === "Expired Session, Please Login Again") {
      navigate("/signin");
    } else {
      setMsg(error.response.data.message);
    }
  };

  const fetchChargeHistory = async () => {
    try {
      const url =
        AppData.VAS_URL +
        `admin/profile/get-charges?profileId=${userId}&limit=25&page=1`;
      const response = await GetData(url);
      // console.log("res", response);
      setTransHistory(response.charges);
    } catch (error) {
      handleErrorFunc(error);
    }
  };

  const handlePageRefresh = () => {
    fetchChargeHistory();
  };

  useEffect(() => {
    fetchChargeHistory();
  }, []);

  const { docs, hasNextPage, hasPrevPage, nextPage, prevPage, totalPages } =
    transHistory;

  return (
    <div className="raise-charge">
      <button className="return-btn mt-3" onClick={() => setOpenCharge(false)}>
        <i className="fa-solid fa-arrow-left"></i>Back
      </button>
      <div className="d-flex mt-3">
        <div className="me-auto">
          <button className="btn" onClick={openModal}>
            Create Charge profile
          </button>
        </div>
        <div className="">
          <button className="btn refresh" onClick={handlePageRefresh}>
            <i className="fa-solid fa-arrows-rotate"></i>
            Refresh
          </button>
          {docs && (
            <CSVLink
              data={docs}
              filename="Charge history"
              className="downloads"
            >
              <img src={Images.download} alt="" />
            </CSVLink>
          )}
        </div>
      </div>
      <div className="vas-table table-responsive mb-4">
        {docs && (
          <Table
            columns={columns}
            data={docs}
            tableClass="table table-borderless table-striped table-hover fs-7 mb-0"
            enableRowSelection={false}
          />
        )}
        {totalPages > 1 && (
          <Pagination
            prevPage={prevPage}
            nextPage={nextPage}
            totalPages={totalPages}
            hasNextPage={hasNextPage}
            hasPrevPage={hasPrevPage}
            setPageNum={setPageNum}
          />
        )}
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          content={<RaiseChargeModal setShowModal={setIsModalOpen} />}
        />
      )}
    </div>
  );
}
