import React from "react";
import { Doughnut } from "react-chartjs-2";


export default function HalfDoughnut({state}) {
  return (
    <div className="chart-doughnut">
      <Doughnut
        data={state}
        options={{
          title: {
            display: true,
            text: "Average Rainfall per month",
            fontSize: 20,
          },
          plugins: {
            legend: {
                display: false,
            }
          },
          legend: {
            display: true,
            position: "right",
          },
        }}
      />
    </div>
  );
}
