import axios from "axios";

export default function HandlePostPayment(requestData, setIsLoading, url) {
  const auth =
    // "a44b0faaff867ead5fa27a05b43e4589517e62da5d32c3d210a119e5b6da9ed2";
    "9919996535c614a6af6c01546e994cc5589a98a3e1e70c3901e96d2281a12e08";
  setIsLoading(true);
  return new Promise((resolve, reject) => {
    axios
      .post(url, requestData, {
        headers: {
          Authorization: auth,
          token: localStorage.getItem("token"),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        resolve(response);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
        setIsLoading(false);
      });
  });
}
