import React, { useEffect, useState } from "react";
import "./AllTargets.css";
import Table from "../../../components/Table";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import AppData from "../../../config/appData.json";
import GetData from "../../../api/GetData";
import AgentDetailComponent from "./AgentDetailComponent";

const StatusCell = ({ value }) => {
  const percentValue = parseInt(value);
  let circleColor;

  if (percentValue >= 70) {
    circleColor = "#179800";
  } else if (percentValue >= 50) {
    circleColor = "#8D4511";
  } else {
    circleColor = "#FF0027";
  }

  return (
    <div className="percentage-cell">
      <div className="status-circle" style={{ backgroundColor: circleColor }} />
      {value}
    </div>
  );
};

const AmountCell = ({ value }) => (
  <div className="amount-cell">
    <span style={{ color: "#905E0E" }}>{value}</span>
  </div>
);

export default function AllTargets() {
  const [selectedState, setSelectedState] = useState("");
  const [targets, setTargets] = useState([]);
  const [agentDetailVisible, setAgentDetailVisible] = useState(false);
  const [targetSummary, setTargetSummary] = useState({});

  const columns = [
    {
      Header: "Agent Name",
      accessor: "name",
      Cell: ({ value, row }) => (
        <a
          className="agent-name"
          href="#"
          onClick={() => handleAgentNameClick(row.original.id)}
        >
          {value}
        </a>
      ),
    },
    { Header: "Wallet ID", accessor: "walletId" },
    {
      Header: "Status by %",
      accessor: (percent) => <>{percent.percentage.toLocaleString()}</>,
      Cell: StatusCell,
    },
    {
      Header: "Target Met of (Budget)",
      accessor: "totalAmt",
      Cell: AmountCell,
    },
    // { Header: "Date and Time", accessor: "dateTime" },
  ];

  const getData = async () => {
    try {
      const endpoint = AppData.VAS_URL + "admin/target-users";
      const response = await GetData(endpoint);
      setTargets(response.data);
      // console.log("res", response.data);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const getTargetSummary = async () => {
    try {
      const endpoint = AppData.VAS_URL + "admin/target-summary";
      const response = await GetData(endpoint);
      setTargetSummary(response.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getTargetSummary();
  }, []);

  const { Engagement, accomplished, goal, onGoingAmount, revenue } =
    targetSummary;

  const handleAgentNameClick = (agentId) => {
    // console.log("Agent Name clicked:", agentId);
    setAgentDetailVisible(true);
  };

  return (
    <div className="col targets">
      {agentDetailVisible ? (
        // Render the AgentDetailComponent when agentDetailVisible is true
        <AgentDetailComponent />
      ) : (
        <div>
          <div className="all-target-header">
            <div>
              <CustomSelect
                className="custom-select"
                selectedVal={selectedState ? selectedState : "Value"}
                setSelectedValue={setSelectedState}
                // items={states}
                defaultOption={{ label: "Value", value: "" }}
              />
              <h6>On going target</h6>
              <p className="mb-0">
                <span>{onGoingAmount?.toLocaleString()}</span> of{" "}
                {revenue?.toLocaleString()}
              </p>
            </div>
            <div className="ms-auto text-end mt-auto">
              <p>
                Click Individual Records To See <br /> Score Board
              </p>
            </div>
          </div>
          <div className="all-target-filters mt-3 mb-3"></div>
          <div>
            {targets.length > 0 ? (
              <Table
                columns={columns}
                data={targets}
                tableClass="table table-striped table-hover fs-7 mb-0"
                enableRowSelection={false}
              />
            ) : (
              <p>No targets available</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
