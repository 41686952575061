// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settlement-dashboard {
  margin-top: 60px;
}

.settlement-dashboard .tab-view .content {
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #dee2e6;
}

.merchant-view .dashboard-main {
  /* margin-left: 14%; */
  margin-top: 30px;
}

.settlement-dashboard .date-filter {
  display: flex;
  align-items: center;
}

.settlement-dashboard .date-filter p {
  margin-bottom: 0;
  margin-right: 5px;
  font-size: 10px;
}

.settlement-dashboard .downloads {
  margin-left: 55px;
  border: none;
  padding: 0;
}

.settlement-dashboard .downloads img {
  height: 46px;
}

.merchant-view .history-param .search-input {
  width: 270px;
  margin-right: 40px;
}

.settlement-dashboard .custom-select {
  width: 120px;
}
`, "",{"version":3,"sources":["webpack://./src/view/merchant-view/settlement/Settlement.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".settlement-dashboard {\n  margin-top: 60px;\n}\n\n.settlement-dashboard .tab-view .content {\n  background-color: #ffffff;\n  padding: 20px;\n  border: 1px solid #dee2e6;\n}\n\n.merchant-view .dashboard-main {\n  /* margin-left: 14%; */\n  margin-top: 30px;\n}\n\n.settlement-dashboard .date-filter {\n  display: flex;\n  align-items: center;\n}\n\n.settlement-dashboard .date-filter p {\n  margin-bottom: 0;\n  margin-right: 5px;\n  font-size: 10px;\n}\n\n.settlement-dashboard .downloads {\n  margin-left: 55px;\n  border: none;\n  padding: 0;\n}\n\n.settlement-dashboard .downloads img {\n  height: 46px;\n}\n\n.merchant-view .history-param .search-input {\n  width: 270px;\n  margin-right: 40px;\n}\n\n.settlement-dashboard .custom-select {\n  width: 120px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
