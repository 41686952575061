import React, { useState, useEffect } from "react";
import ReconciliationBetaSummary from "./ReconciliationBetaSummary";
import ReconciliationSummary from "./ReconciliationSummary";
import ReconciliationHistory from "./ReconciliationHistory";
import TabView from "../../../../../components/TabView";
import GetData from "../../../../../api/GetData";
import AppData from "../../../../../config/appData.json";

export default function Reconciliation() {
  const [transactionData, setTransactionData] = useState([]);

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const endpoint = AppData.BASE_URL + "reconciliation";
        const response = await GetData(endpoint);
        // console.log(response);
        setTransactionData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchTransactionData();
  }, []);

  const history = <ReconciliationHistory data={transactionData} />;
  const summary = <ReconciliationSummary />;
  const betaSummary = <ReconciliationBetaSummary />;

  return (
    <div>
      <TabView
        tabs={[
          { name: "History", content: history },
          { name: "Summary", content: summary },
          { name: "Beta Summary", content: betaSummary },
        ]}
      />
    </div>
  );
}
