import React, { useState } from "react";

export default function InviteLink({ link, loading }) {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipText, setTooltipText] = useState("Copy");

  const handleCopyClick = () => {
    const textToCopy = link;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => setTooltipText("Copied!"))
      .catch((error) => console.error("Copy failed:", error));

    setTooltipVisible(true);
    setTimeout(() => {
      setTooltipVisible(false);
      setTooltipText("Copy");
    }, 2000);
  };

  return (
    <div className="copy-invite">
      {loading ? <div>Loading...</div> : <div className="link">{link}</div>}

      <button className="invite-btn" type="button" onClick={handleCopyClick}>
        <i className="fas fa-copy"></i>
      </button>

      {tooltipVisible && <div className="tooltip">{tooltipText}</div>}
    </div>
  );
}
