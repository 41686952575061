import React, { useState } from "react";
import CustomSelect from "../../../../components/customSelect/CustomSelect";
import NaijaStates from "naija-state-local-government";
import Table from "../../../../components/Table";

export default function LocationForm({
  locations,
  setLocations,
  totalTerminals,
}) {
  const [message, setMessage] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedLga, setSelectedLga] = useState("");
  const states = NaijaStates.states();
  const lgas = selectedState ? NaijaStates.lgas(selectedState).lgas : [];
  const [formData, setFormData] = useState({
    location: "",
    count: "",
    statecode: selectedState,
    lga_lcda: selectedLga,
  });

  // console.log("state", NaijaStates);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddLocation = () => {
    // Check if any of the input fields are empty
    if (
      formData.location.trim() === "" ||
      formData.count.trim() === "" ||
      selectedState.trim() === "" ||
      selectedLga.trim() === ""
    ) {
      // Handle the case where any of the fields are yempty (e.g., show error message)
      return;
    }
    // Check if adding a location exceeds the total number of terminals
    const totalTerminalsUsed = locations.reduce(
      (total, location) => total + parseInt(location.count || 0),
      0
    );

    const newLocationTotalTerminals = parseInt(formData.count || 0);

    if (
      totalTerminalsUsed + newLocationTotalTerminals >
      parseInt(totalTerminals)
    ) {
      // Prevent adding the location if it exceeds the total terminals
      // You can show an error message or handle this scenario accordingly
      setMessage(
        "Number of terminals exceeded, check number of terminals specified above"
      );
      return;
    }

    // Proceed with adding the location if it doesn't exceed the total terminals
    const newLocation = {
      ...formData,
      statecode: selectedState,
      lga_lcda: selectedLga,
    };
    setLocations([...locations, newLocation]);
    setFormData({
      location: "",
      count: "",
      statecode: selectedState,
      lga_lcda: selectedLga,
    });

    setSelectedLga("");
    setSelectedState("");
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Terminal Location",
        accessor: "location",
      },
      {
        Header: "Number of POS",
        accessor: "count",
      },
      {
        Header: "State",
        accessor: "statecode",
      },
      {
        Header: "LGA",
        accessor: "lga_lcda",
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => {
          return (
            <button
              type="button"
              className="btn-delete"
              onClick={() => handleDeleteLocation(row.index)}
            >
              <i className="fa-solid fa-trash-can"></i>
            </button>
          );
        },
      },
    ],
    []
  );

  const handleDeleteLocation = (id) => {
    setLocations((prevLocations) => {
      const updatedLocations = prevLocations.filter(
        (location, index) => index !== id
      );

      return updatedLocations;
    });
  };

  return (
    <div className="mt-4">
      <div>
        <small className="term-no-error">{message && message}</small>
        <div className="mb-3 mt-2 row">
          <label htmlFor="count" className="col-sm-3 col-form-label">
            Terminal Location
          </label>
          <div className="col-sm-4">
            <input
              type="text"
              className="form-control"
              placeholder="Terminal Location"
              name="location"
              value={formData.location}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-sm-4">
            <input
              type="number"
              className="form-control"
              placeholder="0"
              name="count"
              value={formData.count}
              onChange={handleInputChange}
              min="0"
            />
          </div>
        </div>
        <div className="mb-3 mt-2 row">
          <label htmlFor="state" className="col-sm-3 col-form-label">
            State/LGA
          </label>
          <div className="col-sm-3">
            <CustomSelect
              selectedVal={selectedState || "Select State"}
              setSelectedValue={setSelectedState}
              items={states.map((state) => ({ name: state }))}
              defaultOption={{ label: "Select State", value: "" }}
            />
          </div>
          <div className="col-sm-3">
            <CustomSelect
              selectedVal={selectedLga || "Select LGA"}
              setSelectedValue={setSelectedLga}
              items={lgas.map((lga) => ({ name: lga }))}
              defaultOption={{ label: "Select LGA", value: "" }}
            />
          </div>
          <div className="col-2 pe-0">
            <button
              type="button"
              className="btn w-100"
              onClick={handleAddLocation}
            >
              Add
            </button>
          </div>
        </div>
      </div>
      {locations.length > 0 && (
        <div className="mt-4">
          <Table
            columns={columns}
            data={locations}
            enableRowSelection={false}
            tableClass={"table table-borderless mb-0"}
          />
        </div>
      )}
    </div>
  );
}
