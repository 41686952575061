import React, { useState, useEffect } from "react";
import "./Header.css";
import spout from "../../assets/images/spout-logo.png";
import InitialsAvatar from "react-initials-avatar";

export default function Header({ userData, heading }) {
  const [time, setTime] = useState(new Date());

  const admin = userData.admin;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 60);
    return () => {
      clearInterval(intervalId);
    };
  });

  return (
    <header className="navbar">
      <div className="navbar-items">
        <a className="navbar-logo" href="#">
          <img src={spout} alt="spout" />
        </a>
        <div className="navbar-heading">
          <h1>{heading}</h1>
          <p>{`Today is ${time}`}</p>
        </div>
      </div>
      <div className="user">
        <div className="notification">
          <i className="fa-regular fa-bell"></i>
        </div>
        <div className="user-pill">
          <InitialsAvatar
            name={`${admin ? admin.firstName : userData.firstName} ${" "} ${
              admin ? admin.lastName : userData.lastName
            }`}
          />
          <div className="details">
            <h2>
              {admin ? admin.firstName : userData.firstName}{" "}
              {admin ? admin.lastName : userData.lastName}
            </h2>
            <p>{admin ? admin.email : userData.email}</p>
          </div>
        </div>
      </div>
    </header>
  );
}
