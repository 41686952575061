import React, { useState } from "react";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import HandlePostApi from "../../../api/HandlePostApi";
import AppData from "../../../config/appData.json";

export default function AddAdmin({ setShowAdminModal }) {
  const url = AppData.VAS_URL + "admin/create-admin-user";
  const [gender, setGender] = useState("Male");
  const [selectedVal, setSelectedValue] = useState("Super Admin");
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("");

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const schema = yup.object().shape({
    email: yup.string().email().required("Invalid Email Address!"),
    firstName: yup.string().required("Your First Name is Required!"),
    lastName: yup.string().required("Your Last Name is Required!"),
    address: yup.string().required("Field is required"),
    username: yup.string().required("Username is Required!"),
    companyName: yup.string().required("Field is required"),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid!")
      .required(),
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters!")
      .required("Password field Cannot be empty!"),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords Don't Match")
      .required("Passwords Don't Match!"),
    adminType: yup.string().required("Field is required"),
    stateOfOrigin: yup.string().required("Field is required"),
    localGovernment: yup.string().required("Field is required"),
    gender: yup.string().required("Field is required"),
    dateOfBirth: yup.string().required("Field is required"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleCloseModal = () => {
    setShowAdminModal(false);
  };

  const onSubmit = (data) => {
    HandlePostApi(url, data, setErr).then((result) => {
      const { errors, message } = result;
      if (errors) {
        setErr(true);
        setMsg(message[0]);
        return;
      }
      setShowAdminModal(false);
    });
  };

  return (
    <div className="modal add-admin-modal">
      <div className="modal-content mt-3 w-50">
        <div className="modal-header">
          <h4>Add a Member</h4>
          <p>Add a Member With Their Credentials</p>
        </div>
        <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12 mb-3 text-start">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                className={errors.email ? "form-control error" : "form-control"}
                type="text"
                placeholder="example@spoutpay.com"
                {...register("email")}
              />
              <div>
                <small>{errors.email?.message}</small>
              </div>
            </div>
            <div className="col-6 mb-3 text-start">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <input
                className={
                  errors.firstName ? "form-control error" : "form-control"
                }
                type="text"
                placeholder="Enter first name"
                {...register("firstName")}
              />
              <div>
                <small>{errors.firstName?.message}</small>
              </div>
            </div>
            <div className="col-6 mb-3 text-start">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input
                className={
                  errors.lastName ? "form-control error" : "form-control"
                }
                type="text"
                placeholder="Enter Last Name"
                {...register("lastName")}
              />
              <div>
                <small>{errors.lastName?.message}</small>
              </div>
            </div>
            <div className="col-3 mb-3 text-start">
              <label htmlFor="gender" className="form-label">
                Gender
              </label>
              <select
                className="form-control"
                defaultValue={"DEFAULT"}
                {...register("gender")}
                onChange={(e) =>
                  setValue("gender", e.target.value, {
                    shouldValidate: true,
                  })
                }
              >
                <option value="DEFAULT" disabled>
                  Select Gender
                </option>
                <option value="male">male</option>
                <option value="female">female</option>
              </select>
            </div>
            <div className="col-4 mb-3 text-start">
              <label htmlFor="dateOfBirth" className="form-label">
                Date of Birth
              </label>
              <input
                type="date"
                className="form-control"
                placeholder="MM-DD-YY"
                {...register("dateOfBirth")}
              />
              <small>{errors.dateOfBirth?.message}</small>
            </div>
            <div className="col-5 mb-3 text-start">
              <label htmlFor="phoneNumber" className="form-label">
                Phone Number
              </label>
              <input
                type="text"
                className={
                  errors.phoneNumber ? "form-control error" : "form-control"
                }
                placeholder="09023456789"
                {...register("phoneNumber")}
              />
              <small>{errors.phoneNumber?.message}</small>
            </div>
            <div className="col-4 mb-3 text-start">
              <label htmlFor="username" className="form-label">
                Username
              </label>
              <input
                className={
                  errors.username ? "form-control error" : "form-control"
                }
                type="text"
                placeholder="Username"
                {...register("username")}
              />
              <div>
                <small>{errors.username?.message}</small>
              </div>
            </div>
            <div className="col-4 mb-3 text-start">
              <label htmlFor="adminType" className="form-label">
                Admin Type
              </label>
              <select
                className="form-control"
                defaultValue={"DEFAULT"}
                {...register("adminType")}
                onChange={(e) =>
                  setValue("adminType", e.target.value, {
                    shouldValidate: true,
                  })
                }
              >
                <option value="DEFAULT" disabled>
                  Select
                </option>
                <option value="admin">Admin</option>
                <option value="super-admin">Super Admin</option>
                <option value="financial-admin">Financial Admin</option>
              </select>
            </div>
            <div className="col-4 mb-3 text-start">
              <label htmlFor="companyName" className="form-label">
                Company Name
              </label>
              <input
                className={
                  errors.companyName ? "form-control error" : "form-control"
                }
                type="text"
                placeholder="Company Name"
                {...register("companyName")}
              />
            </div>
            <div className="col-12 mb-3 text-start">
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <input
                type="text"
                className={
                  errors.address ? "form-control error" : "form-control"
                }
                placeholder="Address"
                {...register("address")}
              />
              <div>
                <small>{errors.address?.message}</small>
              </div>
            </div>
            <div className="col-6 mb-3 text-start">
              <label htmlFor="stateOfOrigin" className="form-label">
                State of Origin
              </label>
              <input
                className={
                  errors.stateOfOrigin ? "form-control error" : "form-control"
                }
                type="text"
                placeholder="State of Origin"
                {...register("stateOfOrigin")}
              />
            </div>
            <div className="col-6 mb-3 text-start">
              <label htmlFor="localGovernment" className="form-label">
                LGA
              </label>
              <input
                className={
                  errors.localGovernment ? "form-control error" : "form-control"
                }
                type="text"
                placeholder="Enter LGA"
                {...register("localGovernment")}
              />
            </div>
            <div className="col-6 mb-3 text-start">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                className={
                  errors.password ? "form-control error" : "form-control"
                }
                type="text"
                placeholder="•••••••••••••"
                {...register("password")}
              />
              <div>
                <small>{errors.password?.message}</small>
              </div>
            </div>
            <div className="col-6 mb-3 text-start">
              <label htmlFor="password_confirmation" className="form-label">
                Confirm Password
              </label>
              <input
                className={
                  errors.password_confirmation
                    ? "form-control error"
                    : "form-control"
                }
                type="text"
                placeholder="•••••••••••••"
                {...register("password_confirmation")}
              />
              <div>
                <small>{errors.password_confirmation?.message}</small>
              </div>
            </div>
          </div>
          <div className="action">
            <div className="d-flex">
              <button
                type="button"
                className="cancel-btn"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button type="submit" className="ms-auto add-btn">
                Add Member
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
