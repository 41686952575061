import React, { useEffect, useState } from "react";
import "./AddProviders.css";
import CustomSelect from "../../components/customSelect/CustomSelect";
import AppData from "../../config/appData.json";
import handlePostApi from "../../api/HandlePostApi";

export default function AddProvider({ setShowModal, setMessage }) {
  const [providers, setProviders] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const addProvider = async (e) => {
    e.preventDefault();
    try {
      const endpoint = AppData.VAS_URL + "admin/changeProvider";
      const requestData = {
        service: selectedService,
        provider: selectedProvider,
      };
      const { message } = await handlePostApi(endpoint, requestData);
      handleCloseModal();
      setMessage(message);
    } catch (error) {
      console.error("Error", error);
      handleCloseModal();
    }
  };

  const getProducts = async () => {
    try {
      const endpoint = AppData.VAS_URL + "admin/get-all-products";
      const { products } = await handlePostApi(endpoint, {});
      setProducts(products);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const getProviders = async () => {
    try {
      const endpoint = AppData.VAS_URL + "admin/get-all-providers";
      const { providers } = await handlePostApi(endpoint, {});
      setProviders(providers);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    getProviders();
  }, []);

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <form className="add-provider" onSubmit={addProvider}>
      <div className="modal-header">Add Provider</div>
      <div className="row mt-3 mb-5">
        <div className="col">
          {" "}
          <CustomSelect
            heading="Select Provider"
            selectedVal={
              selectedProvider ? selectedProvider : "Select Provider"
            }
            setSelectedValue={setSelectedProvider}
            items={providers}
            defaultOption={{ label: "Select Provider", value: "" }}
          />
        </div>
        <div className="col">
          {" "}
          <CustomSelect
            heading="Select Service"
            selectedVal={selectedService ? selectedService : "Select Service"}
            setSelectedValue={setSelectedService}
            items={products}
            defaultOption={{ label: "Select Service", value: "" }}
          />
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="provider-btn btn-cancel"
          onClick={handleCloseModal}
        >
          Close
        </button>
        <button type="submit" className="provider-btn btn-save">
          Save Changes
        </button>
      </div>
    </form>
  );
}
