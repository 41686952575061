import React, { useContext } from "react";
import "./CardTransactions.css";
import TransactionDashboard from "../card-transactions/transaction-dashboard/TransactionDashboard";
import MerchantManagement from "../card-transactions/merchant-management/MerchantManagement";
import Settlements from "../card-transactions/settlement/Settlements";
import DisputeResolution from "../card-transactions/dispute-resolution/DisputeResolution";
import Configuration from "../card-transactions/configuration/Configuration";
import { UserContext } from "../../../context/UserContext";

const Tabs = ({ tabsData = [], tabHeading }) => {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };
  return (
    <>
      <nav className="card-trans-nav">
        <h1>{tabHeading}</h1>
        <ul>
          {tabsData.map((item, index) => (
            <li
              className={index === activeTab ? "nav-item active" : "nav-item"}
              key={index}
              onClick={() => handleTabChange(index)}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </nav>
      <div className="content">{tabsData[activeTab].content}</div>
    </>
  );
};

export default function CardTransactions() {
  const { userData } = useContext(UserContext);

  const isAdmin =
    userData?.admin?.adminType === "bank" ||
    userData?.adminType === "bank";
  const tabsData = [
    {
      label: "Transaction Dashboard",
      content: <TransactionDashboard />,
    },
    {
      label: "Merchant Management",
      content: <MerchantManagement />,
    },
    {
      label: "Settlement",
      content: <Settlements />,
    },
    {
      label: "Dispute Resolution",
      content: <DisputeResolution />,
    },
    {
      label: "Configuration",
      content: <Configuration />,
    },
  ];

  return (
    <main className={isAdmin ? "mt-3" : "col dashboard-main"}>
      <div className="trans-dashboard">
        <Tabs tabsData={tabsData} tabHeading="Card Transactions" />
      </div>
    </main>
  );
}
