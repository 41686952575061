import React from "react";
import "./ServicePerformance.css";
import DoughnutChart from "../../../components/charts/DoughnutChart";
import BarChart from "../../../components/charts/BarChart";

const chartState = {
  labels: ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
  datasets: [
    {
      label: "Failed - Transfer",
      borderColor: "#DE0000",
      borderWidth: 2,
      borderWidth: 2,
      barThickness: 18,
      stack: "Transfer",
      backgroundColor: "#DE0000",
      data: [5, 9, 6, 3, 0, 4, 5],
    },
    {
      label: "Success - Transfer",
      borderColor: "#DBC140",
      borderRadius: 12,
      borderWidth: 0,
      borderWidth: 2,
      barThickness: 18,
      stack: "Transfer",
      backgroundColor: "#DBC140",
      data: [15, 16, 17, 18, 19, 20, 21],
    },
    {
      label: "Others - Transfer",
      borderColor: "#00B56F",
      borderRadius: 5,
      borderWidth: 2,
      barThickness: 18,
      maxBarThickness: 18,
      stack: "Transfer",
      backgroundColor: "#00B56F",
      data: [15, 16, 17, 18, 19, 20, 21],
    },
  ],
};

const scales = {
  x: {
    stacked: true,
    ticks: {
      beginAtZero: true,
      maxRotation: 0,
      minRotation: 0,
    },
    grid: {
      display: false,
    },
  },
  y: {
    stacked: true,
  },
};

export default function ServicePerformance() {
  return (
    <section className="service-performance">
      <h4 className="section-heading">Service Performance</h4>
      <div className="row">
        <div className="col">
          <div className="doughnut-chart">
            <h6>All Service Traffic</h6>
            <DoughnutChart />
          </div>
        </div>
        <div className="col">
          <div className="service-traffic-growth">
            <BarChart state={chartState} indexAxis={"x"} scales={scales} />
          </div>
        </div>
      </div>
    </section>
  );
}
