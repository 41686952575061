import React, { useState } from "react";
import "./SearchInput.css";

export default function SearchInput({value, setValue}) {
  
  const handleChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  }
  return (
    <div className="search-input">
      <i className="fas fa-search"></i>
      <div>
        <input type="search" id="form1" className="form-control ps-5" value={value} onChange={handleChange}/>
      </div>
    </div>
  );
}
