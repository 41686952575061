import React, { useState, useEffect } from "react";
import Table from "../../../components/Table";
import { CSVLink } from "react-csv";
import "./Analysis.css";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import DateRange from "../../../components/DateRange";
import GetData from "../../../api/GetData";
import AppData from "../../../config/appData.json";
import Pagination from "../../../components/Pagination";
import FormatAmount from "../../../helpers/FormatAmount";

export default function Analysis() {
  const [performance, setPerformance] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [limit, setLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [merchantId, setMerchantId] = useState("");

  const postPerPage = 50;
  const totalVol = performance.data && performance.data.length;
  const lastPageNum = Math.ceil(totalVol / postPerPage);

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  const columns = React.useMemo(
    () => [
      {
        Header: "S/N",
        Cell: (row) => {
          return <>{row.row.index + 1}</>;
        },
      },
      {
        Header: "Terminal ID",
        accessor: (id) => <>{id.terminal_id}</>,
      },
      {
        Header: "Value(₦)",
        accessor: (value) => {
          const formattedNumber = FormatAmount(value.trans_value);

          return <>{formattedNumber}</>;
        },
      },
      {
        Header: "Value Change(₦)",
        accessor: (valueChange) => {
          const formattedNumber = FormatAmount(valueChange.value_change);

          return (
            <div
              className={
                valueChange.value_change < 0 ? "text-danger" : "#00c851"
              }
            >
              {formattedNumber}
            </div>
          );
        },
      },
      {
        Header: "Volume",
        accessor: (volume) => <>{volume.trans_volume}</>,
      },
      {
        Header: "Volume Change",
        accessor: (volume) => (
          <div
            className={
              volume.volume_change < 0 ? "text-danger" : "text-success"
            }
          >
            {volume.volume_change}
          </div>
        ),
      },
    ],
    []
  );

  const fetchPerformanceData = async () => {
    try {
      const endpoint =
        AppData.BASE_URL +
        `merchants/terminal-performance/${merchantId}?page=${pageNumber}&limit=${limit}&startdate=${formattedStartDate}&enddate=${formattedEndDate}`;
      const response = await GetData(endpoint);
      setPerformance(response);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const handleFilter = () => {
    fetchPerformanceData();
  };

  function formatDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  useEffect(() => {
    fetchPerformanceData();
  }, []);

  useEffect(() => {
    const merchantInfoString = localStorage.getItem("merchantInfo");
    const merchantInfo = JSON.parse(merchantInfoString);
    setMerchantId(merchantInfo.merchant_id);
  }, []);

  return (
    <div className="analysis">
      <div className="analysis-param">
        <div className="row me-3">
          <div className="col-3 me-3">
            <CustomSelect
              className="custom-select"
              heading="Limit"
              selectedVal={limit}
              setSelectedValue={setLimit}
              items={[
                {
                  name: "50",
                },
                {
                  name: "100",
                },
                {
                  name: "200",
                },
                {
                  name: "500",
                },
                {
                  name: "1000",
                },
              ]}
            />
          </div>
          <div className="col-6">
            <div className="date-filter">
              <p>Filter by Date: </p>
              <DateRange
                startDate={startDate}
                endDate={endDate}
                handleStartDate={(date) => setStartDate(date)}
                handleEndDate={(date) => setEndDate(date)}
              />
            </div>
          </div>
          <div className="col-2">
            <button type="button" className="btn filter" onClick={handleFilter}>
              Filter
            </button>
          </div>
        </div>
      </div>
      <div className="export">
        {performance?.data?.length > 0 && (
          <CSVLink
            data={performance.data}
            filename="Performance"
            className="export-btn btn"
          >
            Export Data
          </CSVLink>
        )}
      </div>
      <hr />
      <div className="p-4">
        <div className="table-responsive table-wrapper">
          {performance?.data?.length > 0 ? (
            <Table
              columns={columns}
              data={performance.data}
              tableClass="table table-borderless table-striped table-hover fs-7 mb-0"
              enableRowSelection={false}
            />
          ) : (
            <p className="no-record">No record found</p>
          )}
          {lastPageNum > 1 && (
            <Pagination
              prevPage={pageNumber - 1}
              nextPage={pageNumber + 1}
              totalPages={lastPageNum}
              hasNextPage={pageNumber < lastPageNum}
              hasPrevPage={pageNumber > 1}
              setPageNum={setPageNumber}
            />
          )}
        </div>
      </div>
      <hr />
    </div>
  );
}
