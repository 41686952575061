// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-view {
    margin-top: 32px;
}

.tab-view .nav {
    gap: 10px;
}

.tab-view .nav-link {
    /* width: 245px; */
    background-color: #DBDBDB80;
    color: #838383;
    text-align: center;
    padding: 13px;
    border-radius: 4px;
    cursor: pointer;
}

.tab-view .nav-active {
    background-color: #ffffff !important;
    color: #016aa7 !important;
    border-radius: 0 !important;
}

.tab-item {
    display: flex;
    justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/components/TabView.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,2BAA2B;IAC3B,cAAc;IACd,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,oCAAoC;IACpC,yBAAyB;IACzB,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC","sourcesContent":[".tab-view {\n    margin-top: 32px;\n}\n\n.tab-view .nav {\n    gap: 10px;\n}\n\n.tab-view .nav-link {\n    /* width: 245px; */\n    background-color: #DBDBDB80;\n    color: #838383;\n    text-align: center;\n    padding: 13px;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\n.tab-view .nav-active {\n    background-color: #ffffff !important;\n    color: #016aa7 !important;\n    border-radius: 0 !important;\n}\n\n.tab-item {\n    display: flex;\n    justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
