import React, { useState, useEffect } from "react";
import "./Group.css";
import Table from "../../components/Table";
import SearchInput from "../../components/SearchInput";
import CustomSelect from "../../components/customSelect/CustomSelect";
import AppData from "../../config/appData.json";
import GetData from "../../api/GetData";
import GroupedAgentHistory from "./GroupedAgentHistory";
import security from "../../assets/images/security-icon.svg";
import Modal from "../../components/modal/Modal";
import DeleteData from "../../api/DeleteData";
import handlePostApi from "../../api/HandlePostApi";
import SimpleLoader from "../../components/spinner/SimpleLoader";

export default function Agents() {
  const [value, setValue] = useState("");
  const [searchParam, setSearchParam] = useState("Agent Type");
  const [groupMembers, setGroupMembers] = useState({});
  const [showAgentsTransactions, setShowAgentsTransactions] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState("");

  const { members } = groupMembers;

  const [agentWalletId, setAgentWalletId] = useState("");

  const handleShowAgentsTransactions = (agentWalletId) => {
    setAgentWalletId(agentWalletId);
    setShowAgentsTransactions(true);
  };

  const openModal = (agent) => {
    setSelectedAgent(agent);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const columns = React.useMemo(
    () => [
      {
        Header: "Wallet Id",
        accessor: "walletId",
      },
      {
        Header: "Name",
        accessor: (name) => (
          <>
            {name.firstName} {name.lastName}
          </>
        ),
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Username",
        accessor: "username",
      },
      {
        Header: "Agent Type",
        accessor: "agentType",
      },
      {
        Header: "",
        accessor: "id",
        Cell: (tableInstance) => {
          const { row: index } = tableInstance;
          const agentWalletId = index.original.walletId;
          return (
            <button
              className="view-btn"
              onClick={() => handleShowAgentsTransactions(agentWalletId)}
            >
              <i className="fa-sharp fa-solid fa-list"></i>
            </button>
          );
        },
      },
      // {
      //   Header: "",
      //   accessor: "_id",
      //   Cell: (tableInstance) => {
      //     const { row: index } = tableInstance;
      //     const agent = index.original;
      //     return (
      //       <button
      //         className="border-0 bg-transparent text-danger"
      //         onClick={() => openModal(agent)}
      //       >
      //         Delete
      //       </button>
      //     );
      //   },
      // },
    ],
    []
  );

  useEffect(() => {
    const fetchGroupMembers = async () => {
      try {
        const endpoint = AppData.VAS_URL + "auth/group-members";
        const response = await GetData(endpoint);
        setGroupMembers(response);
        // console.log("res", response);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchGroupMembers();
  }, []);

  return (
    <main className="col agent">
      {!showAgentsTransactions ? (
        <>
          <div className="mb-5 d-flex">
            <div style={{ width: "300px", marginRight: "10px" }}>
              <SearchInput value={value} setValue={setValue} />
            </div>
            <CustomSelect
              heading="Agent Type"
              selectedVal={searchParam}
              setSelectedValue={setSearchParam}
              items={[
                {
                  name: "desc",
                },
                {
                  name: "asc",
                },
              ]}
            />
          </div>
          {members ? (
            <Table
              columns={columns}
              data={members}
              enableRowSelection={false}
              tableClass={"table table-borderless table-striped mb-0"}
            />
          ) : (
            <SimpleLoader />
          )}
        </>
      ) : (
        <GroupedAgentHistory
          walletId={agentWalletId}
          setShowAgentsTransactions={setShowAgentsTransactions}
        />
      )}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          content={
            <ModalContent
              onClose={closeModal}
              agent={selectedAgent}
              setIsModalOpen={setIsModalOpen}
            />
          }
        />
      )}
    </main>
  );
}

const ModalContent = ({ onClose, agent, setIsModalOpen }) => {
  const handleDeleteGroup = async () => {
    try {
      const aggregatorId = localStorage.getItem("AdminId");
      // console.log(agent)
      const endpoint =
        AppData.VAS_URL + `aggregator/remove-member/${aggregatorId}`;
      const requestData = {
        wallet: agent.walletId,
      };
      const response = await handlePostApi(endpoint, requestData);
      // console.log(requestData)
      setIsModalOpen(false);
      alert("Group deleted");
    } catch (error) {
      console.log("Error", error);
    }
  };
  return (
    <>
      <img className="security-icon" src={security} alt="" />
      <h1 className="fs-6 mt-2">Confirm Delete</h1>
      <p>Please confirm you will like to delete this Group</p>
      <button onClick={onClose} className="grp-btn cls-btn">
        Cancel
      </button>
      <button onClick={handleDeleteGroup} className="grp-btn del-btn">
        Delete
      </button>
    </>
  );
};
