import React from "react";
import "./Report.css";
import TabView from "../../../../../components/TabView";
import MerchantReport from "./MerchantReport";
import ReportTransaction from "./ReportTransaction";
import TerminalReport from "./TerminalReport";

export default function Report({
  changeStartDate,
  changeEndDate,
  startDate,
  endDate,
}) {
  return (
    <div className="settlement-report">
      <TabView
        tabs={[
          {
            name: "Settlement Transaction",
            content: (
              <ReportTransaction
                changeStartDate={changeStartDate}
                changeEndDate={changeEndDate}
                startDate={startDate}
                endDate={endDate}
              />
            ),
          },
          { name: "Terminal Report", content: <TerminalReport /> },
          { name: "Merchant Report", content: <MerchantReport /> },
        ]}
      />
    </div>
  );
}
