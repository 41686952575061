import React, { useState, useEffect } from "react";
import "./Account.css";

export default function Account() {
  const [user, setUser] = useState([]);

  useEffect(() => {
    const merchantInfoString = localStorage.getItem("merchantInfo");
    const merchantInfo = JSON.parse(merchantInfoString);
    setUser(merchantInfo);
  }, []);

  return (
    <div className="merchant-account">
      <table className="user-table">
        {/* <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead> */}
        <tbody>
          <tr>
            <td className="user-field">Merchant ID:</td>
            <td className="user-value">{user.merchant_id}</td>
          </tr>
          <tr>
            <td className="user-field">Merchant Name:</td>
            <td className="user-value">{user.merchant_name}</td>
          </tr>
          <tr>
            <td className="user-field">Merchant Email:</td>
            <td className="user-value">{user.merchant_email}</td>
          </tr>
          <tr>
            <td className="user-field">Merchant Address:</td>
            <td className="user-value">{user.merchant_address}</td>
          </tr>
          <tr>
            <td className="user-field">Roles</td>
            <td className="user-value">{user?.roles?.join(", ")}</td>
          </tr>
          <tr>
            <td className="user-field">Created At</td>
            <td className="user-value">{new Date(user?.created_at).toLocaleString()}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
