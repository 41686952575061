import React, { useState, useEffect, useCallback } from "react";
import "./Providers.css";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/Table";
import AppData from "../../../config/appData.json";
import AddProvider from "../../modal/AddProvider";
import handlePostApi from "../../../api/HandlePostApi";
import GetData from "../../../api/GetData";
import Modal from "../../../components/modal/Modal";
import imageURL from "../../../imageUrls";
import { ToastContainer, toast } from "react-toastify";

export default function Providers() {
  const navigate = useNavigate();
  const [data, setData] = useState({ providers: [] });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [state, setState] = useState(
    data.providers.map((provider) => provider.status === "ON")
  );

  const url = AppData.VAS_URL + "admin/getProviders";

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const handleErrorFunc = (error) => {
    if (error.response.data.message === "Expired Session, Please Login Again") {
      navigate("/signin");
    } else {
      setMessage(error.response.data.message);
    }
  };

  const handlePageRefresh = async (url) => {
    try {
      const response = GetData(url);
      setData(response);
    } catch (error) {
      handleErrorFunc(error);
    }
  };

  const onToggle = useCallback(
    (e, id) => {
      const status = [...state];
      status[id] = !status[id];
      setState(status);
      const providerStatus = status[id] ? "ON" : "OFF";
      const provider = data.providers[id];
      const endpoint = AppData.VAS_URL + "admin/changeProviderStatus";
      const providerData = {
        service: provider.service,
        status: providerStatus,
      };
      handlePostApi(endpoint, providerData);
    },
    [state, data.providers]
  );

  const fetchData = async () => {
    try {
      const response = await GetData(url);
      setData(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [url]);

  useEffect(() => {
    if (data.providers.length > 0) {
      setState(data.providers.map((provider) => provider.status === "ON"));
    }
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Service",
        accessor: (service) => (
          <div className="select-icon">
            <img
              src={imageURL[service.service]}
              className="service-logo me-2"
              alt="product"
            />{" "}
            {service.service}
          </div>
        ),
      },
      {
        Header: "Provider",
        accessor: "provider",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (cell) => {
          const { row: index } = cell;
          return (
            <label className="switch">
              <input
                type="checkbox"
                className="checkbox"
                onChange={(e) => onToggle(e, index.id)}
                checked={state[index.id]}
                name={index.id}
              />
              <span className="slider" />
              <span className="inner" />
            </label>
          );
        },
      },
    ],
    [onToggle, state]
  );

  useEffect(() => {
    if (message?.trim().length > 0) {
      toast(message);
      setMessage(""); // Clear the message after displaying the toast
    }
  }, [message]);

  return (
    <main className="col provider-container">
      <div className="provider">
        <h1>User Management </h1>
        {/* <div>{providers.provider}</div> */}
        <div className="actions">
          <button className="btn btn-provider" onClick={openModal}>
            Add Providers
          </button>
          <button
            className="btn btn-refresh"
            // onClick={() => handlePageRefresh(url)}
          >
            <i className="fa-solid fa-arrows-rotate"></i>Refresh
          </button>
        </div>
        <div>
          <Table
            columns={columns}
            data={data.providers}
            tableClass={"table table-borderless table-striped mb-0"}
            enableRowSelection={false}
          />
        </div>
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          content={
            <AddProvider
              setShowModal={setIsModalOpen}
              setMessage={setMessage}
            />
          }
        />
      )}
      <ToastContainer />
    </main>
  );
}
