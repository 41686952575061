import React, { useState, useContext } from "react";
import "./Header.css";
import { UserContext } from "../../../context/UserContext";
import notification from "../../../assets/images/notification-active.svg";
import angle from "../../../assets/images/polygon.svg";
import InitialsAvatar from "react-initials-avatar";
import { useNavigate } from "react-router-dom";
import ChangePassModal from "../../modal/ChangePassModal";
import AppData from "../../../config/appData.json";
import postData from "../../../api/PostData";

export default function Header({ heading }) {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const user = userData.user ? userData.user : userData;
  const [showDropdown, setShowDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);

  const handleShowDropdown = () => {
    setShowDropdown(true);
  };

  const handleCloseDropdown = () => {
    setShowDropdown(false);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleAction = async (type) => {
    try {
      setModalType(type);
      const url = AppData.VAS_URL + "auth/send-reset-code";
      const requestData = { email: user.email };
      await postData(url, requestData);
      setShowModal(true);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSignOut = () => {
    localStorage.removeItem("token");
    navigate("/signin");
  };

  const handleUserUpgrade = () => {
    navigate("/onboard");
  };

  const renderListItem = (text, onClick) => (
    <li className="mb-2">
      <button className="menu-action-btn" type="button" onClick={onClick}>
        {text}
      </button>
    </li>
  );

  return (
    <header className="navbar">
      <h1>
        {user.agentType} {heading}
      </h1>
      <div className="user">
        <div className="notification">
          <img src={notification} alt="" />
        </div>
        <div
          onMouseEnter={handleShowDropdown}
          onMouseLeave={handleCloseDropdown}
        >
          {user.picture ? (
            <img className="profile-pic" src={user.picture} alt="profile-pic" />
          ) : (
            <InitialsAvatar name={`${user.firstName} ${user.lastName}`} />
          )}
          <img className="angle" src={angle} alt="" />
          <div className={showDropdown ? "user-menu" : "user-menu hide"}>
            <div className="user-info">
              <h4>
                {user.firstName} {user.lastName}
              </h4>
              <p>{user.agentType}</p>
            </div>
            <ul>
              {user.agentType === "user" &&
                renderListItem("Become an Agent", handleUserUpgrade)}
              {renderListItem("Change Password", () =>
                handleAction("passwordReset")
              )}
              {renderListItem("Reset Transaction Pin", () =>
                handleAction("transactionPinReset")
              )}
              {renderListItem("Sign out", handleSignOut)}
            </ul>
          </div>
        </div>
      </div>
      {showModal && (
        <ChangePassModal
          closeModal={handleModalClose}
          modalType={modalType}
          userEmail={user.email}
        />
      )}
    </header>
  );
}
