import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./MerchantList.css";
import Table from "../../../components/Table";
import Pagination from "../../../components/Pagination";
import AppData from "../../../config/appData.json";
import handlePostApi from "../../../api/HandlePostApi";
import GetData from "../../../api/GetData";
import Modal from "../../../components/modal/Modal";
import RequestOnboardModal from "./RequestOnboardModal";

const AGENT_TYPES = [
  { name: "Tier 1" },
  { name: "Tier 2" },
  { name: "Tier 3" },
];

export default function MerchantList({ setAddMerchant }) {
  const [marchantList, setMerchantList] = useState([]);
  const [merchantInfo, setMerchantInfo] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  // console.log("marchantList", marchantList);

  const openMerchantOnboard = () => {
    setAddMerchant(true);
  };

  const requestData = {
    page: 1,
  };

  const fetchData = async () => {
    try {
      const url = AppData.BASE_URL + "merchants/onboard";
      const response = await GetData(url);
      setMerchantList(response.data);
      // console.log("response", response.data);
    } catch (error) {
      const { response } = error;
      const err = response?.data?.message;
      console.error("Error fetching transactions:", error);
      if (err === "Expired Session, Please Login Again") {
        navigate("/signin");
      }
    }
  };

  const closeModal = () => setIsModalOpen(false);

  const handleViewRequest = (merchantInfo) => {
    setIsModalOpen(true);
    setMerchantInfo(merchantInfo);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Merchant Name",
        accessor: (row) => row.merchant[0]?.merchant_name || "N/A",
      },
      {
        Header: "Merchant Phone",
        accessor: (row) => row.merchant[0]?.contact_phone || "N/A",
      },
      {
        Header: "Account Number",
        accessor: (row) => row.merchant[0]?.account_number || "N/A",
      },
      {
        Header: "MID",
        accessor: "",
      },
      {
        Header: "TID",
        accessor: (row) => row.merchant[0]?.terminals[0] || "N/A",
      },
      {
        Header: "Approved By",
        accessor: "",
      },
      {
        Header: "Status",
        accessor: "",
      },
      {
        Header: "Created At",
        accessor: (row) => row.createdAt.toLocaleString(),
      },
      {
        Header: "Updated At",
        accessor: (row) => row.updatedAt.toLocaleString(),
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => {
          const disable = row.original.merchant.length === 0;
          return (
            <button
              className={disable ? "" : "view-request-btn" }
              onClick={() => handleViewRequest(row.original)}
              disabled={disable}
            >
              VIEW
            </button>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    fetchData(requestData);
  }, []);

  return (
    <div className="merchant-list pt-4">
      <div className="d-flex mb-3">
        <div className="ms-auto">
          <button className="group-btn" onClick={openMerchantOnboard}>
            On Board Merchant
          </button>
        </div>
      </div>
      {marchantList && (
        <div>
          <Table
            columns={columns}
            data={marchantList}
            enableRowSelection={false}
            tableClass={"table table-borderless table-striped mb-0"}
          />
        </div>
      )}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          content={
            <RequestOnboardModal
              merchantInfo={merchantInfo}
              onClose={closeModal}
            />
          }
        />
      )}
    </div>
  );
}
