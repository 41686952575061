// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.merchant-list .custom-select {
  width: 120px;
}

.merchant-list .group-btn {
  background-color: #060d27;
  color: #ffffff;
}

.merchant-list .view-request-btn {
  border: 2px solid rgb(53, 53, 253);
  text-align: center;
  padding-top: 4px;
  font-weight: 200;
  color: rgb(53, 53, 253);
  background-color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/view/reporting-dashboard/profle/MerchantList.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,kCAAkC;EAClC,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,uBAAuB;EACvB,yBAAyB;AAC3B","sourcesContent":[".merchant-list .custom-select {\n  width: 120px;\n}\n\n.merchant-list .group-btn {\n  background-color: #060d27;\n  color: #ffffff;\n}\n\n.merchant-list .view-request-btn {\n  border: 2px solid rgb(53, 53, 253);\n  text-align: center;\n  padding-top: 4px;\n  font-weight: 200;\n  color: rgb(53, 53, 253);\n  background-color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
