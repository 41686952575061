import React from "react";
import "./DisputeResolution.css";
import DropdownMenu from "../../../../components/DropdownMenu";
import Audit from "./audit/Audit";
import DisputeReport from "./dispute-report/DisputeReport";

const disputeReport = <DisputeReport />;
const audit = <Audit />;

export default function DisputeResolution() {
  return (
    <div>
      <DropdownMenu
        label="Dispute"
        items={[
          { name: "Report", content: disputeReport },
          { name: "Audit Trial", content: audit },
        ]}
      />
    </div>
  );
}
