// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-trans-nav {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  color: #a0a0a0;
}

.card-trans-nav h1 {
  margin-right: auto;
  margin-left: 0;
  font-size: 18px;
}

.card-trans-nav ul {
  display: flex;
  gap: 30px;
  list-style: none;
  margin-bottom: 0;
}

.card-trans-nav .nav-item {
  padding: 10px;
  color: #a0a0a0;
  font-weight: 100;
  cursor: pointer;
}

.card-trans-nav .nav-item:hover {
  color: #2626e5;
}

.card-trans-nav .active {
  color: #2626e5;
  font-weight: 500;
  border-bottom: 4px solid #002e5b;
}
`, "",{"version":3,"sources":["webpack://./src/view/reporting-dashboard/card-transactions/CardTransactions.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gCAAgC;EAChC,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,gCAAgC;AAClC","sourcesContent":[".card-trans-nav {\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid #ebebeb;\n  color: #a0a0a0;\n}\n\n.card-trans-nav h1 {\n  margin-right: auto;\n  margin-left: 0;\n  font-size: 18px;\n}\n\n.card-trans-nav ul {\n  display: flex;\n  gap: 30px;\n  list-style: none;\n  margin-bottom: 0;\n}\n\n.card-trans-nav .nav-item {\n  padding: 10px;\n  color: #a0a0a0;\n  font-weight: 100;\n  cursor: pointer;\n}\n\n.card-trans-nav .nav-item:hover {\n  color: #2626e5;\n}\n\n.card-trans-nav .active {\n  color: #2626e5;\n  font-weight: 500;\n  border-bottom: 4px solid #002e5b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
