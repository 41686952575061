// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  background-color: #ffffff;
  width: 14%;
  display: block;
  padding-top: 20px;
  padding-left: 20px;
  position: fixed;
  top: 80px;
  bottom: 0;
  left: 0;
  z-index: 1;
  box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%);
}

.sidebar-container {
  position: sticky;
}

.sidebar-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-heading {
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0.54px;
  font-weight: 400;
  font-size: 12px;
}

.sidebar .nav-link {
  color: #a0a0a0;
  font-weight: 200;
  font-size: 12px;
  letter-spacing: 0.72px;
  padding: 6px 0px;
}

.sidebar .nav-link span {
  padding-left: 10px;
}

.sidebar-footer {
  border-top: 1px solid #e3e3e3;
  padding-top: 44px;
}

.sidebar .log-out {
  color: #a7580a !important;
  border: none;
  background-color: transparent;
}

.sidebar .home__icon g {
  fill: red;
}
.sidebar .home__icon path {
  stroke: transparent;
  stroke-width: 10px;
}

.active-url {
    filter: brightness(0.1);
    /* color: #0d1d59; */
    /* font-weight: 600; */
}
`, "",{"version":3,"sources":["webpack://./src/components/sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,UAAU;EACV,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,SAAS;EACT,SAAS;EACT,OAAO;EACP,UAAU;EACV,2CAA2C;AAC7C;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;EAC7B,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,6BAA6B;AAC/B;;AAEA;EACE,SAAS;AACX;AACA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;IACI,uBAAuB;IACvB,oBAAoB;IACpB,sBAAsB;AAC1B","sourcesContent":[".sidebar {\n  background-color: #ffffff;\n  width: 14%;\n  display: block;\n  padding-top: 20px;\n  padding-left: 20px;\n  position: fixed;\n  top: 80px;\n  bottom: 0;\n  left: 0;\n  z-index: 1;\n  box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%);\n}\n\n.sidebar-container {\n  position: sticky;\n}\n\n.sidebar-heading {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.sidebar-heading {\n  color: #000000;\n  text-transform: uppercase;\n  letter-spacing: 0.54px;\n  font-weight: 400;\n  font-size: 12px;\n}\n\n.sidebar .nav-link {\n  color: #a0a0a0;\n  font-weight: 200;\n  font-size: 12px;\n  letter-spacing: 0.72px;\n  padding: 6px 0px;\n}\n\n.sidebar .nav-link span {\n  padding-left: 10px;\n}\n\n.sidebar-footer {\n  border-top: 1px solid #e3e3e3;\n  padding-top: 44px;\n}\n\n.sidebar .log-out {\n  color: #a7580a !important;\n  border: none;\n  background-color: transparent;\n}\n\n.sidebar .home__icon g {\n  fill: red;\n}\n.sidebar .home__icon path {\n  stroke: transparent;\n  stroke-width: 10px;\n}\n\n.active-url {\n    filter: brightness(0.1);\n    /* color: #0d1d59; */\n    /* font-weight: 600; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
