import React from "react";
import { Pie } from "react-chartjs-2";

export default function PieChart() {
  const data = {
    labels: ["Product 1", "Product 2", "Product 3", "Product 4"],
    datasets: [
      {
        data: [25, 25, 25, 25],
        backgroundColor: ["#387FF4", "#83DE94", "#4534C2", "#0D28EE"],
        hoverBackgroundColor: ["#387FF4", "#83DE94", "#4534C2", "#0D28EE"],
        borderColor: ["#387FF4", "#83DE94", "#4534C2", "#FFF"], 
        borderWidth: [0, 0, 0, 3],
      },
    ],
  };

  return (
    <>
      <Pie
        data={data}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
        }}
      />
    </>
  );
}
