import React, { useState, useEffect } from "react";
import Table from "../../../../components/Table";
import "./History.css";
import { CSVLink } from "react-csv";
import Images from "../../../../assets/images/Images";
import CustomSelect from "../../../../components/customSelect/CustomSelect";
import SearchInput from "../../../../components/SearchInput";
import DateRange from "../../../../components/DateRange";
import GetData from "../../../../api/GetData";
import AppData from "../../../../config/appData.json";
import Pagination from "../../../../components/Pagination";
import imageURL from "../../../../imageUrls";
import FailedReasons from "./FailedReasons";

export default function History() {
  const [banks, setBanks] = useState([]);
  const [history, setHistory] = useState([]);
  const [status, setStatus] = useState("");
  const [failedReasons, setFailedReasons] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [searchParm, setSearchParam] = useState("");
  const [selectedBank, setSelectedBank] = useState("All banks");
  const [selectedStatus, setSelectedStatus] = useState("Status");
  const [selectedMethod, setSelectedMethod] = useState("Card");
  const [pageNumber, setPageNumber] = useState(1);
  const postPerPage = 50;
  const totalVol = status.data && status.data.total_volume;
  const lastPageNum = Math.ceil(totalVol / postPerPage);
  const pageStart = (pageNumber - 1) * postPerPage + 1;

  const borderColors = ["#838383", "#6C540E", "#0584FE", "#FB4013", "#550000"];
  const backgroundColors = [
    "#0000001A",
    "#745A021A",
    "#0584FE19",
    "#FB40131A",
    "#55000019",
  ];

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  // console.log("formatted", formattedStartDate, formattedEndDate);

  const columns = React.useMemo(
    () => [
      {
        Header: "S/N",
        Cell: (row) => {
          return <div>{row.row.index + pageStart}</div>;
        },
      },
      {
        Header: "Merchant Name",
        accessor: (name) => (
          <div className="merchant-name">{name.merchant_name}</div>
        ),
      },
      {
        Header: "Amount",
        accessor: (amount) => <div>N{amount.amount.toLocaleString()}</div>,
      },
      {
        Header: "Terminal Id",
        accessor: "terminal_id",
      },
      {
        Header: "Merchant Id",
        accessor: "merchant_id",
      },
      {
        Header: "RRN",
        accessor: "rrn",
      },
      {
        Header: "Card Pan",
        accessor: "pan",
      },
      {
        Header: "Bank",
        accessor: (bankPan) => {
          const bankCode = bankPan?.pan?.slice(0, 6);
          const bankImage = imageURL[bankCode];
          if (bankImage) {
            return (
              <div className="select-icon">
                <img src={bankImage} className="service-logo" alt="service" />
              </div>
            );
          } else {
            return <div className="select-icon">N/A</div>;
          }
        },
      },
      {
        Header: "Status",
        accessor: (response) => (
          <div
            className={
              response.response_msg === "Approved"
                ? "text-success"
                : "text-danger"
            }
          >
            {" "}
            {response.response_msg}
          </div>
        ),
      },
      {
        Header: "Date",
        accessor: (date) => {
          let dDate = date.transaction_date;
          const formatDate = new Date(dDate);
          return <div> {formatDate.toLocaleString()}</div>;
        },
      },
    ],
    [pageNumber]
  );

  useEffect(() => {
    fetchHistoryData();
  }, [formattedStartDate, formattedEndDate, pageNumber, searchParm]);

  useEffect(() => {
    fetchFailedReasons();
  }, [formattedStartDate, formattedEndDate]);

  useEffect(() => {
    fetchTransactionStatus();
  }, [formattedStartDate, formattedEndDate]);

  useEffect(() => {
    fetchBankList();
  }, []);

  const fetchHistoryData = async () => {
    try {
      const endpoint =
        AppData.BASE_URL +
        `transactions/history?limit=${postPerPage}&page=${pageNumber}&startdate=${formattedStartDate}&enddate=${formattedEndDate}$search=${searchParm}`;
      const response = await GetData(endpoint);
      setHistory(response);
      // console.log(response);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const fetchFailedReasons = async () => {
    try {
      const endpoint =
        AppData.BASE_URL +
        `transactions/failure-reasons?startdate=${formattedStartDate}&enddate=${formattedEndDate}`;
      const response = await GetData(endpoint);
      setFailedReasons(response);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const fetchTransactionStatus = async () => {
    try {
      const endpoint =
        AppData.BASE_URL +
        `transactions/stats?startdate=${formattedStartDate}&enddate=${formattedEndDate}`;
      const response = await GetData(endpoint);
      setStatus(response);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const fetchBankList = async () => {
    try {
      const endpoint = AppData.BASE_URL + "banks/list";
      const response = await GetData(endpoint);
      setBanks(response);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  function formatDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  return (
    <div className="history">
      <div className="history-param">
        <div className="row me-3">
          <div className="row">
            <div className="col-4">
              <CustomSelect
                heading="Banks"
                selectedVal={selectedBank}
                setSelectedValue={setSelectedBank}
                items={banks.data}
              />
            </div>
            <div className="col-4">
              <CustomSelect
                heading="Method"
                selectedVal={selectedMethod}
                setSelectedValue={setSelectedMethod}
                items={[
                  {
                    name: "Card",
                  },
                ]}
              />
            </div>
            <div className="col-4">
              <CustomSelect
                heading="Status"
                selectedVal={selectedStatus}
                setSelectedValue={setSelectedStatus}
                items={[
                  {
                    name: "All Status",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div>
          <SearchInput />
        </div>
        <div>
          <div className="d-flex">
            <div className="date-filter">
              <p>Filter by Date: </p>
              <DateRange
                startDate={startDate}
                endDate={endDate}
                handleStartDate={(date) => setStartDate(date)}
                handleEndDate={(date) => setEndDate(date)}
              />
            </div>
            <button type="button" className="downloads">
              <img src={Images.download} alt="download" />
            </button>
          </div>
        </div>
      </div>
      <div className="export">
        {history.data && history.data.length > 0 && (
          <CSVLink
            data={history.data}
            filename="History"
            className="export-btn btn"
          >
            Export Data
          </CSVLink>
        )}
      </div>
      <div className="row failed-reason-cont pb-4">
        <h6 className="mb-3">Failed Transaction Summary</h6>
        <FailedReasons failedReasons={failedReasons} />
      </div>
      <h6>Transaction History</h6>
      <div className="history-table table-responsive">
        {history.data && (
          <Table
            columns={columns}
            data={history.data}
            enableRowSelection={false}
            tableClass={"table table-borderless table-striped mb-0"}
          />
        )}
        {lastPageNum > 1 && (
          <Pagination
            prevPage={pageNumber - 1}
            nextPage={pageNumber + 1}
            totalPages={lastPageNum}
            hasNextPage={pageNumber < lastPageNum}
            hasPrevPage={pageNumber > 1}
            setPageNum={setPageNumber}
          />
        )}
      </div>
    </div>
  );
}
