import React from "react";

export default function PasswordChecker({checks}) {
  return (
    <div className="pwd-checker">
      <div className="row mt-3">
        <div className="col-7">
          <div className="checker">
            <div
              className={
                checks.capsLetterCheck
                  ? "check-icon checked"
                  : "check-icon unchecked"
              }
            >
              <i className="fa-solid fa-check"></i>
            </div>
            <p className="check-text">at least 1 uppercase character</p>
          </div>
        </div>
        <div className="col-5">
          <div className="checker">
            <div
              className={
                checks.specialCharCheck
                  ? "check-icon checked"
                  : "check-icon unchecked"
              }
            >
              <i className="fa-solid fa-check"></i>
            </div>
            <p className="check-text">one special character</p>
          </div>
        </div>
        <div className="col-7">
          <div className="checker">
            <div
              className={
                checks.smallLetterCheck
                  ? "check-icon checked"
                  : "check-icon unchecked"
              }
            >
              <i className="fa-solid fa-check"></i>
            </div>
            <p className="check-text">minimum of a lowercase character</p>
          </div>
        </div>
        <div className="col-5">
          <div className="checker">
            <div
              className={
                checks.pwdLengthCheck
                  ? "check-icon checked"
                  : "check-icon unchecked"
              }
            >
              <i className="fa-solid fa-check"></i>
            </div>
            <p className="check-text">minimum of 6 characters</p>
          </div>
        </div>
        <div className="col-6">
          <div className="checker">
            <div
              className={
                checks.numberCheck
                  ? "check-icon checked"
                  : "check-icon unchecked"
              }
            >
              <i className="fa-solid fa-check"></i>
            </div>
            <p className="check-text">one number</p>
          </div>
        </div>
      </div>
    </div>
  );
}
