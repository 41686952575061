import React, { useState } from "react";
import TabView from "../../../components/TabView";
import UnassignedAgentsList from "./UnassignedAgentsList";
import AssignedAgentsList from "./AssignedAgentsList";
import UserProfile from "./UserProfile";

export default function AgentsList() {
  const [showUserData, setShowUserData] = useState(false);
  const [userId, setUserId] = useState("");

  return (
    <div className="agent-assignment mt-0 pb-3">
      {showUserData ? (
        <UserProfile userId={userId} setShowUserData={setShowUserData} />
      ) : (
        <TabView
          tabs={[
            {
              name: "Unassigned Agents",
              content: (
                <UnassignedAgentsList
                  setShowUserData={setShowUserData}
                  setUserId={setUserId}
                />
              ),
            },
            {
              name: "Assigned Agents",
              content: (
                <AssignedAgentsList
                  setShowUserData={setShowUserData}
                  setUserId={setUserId}
                />
              ),
            },
          ]}
        />
      )}
    </div>
  );
}
