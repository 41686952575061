// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  width: auto;
  border: 1px solid #707070;
  border-radius: 15px;
  padding: 20px !important;
  text-align: center;
  opacity: 1;
}

.modal-content .close {
  position: absolute;
  top: -40px;
  right: -50px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border: none;
  background-color: #ffffff;
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/modal/Modal.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,oCAAoC;AACtC;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,mBAAmB;EACnB,wBAAwB;EACxB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":[".modal {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgba(0, 0, 0, 0.7);\n}\n\n.modal-content {\n  width: auto;\n  border: 1px solid #707070;\n  border-radius: 15px;\n  padding: 20px !important;\n  text-align: center;\n  opacity: 1;\n}\n\n.modal-content .close {\n  position: absolute;\n  top: -40px;\n  right: -50px;\n  border-radius: 50%;\n  height: 40px;\n  width: 40px;\n  border: none;\n  background-color: #ffffff;\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
