// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.provider-container .modal-content {
    padding: 0;
}

.provider-container .modal-header {
    background-color: #F5F5F5;
    border: 1px solid #E6E6E6;
    border-radius: 15px 15px 0px 0px;
    color: #606060;
}

.provider-container .modal-header ul {
    display: flex;
    list-style: none;
    gap: 15px;
}

.provider-container .modal-header li {
    cursor: pointer;
}

.provider-container .modal-footer {
    flex-wrap: nowrap;

} `, "",{"version":3,"sources":["webpack://./src/view/modal/AddProviders.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,gCAAgC;IAChC,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;;AAErB","sourcesContent":[".provider-container .modal-content {\n    padding: 0;\n}\n\n.provider-container .modal-header {\n    background-color: #F5F5F5;\n    border: 1px solid #E6E6E6;\n    border-radius: 15px 15px 0px 0px;\n    color: #606060;\n}\n\n.provider-container .modal-header ul {\n    display: flex;\n    list-style: none;\n    gap: 15px;\n}\n\n.provider-container .modal-header li {\n    cursor: pointer;\n}\n\n.provider-container .modal-footer {\n    flex-wrap: nowrap;\n\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
