import React from "react";
import "./TransactionDashboard.css";
import TabView from "../../../../components/TabView";
import Analysis from "./Analysis";
import Dashboard from "./Dashboard";
import History from "./History";
import Status from "./Status";

export default function TransactionDashboard() {
  const OverviewDashboard = <Dashboard />;
  const TransactionStatus = <Status />;
  const TransactionHistory = <History />;
  const TransactionAnalysis = <Analysis />;

  return (
    <div className="transactions-dashboard pb-3">
      <TabView
        tabs={[
          {
            name: "Overview Dashboard",
            content: OverviewDashboard,
          },
          {
            name: "Transaction Status",
            content: TransactionStatus,
          },
          {
            name: "Transaction History",
            content: TransactionHistory,
          },
          {
            name: "Transaction Analysis",
            content: TransactionAnalysis,
          },
        ]}
      />
    </div>
  );
}
