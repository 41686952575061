import React, { useState, useEffect } from "react";
import TabView from "../../../../../components/TabView";
import Status from "./StatusDetails";
import Map from "./MapOverview";
import pos from "../../../../../assets/images/pos.png";
import battery from "../../../../../assets/images/battery.png";
import printer from "../../../../../assets/images/printer.png";
import BarChart from "../../../../../components/charts/BarChart";
import GetData from "../../../../../api/GetData";
import AppData from "../../../../../config/appData.json";

const state = {
  labels: ["January", "February", "March", "April"],
  datasets: [
    {
      label: "Rainfall",
      backgroundColor: "rgba(75,192,192,1)",
      borderColor: "rgba(0,0,0,1)",
      borderWidth: 1,
      data: [65, 59, 80, 81],
    },
  ],
};
const scales = {
  x: {
    grid: {
      display: false,
    },
  },
};

export default function TerminalView() {
  return (
    <div>
      <TabView
        tabs={[
          {
            name: "Terminal status details",
            content: StatusDetails,
            param: MerchantParam,
          },
          {
            name: "Terminal map overview",
            content: MapOverview,
            param: MerchantParam,
          },
        ]}
      />
    </div>
  );
}

const Summary = () => {
  const [terminalStatus, setTerminalStatus] = useState("");

  useEffect(() => {
    const fetchTerminalStatus = async () => {
      try {
        const endpoint = AppData.BASE_URL + `terminals/stats`;
        const response = await GetData(endpoint);
        setTerminalStatus(response);
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    fetchTerminalStatus();
  }, []);

  return (
    <div className="row ms-4">
      <div className="col-3">
        <div className="border w-75 h-100 p-2">
          <div className="d-flex">
            <h3 className="text-primary fs-6 me-auto">Terminals</h3>
            <div>
              <img src={pos} alt="pos" style={{ height: 20 }} />
            </div>
          </div>
          <div className="text-center">
            <h4 className="fs-6">Total</h4>
            <p className="fs-7 mb-1">
              {terminalStatus.data && terminalStatus.data.allTerminals}
            </p>
          </div>
          <div className="d-flex justify-content-center fs-7">
            <div className="text-end p-2">
              <p className="mb-1">Active</p>
              <p className="mb-1">
                {terminalStatus.data && terminalStatus.data.activeTerminals}
              </p>
              <p className="mb-1">95.7%</p>
            </div>
            <div className="text-start p-2">
              <p className="mb-1">Inactive</p>
              <p className="mb-1">
                {terminalStatus.data && terminalStatus.data.inactiveTerminals}
              </p>
              <p className="mb-1">95.7%</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-3">
        <div className="border w-75 h-100 p-2">
          <div className="d-flex">
            <h3 className="text-primary fs-6 me-auto">Printer Status</h3>
            <div>
              <img src={printer} alt="printer" style={{ height: 20 }} />
            </div>
          </div>
          <div className="text-center">
            <h4 className="fs-6">Total</h4>
            <p className="fs-7 mb-1">12,000</p>
          </div>
          <div className="d-flex justify-content-center fs-7">
            <div className="text-end p-2">
              <p className="mb-1">Active</p>
              <p className="mb-1">9000</p>
              <p className="mb-1">95.7%</p>
            </div>
            <div className="text-start p-2">
              <p className="mb-1">Inactive</p>
              <p className="mb-1">9000</p>
              <p className="mb-1">95.7%</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-3">
        <div className="border w-75 h-100 p-2">
          <div className="d-flex">
            <h3 className="text-primary fs-6 me-auto">Battery</h3>
            <div>
              <img src={battery} alt="battery" style={{ height: 20 }} />
            </div>
          </div>
          <div>
            <BarChart state={state} indexAxis={"x"} />
          </div>
        </div>
      </div>
      <div className="col-3">
        <div className="border w-75 h-100 p-2">
          <div className="d-flex">
            <h3 className="text-primary fs-6">Signal</h3>
          </div>
          <div>
            <BarChart state={state} indexAxis={"y"} scales={scales} />
          </div>
        </div>
      </div>
    </div>
  );
};

const summary = <Summary />;

const StatusDetails = <Status summary={summary} />;
const MapOverview = <Map summary={summary} />;

const MerchantParam = <div></div>;
