import Images from "./assets/images/Images";

const imageURL = {
  mtnvtu: Images.mtn,
  glovtu: Images.glo,
  airtelvtu: Images.airtel,
  "9mobilevtu": Images.nineMobile,
  mtndata: Images.mtn,
  glodata: Images.glo,
  airteldata: Images.airtel,
  "9mobiledata": Images.nineMobile,
  ekedc: Images.ekedc,
  aedc: Images.aedc,
  ikedc: Images.ikedc,
  ibedc: Images.ibedc,
  jedc: Images.jedc,
  kedco: Images.kedco,
  smile: Images.smile,
  multichoice: Images.multichoice,
  startimes: Images.startimes,
  kadec: Images.kadec,
  phedc: Images.phedc,
  eedc: Images.eedc,
  transfer: Images.transferImg,
  withdrawal: Images.withdraw,
  linuxpos: Images.posIcon,
  mobile: Images.mobileIcon,
  web: Images.webIcon,
  b2b: Images.business2b,
  androidpos: Images.androidpos,
  cash: Images.cash,
  card: Images.cardIcon,
  539923: Images.fbnIcon,
  506105: Images.fbnIcon,
  468588: Images.zenithIcon,
  539941: Images.zenithIcon,
  468219: Images.zenithIcon,
  496009: Images.zenithIcon,
  513469: Images.zenithIcon,
  471464: Images.zenithIcon,
  522340: Images.zenithIcon,
  471415: Images.zenithIcon,
  514585: Images.zenithIcon,
  458274: Images.zenithIcon,
  521982: Images.zenithIcon,
  539983: Images.gtbIcon,
  469667: Images.accessIcon,
  532732: Images.gtbIcon,
  539983: Images.gtbIcon,
  519911: Images.ubaIcon,
  422584: Images.ubaIcon,
  420359: Images.ubaIcon,
};

export default imageURL;
