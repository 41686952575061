import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const borderColors = ["#838383", "#6C540E", "#0584FE", "#FB4013", "#550000"];
  const backgroundColors = [
    "#0000001A",
    "#745A021A",
    "#0584FE19",
    "#FB40131A",
    "#55000019",
  ];

const FailedReasons = ({ failedReasons }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {failedReasons.data &&
          failedReasons.data.map((reason, index) => (
            <div className="col-2" key={index}>
              <div
                className="history-summary p-3"
                style={{
                  borderColor: borderColors[index % borderColors.length],
                  backgroundColor:
                      backgroundColors[index % backgroundColors.length],
                }}
              >
                <div
                  className="summary-icon mb-2"
                  style={{
                    borderColor: borderColors[index % borderColors.length],
                    backgroundColor:
                      backgroundColors[index % backgroundColors.length],
                  }}
                >
                  <i
                    className="fa-solid fa-circle-exclamation"
                    style={{
                      color: borderColors[index % borderColors.length],
                    }}
                  ></i>
                </div>
                <p className="reason-msg mb-1">{reason.message}</p>
                <p className="reason-count">{reason.count}</p>
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default FailedReasons;
