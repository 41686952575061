import React, { useState } from "react";
import "./Targets.css";
import { CircularProgressbar } from "react-circular-progressbar";
import ServicePerformance from "./ServicePerformance";
import Table from "../../../components/Table";
import Data from "../../../data.json";
import TerminalUpTime from "./TerminalUpTime";
import AppData from "../../../config/appData.json";
import GetData from "../../../api/GetData";
import { useEffect } from "react";
import TargetStrategies from "./TargetStrategies";

function formatDate(timestamp) {
  const date = new Date(timestamp);
  const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
    date
  );
  const day = date.getDate().toString().padStart(2, "0");

  return `${day}-${month}`;
}

export default function Targets() {
  const percentage = 50;
  const data = React.useMemo(() => Data, []);
  const [showTargetModal, setShowTargetModal] = useState(false);
  const [targets, setTargets] = useState([]);

  // console.log("targets", targets)

  const handleShowTargetModal = () => {
    setShowTargetModal(true);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Product",
        accessor: "product",
      },
      {
        Header: "Campaign",
        accessor: (volume) => <>{volume?.volume.toLocaleString()}</>,
      },
      {
        Header: "Duration",
        accessor: (duration) => (
          <>
            {formatDate(duration.createdAt)} - {formatDate(duration.duration)}
          </>
        ),
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Minimum",
        accessor: (amount) => <>{amount?.minimumAmount.toLocaleString()}</>,
      },
      {
        Header: "Target",
        accessor: "percentage",
        Cell: (tableInstance) => {
          const { row: index } = tableInstance;
          return (
            <div className="d-flex">
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow="70"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: `${index.original.percentage}%` }}
                ></div>
              </div>
              <p className="mb-0">{index.original.percentage}%</p>
            </div>
          );
        },
      },
    ],
    []
  );

  const handleGetData = async () => {
    try {
      const endpoint = AppData.VAS_URL + "admin/get-target";
      const response = await GetData(endpoint);
      setTargets(response.target);
      // console.log("targetts", response);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <div className="col targets">
      <TargetStrategies />
      <div className="mt-4">
        {targets.length > 0 ? (
          <Table columns={columns} data={targets} tableClass={"table mb-0"} />
        ) : (
          <p>No targets available</p>
        )}
      </div>
      <ServicePerformance />
      <TerminalUpTime />
    </div>
  );
}
