import React from "react";
import "./Navbar.css";
import logo from "../../assets/images/spout-logo.png";

function Button({ btnClass, btnText }) {
  return <button className={btnClass}>{btnText}</button>;
}

export default function Navbar() {
  return (
    <nav className="navbar">
      <div className="container-fluid">
        <img className="nav-logo" src={logo} alt="logo" />
        <ul className="nav">
          <li className="nav-item">
            <a href="#services">Services</a>
          </li>
          <li className="nav-item">
            <a href="#how-it-works">How it works</a>
          </li>
          <li className="nav-item">
            <a href="#api-doc">Api Documentation</a>
          </li>
        </ul>
        <div className="enterance">
          <ul className="nav">
            <li>
              <a href="/signin">
                <Button btnClass="btn signin-btn" btnText="Sign In" />
              </a>
            </li>
            <li>
              <a href="/signup">
                <Button
                  btnClass="btn signup-btn"
                  btnText="Sign Up"
                  onClick="/signup"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
