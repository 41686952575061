import React, { useEffect, useState } from "react";
import HandlePostPayment from "../../api/HandlePostPayment";
import AppData from "../../config/appData.json";
import Images from "../../assets/images/Images";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LoadingSpinner from "../../components/spinner/LoadingSpinner";
import { v4 as uuidv4 } from "uuid";
import PinValidationModal from "../modal/PinValidationModal";

const AddDescription = ({
  setTransDesc,
  setShowModal,
  setShowAccountDetails,
}) => {
  const [value, setValue] = useState("Description");

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setTransDesc(value);
    setShowModal(true);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <textarea
          className="w-75 mt-3"
          placeholder="Description"
          value={value}
          onChange={handleChange}
        ></textarea>
      </div>
      <div className="d-flex mt-4">
        <div className="cancel">
          <button
            type="button"
            className="btn"
            onClick={() => setShowAccountDetails(false)}
          >
            Cancel
          </button>
        </div>
        <div className="continue">
          <button type="submit" className="btn">
            Proceed
          </button>
        </div>
      </div>
    </form>
  );
};

export default function BankTransfer() {
  const [toggleState, setToggleState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const [bankId, setBankId] = useState(0);
  const [bankDetails, setBankDetails] = useState({});
  const [transactionPin, setTransactionPin] = useState("");
  const [showAccountDetails, setShowAccountDetails] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [msg, setMsg] = useState("");
  const channel = "web";
  const [transactionData, setTransactionData] = useState({});
  const [transDesc, setTransDesc] = useState("");
  const getCodeData = { service: "transfer" };
  const bankCode = `${banks.length > 0 && banks[bankId].bankCode}`;
  const transBank = banks.length > 0 && banks[bankId].bankName;
  const transID = bankDetails.transactionId;
  const uniqueId = uuidv4();

  const { accountNo, amount, senderName, phoneNumber } = transactionData;

  const requestUrl = AppData.VAS_URL + "vas/transfer/payment";
  const requestData = {
    phoneNumber: phoneNumber,
    narration: transDesc,
    transactionId: bankDetails.transactionId,
    service: getCodeData.service,
    senderName: senderName,
    uniqueId: uniqueId,
    paymentMethod: "cash",
    pin: transactionPin,
  };

  const banksSort = banks.sort(function (a, b) {
    var nameA = a.bankName.toUpperCase(); // ignore upper and lowercase
    var nameB = b.bankName.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1; //nameA comes first
    }
    if (nameA > nameB) {
      return 1; // nameB comes first
    }
    return 0; // names must be equal
  });

  const handleToggle = (index) => {
    setToggleState(index);
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const schema = yup.object().shape({
    service: yup.string().required().default(getCodeData.service),
    amount: yup.string().required("Enter a valid Amount!"),
    accountNo: yup.string().required("Account number is required!"),
    senderName: yup.string().required("Senders name is required!"),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid!")
      .required("Phone Number is Required!"),
    bankCode: yup.string().required().default(bankCode),
    channel: yup.string().required().default(channel),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleSelectChange = (e) => {
    setValue("select", e.target.value, {
      shouldValidate: true,
    });
    setBankId(e.target.value);
  };

  const handleFormSubmit = async (requestData) => {
    setTransactionData(requestData);
    try {
      const endpoint = AppData.VAS_URL + "vas/transfer/validation";
      const { data } = await HandlePostPayment(
        requestData,
        setIsLoading,
        endpoint
      );
      console.log("verify detail", data);
      if (data.responseCode === "00") {
        setBankDetails(data);
        setShowAccountDetails(true);
      } else {
        setMsg(data.message);
      }
    } catch (error) {
      console.log("Error occurred", error);
    }
  };

  useEffect(() => {
    const GetBankCode = async () => {
      try {
        const url = AppData.VAS_URL + "vas/get-bank-codes";
        const { data } = await HandlePostPayment(
          getCodeData,
          setIsLoading,
          url
        );
        // console.log("data", data);
        if (data.responseCode === "00") {
          setBanks(data.response.bankCodes);
        } else {
          setMsg(data.message);
        }
      } catch (error) {
        console.log("Error Occurred");
      }
    };
    GetBankCode();
  }, []);

  return (
    <div className="services">
      <div className="service-heading">Bank Transfer</div>
      <div className="row mt-3">
        <div className="col-2">
          <div className="service-type">
            <h6>Choose a meter type</h6>
            <ul>
              <li
                onClick={() => handleToggle(0)}
                className={toggleState === 0 ? "active-service" : ""}
              >
                <img className="icon-service" src={Images.transfer} alt="" />
                Transfer To Any Bank
              </li>
              <li
                onClick={() => handleToggle(1)}
                className={toggleState === 1 ? "active-service" : ""}
              >
                <img className="icon-service" src={Images.ussd} alt="" />
                USSD
              </li>
              <li
                onClick={() => handleToggle(2)}
                className={toggleState === 2 ? "active-service" : ""}
              >
                <img className="icon-service" src={Images.nqr} alt="" />
                NQR
              </li>
            </ul>
          </div>
        </div>
        <div className="col-4">
          <div
            className={
              !showAccountDetails ? "service-form" : "service-form disabled"
            }
          >
            <h6>Complete Field To Buy Electricity</h6>
            <small>{msg !== "Successful" && msg}</small>
            {toggleState === 0 && (
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="grouped-input default">
                  <label>Select Bank</label>
                  <select
                    className="form-control"
                    // {...register("bank")}
                    onChange={(e) => handleSelectChange(e)}
                  >
                    {banksSort.map((bank, index) => (
                      <option key={index} value={index}>
                        {bank.bankName}
                      </option>
                    ))}
                  </select>
                  <div className="select-icon">
                    {/* <div className="service-logo"></div> */}
                    <div>
                      <i className="fa-solid fa-angle-down"></i>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    errors.accountNo
                      ? "grouped-input error"
                      : "grouped-input default"
                  }
                >
                  <label>Beneficiary Account Number</label>
                  <input
                    className="form-control"
                    placeholder="00000000000000"
                    {...register("accountNo")}
                  />
                  <small>{errors.accountNo?.message}</small>
                </div>
                <div
                  className={
                    errors.amount
                      ? "grouped-input error"
                      : "grouped-input default"
                  }
                >
                  <label>Input Amount</label>
                  <input
                    className="form-control"
                    placeholder="0.00"
                    {...register("amount")}
                  />
                  <small>{errors.amount?.message}</small>
                </div>
                <div
                  className={
                    errors.phoneNumber
                      ? "grouped-input error"
                      : "grouped-input default"
                  }
                >
                  <label>Phone Number</label>
                  <input
                    className="form-control"
                    placeholder="080000000000"
                    {...register("phoneNumber")}
                  />
                  <small>{errors.phoneNumber?.message}</small>
                </div>
                <div
                  className={
                    errors.senderName
                      ? "grouped-input error"
                      : "grouped-input default"
                  }
                >
                  <label>Sender Name</label>
                  <input
                    className="form-control"
                    placeholder="Enter Senders Name"
                    {...register("senderName")}
                  />
                  <small>{errors.senderName?.message}</small>
                </div>
                <div className="continue">
                  <button type="submit" className="btn">
                    Continue
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
        <div className="col">
          <div className="service-confirmation">
            <h6>Account Holders Details</h6>
            {isLoading && <LoadingSpinner />}
            {showAccountDetails && (
              <div>
                <table>
                  <tbody>
                    <tr>
                      <th>Bank</th>
                      <td>
                        {accountNo}
                        <div>{transBank}</div>
                      </td>
                    </tr>
                    <tr>
                      <th>Account Name</th>
                      <td>{bankDetails.response.name}</td>
                    </tr>
                    <tr>
                      <th>Amount</th>
                      <td>{amount}</td>
                    </tr>
                  </tbody>
                </table>
                <AddDescription
                  setTransDesc={setTransDesc}
                  setShowModal={setShowModal}
                  setShowAccountDetails={setShowAccountDetails}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <PinValidationModal
          setShowModal={setShowModal}
          setMsg={setMsg}
          requestData={requestData}
          url={requestUrl}
          transactionPin={transactionPin}
          setTransactionPin={setTransactionPin}
          info="We have delivered"
          extraInfo={`N${amount}`}
        />
      )}
    </div>
  );
}
