import React from "react";
import Table from "../../../../..//components/Table";
import Data from "../../../../../data.json";

export default function AllHistory({ fileUploadHistory }) {
  const data = React.useMemo(() => Data, []);
  const columns = React.useMemo(
    () => [
      {
        Header: "File Name",
        accessor: "filename",
      },
      {
        Header: "Uploaded Count",
        accessor: "count",
      },
      {
        Header: "Unique Count",
        accessor: "",
      },
      {
        Header: "Duplicate Count",
        accessor: "",
      },
      {
        Header: "Processor",
        accessor: "processor",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
      },
      {
        Header: "Updated At",
        accessor: "updatedAt",
      },
    ],
    []
  );
  return (
    <div>
      <div className="bg-white border p-4 fs-7">
        <div className="table-responsive table-wrapper">
          {fileUploadHistory && (
            <Table
              columns={columns}
              data={fileUploadHistory}
              tableClass="table table-borderless table-striped table-hover fs-7 mb-0"
              enableRowSelection={false}
            />
          )}
        </div>
      </div>
    </div>
  );
}
