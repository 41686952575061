import React, { useState } from "react";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import Images from "../../../assets/images/Images";
import { CSVLink } from "react-csv";
import DateRange from "../../../components/DateRange";
import AppData from "../../../config/appData.json";
import GetData from "../../../api/GetData";
import { useEffect } from "react";

export default function TransactionFilter({
  selectedStatus,
  setSelectedStatus,
  selectedProduct,
  setSelectedProduct,
  selectedLimit,
  setSelectedLimit,
  selectedAccountType,
  setSelectedAccountType,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  selectedChannel,
  setSelectedChannel,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  walletId,
  setWalletId,
  reference,
  setReference,
  handleFilter,
  handleRefresh,
  docs,
}) {
  const [products, setProducts] = useState([]);

  const getAllProducts = async () => {
    try {
      const endpoint = AppData.VAS_URL + "admin/getProviders";
      const { providers } = await GetData(endpoint);
      setProducts(providers);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <form className="row filter-data" onSubmit={handleFilter}>
      <div className="col-2 mt-3">
        <CustomSelect
          heading="Status"
          selectedVal={selectedStatus ? selectedStatus : "Select Status"}
          setSelectedValue={setSelectedStatus}
          items={[
            { name: "successful" },
            { name: "failed" },
            { name: "pending" },
          ]}
          defaultOption={{ label: "Select Status", value: "" }}
        />
      </div>
      <div className="col-2 mt-3">
        <CustomSelect
          heading="Product"
          selectedVal={selectedProduct ? selectedProduct : "Select Product"}
          setSelectedValue={setSelectedProduct}
          items={products.map((product) => ({ name: product.service }))}
          defaultOption={{ label: "Select Product", value: "" }}
        />
      </div>
      <div className="col-2 mt-3">
        <CustomSelect
          heading="Limit"
          selectedVal={selectedLimit}
          setSelectedValue={setSelectedLimit}
          items={[
            { name: "50" },
            { name: "100" },
            { name: "200" },
            { name: "300" },
            { name: "400" },
            { name: "500" },
          ]}
        />
      </div>
      <div className="col-2 mt-3">
        <CustomSelect
          heading="Channel"
          selectedVal={selectedChannel ? selectedChannel : "Select Channel"}
          setSelectedValue={setSelectedChannel}
          items={[
            { name: "linuxpos" },
            { name: "androidpos" },
            { name: "mobile" },
            { name: "web" },
            { name: "b2b" },
          ]}
          defaultOption={{ label: "Select Channel", value: "" }}
        />
      </div>
      <div className="col-2 mt-3">
        <CustomSelect
          heading="Account Type"
          selectedVal={
            selectedAccountType ? selectedAccountType : "Select Account Type"
          }
          setSelectedValue={setSelectedAccountType}
          items={[{ name: "Prepaid" }, { name: "Postpaid" }]}
          defaultOption={{ label: "Select Account Type", value: "" }}
        />
      </div>
      <div className="col-2 mt-3">
        <CustomSelect
          heading="Payment Method"
          selectedVal={
            selectedPaymentMethod ? selectedPaymentMethod : "Select Method"
          }
          setSelectedValue={setSelectedPaymentMethod}
          items={[{ name: "cash" }, { name: "card" }]}
          defaultOption={{ label: "Select Payment Method", value: "" }}
        />
      </div>
      <div className="col-2 mt-3 grouped-input">
        <label>Wallet ID</label>
        <input
          type="text"
          name="walletId"
          className="form-control"
          placeholder="Enter Wallet ID"
          value={walletId}
          onChange={(e) => setWalletId(e.target.value)}
        />
      </div>
      <div className="col-2 mt-3 grouped-input">
        <label>Reference</label>
        <input
          type="text"
          name="reference"
          className="form-control"
          placeholder="Enter Reference"
          value={reference}
          onChange={(e) => setReference(e.target.value)}
        />
      </div>
      <div className="col-3 mt-3">
        <div className="d-flex">
          <div className="date-filter">
            <p>Filter by Date: </p>
            <DateRange
              startDate={startDate}
              endDate={endDate}
              handleStartDate={(date) => setStartDate(date)}
              handleEndDate={(date) => setEndDate(date)}
            />
          </div>
        </div>
      </div>
      <div className="col mt-3">
        <button className="btn btn-filter" type="submit">
          Filter
        </button>
      </div>
      <div className="col mt-3">
        <button
          className="btn btn-refresh ms-auto"
          type="button"
          onClick={handleRefresh}
        >
          Refresh
        </button>
      </div>
      <div className="col mt-3">
        {docs && (
          <CSVLink data={docs} filename="History" className="downloads">
            <img src={Images.download} alt="download" />
          </CSVLink>
        )}
      </div>
    </form>
  );
}
