import React, { useState, useEffect } from "react";
import "./About.css";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AppData from "../../config/appData.json";
import HandlePostApi from "../../api/HandlePostApi";
import GroupedInput from "../../components/GroupedInput";
import NaijaStates from "naija-state-local-government";

export default function About() {
  const navigate = useNavigate();
  const url = AppData.VAS_URL + "auth/update-user-details";
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("");
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedLga, setSelectedLga] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [lgas, setLgas] = useState([]);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const schema = yup.object().shape({
    bvn: yup
      .string()
      .min(11, "BVN must be exactly 11 characters long")
      .max(11, "BVN must be exactly 11 characters long")
      .required("BVN is required"),
    nin: yup
      .string()
      .min(11, "NIN must be exactly 11 characters long")
      .max(11, "NIN must be exactly 11 characters long")
      .required("NIN is required"),
    dateOfBirth: yup.string().required("Date of Birth is required"),
    address: yup.string().required("Address is required"),
    businessName: yup.string().required("Business Name is required"),
    officeAddress: yup.string().required("Office Address is required"),
    officeState: yup
      .string()
      .required("Office State is required")
      .default(selectedState),
    officeLga: yup
      .string()
      .required("Office LGA is required")
      .default(selectedLga),
    gender: yup.string().required("Gender is required").default(selectedGender),
    nokFullName: yup.string().required("Next of Kin's Full Name is required"),
    nokEmail: yup
      .string()
      .email("Invalid Email Address")
      .required("Email Address is required"),
    nokPhone: yup
      .string()
      .matches(phoneRegExp, "Invalid Phone Number")
      .required("Phone Number is required"),
    guarantorsFullName: yup
      .string()
      .required("Guarantor's Full Name is required"),
    guarantorsProfession: yup
      .string()
      .required("Guarantor's Profession is required"),
    guarantorsEmail: yup
      .string()
      .email("Invalid Email Address")
      .required("Email Address is required"),
    guarantorsResidence: yup
      .string()
      .required("Guarantor's Residence is required"),
    guarantorsOffice: yup.string().required("Guarantor's Office is required"),
    guarantorsPhone: yup
      .string()
      .matches(phoneRegExp, "Invalid Phone Number")
      .required("Phone Number is required"),
    guarantorsRelationship: yup
      .string()
      .required("Guarantor's Relationship is required"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleDataSubmit = (data) => {
    HandlePostApi(url, data, setErr).then((result) => {
      const { errors, message } = result;
      if (errors) {
        setErr(true);
        setMsg(message[0]);
        return;
      }
      navigate("/onboard/upload");
    });
  };

  useEffect(() => {
    setStates(() => NaijaStates.states());
  }, []);

  const handleStateChange = (e) => {
    const state = e.target.value;
    setSelectedState(state);

    setLgas(NaijaStates.lgas(state).lgas);
  };

  const handleLgaChange = (e) => {
    const lga = e.target.value;
    setSelectedLga(lga);
  };

  const handleGenderChange = (e) => {
    const gender = e.target.value;
    setSelectedGender(gender);
  };

  return (
    <div className="onboard">
      <Header />
      <main>
        <div className="about-agent">
          <h2>About You</h2>
          <form onSubmit={handleSubmit(handleDataSubmit)}>
            <a href="/user">
              <button type="button" className="btn skip">
                Skip
              </button>
            </a>
            <div className="row personal-info">
              <div className="col">
                <h3>Personal Information</h3>
                <p>Please provide your personal info</p>
              </div>
              <div className="col form-data">
                <div className="row">
                  <div className="col-12 mb-2">
                    <GroupedInput
                      label="organization name"
                      placeholder="organization name"
                      register={register}
                      name="businessName"
                      error={errors.businessName?.message}
                      required={true}
                    />
                  </div>
                  <div className="col-12 mb-2">
                    <GroupedInput
                      label="Address"
                      placeholder="Address"
                      register={register}
                      name="address"
                      error={errors.address?.message}
                      required={true}
                    />
                  </div>
                  <div className="col-6 mb-2">
                    <GroupedInput
                      label="Full Name"
                      placeholder="Full Name"
                      register={register}
                      name="nokFullName"
                      error={errors.nokFullName?.message}
                      required={true}
                    />
                  </div>
                  <div className="col-6 mb-2">
                    <GroupedInput
                      label="Email"
                      placeholder="Email"
                      register={register}
                      name="nokEmail"
                      error={errors.nokEmail?.message}
                      required={true}
                    />
                  </div>
                  <div className="col-12 mb-2">
                    <GroupedInput
                      label="Office Address"
                      placeholder="Office Address"
                      register={register}
                      name="officeAddress"
                      error={errors.officeAddress?.message}
                      required={true}
                    />
                  </div>
                  <div className="col-6 mb-2">
                    <div
                      className={
                        errors.officeState
                          ? "grouped-input grouped-input-error"
                          : "grouped-input"
                      }
                    >
                      <div className="for-select">
                        <div>
                          <label>
                            Office State <small>*</small>
                          </label>
                          <select
                            id="state"
                            className="form-control"
                            value={selectedState}
                            onChange={handleStateChange}
                          >
                            <option value="">Select a state</option>
                            {states.map((state) => (
                              <option key={state} value={state}>
                                {state}
                              </option>
                            ))}
                          </select>
                        </div>
                        <i className="fa-solid fa-angle-down"></i>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    {lgas.length > 0 && (
                      <div
                        className={
                          errors.officeLga
                            ? "grouped-input grouped-input-error"
                            : "grouped-input"
                        }
                      >
                        <div className="for-select">
                          <div>
                            <label>
                              Local Govt Area <small>*</small>
                            </label>
                            <select
                              id="lga"
                              className="form-control"
                              value={selectedLga}
                              onChange={handleLgaChange}
                            >
                              <option value="">Select an LGA</option>
                              {lgas.map((lga) => (
                                <option key={lga} value={lga}>
                                  {lga}
                                </option>
                              ))}
                            </select>
                          </div>
                          <i className="fa-solid fa-angle-down"></i>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row id-info">
              <div className="col">
                <h3>Identification Infomation</h3>
                <p>Please provide your identification info</p>
              </div>
              <div className="col form-data">
                <div className="row">
                  <div className="col-12 mb-2">
                    <GroupedInput
                      label="National Identification Number (NIN)"
                      placeholder="1 2 3 4 5 6 7 8 9 0"
                      register={register}
                      name="nin"
                      error={errors.nin?.message}
                      required={true}
                    />
                  </div>
                  <div className="col-12 mb-2">
                    <GroupedInput
                      label="Bank Verification number (BVN)"
                      placeholder="1 2 3 4 5 6 7 8 9 0"
                      register={register}
                      name="bvn"
                      error={errors.bvn?.message}
                      required={true}
                    />
                  </div>
                  <div className="col mb-2">
                    <GroupedInput
                      type="date"
                      label="Date of Birth"
                      placeholder="MM-DD-YY"
                      register={register}
                      name="dateOfBirth"
                      error={errors.dateOfBirth?.message}
                      required={true}
                    />
                  </div>
                  <div className="col mb-2">
                    <div
                      className={
                        errors.gender
                          ? "grouped-input grouped-input-error"
                          : "grouped-input"
                      }
                    >
                      <div className="for-select">
                        <div>
                          <label>
                            Gender <small>*</small>
                          </label>
                          <select
                            id="gender"
                            className="form-control"
                            value={selectedGender}
                            onChange={handleGenderChange}
                          >
                            <option value="">Select gender</option>
                            <option value="male">male</option>
                            <option value="female">female</option>
                          </select>
                        </div>
                        <div>
                          <i className="fa-solid fa-angle-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-5 mb-2">
                    <GroupedInput
                      label="Phone number associated with BVN"
                      placeholder="09023456789"
                      register={register}
                      name="nokPhone"
                      error={errors.nokPhone?.message}
                      required={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row guarantor">
              <div className="col">
                <h3>Gurantors Information</h3>
                <p>Please provide your guarantors info</p>
              </div>
              <div className="col form-data">
                <div className="row">
                  <div className="col-12 mb-2">
                    <GroupedInput
                      label="Full Name"
                      placeholder="Ademola Olatunde"
                      register={register}
                      name="guarantorsFullName"
                      error={errors.guarantorsFullName?.message}
                      required={true}
                    />
                  </div>
                  <div className="col-6 mb-2">
                    <GroupedInput
                      label="Email Address"
                      placeholder="Email Address"
                      register={register}
                      name="guarantorsEmail"
                      error={errors.guarantorsEmail?.message}
                      required={true}
                    />
                  </div>
                  <div className="col-6 mb-2">
                    <GroupedInput
                      label="Occupation"
                      placeholder="Occupation"
                      register={register}
                      name="guarantorsProfession"
                      error={errors.guarantorsProfession?.message}
                      required={true}
                    />
                  </div>
                  <div className="col-12 mb-2">
                    <GroupedInput
                      label="Office"
                      placeholder="Office"
                      register={register}
                      name="guarantorsOffice"
                      error={errors.guarantorsOffice?.message}
                      required={true}
                    />
                  </div>
                  <div className="co-12 mb-2">
                    <GroupedInput
                      label="Residential Address"
                      placeholder="Residential Address"
                      register={register}
                      name="guarantorsResidence"
                      error={errors.guarantorsResidence?.message}
                      required={true}
                    />
                  </div>
                  <div className="col-6 mb-2">
                    <GroupedInput
                      label="Relationship"
                      placeholder="Relationship"
                      register={register}
                      name="guarantorsRelationship"
                      error={errors.guarantorsRelationship?.message}
                      required={true}
                    />
                  </div>
                  <div className="col-6 mb-2">
                    <GroupedInput
                      label="Phone Number"
                      placeholder="Phone Number"
                      register={register}
                      name="guarantorsPhone"
                      error={errors.guarantorsPhone?.message}
                      required={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="operations">
              <button type="submit" className="btn">
                Save & Continue
              </button>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
}
