import React from "react";
import "./TabView.css";
import Tab from "./Tab";

export default function TabView({ tabs = {}, resetParams }) {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (index) => {
    resetParams && resetParams();
    setActiveTab(index);
  };

  return (
    <div>
      <div className="d-flex">{tabs[activeTab].param}</div>
      {Object.keys(tabs).length === 0 ? (
        <div>No Tabs</div>
      ) : (
        <div className="tab-view">
          <div className="tab-item">
            <ul className="nav nav-pills">
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  index={index}
                  activeTab={activeTab}
                  tabClass={"nav-link"}
                  activeTabClass={"active nav-active nav-link"}
                  tabName={tab.name}
                  handleTabChange={() => handleTabChange(index)}
                />
              ))}
            </ul>
          </div>
          <div className="content">{tabs[activeTab].content}</div>
        </div>
      )}
    </div>
  );
}
