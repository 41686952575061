"use client";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./TargetStrategies.css";
import targetIcon from "../../../assets/images/goal.svg";
import revenueIcon from "../../../assets/images/revenue.svg";
import agentsIcon from "../../../assets/images/agents.svg";
import Modal from "../../../components/modal/Modal";
import AppData from "../../../config/appData.json";
import GetData from "../../../api/GetData";
import TargetForm from "./TargetForm";

const TargetSummary = ({ boxClass, icon, lastWeekGoal, heading, value }) => {
  return (
    <div className={boxClass}>
      <div className="d-flex">
        <img src={icon} alt="icon" />
        <div className="last-week-goal">
          <h6>Last Week Goal</h6>
          <p>{lastWeekGoal}</p>
        </div>
      </div>
      <h3>{heading}</h3>
      {value}
    </div>
  );
};

export default function TargetStrategies() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [targetSummary, setTargetSummary] = useState({});
  const navigate = useNavigate();

  const handlePageRoute = () => {
    navigate("all");
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const getTargetSummary = async () => {
    try {
      const endpoint = AppData.VAS_URL + "admin/target-summary";
      const response = await GetData(endpoint);
      setTargetSummary(response.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getTargetSummary();
  }, []);

  const { Engagement, accomplished, goal, onGoingAmount, revenue } =
    targetSummary;

    // console.log("summary", goal)

  return (
    <div className="d-flex">
      <TargetSummary
        boxClass="target-strat target-goal"
        icon={targetIcon}
        lastWeekGoal="+6.0%"
        heading="Target Goals"
        value={
          <div className="value">
            <p>{goal?.toLocaleString()}%</p>
            <div className="progress mb-1">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="70"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${goal}%` }}
              ></div>
            </div>
            Weekly Goal
          </div>
        }
      />
      <TargetSummary
        boxClass="target-strat target-revenue"
        icon={revenueIcon}
        lastWeekGoal="+30%"
        heading="Revenue"
        value={
          <div className="value">
            <p>₦ {revenue?.toLocaleString()}</p>
          </div>
        }
      />
      <TargetSummary
        boxClass="target-strat target-agent"
        icon={agentsIcon}
        lastWeekGoal="-4.5%"
        heading="Agents"
        value={
          <div className="value">
            <div className="row">
              <div className="col">
                <div className="current-engagement">
                  <h6>Current Engagements</h6>
                  <p>{Engagement}</p>
                </div>
              </div>
              <div className="col">
                <div className="accomplished">
                  <h6>Target Accomplished</h6>
                  <p>{accomplished}</p>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <div className="d-flex flex-column">
        <button className="btn more-info"onClick={handlePageRoute}>View More Info</button>
        <button className="target-btn" onClick={openModal}>
          Set Target Now
        </button>
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          content={<ModalContent setIsModalOpen={setIsModalOpen} />}
        />
      )}
    </div>
  );
}

const ModalContent = ({ setIsModalOpen }) => {
  const [openTargetForm, setOpenTargetForm] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const TargetTypeOption = ({
    option,
    selectedOption,
    handleOptionChange,
    label,
    description,
  }) => {
    const isActive = selectedOption === option;

    return (
      <div className={`col`}>
        <div className={isActive ? "active-target target-type" : "target-type"}>
          <input
            type="radio"
            name="targetType"
            checked={isActive}
            onChange={() => handleOptionChange(option)}
          />
          <p>{label}</p>
          <p>{description}</p>
        </div>
      </div>
    );
  };

  const handleOpenTargetForm = () => {
    setOpenTargetForm(true);
  };

  return (
    <>
      {!openTargetForm ? (
        <>
          <h6>Select A Type Of Target You Want To Set</h6>
          <div className="row mt-4 mb-4">
            <TargetTypeOption
              option="single"
              selectedOption={selectedOption}
              handleOptionChange={handleOptionChange}
              label="Single Target"
              description="Create a link to receive one-time payment from your customers"
            />
            <TargetTypeOption
              option="multiple"
              selectedOption={selectedOption}
              handleOptionChange={handleOptionChange}
              label="Multiple Target"
              description="Create a link to receive one-time payment from your customers"
            />
            <TargetTypeOption
              option="custom"
              selectedOption={selectedOption}
              handleOptionChange={handleOptionChange}
              label="Custom Target"
              description="Create a link to receive one-time payment from your customers"
            />
          </div>
          {selectedOption != null && (
            <div className="d-flex align-items-center justify-content-center">
              <button
                className="target-btn"
                onClick={handleOpenTargetForm}
                disabled={selectedOption === null}
              >
                Create Target +
              </button>
            </div>
          )}
        </>
      ) : (
        <TargetForm setIsModalOpen={setIsModalOpen} selectedOption={selectedOption}/>
      )}
    </>
  );
};
