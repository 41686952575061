// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboard {
    padding: 30px 50px;
    background-color: #ffffff;
    height: 100%;
    color: #A0A0A0;
}

.onboard .nav {
    border-bottom: 2px solid #EBEBEB;
}

.onboard h1 {
    font-size: 18px;
    padding-bottom: 17px;
    margin-bottom: 0;
    padding-right: 250px;
    border-bottom: 3px solid #3C68FA;
} 

.onboard .logo {
    height: 60px;
    margin-bottom: 10px;
}

.onboard ul {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    margin-right: 60px;
}

.onboard .number {
    background-color: #797979;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    text-align: center;
    color: #ffffff;
    margin-right: 5px;
}

.onboard .nav-item {
    display: flex;
    gap: 10px;
}

.onboard .one {
    padding-left: 250px;
}

.operations {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-right: 80px;
    margin-bottom: 50px;
}

.operations .btn {
    background-color: #060D28;
    color: #ffffff;
    padding: 0 30px;
}

.operations .skip {
    background-color: #ffffff;
    color: #060D28;
}
`, "",{"version":3,"sources":["webpack://./src/view/onboarding/AgentOnboarding.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,eAAe;IACf,oBAAoB;IACpB,gBAAgB;IAChB,oBAAoB;IACpB,gCAAgC;AACpC;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,SAAS;IACT,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":[".onboard {\n    padding: 30px 50px;\n    background-color: #ffffff;\n    height: 100%;\n    color: #A0A0A0;\n}\n\n.onboard .nav {\n    border-bottom: 2px solid #EBEBEB;\n}\n\n.onboard h1 {\n    font-size: 18px;\n    padding-bottom: 17px;\n    margin-bottom: 0;\n    padding-right: 250px;\n    border-bottom: 3px solid #3C68FA;\n} \n\n.onboard .logo {\n    height: 60px;\n    margin-bottom: 10px;\n}\n\n.onboard ul {\n    display: flex;\n    gap: 30px;\n    flex-wrap: wrap;\n    padding-left: 0;\n    margin-bottom: 0;\n    list-style: none;\n    margin-right: 60px;\n}\n\n.onboard .number {\n    background-color: #797979;\n    height: 20px;\n    width: 20px;\n    border-radius: 50%;\n    text-align: center;\n    color: #ffffff;\n    margin-right: 5px;\n}\n\n.onboard .nav-item {\n    display: flex;\n    gap: 10px;\n}\n\n.onboard .one {\n    padding-left: 250px;\n}\n\n.operations {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    gap: 20px;\n    margin-right: 80px;\n    margin-bottom: 50px;\n}\n\n.operations .btn {\n    background-color: #060D28;\n    color: #ffffff;\n    padding: 0 30px;\n}\n\n.operations .skip {\n    background-color: #ffffff;\n    color: #060D28;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
