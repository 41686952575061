import React, { useState, useRef } from "react";
import "./SettlementUpload.css";
import TabView from "../../../../../components/TabView";
import AllHistory from "./AllHistory";
import ChangeUpsl from "./ChangeUpsl";
import Interswitch from "./Interswitch";
import NewUpsl from "./NewUpsl";
import UpdateUpsl from "./UpdateUpsl";
import document from "../../../../../assets/images/doc.png";
import PostData from "../../../../../api/PostData";
import AppData from "../../../../../config/appData.json";

export default function SettlementUpload({ fileUploadHistory }) {
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("");
  const [files, setFiles] = useState(null);
  const inputRef = useRef();
  const url = AppData.BASE_URL + "settlements/uploads";
  const data = {
    pid: "pid",
    itemCount: "itemCount",
    filename: "filename",
    processor: "processor",
    data: "data",
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setFiles(e.dataTransfer.files);
  };

  const UploadFile = (e) => {
    e.preventDefault();
    PostData(url, data).then((result) => {
      const { error, message } = result;
      if (error) {
        setErr(true);
        setMsg(message[0]);
        return;
      }
    });
  };

  return (
    <div>
      <div className="file-upload">
        <div className="file-upload-card">
          <div
            className="file-upload-area"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={() => inputRef.current.click()}
          >
            <input
              type="file"
              accept=".xlsx, .csv"
              onChange={(e) => setFiles(e.target.files)}
              hidden
              ref={inputRef}
            />
            <div className="d-flex justify-content-center">
              <img src={document} alt="document" />
            </div>
            <p className="text-center mt-2">
              Select An Excel File To Upload <br /> Or Drag File Here
            </p>
          </div>
        </div>
      </div>
      {/* )} */}
      <TabView
        tabs={[
          {
            name: "All History",
            content: <AllHistory fileUploadHistory={fileUploadHistory.data} />,
          },
          { name: "Upsl New", content: <NewUpsl /> },
          { name: "Upsl New Updated", content: <UpdateUpsl /> },
          { name: "Upsl Change", content: <ChangeUpsl /> },
          { name: "Interswitch", content: <Interswitch /> },
        ]}
      />
    </div>
  );
}
