import { useEffect, useState } from "react";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AppData from "../../../config/appData.json";
import handlePostApi from "../../../api/HandlePostApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GetData from "../../../api/GetData";

export default function TargetForm({ setIsModalOpen, selectedOption }) {
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("Select");
  const [products, setProducts] = useState();
  const [selectedProduct, setSelectedProduct] = useState("Select");

  const emailOptions = agents.map((agent) => agent.email).sort();
  const productOptions = products?.map((product) => product.service).sort();

  const schema = yup.object().shape({
    volume: yup.string().required("Volume is required"),
    count: yup.string().required("Count is required"),
    product: yup
      .string()
      .required("Product is required")
      .default(selectedProduct),
    duration: yup.string().required("Duration is required"),
    minimumAmount: yup.string().required("Minimum Amount is required"),
    email:
      selectedOption === "single"
        ? yup
            .string()
            .email("Invalid email address")
            .required("Email is required")
        : yup.string().notRequired(),
    users:
      selectedOption === "multiple"
        ? yup
            .string()
            .required("Emails are required")
            .test("valid-emails", "Invalid email addresses", function (value) {
              if (!value) return true; // If value is empty, it's not an error
              const emails = value.split(",").map((email) => email.trim());
              return emails.every((email) =>
                yup.string().email().isValidSync(email)
              );
            })
        : yup.string().notRequired(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (requestData) => {
    if (selectedOption === "multiple") {
      // Split the comma-separated email string into an array
      requestData.users = requestData.users
        .split(",")
        .map((email) => email.trim());
    }
    try {
      // console.log("requestData", requestData);

      let endpoint;

      if (selectedOption === "single") {
        endpoint = AppData.VAS_URL + "admin/create/single-target";
      } else if (selectedOption === "multiple") {
        // Define the endpoint for the "multiple" option
        endpoint = AppData.VAS_URL + "admin/create/multiple-target";
      } else {
        // Define the endpoint for other options
        endpoint = AppData.VAS_URL + "admin/create/target";
      }

      await handlePostApi(endpoint, requestData);
      toast("target has been sucessully created");
    } catch (error) {
      console.error("Error", error);
      toast("Error creating target");
    }
  };

  const fetchAgents = async () => {
    try {
      const url = AppData.WALLET_URL + "admin/get-users";
      const requestData = {
        page: 1,
        agentType: "agent",
        email: "",
        username: "",
      };
      const { users } = await handlePostApi(url, requestData);
      setAgents(users.docs);
      console.log(users.docs);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAgents();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const endpoint = AppData.VAS_URL + "admin/getProviders";
        const response = await GetData(endpoint);
        setProducts(response.providers);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProducts();
  });

  return (
    <div className="raise-charge-modal target-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-container">
          <div className="header">{selectedOption} Target</div>
          <div className="row p-4">
            <div className="col-4 mb-3">
              <div className="grouped-input">
                <label>Volume</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="NGN 0.00"
                  {...register("volume")}
                />
              </div>
              <small>{errors.volume?.message}</small>
            </div>
            <div className="col-4 mb-3">
              <div className="grouped-input">
                <label>Count</label>
                <input
                  type="tel"
                  className="form-control"
                  placeholder="0.00"
                  {...register("count")}
                />
              </div>
              <small>{errors.count?.message}</small>
            </div>
            {/* <div className="col-4 mb-3">
              <div className="grouped-input">
                <label>Product</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Mtnvtu"
                  {...register("product")}
                />
              </div>
              <small>{errors.product?.message}</small>
            </div> */}
            <div className="col-4 mb-3">
              <CustomSelect
                heading="Product"
                selectedVal={selectedProduct}
                setSelectedValue={setSelectedProduct}
                items={productOptions}
              />
              <small>{errors.product?.message}</small>
            </div>
            <div className="col-4 mb-3">
              <div className="grouped-input">
                <label>Duration</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="2023-10-05"
                  {...register("duration")}
                />
              </div>
              <small>{errors.duration?.message}</small>
            </div>
            <div className="col-4 mb-3">
              <div className="grouped-input">
                <label>Minimum Amount</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="50.00"
                  {...register("minimumAmount")}
                />
              </div>
              <small>{errors.minimumAmount?.message}</small>
            </div>
            {selectedOption === "single" && (
              // <div className="col-4 mb-3">
              //   <CustomSelect
              //     heading="Email"
              //     selectedVal={selectedAgent}
              //     setSelectedValue={setSelectedAgent}
              //     items={emailOptions}
              //   />
              // </div>
              <div className="col-4 mb-3">
                <div className="grouped-input">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="example@example.com"
                    {...register("email")}
                  />
                </div>
                <small>{errors.email?.message}</small>
              </div>
            )}
            {selectedOption === "multiple" && (
              <div className="col-4 mb-3">
                <div className="grouped-input">
                  <label>Emails (comma-separated)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="example@example.com, example@example.com"
                    {...register("users")}
                  />
                </div>
                <small>{errors.users?.message}</small>
              </div>
            )}
          </div>
        </div>
        <div className="form-footer">
          <div className="charge-action mt-3">
            <button
              className="action-btn cancel"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </button>
            <button className="action-btn create" type="submit">
              Set Target
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}
