// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-pass-modal .modal-content {
  border-radius: 6px;
  padding: 0;
  width: 40%;
  text-align: start;
}

.change-pass-modal .modal-heading {
  padding: 20px;
}

.change-pass-modal .modal-body {
  background-color: #f5f5f5;
  padding: 0;
}

.change-pass-modal .pass-modal-label {
  font-size: 11px;
}

.change-pass-modal .modal-body p {
  padding: 10px 20px;
}

.change-pass-modal .modal-body p span {
  color: #3383f4;
  font-size: 14px;
}

.change-pass-modal .otp-inputs input {
  background-color: #ffffff;
}

.change-pass-modal .form-actions {
  margin-top: 30px;
  background-color: #ffffff;
  padding: 15px;
  display: flex;
}

.change-pass-modal .form-actions .continue {
  margin-left: auto;
  background-color: #2300e5;
  color: #ffffff;
  border: none;
  box-shadow: 0px 3px 6px #00000019;
  border-radius: 8px;
  padding: 10px;
}

.change-pass-modal .form-actions .cancel-btn {
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000019;
  border: 1px solid #cecece;
  border-radius: 8px;
  padding: 10px;
}

.change-pass-modal input {
  border: 1px solid #d8d8d8;
}

.change-pass-modal .pwd-checker {
  padding-inline: 15px;
}

.change-pass-modal .check-text {
  padding: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/view/modal/ChangePassModal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,UAAU;AACZ;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,aAAa;EACb,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,iCAAiC;EACjC,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,iCAAiC;EACjC,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".change-pass-modal .modal-content {\n  border-radius: 6px;\n  padding: 0;\n  width: 40%;\n  text-align: start;\n}\n\n.change-pass-modal .modal-heading {\n  padding: 20px;\n}\n\n.change-pass-modal .modal-body {\n  background-color: #f5f5f5;\n  padding: 0;\n}\n\n.change-pass-modal .pass-modal-label {\n  font-size: 11px;\n}\n\n.change-pass-modal .modal-body p {\n  padding: 10px 20px;\n}\n\n.change-pass-modal .modal-body p span {\n  color: #3383f4;\n  font-size: 14px;\n}\n\n.change-pass-modal .otp-inputs input {\n  background-color: #ffffff;\n}\n\n.change-pass-modal .form-actions {\n  margin-top: 30px;\n  background-color: #ffffff;\n  padding: 15px;\n  display: flex;\n}\n\n.change-pass-modal .form-actions .continue {\n  margin-left: auto;\n  background-color: #2300e5;\n  color: #ffffff;\n  border: none;\n  box-shadow: 0px 3px 6px #00000019;\n  border-radius: 8px;\n  padding: 10px;\n}\n\n.change-pass-modal .form-actions .cancel-btn {\n  background-color: #ffffff;\n  box-shadow: 0px 3px 6px #00000019;\n  border: 1px solid #cecece;\n  border-radius: 8px;\n  padding: 10px;\n}\n\n.change-pass-modal input {\n  border: 1px solid #d8d8d8;\n}\n\n.change-pass-modal .pwd-checker {\n  padding-inline: 15px;\n}\n\n.change-pass-modal .check-text {\n  padding: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
