import React from "react";
import "./AgentOnboarding.css";
import spoutLogo from "../../assets/images/spout-logo.png";

export default function Header() {
  return (
    <header>
      <div>
        <img className="logo" src={spoutLogo} alt="spoutPay" />
      </div>
      <div className="nav">
        <h1>Agent Onboarding</h1>
        <ul>
          <li className="nav-item one">
            <div className="number">1</div>
            About you
          </li>
          <li className="nav-item">
            <div className="number">2</div>
            Document Upload
          </li>
          <li className="nav-item">
            <div className="number">3</div>
            Get Started
          </li>
        </ul>
      </div>
    </header>
  );
}
