// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terminal-up-time .terminal-time-summary {
  padding: 35px 35px 25px;
  border-radius: 10px;
  color: #fff;
}

.terminal-up-time .terminal-time-summary h6 {
  font-weight: 100;
}

.terminal-up-time .terminal-time-summary .yearly {
  font-size: 24px;
  margin: 0;
  font-weight: 600;
}

.terminal-up-time .terminal-time-summary .hourly {
  color: #d9d9d9;
  font-weight: 100;
  font-size: 12px;
  margin-bottom: 0;
}

.terminal-up-time .performance-indicators {
  background-color: #ffffff;
  padding: 20px 15px;
  width: 600px;
  margin-left: auto;
  border-radius: 8px;
}

.terminal-up-time .performance-indicators .performance {
  background-color: #f4f4f4;
  text-align: center;
  padding: 16px;
  margin-bottom: 20px;
}

.terminal-up-time .performance-indicators .performance img {
  height: 40px;
  width: 40px;
}

.terminal-up-time .performance-indicators .performance .count {
  font-size: 24px;
  font-weight: 600;
  color: #002e5b;
  margin-top: 10px;
  margin-bottom: 0;
}

.terminal-up-time .performance-indicators .performance .p-label {
  font-size: 12px;
  color: #5f5f5f;
}
`, "",{"version":3,"sources":["webpack://./src/view/reporting-dashboard/target/TerminalUpTime.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":[".terminal-up-time .terminal-time-summary {\n  padding: 35px 35px 25px;\n  border-radius: 10px;\n  color: #fff;\n}\n\n.terminal-up-time .terminal-time-summary h6 {\n  font-weight: 100;\n}\n\n.terminal-up-time .terminal-time-summary .yearly {\n  font-size: 24px;\n  margin: 0;\n  font-weight: 600;\n}\n\n.terminal-up-time .terminal-time-summary .hourly {\n  color: #d9d9d9;\n  font-weight: 100;\n  font-size: 12px;\n  margin-bottom: 0;\n}\n\n.terminal-up-time .performance-indicators {\n  background-color: #ffffff;\n  padding: 20px 15px;\n  width: 600px;\n  margin-left: auto;\n  border-radius: 8px;\n}\n\n.terminal-up-time .performance-indicators .performance {\n  background-color: #f4f4f4;\n  text-align: center;\n  padding: 16px;\n  margin-bottom: 20px;\n}\n\n.terminal-up-time .performance-indicators .performance img {\n  height: 40px;\n  width: 40px;\n}\n\n.terminal-up-time .performance-indicators .performance .count {\n  font-size: 24px;\n  font-weight: 600;\n  color: #002e5b;\n  margin-top: 10px;\n  margin-bottom: 0;\n}\n\n.terminal-up-time .performance-indicators .performance .p-label {\n  font-size: 12px;\n  color: #5f5f5f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
