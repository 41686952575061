import React from "react";
import "./SimpleLoader.css";

export default function SimpleLoader() {
  return (
    <div className="loader-body">
      <div className="loader"></div>
    </div>
  );
}
