// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analysis {
    background-color: #ffffff;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 0.5px solid #CBCBCB;
    position: relative;
}

.analysis .export {
    position: absolute;
    right: 0px;
    top: -50px;
}

.analysis .analysis-param {
    position: absolute;
    top: -140px;
    left: -2px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.analysis .analysis-param .select-search select {
    border: 0.5px solid #016AA7;
    width: 130px;
    border-radius: 4px;
    padding: 9px;
    background-color: transparent;
    color: #016AA7;
}`, "",{"version":3,"sources":["webpack://./src/view/reporting-dashboard/card-transactions/transaction-dashboard/Analysis.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,oBAAoB;IACpB,2BAA2B;IAC3B,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,UAAU;IACV,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;IAC3B,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,6BAA6B;IAC7B,cAAc;AAClB","sourcesContent":[".analysis {\n    background-color: #ffffff;\n    padding-top: 20px;\n    padding-bottom: 20px;\n    border: 0.5px solid #CBCBCB;\n    position: relative;\n}\n\n.analysis .export {\n    position: absolute;\n    right: 0px;\n    top: -50px;\n}\n\n.analysis .analysis-param {\n    position: absolute;\n    top: -140px;\n    left: -2px;\n    margin-top: 20px;\n    margin-bottom: 20px;\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n}\n\n.analysis .analysis-param .select-search select {\n    border: 0.5px solid #016AA7;\n    width: 130px;\n    border-radius: 4px;\n    padding: 9px;\n    background-color: transparent;\n    color: #016AA7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
