import React from "react";
import "./GatewayReport.css";
import History from "./gateway-dashboard/History";
import Compliance from "./Compliance";
import Configuration from "./Configuration";

const Tabs = ({ tabsData = [], tabHeading }) => {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };
  return (
    <>
      <nav className="card-trans-nav">
        <h1>{tabHeading}</h1>
        <ul>
          {tabsData.map((item, index) => (
            <li
              className={index === activeTab ? "nav-item active" : "nav-item"}
              key={index}
              onClick={() => handleTabChange(index)}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </nav>
      <div className="content">{tabsData[activeTab].content}</div>
    </>
  );
};

export default function GatewayReport() {
  const tabsData = [
    {
      label: "Transaction Dashboard",
      content: <History />,
    },
    {
      label: "Compliance",
      content: <Compliance />,
    },
    {
      label: "Settlement",
      content: "",
    },
    {
      label: "Dispute Resolution",
      content: "",
    },
    {
      label: "Refund",
      content: "",
    },
    {
      label: "Configuration",
      content: <Configuration />,
    },
  ];
  return (
    <main className="col dashboard-main">
      <div className="gateway-report">
        <Tabs tabsData={tabsData} tabHeading="Gateway Report" />
      </div>
    </main>
  );
}
