// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-modal {
  width: 400px;
  padding: 10px 20px;
}

.group-modal p {
  margin-bottom: 0;
}

.group-modal .create-group-btn {
  width: 100%;
  background-color: #060d27;
  color: #ffffff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  box-shadow: none;
}

.group-modal .input-error {
  border: 1px solid #de0000;
  background-color: transparent;
}

.group-modal .err-text {
  color: #de0000;
}

.group-modal .success-text {
  color: #106745;
  margin-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/view/modal/GroupModal.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,cAAc;EACd,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,6BAA6B;AAC/B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".group-modal {\n  width: 400px;\n  padding: 10px 20px;\n}\n\n.group-modal p {\n  margin-bottom: 0;\n}\n\n.group-modal .create-group-btn {\n  width: 100%;\n  background-color: #060d27;\n  color: #ffffff;\n  padding: 10px;\n  border: none;\n  border-radius: 4px;\n  box-shadow: none;\n}\n\n.group-modal .input-error {\n  border: 1px solid #de0000;\n  background-color: transparent;\n}\n\n.group-modal .err-text {\n  color: #de0000;\n}\n\n.group-modal .success-text {\n  color: #106745;\n  margin-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
