import React, { useState } from "react";
import "./OnboardAdmin.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import HandlePostApi from "../../../api/HandlePostApi";
import AppData from "../../../config/appData.json";
import GroupedInput from "../../../components/GroupedInput";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import NaijaStates from "naija-state-local-government";
import { ToastContainer, toast } from "react-toastify";

export default function OnboardAdmin() {
  const url = AppData.VAS_URL + "admin/create-admin-user";
  const [msg, setMsg] = useState("");
  const [selectedGender, setSelectedGender] = useState("Male");
  const [adminType, setAdminType] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedLga, setSelectedLga] = useState("");
  const states = NaijaStates.states();
  const lgas = selectedState ? NaijaStates.lgas(selectedState).lgas : [];

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const schema = yup.object().shape({
    email: yup.string().email().required("Invalid Email Address!"),
    firstName: yup.string().required("Your First Name is Required!"),
    lastName: yup.string().required("Your Last Name is Required!"),
    address: yup.string().required("Field is required"),
    username: yup.string().required("Username is Required!"),
    companyName: yup.string().required("Field is required"),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid!")
      .required(),
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters!")
      .required("Password field Cannot be empty!"),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords Don't Match")
      .required("Passwords Don't Match!"),
    adminType: yup.string().required("Field is required").default(adminType),
    stateOfOrigin: yup
      .string()
      .required("Field is required")
      .default(selectedState),
    localGovernment: yup
      .string()
      .required("Field is required")
      .default(selectedLga),
    gender: yup.string().required("Field is required").default(selectedGender),
    dateOfBirth: yup.string().required("Field is required"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      await HandlePostApi(url, data);
      toast("Admin Created successfully!");
    } catch (error) {
      toast("An Error Occured!");
      console.error("Error:", error);
      setMsg(error.response.data.message);
    }
  };

  return (
    <div className="admin-onboarding mb-3">
      <div className=" mt-3">
        <p className="message">{msg && msg}</p>
        <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="row personal-info">
            <div className="col">
              <h3>Add a Member</h3>
              <p>Add a Member With Their Credentials</p>
            </div>
            <div className="col form-data">
              <div className="row">
                <div className="col-12 mb-2">
                  <GroupedInput
                    label="Email"
                    placeholder="Email"
                    register={register}
                    name="email"
                    error={errors.email?.message}
                    required={true}
                  />
                </div>
                <div className="col-6 mb-2">
                  <GroupedInput
                    label="First Name"
                    placeholder="First Name"
                    register={register}
                    name="firstName"
                    error={errors.firstName?.message}
                    required={true}
                  />
                </div>
                <div className="col-6 mb-2">
                  <GroupedInput
                    label="Last Name"
                    placeholder="Last Name"
                    register={register}
                    name="lastName"
                    error={errors.lastName?.message}
                    required={true}
                  />
                </div>
                <div className="col-3 mb-2">
                  <CustomSelect
                    heading="Gender"
                    selectedVal={selectedGender}
                    setSelectedValue={setSelectedGender}
                    items={[
                      {
                        name: "Male",
                      },
                      {
                        name: "Female",
                      },
                    ]}
                  />
                </div>
                <div className="col-4 mb-2">
                  <GroupedInput
                    type="date"
                    label="Date of Birth"
                    placeholder="MM-DD-YY"
                    register={register}
                    name="dateOfBirth"
                    error={errors.dateOfBirth?.message}
                    required={true}
                  />
                </div>
                <div className="col-5 mb-2">
                  <GroupedInput
                    label="Phone Number"
                    placeholder="09023456789"
                    register={register}
                    name="phoneNumber"
                    error={errors.phoneNumber?.message}
                    required={true}
                  />
                </div>
                <div className="col-6 mb-2">
                  <GroupedInput
                    label="Username"
                    placeholder="Username"
                    register={register}
                    name="username"
                    error={errors.username?.message}
                    required={true}
                  />
                </div>
                <div className="col-6 mb-2">
                  <CustomSelect
                    heading="Admin Type"
                    selectedVal={adminType ? adminType : "Select Admin Type"}
                    setSelectedValue={setAdminType}
                    items={[
                      {
                        name: "admin",
                      },
                      {
                        name: "super-admin",
                      },
                      {
                        name: "financial-admin",
                      },
                      {
                        name: "controller",
                      },
                    ]}
                    defaultOption={{ label: "Select Admin Type", value: "" }}
                  />
                </div>
                <div className="col-12 mb-2">
                  <GroupedInput
                    label="Company Name"
                    placeholder="Company Name"
                    register={register}
                    name="companyName"
                    error={errors.companyName?.message}
                    required={true}
                  />
                </div>
                <div className="col-12 mb-2">
                  <GroupedInput
                    label="Address"
                    placeholder="Address"
                    register={register}
                    name="address"
                    error={errors.address?.message}
                    required={true}
                  />
                </div>
                <div className="col-6 mb-2">
                  <CustomSelect
                    heading="State of origin"
                    selectedVal={selectedState || "Select State"}
                    setSelectedValue={setSelectedState}
                    items={states.map((state) => ({ name: state }))}
                    defaultOption={{ label: "Select State", value: "" }}
                  />
                </div>
                <div className="col-6 mb-2">
                  <CustomSelect
                    heading="LGA"
                    selectedVal={selectedLga || "Select LGA"}
                    setSelectedValue={setSelectedLga}
                    items={lgas.map((lga) => ({ name: lga }))}
                    defaultOption={{ label: "Select LGA", value: "" }}
                  />
                </div>
                <div className="col-6 mb-2">
                  <GroupedInput
                    label="Password"
                    placeholder="Password"
                    register={register}
                    name="password"
                    error={errors.password?.message}
                    required={true}
                  />
                </div>
                <div className="col-6 mb-2">
                  <GroupedInput
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    register={register}
                    name="password_confirmation"
                    error={errors.password_confirmation?.message}
                    required={true}
                  />
                </div>
              </div>
              <div className="action">
                <div className="d-flex">
                  <button type="button" className="cancel-btn">
                    Cancel
                  </button>
                  <button type="submit" className="ms-auto add-btn">
                    Add Member
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
