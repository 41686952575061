import axios from "axios";

export default function GetData(url) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        response.data ? resolve(response.data) : resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
}
