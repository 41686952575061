import React, { useState } from "react";
import "./GroupModal.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AppData from "../../config/appData.json";
import handlePostApi from "../../api/HandlePostApi";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function GroupModal({
  setShowCreateGroupModal,
  selectedIds,
  hasAggregatorWallet,
  setSelectedIds,
}) {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [aggregators, setAggregators] = useState("");
  const [selectedAggregator, setSelectedAggregator] = useState("");
  const [email, setEmail] = useState("");
  const [walletId, setWalletId] = useState("");
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("");
  let url;
  let schema;

  // console.log("requestData", selectedIds, walletId);

  if (!hasAggregatorWallet) {
    schema = yup.object().shape({
      usersWallet: yup.array().default(selectedIds),
      email: yup
        .string()
        .email("Enter a valid Email Address")
        .required("Email is required!")
        .default(email),
      aggregatorWalletId: yup
        .string()
        .required("Wallet ID is required!")
        .default(walletId),
      nameOfGroup: yup.string().required("Enter a group name"),
    });
  } else {
    schema = yup.object().shape({
      usersWallet: yup.array().default(selectedIds),
      aggregatorWallet: yup
        .string()
        .required("Wallet ID is required!")
        .default(walletId),
      nameOfGroup: yup.string().required("Enter a group name"),
    });
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleErrorFunc = (error) => {
    if (error.response.data.message === "Expired Session, Please Login Again") {
      navigate("/signin");
    } else {
      setErr(true);
      setMsg(error.response.data.message);
    }
  };

  const handleFormSubmit = async (requestData) => {
    console.log(requestData);
    try {
      url = !hasAggregatorWallet
        ? AppData.VAS_URL + "aggregator-grouping"
        : AppData.VAS_URL + "group/reassignment";
      console.log(url, requestData);
      const response = await handlePostApi(url, requestData);
      setErr(false);
      setMessage(response.message);
      setTimeout(() => {
        setShowCreateGroupModal(false);
      }, 2000);
      setSelectedIds([]);
    } catch (error) {
      setErr(true);
      setMessage(error.response.data.message);
    }
  };

  const handleCloseModal = () => {
    setShowCreateGroupModal(false);
    setSelectedIds([]);
  };

  const fetchAggregatorList = async () => {
    try {
      const url = AppData.WALLET_URL + "admin/get-users";
      const requestData = {
        page: 1,
        agentType: "aggregator",
        email: "",
        username: "",
      };
      const { users } = await handlePostApi(url, requestData);
      setAggregators(users);
    } catch (error) {
      handleErrorFunc(error);
    }
  };

  useEffect(() => {
    fetchAggregatorList();
  }, []);

  const handleAggregatorChange = (e) => {
    const selectedAggregatorIndex = e.target.value;
    setSelectedAggregator(selectedAggregatorIndex);

    const selectedAggregator = aggregators.docs.find(
      (aggregator) => aggregator.firstName === selectedAggregatorIndex
    );

    if (selectedAggregator) {
      setEmail(selectedAggregator.email);
      setWalletId(selectedAggregator.walletId);
    } else {
      setEmail("");
      setWalletId("");
    }
  };

  const { docs } = aggregators;

  return (
    <div className="modal">
      <div className="cancel-modal" onClick={handleCloseModal}>
        X
      </div>
      <div className="modal-content group-modal">
        <div className="modal-header d-block text-start">
          <h6>{!hasAggregatorWallet ? "Assign Group " : "Reassign Group"}</h6>
          <p>
            {!hasAggregatorWallet ? "Assign" : "Reassign"} group to an agent
          </p>
        </div>
        <div className="modal-body text-start">
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            {message && (
              <small className={err ? "err-text" : "success-text"}>
                {message}
              </small>
            )}
            <div className="mb-3">
              <label htmlFor="email">Aggregator</label>
              <select
                className={
                  errors.nameOfGroup
                    ? "form-control input-error"
                    : "form-control"
                }
                value={selectedAggregator}
                onChange={handleAggregatorChange}
              >
                <option value="">Select Aggregator</option>
                {docs?.map((option, index) => (
                  <option key={index} value={option.firstName}>
                    {option.firstName} {option.lastName}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="groupName">Group</label>
              <input
                className={
                  errors.nameOfGroup
                    ? "form-control input-error"
                    : "form-control"
                }
                type="text"
                list="values"
                id="myInput"
                name="myInput"
                autoComplete="off"
                placeholder="Select or enter a group name"
                {...register("nameOfGroup")}
              />
              <datalist id="values">
                <option value="Option 1" />
                <option value="Option 2" />
                <option value="Option 3" />
              </datalist>
              {errors.nameOfGroup && (
                <small>{errors.nameOfGroup.message}</small>
              )}
            </div>
            <div>
              <button className="create-group-btn" type="submit">
                {!hasAggregatorWallet ? "Assign group" : "Reassign group"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
