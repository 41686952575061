import React from "react";
import TabView from "../../../components/TabView";
import BandConfig from "./BandConfig";
import SwitchConfig from "./SwitchConfig";
import RiskProfiling from "./RiskProfiling";
import ChargeProfiling from "./ChargeProfiling";
import ReportSetting from "./ReportSetting";

export default function Configuration() {
  return (
    <div className="gateway-config pb-3">
      <TabView
        tabs={[
          {
            name: "Band Config",
            content: <BandConfig />,
          },
          {
            name: "Switch Config",
            content: <SwitchConfig />,
          },
          {
            name: "Risk Profiling",
            content: <RiskProfiling />,
          },
          {
            name: "Charge Profiling",
            content: <ChargeProfiling />,
          },
          // {
          //   name: "Reporting Settings",
          //   content: <ReportSetting />,
          // },
        ]}
      />
    </div>
  );
}
