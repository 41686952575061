import React, { useState, useEffect } from "react";
import "./Group.css";
import Table from "../../components/Table";
import SearchInput from "../../components/SearchInput";
import security from "../../assets/images/security-icon.svg";
import CustomSelect from "../../components/customSelect/CustomSelect";
import AppData from "../../config/appData.json";
import handlePostApi from "../../api/HandlePostApi";
import Pagination from "../../components/Pagination";
import FormatDate from "../../components/FormatDate";
import Modal from "../../components/modal/Modal";
import DeleteData from "../../api/DeleteData";

export default function Group() {
  const [value, setValue] = useState("");
  const [searchParam, setSearchParam] = useState("Agent Type");
  const [group, setGroup] = useState({});
  const [pageNum, setPageNum] = useState(1);
  const [endDate, setEndDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [deleteStatus, setDeleteStatus] = useState("");

  const openModal = (groupId) => {
    setGroupId(groupId);
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: (name) => (<div className="name-link">{name.nameOfGroup}</div>),
      },
      {
        Header: "Wallet Id",
        accessor: "aggregatorWalletId",
      },
      {
        Header: "Members",
        accessor: (agents) => <>{agents?.usersWallet?.length}</>,
      },
      {
        Header: "Created At",
        accessor: (date) => FormatDate(date.createdAt),
      },
      {
        Header: "",
        accessor: "_id",
        Cell: (tableInstance) => {
          const { row: index } = tableInstance;
          const groupId = index.original._id;
          return (
            <button
              className="grp-btn del-btn"
              onClick={() => openModal(groupId)}
            >
              Delete
            </button>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    const fetchGroupMembers = async () => {
      try {
        const endpoint = AppData.VAS_URL + "get/aggregator/groups";
        const requestData = {
          page: "1",
          limit: 50,
          pageNumber: 1,
          startDate: "2023-06-01",
          endDate: endDate,
        };
        const { data } = await handlePostApi(endpoint, requestData);
        setGroup(data);
        console.log("dssf", data)
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchGroupMembers();
  }, []);

  const { docs, hasNextPage, hasPrevPage, nextPage, prevPage, totalPages } =
    group;

  return (
    <main className="col group">
      <div className="mb-5 d-flex">
        <div style={{ width: "300px" }}>
          <SearchInput value={value} setValue={setValue} />
        </div>
        <CustomSelect
          heading="Agent Type"
          selectedVal={searchParam}
          setSelectedValue={setSearchParam}
          items={[
            {
              name: "desc",
            },
            {
              name: "asc",
            },
          ]}
        />
      </div>
      {docs && (
        <Table
          columns={columns}
          data={docs}
          tableClass="table table-borderless table-striped table-hover fs-7 mb-0"
          enableRowSelection={false}
        />
      )}
      {totalPages > 1 && (
        <Pagination
          prevPage={prevPage}
          nextPage={nextPage}
          totalPages={totalPages}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          setPageNum={setPageNum}
        />
      )}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          content={
            <ModalContent
              onClose={closeModal}
              groupId={groupId}
              setIsModalOpen={setIsModalOpen}
              setDeleteStatus={setDeleteStatus}
            />
          }
        />
      )}
    </main>
  );
}

const ModalContent = ({
  onClose,
  groupId,
  setIsModalOpen,
  setDeleteStatus,
}) => {
  const handleDeleteGroup = async () => {
    try {
      const endpoint = AppData.VAS_URL + `aggregator/delete-group/${groupId}`;
      await DeleteData(endpoint, setDeleteStatus);
      setIsModalOpen(false);
      alert("Group deleted");
    } catch (error) {
      console.log("Error", error);
    }
  };
  return (
    <>
      <img className="security-icon" src={security} alt="" />
      <h1 className="fs-6 mt-2">Confirm Delete</h1>
      <p>Please confirm you will like to delete this Group</p>
      <button onClick={onClose} className="grp-btn cls-btn">
        Cancel
      </button>
      <button onClick={handleDeleteGroup} className="grp-btn del-btn">
        Delete
      </button>
    </>
  );
};
