// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vas-container {
    margin-left: 14%;
    margin-top: 30px;
}

.date-container {
    display: flex;
    justify-content: flex-start;
}

.vas-nav {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EBEBEB;
    color: #A0A0A0;
}

.vas-nav h1 {
    margin-right: 30px;
    margin-left: 0;
    font-size: 18px;
    margin-bottom: 0;
}

.vas-nav p {
    margin-right: auto;
    margin-bottom: 0;
}

.vas-nav span {
    font-weight: 600;
    color: #000000;
    margin-left: 15px;
}

.vas-nav ul {
    display: flex;
    gap: 30px;
    list-style: none;
    margin-bottom: 0;
}

.vas-nav .nav-item {
    padding: 10px;
    color: #A0A0A0;
    font-weight: 100;
    cursor: pointer;
}

.vas-nav .nav-item:hover {
    color: #2626E5;
}

.vas-nav .active{
    color: #2626E5;
    font-weight: 500;
    border-bottom: 4px solid #002E5B;
}`, "",{"version":3,"sources":["webpack://./src/view/reporting-dashboard/vas-transactions/VasTransaction.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gCAAgC;IAChC,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,gCAAgC;AACpC","sourcesContent":[".vas-container {\n    margin-left: 14%;\n    margin-top: 30px;\n}\n\n.date-container {\n    display: flex;\n    justify-content: flex-start;\n}\n\n.vas-nav {\n    display: flex;\n    align-items: center;\n    border-bottom: 1px solid #EBEBEB;\n    color: #A0A0A0;\n}\n\n.vas-nav h1 {\n    margin-right: 30px;\n    margin-left: 0;\n    font-size: 18px;\n    margin-bottom: 0;\n}\n\n.vas-nav p {\n    margin-right: auto;\n    margin-bottom: 0;\n}\n\n.vas-nav span {\n    font-weight: 600;\n    color: #000000;\n    margin-left: 15px;\n}\n\n.vas-nav ul {\n    display: flex;\n    gap: 30px;\n    list-style: none;\n    margin-bottom: 0;\n}\n\n.vas-nav .nav-item {\n    padding: 10px;\n    color: #A0A0A0;\n    font-weight: 100;\n    cursor: pointer;\n}\n\n.vas-nav .nav-item:hover {\n    color: #2626E5;\n}\n\n.vas-nav .active{\n    color: #2626E5;\n    font-weight: 500;\n    border-bottom: 4px solid #002E5B;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
