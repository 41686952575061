import React, { useState } from "react";
import TransactionFailed from "./TransactionFailed";
import TransactionSuccess from "./TransactionSuccess";
import Images from "../../assets/images/Images";
import HandlePostPayment from "../../api/HandlePostPayment";
import OTPInput from "react-otp-input";

export default function PinValidationModal({
  setShowModal,
  setMsg,
  requestData,
  serviceIcon,
  url,
  transactionPin,
  setTransactionPin,
  transType,
  info,
  extraInfo,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailedModal, setShowFailedModal] = useState(false);
  const [pinError, setPinError] = useState("");
  const [token, setToken] = useState("");
  const [receiverName, setReceiverName] = useState("");

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    // console.log("requestData", requestData);
    e.preventDefault();
    try {
      const response = await HandlePostPayment(requestData, setIsLoading, url);
      // console.log("response", response);
      if (response.data.responseCode === "00") {
        setShowSuccessModal(true);
      } else {
        setMsg(response.data.message);
        setShowFailedModal(true);
      }
      setToken(response.data.token);
      setReceiverName(response.data?.response?.beneficiaryAccountName);
    } catch (error) {
      console.log("Error occurred", error);
      setPinError(error.response.data.message || "An error occurred");
    }
  };

  return (
    <div className="modal">
      {!showSuccessModal ? (
        <>
          <div className="cancel-modal" onClick={handleCloseModal}>
            X
          </div>
          <form className="modal-content" onSubmit={handleSubmit}>
            <img src={Images.security} alt="icon" className="security-icon" />
            <h6>Enter Transaction Pin</h6>
            {transType === "airtime" && (
              <div className="airtime-trans-details">
                <div>
                  <div className="info">
                    <div className="info-label">
                      <img src={serviceIcon} />
                    </div>
                    <div className="text-start">
                      <div className="bold">{requestData.phoneNumber}</div>
                      <div>{requestData.service}</div>
                    </div>
                  </div>
                  <div className="info mt-2 mb-2">
                    <div className="info-label">Amount</div>
                    <div className="bold">N{requestData.amount}</div>
                  </div>
                </div>
              </div>
            )}
            <p>
              Enter Your Transaction Pin To Complete <br /> This Transfer
            </p>
            {pinError && <small>{pinError}</small>}
            <div className="otp-inputs">
              <OTPInput
                value={transactionPin}
                onChange={setTransactionPin}
                numInputs={4}
                renderSeparator={<span> </span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <div className="mt-4 mb-3">
              <button className="btn" type="submit">
                Complete Transfer
              </button>
            </div>
          </form>
        </>
      ) : (
        <TransactionSuccess
          info={info}
          extraInfo={token ? token : extraInfo}
          receiverName={receiverName ? `to ${receiverName}` : ""}
        />
      )}
      {showFailedModal && <TransactionFailed />}
    </div>
  );
}
