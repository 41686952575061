import React from "react";
import "./Experience.css";
import malePotrait from "../../assets/images/male-portrait.png";
import femalePotrait from "../../assets/images/female-portrait.png";
import pexel from "../../assets/images/pexels-kelly.png"

export default function Experience() {
  return (
    <div className="experience">
      <div>
        <h1>Everything You Need For The Best Experience Is Here</h1>
        <div className="row">
          <div className="col">
            <h2>Active Agents</h2>
            <p>67,000</p>
          </div>
          <div className="col">
            <h2>Active Agents</h2>
            <p>Over 35 Millions</p>
          </div>
          <div className="col">
            <h2>Active Agents</h2>
            <p>99.9% Uptime</p>
          </div>
        </div>
        <div className="testimonies">
            <div className="testifier">
                <img src={malePotrait} alt="testimonies"/>
            </div>
            <div className="female">
                <img src={femalePotrait} alt="testimonies"/>
            </div>
            <div className="testifier">
                <img src={pexel} alt="testimonies"/>
            </div>
        </div>
      </div>
    </div>
  );
}
