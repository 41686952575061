import React, { useState } from "react";
import "./Signup.css";
import AppData from "../../config/appData.json";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import welcome from "../../assets/videos/welcome.mp4";
import OtpModal from "../modal/OtpModal";
import HandlePostApi from "../../api/HandlePostApi";
import PostData from "../../api/PostData";
import Images from "../../assets/images/Images";

export default function Signup() {
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [showNin, setShowNin] = useState(false);
  const [showBvn, setShowBvn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [err, setErr] = React.useState(false);
  const [msg, setMsg] = React.useState("");

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const schema = yup.object().shape({
    email: yup.string().email().required("Invalid Email Address!"),
    firstName: yup.string().required("Your First Name is Required!"),
    lastName: yup.string().required("Your Last Name is Required!"),
    username: yup.string().required("Username is Required!"),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid!")
      .required(),
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters!")
      .required("Password field Cannot be empty!"),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords Don't Match")
      .required("Passwords Don't Match!"),
    agreement: yup
      .boolean("agree")
      .oneOf([true], "You must accept our terms and conditions to continue!")
      .required(),
    bvn: yup
      .string()
      .min(11, "BVN must be exactly 11 characters long")
      .max(11, "BVN must be exactly 11 characters long")
      .required("BVN is required"),
    nin: yup
      .string()
      .min(11, "NIN must be exactly 11 characters long")
      .max(11, "NIN must be exactly 11 characters long")
      .required("NIN is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (requestData) => {
    try {
      localStorage.setItem("email", requestData.email);

      const url = AppData.VAS_URL + "auth/sign-up";
      const response = await PostData(url, requestData);

      if (response) {
        setMsg("");
        setShowOTPModal(true);
      }
    } catch (error) {
      console.log("error", error);
      setShowOTPModal(false);
      setMsg(
        error.response?.data?.message || error.message || "Signup unsuccessful!"
      );
    }
  };

  return (
    <div className="signup">
      <div className="container">
        <div className="signup-header">
          <img src={Images.spoutLogo} alt="spoutpay" />
          <h1>You're Welcome</h1>
          <p>
            We can&apos;t wait for you to unbox the suprise we <br /> have got
            for you
          </p>
        </div>
        <div className="welcome-vid">
          <video src={welcome} autoPlay loop muted />
        </div>
        <div className="signup-section">
          <div className="home">
            <a href="/">Home</a>
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <h2>Sign up and get started</h2>
            <p>
              Please fill in the correct informations to create your account
            </p>
            {msg && <small>{msg}</small>}
            <div className="row form-data">
              <div className="col-12 mb-3 text-start">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  className={
                    errors.email ? "form-control error" : "form-control"
                  }
                  type="text"
                  placeholder="example@spoutpay.com"
                  {...register("email")}
                />
                <div>
                  <small>{errors.email?.message}</small>
                </div>
              </div>
              <div className="col-6 mb-3 mt-2 text-start">
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
                <input
                  className={
                    errors.firstName ? "form-control error" : "form-control"
                  }
                  type="text"
                  placeholder="First Name"
                  {...register("firstName")}
                />
                <div>
                  <small>{errors.firstName?.message}</small>
                </div>
              </div>
              <div className="col-6 mb-3 mt-2 text-start">
                <label htmlFor="lastName" className="form-label">
                  Last Name
                </label>
                <input
                  className={
                    errors.lastName ? "form-control error" : "form-control"
                  }
                  type="text"
                  placeholder="Last Name"
                  {...register("lastName")}
                />
                <div>
                  <small>{errors.lastName?.message}</small>
                </div>
              </div>
              <div className="col-6 mb-3 mt-2 text-start">
                <label htmlFor="username" className="form-label">
                  Username
                </label>
                <input
                  className={
                    errors.username ? "form-control error" : "form-control"
                  }
                  type="text"
                  placeholder="Username"
                  {...register("username")}
                />
                <div>
                  <small>{errors.username?.message}</small>
                </div>
              </div>
              <div className="col-6 mb-3 text-start">
                <label htmlFor="phoneNumber" className="form-label">
                  Phone Number
                </label>
                <input
                  className={
                    errors.phoneNumber ? "form-control error" : "form-control"
                  }
                  type="tel"
                  id="phone"
                  placeholder="+234 (XXXXXXXXXX)"
                  // pattern="+234(XXXXXXXXXX)"
                  {...register("phoneNumber")}
                />
                <div>
                  <small>{errors.phoneNumber?.message}</small>
                </div>
              </div>
              <div className="col-6 mb-3 mt-2 text-start password">
                <label htmlFor="bvn" className="form-label">
                  Bank Verification Number(BVN)
                </label>
                <input
                  className={errors.bvn ? "form-control error" : "form-control"}
                  type={showBvn ? "text" : "password"}
                  placeholder="•••••••••••"
                  {...register("bvn")}
                />
                <img
                  src={Images.show}
                  alt="pass"
                  onClick={() => setShowBvn(!showBvn)}
                />
                <div>
                  <small>{errors.bvn?.message}</small>
                </div>
              </div>
              <div className="col-6 mb-3 mt-2 text-start password">
                <label htmlFor="nin" className="form-label">
                  National Identification Number (NIN)
                </label>
                <input
                  className={errors.nin ? "form-control error" : "form-control"}
                  type={showNin ? "text" : "password"}
                  placeholder="•••••••••••"
                  {...register("nin")}
                />
                <img
                  src={Images.show}
                  alt="pass"
                  onClick={() => setShowNin(!showNin)}
                />
                <div>
                  <small>{errors.nin?.message}</small>
                </div>
              </div>
              <div className="col-6 mb-3 text-start password">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  className={
                    errors.password ? "form-control error" : "form-control"
                  }
                  type={showPassword ? "text" : "password"}
                  placeholder="•••••••••••••"
                  {...register("password")}
                />
                <img
                  src={Images.show}
                  alt="pass"
                  onClick={() => setShowPassword(!showPassword)}
                />
                <div>
                  <small>{errors.password?.message}</small>
                </div>
              </div>
              <div className="col-6 mb-3 text-start password">
                <label htmlFor="confirm_password" className="form-label">
                  Confirm Password
                </label>
                <input
                  className={
                    errors.password_confirmation
                      ? "form-control error"
                      : "form-control"
                  }
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="•••••••••••••"
                  {...register("password_confirmation")}
                />
                <img
                  src={Images.show}
                  alt="pass"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
                <div>
                  <small>{errors.password_confirmation?.message}</small>
                </div>
              </div>
            </div>
            <div>
              <input
                className="me-3"
                type="checkbox"
                id="agree"
                {...register("agreement")}
              />
              Agree with our <a href="#">Terms of Services</a> and{" "}
              <a href="#">Privacy Policy</a>.
            </div>
            <small>{errors.agreement?.message}</small>
            <div>
              <button type="submit" className="btn">
                Get Started
              </button>
            </div>
            <p>
              Already have an account?{" "}
              <span>
                <a href="/signin">Login</a>
              </span>
            </p>
          </form>
        </div>
      </div>
      {showOTPModal && <OtpModal setShowOTPModal={setShowOTPModal} />}
    </div>
  );
}
