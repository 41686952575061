// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-range {
    background-color: #ffffff;
    display: flex;
    padding: 5px;
    border: 0.5px solid #48484833;
    border-radius: 5px;
}

.date-range .date {
    padding: 10px;
    font-size: 10px;
    position: relative;
}

.date-range .starts {
    border-right: 1px solid #d7d7d780;
}

.date-range .fa-calendar-days {
    padding-right: 3px;
}

.date-range .react-datepicker__input-container input {
    border: none;
    background-color: transparent;
    width: 50px;
    font-size: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/DateRange.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,YAAY;IACZ,6BAA6B;IAC7B,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,6BAA6B;IAC7B,WAAW;IACX,cAAc;AAClB","sourcesContent":[".date-range {\n    background-color: #ffffff;\n    display: flex;\n    padding: 5px;\n    border: 0.5px solid #48484833;\n    border-radius: 5px;\n}\n\n.date-range .date {\n    padding: 10px;\n    font-size: 10px;\n    position: relative;\n}\n\n.date-range .starts {\n    border-right: 1px solid #d7d7d780;\n}\n\n.date-range .fa-calendar-days {\n    padding-right: 3px;\n}\n\n.date-range .react-datepicker__input-container input {\n    border: none;\n    background-color: transparent;\n    width: 50px;\n    font-size: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
