import React from "react";

export default function TransactionDetails({ selectedRowData }) {
  const {
    reference,
    status,
    product,
    amount,
    channel,
    paymentMethod,
    provider,
    walletId,
    response,
    reversal,
    rrn,
    terminalID,
  } = selectedRowData;

  const formattedResponse = response ? JSON.stringify(response, null, 2) : null;

  //   console.log("trans", selectedRowData);

  return (
    <div>
      <h3>Transaction Details</h3>
      <div className="transaction-info">
        <div className="info-section">
          <div className="info-item ref">Reference: {reference}</div>
          <div className="info-item">
            Status:{" "}
            <span
              className={
                status === "successful"
                  ? "success"
                  : status === "failed"
                  ? "failed"
                  : "pending"
              }
            >
              {status}
            </span>
          </div>
          <div className="info-item">Product: {product}</div>
          <div className="info-item">Amount: {amount.toLocaleString()}</div>
          <div className="info-item">Channel: {channel}</div>
          <div className="info-item">Payment Method: {paymentMethod}</div>
          <div className="info-item">Provider: {provider}</div>
          <div className="info-item">Terminal ID: {terminalID}</div>
          <div className="info-item">Wallet ID: {walletId}</div>
          <div className="info-item">
            Reversal: {reversal ? "True" : "False"}
          </div>
          <div className="info-item">RRN: {rrn}</div>
        </div>
        <div className="trans-response">
          <p>Response:</p>
          <pre>{formattedResponse}</pre>
        </div>
      </div>
    </div>
  );
}
