import React, { useState, useEffect } from "react";
import "./MerchantView.css";
import Table from "../../../../../components/Table";
import GetData from "../../../../../api/GetData";
import AppData from "../../../../../config/appData.json";
import FormatDate from "../../../../../components/FormatDate";

export default function MerchantView() {
  const [merchants, setMerchants] = useState([]);
  const [startDate, setStartDate] = useState("2019-01-01");
  const [endDate, setEndDate] = useState(new Date());

  // const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  useEffect(() => {
    const fetchMerchants = async () => {
      try {
        const endpoint =
          AppData.BASE_URL +
          `merchants?startdate=${startDate}&enddate=${formattedEndDate}`;
        const response = await GetData(endpoint);
        // console.log("merchants", response, endpoint);
        setMerchants(response);
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    fetchMerchants();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "S/N",
        accessor: "",
      },
      {
        Header: "Merchant Name",
        accessor: "merchant_name",
      },
      {
        Header: "Merchant Id",
        accessor: "merchant_id",
      },
      {
        Header: "Merchant Account no",
        accessor: "merchant_account_nr",
      },
      {
        Header: "No of terminal",
        accessor: "terminals_count",
      },
      {
        Header: "Phone Numbers",
        accessor: "merchant_phone",
      },
      {
        Header: "Emails",
        accessor: "merchant_email",
      },
      {
        Header: "Date Created",
        accessor: "created_at",
      },
    ],
    []
  );

  function formatDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  return (
    <div className="merchant-view">
      <div className="row">
        <div className="merchant-count">
          <h3 className="heading">Current Merchant</h3>
          <p className="count">{merchants.data && merchants.data.length}</p>
        </div>
      </div>
      <div className="mt-1">
        <div className="table-responsive table-wrapper">
          {merchants.data && (
            <Table
              columns={columns}
              data={merchants.data}
              tableClass="table table-borderless table-striped table-hover fs-7 mb-0"
              enableRowSelection={false}
            />
          )}
        </div>
      </div>
    </div>
  );
}
