import React, { useState, useEffect } from "react";
import putData from "../../../api/PutData";
import AppData from "../../../config/appData.json";
import GetData from "../../../api/GetData";

const UserDetails = ({ heading, name }) => (
  <div className="col-3">
    <div>
      <h4>{heading}</h4>
      <p className="data-info">{name}</p>
    </div>
  </div>
);

export default function DocumentRequest({
  userId,
  requestId,
  setShowUpdateList,
  setRequestStatus,
  updatedData,
  setMessage,
  handleDeclineRequest,
}) {
  const [error, setError] = useState(null);
  const [user, setUser] = useState({});
  const requestData = { updateId: requestId };
  const {
    passport,
    idCard,
    utilityBill,
    guarantorsId,
    guarantorsPassport,
    cacDocument,
  } = updatedData;
  const isUserIncomplete =
    Object.keys(user).length === 0 && user.constructor === Object;

  const handleApproveDocument = async () => {
    const endpoint = AppData.VAS_URL + `controller/accept/document/${userId}`;

    try {
      const response = await putData(endpoint, requestData);
      if (response.status === "success") {
        setRequestStatus("success");
        setMessage(response.message);
        setTimeout(() => {
          setShowUpdateList(true);
        }, 3000);
      } else {
        setRequestStatus("failed");
        setMessage(response.data.message);
      }
    } catch (error) {
      console.log("error", error);
      setRequestStatus("failed");
      setMessage(error.response?.data?.message || "An error occurred");
    }
  };

  const handleApproveDocumentClick = () => {
    const endpoint = AppData.VAS_URL + `controller/accept/document/${userId}`;
    if (!isUserIncomplete) {
      handleApproveDocument(endpoint);
    } else {
      alert("User information is incomplete");
    }
  };

  const handleDeclineRequestClick = () => {
    const endpoint = AppData.VAS_URL + `controller/reject/update/${userId}`;
    if (!isUserIncomplete) {
      handleDeclineRequest(endpoint);
    } else {
      alert("User information is incomplete");
    }
  };

  const getUserProfile = async () => {
    try {
      const url = AppData.WALLET_URL + `admin/user/${userId}`;
      const { user, wallet } = await GetData(url);
      setUser(user);
      console.log("user", user);
    } catch (error) {
      console.error("Error Fetching user profile", error);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <>
      <div className="user-data">
        <div className="section-header">User Details</div>
        <div className="row">
          <UserDetails heading="Agent Type" name={user?.agentType} />
          <UserDetails heading="First name" name={user?.firstName} />
          <UserDetails heading="Last name" name={user?.lastName} />
          <UserDetails heading="Date of birth" name={user?.dateOfBirth} />
          <UserDetails heading="Company name" name={user?.companyName} />
          <UserDetails heading="State Of Origin" name={user?.stateOfOrigin} />
          <UserDetails heading="Local Govt Area" name={user?.localGovernment} />
          <UserDetails heading="Phone Number" name={user?.phoneNumber} />
          <UserDetails heading="Gender" name={user?.gender} />
          <UserDetails heading="Address" name={user?.address} />
        </div>
      </div>
      <div className="row uploaded-document pb-5 border-bottom">
        <div className="col-2 mt-3 p-2">
          <p className="mt-2">Passport</p>
          <img src={passport} alt="cac-document" />
        </div>
        <div className="col-4 mt-3 p-2">
          <p className="mt-2">ID Card</p>
          <img src={idCard} alt="cac-document" />
        </div>
        <div className="col-6 mt-3 p-2">
          <p className="mt-2">Utility Bill</p>
          <img className="ms-2 w-100" src={utilityBill} alt="cac-document" />
        </div>
        <div className="col-2 mt-3 p-2">
          <p className="mt-2">Guarantor's Passport</p>
          {user && <img src={guarantorsPassport} alt="cac-document" />}
        </div>
        <div className="col-4 mt-3 p-2">
          <p className="mt-2">Guarantor's ID Card</p>
          <img src={guarantorsId} alt="cac-document" />
        </div>
        <div className="col-6 mt-3 p-2">
          <p className="mt-2">CAC Document</p>
          <img className="ms-2 w-50" src={cacDocument} alt="cac-document" />
        </div>
      </div>
      <div className="actions">
        <button
          className="btn approve-btn"
          onClick={handleApproveDocumentClick}
        >
          Approve
        </button>
        <button className="btn decline-btn" onClick={handleDeclineRequestClick}>
          Decline
        </button>
      </div>
    </>
  );
}
