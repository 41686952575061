import React, { useState } from "react";
import "./ChangePassModal.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PasswordChecker from "../../components/PasswordChecker";
import AppData from "../../config/appData.json";
import postData from "../../api/PostData";
import handlePostApi from "../../api/HandlePostApi";

export default function ChangePassModal({ modalType, userEmail, closeModal }) {
  const email = userEmail;
  const [pwdChecker, setPwdChecker] = useState(false);
  const [msg, setMsg] = useState("");
  const [passChangeSuccess, setPassChangeSuccess] = useState(false);
  const [checks, setChecks] = useState({
    capsLetterCheck: false,
    smallLetterCheck: false,
    numberCheck: false,
    pwdLengthCheck: false,
    specialCharCheck: false,
  });

  yup.addMethod(yup.string, "stripEmptyString", function () {
    return this.transform((value) => (value === "" ? undefined : value));
  });

  const emailRegex = /[^]*@[^]*\..+/;
  const passwordRegex = /.*[!@#$%^&*(),.?":{}|<>]/;

  const passwordResetSchema = yup.object().shape({
    pin: yup.string().min(4).max(4).required("PIN is required!"),
    email: yup
      .string()
      .stripEmptyString()
      .default(email)
      .matches(emailRegex, "Invalid email format"),
    old_password: yup.string().required("Old password is required!"),
    password: yup
      .string()
      .min(6)
      .required()
      .matches(/[a-z]/, "Password must contain a lowercase letter")
      .matches(/[A-Z]/, "Password must contain an uppercase letter")
      .matches(/\d/, "Password must contain a number")
      .matches(passwordRegex, "Password must contain a special character"),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords don't match")
      .required("Password confirmation field cannot be empty!"),
  });

  const transactionPinResetSchema = yup.object().shape({
    pin: yup.string().required(),
    pin_confirmation: yup
      .string()
      .oneOf([yup.ref("pin"), null], "Pin don't match")
      .required("Pin confirmation field cannot be empty!"),
    resetPin: yup.string().min(4).max(4).required("Reset pin is required!"),
  });

  const schema =
    modalType === "passwordReset"
      ? passwordResetSchema
      : transactionPinResetSchema;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleKeyUp = (e) => {
    const { value } = e.target;
    const smallLetterCheck = /[a-z]/.test(value);
    const capsLetterCheck = /[A-Z]/.test(value);
    const numberCheck = /[0-9]/.test(value);
    const pwdLengthCheck = value.length >= 6;
    const specialCharCheck = /[!@#$%^&*(),.?":{}|<>']/.test(value);
    setChecks({
      capsLetterCheck,
      smallLetterCheck,
      numberCheck,
      pwdLengthCheck,
      specialCharCheck,
    });
  };

  const handleFormSubmit = async (requestData) => {
    if (modalType === "passwordReset") {
      try {
        const url = AppData.VAS_URL + "auth/new-password";
        await postData(url, requestData);
        setPassChangeSuccess(true);
      } catch (error) {
        console.log("Request error", error);
        setMsg(error.response.data.message);
      }
    } else {
      try {
        const url = AppData.VAS_URL + "auth/change-transaction-pin";
        await handlePostApi(url, requestData);
        setPassChangeSuccess(true);
      } catch (error) {
        console.log("Request error", error);
        setMsg(error.response.data.message);
      }
    }
  };

  return (
    <div className="modal change-pass-modal">
      {!passChangeSuccess ? (
        <div className="modal-content">
          <div className="modal-heading">
            {modalType === "passwordReset"
              ? "Change Password"
              : "Reset Transaction Pin"}
          </div>
          <div className="modal-body">
            <p>
              To continue, please enter the 4- digit reset pin sent to <br />
              <span>{userEmail}</span>
            </p>
            <p>
              Didn’t Receive any code? <span>Resend</span>
            </p>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              {msg && <p className="error">{msg}</p>}
              {modalType === "passwordReset" ? (
                <>
                  <div className="row mt-3 ps-3">
                    <label htmlFor="pin" className="col-4">
                      Reset Pin
                    </label>
                    <input className="col-6" type="text" {...register("pin")} />
                    <small className="error">{errors.pin?.message}</small>
                  </div>
                  <div className="row mt-3 ps-3">
                    <label htmlFor="old_password" className="col-4">
                      Old Password
                    </label>
                    <input
                      className="col-6"
                      type="password"
                      {...register("old_password")}
                    />
                    <small className="error">
                      {errors.old_password?.message}
                    </small>
                  </div>
                  <div className="row mt-3 ps-3">
                    <label htmlFor="password" className="col-4">
                      New Password
                    </label>
                    <input
                      className="col-6"
                      type="password"
                      onFocus={() => setPwdChecker(true)}
                      onBlur={() => setPwdChecker(false)}
                      onKeyUp={handleKeyUp}
                      {...register("password")}
                    />
                    <small className="error">{errors.password?.message}</small>
                  </div>
                  <div className="row mt-3 ps-3">
                    <label
                      htmlFor="password_conirmation"
                      className="col-4 pass-modal-label"
                    >
                      Confirm Password
                    </label>
                    <input
                      className="col-6"
                      type="password"
                      {...register("password_confirmation")}
                    />
                    <small className="error">
                      {errors.password_confirmation?.message}
                    </small>
                  </div>
                  {pwdChecker && <PasswordChecker checks={checks} />}
                </>
              ) : (
                <>
                  <div className="row mt-3 ps-3">
                    <label htmlFor="reset-pin" className="col-3">
                      Reset Pin
                    </label>
                    <input
                      className="col-6"
                      type="text"
                      {...register("resetPin")}
                    />
                    <small className="error">{errors.resetPin?.message}</small>
                  </div>
                  <div className="row mt-3 ps-3">
                    <label htmlFor="new_pin" className="col-3">
                      Pin
                    </label>
                    <input
                      className="col-6"
                      type="password"
                      {...register("pin")}
                    />
                    <small className="error">{errors.pin?.message}</small>
                  </div>
                  <div className="row mt-3 ps-3">
                    <label htmlFor="pin_confirmation" className="col-3">
                      Confirm Pin
                    </label>
                    <input
                      className="col-6"
                      type="password"
                      {...register("pin_confirmation")}
                    />
                    <small className="error">
                      {errors.pin_confirmation?.message}
                    </small>
                  </div>
                </>
              )}
              <div className="form-actions">
                <button className="cancel-btn" onClick={closeModal}>
                  Cancel
                </button>
                <button className="continue" type="submit">
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <PassSuccessModal closeModal={closeModal} modalType={modalType} />
      )}
    </div>
  );
}

function PassSuccessModal({ closeModal, modalType }) {

  console.log("mt", modalType)
  return (
    <div className="modal-content">
      <div className="modal-heading">
        {modalType === "passwordReset" ? "Password" : "Pin"} Change Completed
      </div>
      <div className="modal-body text-center">
        <p>Congratulations</p>
        <div className="d-flex justify-content-center align-items-center">
          <div className="success-circle">
            <i className="fa-solid fa-check"></i>
          </div>
        </div>
        <p>
          {modalType === "passwordReset" ? "Password" : "Pin"} changed
          successfully
        </p>
        <div className="form-actions d-flex justify-content-center">
          <button className="cancel-btn" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
