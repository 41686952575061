import React, { useState, useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/navbar/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import { UserContext } from "../../context/UserContext";

export default function ReportingDashbord() {
  const { userData } = useContext(UserContext);
  const [heading, setHeading] = useState("Reporting");

  const isAdmin =
    userData?.admin?.adminType === "bank" ||
    userData?.adminType === "bank";

  return (
    <div>
      <Header userData={userData} heading={heading} />
      <div className="container-fluid">
        <div className="row">
          {!isAdmin && <Sidebar setHeading={setHeading}/>}
          <Outlet />
        </div>
      </div>
    </div>
  );
}
