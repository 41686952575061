import React from "react";
import "./TerminalUpTime.css";
import PieChart from "../../../components/charts/PieChart";
import Images from "../../../assets/images/Images";

const TerminalTimeSummary = ({ title, yearly, hourly, backgroundColor }) => {
  return (
    <div className="terminal-time-summary" style={{ backgroundColor }}>
      <h6>{title}</h6>
      <div className="d-flex">
        <div>
          <p className="yearly">{yearly}</p>
          <p className="hourly">{hourly}</p>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default function TerminalUpTime() {
  return (
    <section className="terminal-up-time">
      <h4 className="section-heading">Terminal Up Time</h4>
      <div className="row mt-4 mb-4">
        <div className="col-4">
          <TerminalTimeSummary
            title="All Time Running Time"
            yearly="2Yrs"
            hourly="344 Hours"
            backgroundColor="#4534C2"
          />
        </div>
        <div className="col-4">
          <TerminalTimeSummary
            title="Successful Running Time"
            yearly="5674"
            hourly="Hours"
            backgroundColor="#80DA8F"
          />
        </div>
        <div className="col-4">
          <TerminalTimeSummary
            title="Idle Running Time"
            yearly="8765"
            hourly="Hours"
            backgroundColor="#DFBE40"
          />
        </div>
      </div>
      <div className="d-flex">
        <div className="mt-3">
          {" "}
          <PieChart />
        </div>
        <div className="performance-indicators">
          <div className="row">
            <div className="col-4">
              <div className="performance">
                <img src={Images.onlineTerm} alt="" />
                <p className="count">23M</p>
                <p className="p-label">Active Terminals</p>
              </div>
            </div>
            <div className="col-4">
              <div className="performance">
                <img src={Images.onlineTerm} alt="" />
                <p className="count">23M</p>
                <p className="p-label">Active Terminals</p>
              </div>
            </div>
            <div className="col-4">
              <div className="performance">
                <img src={Images.onlineTerm} alt="" />
                <p className="count">23M</p>
                <p className="p-label">Active Terminals</p>
              </div>
            </div>
            <div className="col-4">
              <div className="performance">
                <img src={Images.onlineTerm} alt="" />
                <p className="count">23M</p>
                <p className="p-label">Active Terminals</p>
              </div>
            </div>
            <div className="col-4">
              <div className="performance">
                <img src={Images.onlineTerm} alt="" />
                <p className="count">23M</p>
                <p className="p-label">Active Terminals</p>
              </div>
            </div>
            <div className="col-4">
              <div className="performance">
                <img src={Images.onlineTerm} alt="" />
                <p className="count">23M</p>
                <p className="p-label">Active Terminals</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
