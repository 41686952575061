import React from "react";
import Images from "../../assets/images/Images";

export default function TransactionFailed({amount, receiverName}) {
  return (
    <div className="trans-success">
      <div className="modal">
        <div className="modal-content">
          <h4 className="failed">Try again!</h4>
          <img src={Images.failedImg} alt="" />
          <p className="desc">Sorry! Something Happened We Couldn&apos;t Deliver</p>
          <p className="amount">{amount}</p>
          <p className="name">{receiverName}</p>
          <a href="/user">
            <button className="btn">Back To Homepage</button>
          </a>
        </div>
      </div>
    </div>
  );
}
