import React, { useState, useEffect } from "react";
import "./Transactions.css";
import { useNavigate } from "react-router-dom";
import Images from "../../../assets/images/Images";
import Table from "../../../components/Table";
import AppData from "../../../config/appData.json";
import HandlePostApi from "../../../api/HandlePostApi";
import imageURL from "../../../imageUrls";
import Pagination from "../../../components/Pagination";
import TransactionFilter from "./TransactionFilter";
import Modal from "../../../components/modal/Modal";
import TransactionDetails from "./TransactionDetails";
import SimpleLoader from "../../../components/spinner/SimpleLoader";

// Sub-component for Summary Card
const SummaryCard = ({ icon, amount, totalCount, title }) => {
  return (
    <div className="col-3">
      <div className="card-summary">
        <div className="summary-icon">
          <img src={icon} alt="" />
        </div>
        <div className="data">
          {amount ? (
            <p className="amount">
              ₦<span>{amount}</span>
            </p>
          ) : (
            <SimpleLoader />
          )}
          <p>
            {totalCount} {title}
          </p>
        </div>
      </div>
    </div>
  );
};

export default function Transactions() {
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(true);
  const [summary, setSummary] = useState({});
  const [transactionHistory, setTransactionHistory] = useState({});
  const [msg, setMsg] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedLimit, setSelectedLimit] = useState("50");
  const [selectedAccountType, setSelectedAccountType] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [selectedChannel, setSelectedChannel] = useState("");
  const [walletId, setWalletId] = useState("");
  const [reference, setReference] = useState("");

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [pageNum, setPageNum] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});

  const openModal = (rowData) => {
    setSelectedRowData(rowData);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const requestData = {
    page: pageNum,
    startDate: startDate,
    endDate: endDate,
    limit: selectedLimit,
    status: selectedStatus,
    walletId: walletId,
    reference: reference,
    product: selectedProduct,
    account: selectedAccountType,
    channel: selectedChannel,
    provider: "",
  };

  // Columns for the table
  const columns = React.useMemo(
    () => [
      {
        Header: "S/N",
        id: "row",
        Cell: (row) => {
          const PageStart = (pageNum - 1) * selectedLimit + 1;
          return <div>{row.row.index + PageStart}</div>;
        },
      },
      {
        Header: "Product",
        accessor: (product) => (
          <div className="select-icon">
            {product.product}{" "}
            <img
              src={imageURL[product.product]}
              className="service-logo"
              alt="product"
            />
          </div>
        ),
      },
      {
        Header: "Agents Name",
        accessor: "userId.firstName",
      },
      {
        Header: "Wallet Id",
        accessor: "walletId",
      },
      {
        Header: "Terminal Id",
        accessor: (terminalId) => (
          <div>{terminalId.terminalId ? terminalId.terminalId : "N/A"}</div>
        ),
      },
      {
        Header: "Reference",
        accessor: "reference",
      },
      {
        Header: "Channel",
        accessor: (channel) => (
          <div className="select-icon">
            {channel.channel}{" "}
            <img
              src={imageURL[channel.channel]}
              className="service-logo"
              alt="service"
            />
          </div>
        ),
      },
      // {
      //   Header: "RRN",
      //   accessor: (rrn) => <>{rrn.rrn ? rrn.rrn : "N/A"}</>,
      // },
      {
        Header: "Amount",
        accessor: (amount) => (
          <>
            {" "}
            N{amount.amount.toLocaleString()}
            <div
              style={{
                color: "#AAAAAA",
                textTransform: "capitalize",
              }}
            >
              {amount.paymentMethod}
            </div>
          </>
        ),
      },
      {
        Header: "Status",
        accessor: (status) => (
          <div
            style={{
              color:
                status.status === "successful"
                  ? "#106745"
                  : status.status === "failed"
                  ? "#DE0000"
                  : "#F1B207",
            }}
          >
            {status.status}
          </div>
        ),
      },
      {
        Header: "Date & Time",
        accessor: (date) => {
          let dDate = date.createdAt;
          const formatDate = new Date(dDate);
          return <div> {formatDate.toLocaleString()}</div>;
        },
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <button className="btn" onClick={() => openModal(row.original)}>
            VIEW
          </button>
        ),
      },
    ],
    [pageNum]
  );

  // Function to handle API errors
  const handleApiError = (error) => {
    console.error("API Error:", error);
    if (error.response && error.response.data) {
      const errorMessage = error.response.data.message;
      if (errorMessage === "Expired Session, Please Login Again") {
        navigate("/signin");
      } else {
        setMsg(errorMessage);
      }
    } else {
      setMsg("Something went wrong. Please try again later.");
    }
  };

  // Function to fetch data from the API
  const fetchData = async (data) => {
    try {
      const url = AppData.WALLET_URL + "admin/transaction-history";

      setLoadingData(true);

      const response = await HandlePostApi(url, data);

      setTransactionHistory(response.transactions);
      setSummary(response.summary);
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoadingData(false);
    }
  };

  // Function to handle the filter button click
  const handleFilter = (e) => {
    e.preventDefault();
    fetchData(requestData);
  };

  // Function to handle refresh
  const handleRefresh = () => {
    const data = {
      page: 1,
      startDate: new Date(),
      endDate: new Date(),
      limit: "",
      status: "",
      walletId: "",
      reference: "",
      product: "",
      account: "",
      channel: "",
      provider: "",
    };
    // Reset all filter state variables to their initial values
    setSelectedStatus("");
    setSelectedProduct("");
    setSelectedLimit("50");
    setSelectedAccountType("");
    setSelectedPaymentMethod("");
    setSelectedChannel("");
    setWalletId("");
    setReference("");

    // Reset date range state variables if needed
    setStartDate(new Date());
    setEndDate(new Date());

    // Reset pagination page number to 1
    setPageNum(1);

    // Fetch data with reset filters
    fetchData(data);
  };

  useEffect(() => {
    fetchData(requestData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, selectedLimit]);

  return (
    <div className="mt-3">
      {/* Summary Cards */}
      <div className="row">
        <SummaryCard
          icon={Images.allTrans}
          amount={summary?.total?.toLocaleString()}
          totalCount={summary?.totalCount?.toLocaleString()}
          title="Total Transactions"
        />
        <SummaryCard
          icon={Images.pendingTrans}
          amount={summary?.pending?.toLocaleString()}
          totalCount={summary?.pendingCount?.toLocaleString()}
          title="Pending Transactions"
        />
        <SummaryCard
          icon={Images.successfulTrans}
          amount={summary?.successful?.toLocaleString()}
          totalCount={summary?.successfulCount?.toLocaleString()}
          title="Successful Transactions"
        />
        <SummaryCard
          icon={Images.failedTrans}
          amount={summary?.failed?.toLocaleString()}
          totalCount={summary?.failedCount?.toLocaleString()}
          title="Failed Transactions"
        />
      </div>

      {/* Search/Filter Section */}
      <div className="row mt-2">
        <TransactionFilter
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          selectedLimit={selectedLimit}
          setSelectedLimit={setSelectedLimit}
          selectedAccountType={selectedAccountType}
          setSelectedAccountType={setSelectedAccountType}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          selectedChannel={selectedChannel}
          setSelectedChannel={setSelectedChannel}
          walletId={walletId}
          setWalletId={setWalletId}
          reference={reference}
          setReference={setReference}
          handleFilter={handleFilter}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          handleRefresh={handleRefresh}
          docs={transactionHistory.docs || []}
        />
      </div>
      {/* Table */}
      <div className="vas-table mb-3 pb-3">
        {loadingData ? (
          <SimpleLoader />
        ) : (
          <Table
            columns={columns}
            data={transactionHistory.docs || []}
            enableRowSelection={false}
            tableClass={"table table-borderless table-striped mb-0"}
          />
        )}
        {transactionHistory.totalPages > 1 && (
          <Pagination
            prevPage={transactionHistory.prevPage}
            nextPage={transactionHistory.nextPage}
            totalPages={transactionHistory.totalPages}
            hasNextPage={transactionHistory.hasNextPage}
            hasPrevPage={transactionHistory.hasPrevPage}
            setPageNum={setPageNum}
          />
        )}
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          content={<TransactionDetails selectedRowData={selectedRowData} />}
        />
      )}
    </div>
  );
}
