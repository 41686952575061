// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.description {
    background-color: transparent;
    color: #ffffff;
    padding: 50px 85px 35px;
}

.description h1 {
    font-size: 30px;
    margin-bottom: 40px;
}

.description .options {
    padding: 30px 0px 30px 20px;
    border-left: 3px solid #888484;
}

.description .option {
    margin-bottom: 70px;
}

.description .option h2 {
    color: #888484;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}

.description .active {
    color: #ffffff !important;
}

.description .option p {
    font-size: 14px;
    color: #808080;
}

.description .screenshot img{
    height: 350px;
    width: 650px;
}

.description .two {
    margin-top: 100px;
}

.description .three {
    margin-top: 140px;
}`, "",{"version":3,"sources":["webpack://./src/view/landing/Description.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,cAAc;IACd,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;IAC3B,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".description {\n    background-color: transparent;\n    color: #ffffff;\n    padding: 50px 85px 35px;\n}\n\n.description h1 {\n    font-size: 30px;\n    margin-bottom: 40px;\n}\n\n.description .options {\n    padding: 30px 0px 30px 20px;\n    border-left: 3px solid #888484;\n}\n\n.description .option {\n    margin-bottom: 70px;\n}\n\n.description .option h2 {\n    color: #888484;\n    font-size: 18px;\n    font-weight: 600;\n    cursor: pointer;\n}\n\n.description .active {\n    color: #ffffff !important;\n}\n\n.description .option p {\n    font-size: 14px;\n    color: #808080;\n}\n\n.description .screenshot img{\n    height: 350px;\n    width: 650px;\n}\n\n.description .two {\n    margin-top: 100px;\n}\n\n.description .three {\n    margin-top: 140px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
