// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settlement-report {
    position: relative;
}

.settlement-report .params {
    display: flex;
}

.settlement-report .search-params {
    margin-right: 10px;
}

.settlement-report .params {
    position: absolute;
    right: -30px;
    top: 20px;
}

.settlement-report .params input {
    border: 0.5px solid #016AA7;
    border-radius: 4px;
    opacity: 1;
    font-size: 10px;
    margin-top: 20px;
    padding: 13px;
    width: 240px;
    margin-right: 15px;
}

.pagination .current-page {
    background-color: #F2F2F2;
    border: 1px solid #D4D9DE;
    border-radius: 4px;
    padding: 10px 15px;
    margin-right: 15px;
}

.pagination .page-scroll {
    margin-right: 15px;
    padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/view/reporting-dashboard/card-transactions/settlement/settlement-report/Report.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB","sourcesContent":[".settlement-report {\n    position: relative;\n}\n\n.settlement-report .params {\n    display: flex;\n}\n\n.settlement-report .search-params {\n    margin-right: 10px;\n}\n\n.settlement-report .params {\n    position: absolute;\n    right: -30px;\n    top: 20px;\n}\n\n.settlement-report .params input {\n    border: 0.5px solid #016AA7;\n    border-radius: 4px;\n    opacity: 1;\n    font-size: 10px;\n    margin-top: 20px;\n    padding: 13px;\n    width: 240px;\n    margin-right: 15px;\n}\n\n.pagination .current-page {\n    background-color: #F2F2F2;\n    border: 1px solid #D4D9DE;\n    border-radius: 4px;\n    padding: 10px 15px;\n    margin-right: 15px;\n}\n\n.pagination .page-scroll {\n    margin-right: 15px;\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
