import React from "react";
import "./AddMerchant.css";
import TabView from "../../../../components/TabView";
import NewMerchantRequest from "./NewMerchantRequest";

export default function AddMerchant({ setAddMerchant }) {
  const handleExit = () => {
    setAddMerchant(false);
  };
  return (
    <div className="add-merchant">
      <button className="exit-btn" onClick={handleExit}>
        <i className="fa-solid fa-arrow-left"></i>Back
      </button>
      <div>
        <TabView
          tabs={[
            {
              name: "New Merchant",
              content: <NewMerchantRequest />,
            },
            {
              name: "Existing Merchant",
              content: "",
              // content: <MerchantOnboard />,
            },
          ]}
        />
      </div>
    </div>
  );
}
